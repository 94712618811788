define('postedin/controllers/admin/settings', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, {
    session: Ember.inject.service(),
    actions: {
      resetSetting: function resetSetting(setting) {
        setting.set('updatedValue', setting.get('value'));
      },
      updateSetting: function updateSetting(setting, updatedValue) {
        var _this = this;

        this.uiPromise(setting.update(updatedValue), 'updateSetting', setting).then(function () {
          _this.get('session').loadSessionData();
        });
      }
    }
  });
});