define('postedin/controllers/international-payments/show', ['exports', 'postedin/mixins/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_utils.default, {
    queryParams: ['search', 'sort', 'descending', 'status'],
    search: '',
    sort: '',
    status: 'all',
    descending: 0,
    rejectionModal: false,
    rejectionReason: null,
    currentInvoice: null,
    selectedReason: null,
    isSending: false,
    meta: Ember.computed('sort', 'descending', 'search', function () {
      var sort = {};
      sort[this.get('sort')] = this.get('descending') === 1 ? 'descending' : 'ascending';

      return {
        sort: sort
      };
    }),
    invoicesTotal: Ember.computed('invoiceSet', function () {
      return this.get('invoiceSet').reduce(function (carry, current) {
        return carry + current.total;
      }, 0);
    }),
    invoicesAcceptedCount: Ember.computed('model.payment.invoices.@each.status', function () {
      return this.get('model.payment.invoices').filter(function (invoice) {
        return invoice.status === 'invoice_received';
      }).length;
    }),
    invoicesNotAcceptedCount: Ember.computed('invoicesAcceptedCount', function () {
      return this.get('model.payment.invoices').length - this.get('invoicesAcceptedCount');
    }),
    invoicesAcceptedTotal: Ember.computed('model.payment.invoices.@each.status', function () {
      return this.get('model.payment.invoices').filter(function (invoice) {
        return invoice.status === 'invoice_received';
      }).reduce(function (carry, current) {
        return carry + current.total;
      }, 0);
    }),
    invoicesNotAcceptedTotal: Ember.computed('model.payment.invoices.@each.status', function () {
      return this.get('model.payment.invoices').filter(function (invoice) {
        return invoice.status !== 'invoice_received';
      }).reduce(function (carry, current) {
        return carry + current.total;
      }, 0);
    }),
    sortedInvoices: Ember.computed('sort', 'descending', 'invoiceSet', 'search', function () {
      var sort = this.get('sort');
      var order = this.get('descending') === 1 ? -1 : 1;
      var search = this.get('search');
      var invoices = this.get('invoiceSet');

      if (search) {
        invoices = this.filterInvoices(invoices, search);
      }

      switch (sort) {
        case 'id':
          return this.sortById(invoices, order);
        case 'name':
          return this.sortByName(invoices, order);
        case 'email':
          return this.sortByEmail(invoices, order);
      }

      return invoices;
    }),
    statuses: Ember.computed('model.payment.invoices.@each.status', function () {
      var invoices = this.get('model.payment.invoices');
      var statuses = {
        all: {
          active: true,
          invoices: invoices,
          name: 'All'
        }
      };

      invoices.forEach(function (invoice) {
        if (!statuses[invoice.status]) {
          statuses[invoice.status] = {
            name: invoice.status.capitalize().replace(/_/g, ' '),
            active: false,
            invoices: []
          };
        }

        statuses[invoice.status].invoices.push(invoice);
      });

      if (!this.get('status') || !statuses[this.get('status')]) {
        this.set('status', 'all');
      }

      this.set('invoiceSet', statuses[this.get('status')].invoices);
      return statuses;
    }),
    filterInvoices: function filterInvoices(invoices, query) {
      var utils = this.get('utils');
      query = query.toLowerCase();

      return invoices.filter(function (invoice) {
        if (utils.cleanString(invoice.betaUser.name).includes(query)) {
          return true;
        }

        if (utils.cleanString(invoice.betaUser.email).includes(query)) {
          return true;
        }

        if (utils.cleanString(invoice.betaUser.paypalAccount.country).includes(query)) {
          return true;
        }

        if (invoice.id === query) {
          return true;
        }

        return false;
      });
    },
    sortByEmail: function sortByEmail(invoices, order) {
      return invoices.sort(function (a, b) {
        return (a.betaUser.email > b.betaUser.email ? 1 : -1) * order;
      });
    },
    sortByName: function sortByName(invoices, order) {
      return invoices.sort(function (a, b) {
        return (a.betaUser.name > b.betaUser.name ? 1 : -1) * order;
      });
    },
    sortById: function sortById(invoices, order) {
      return invoices.sort(function (a, b) {
        return (a.id - b.id) * order;
      });
    },

    actions: {
      setCurrentStatus: function setCurrentStatus(key, value) {
        this.set('status', key);
        this.set('invoiceSet', value.invoices);
      },
      exportInvoices: function exportInvoices() {
        var invoices = this.get('statuses.all.invoices');
        var button = document.createElement('a');
        var output = '';
        var url = 'data:text/plain;charset=utf-8,';

        invoices.forEach(function (invoice) {
          if (!invoice.betaUser.paypalAccount) {
            // TODO: bugsnag notify
            return;
          }

          var line = [invoice.id, invoice.invoiceNumber, invoice.betaUser.name, invoice.betaUser.paypalAccount.country, invoice.betaUser.email, invoice.betaUser.paypalAccount.paypalEmail, (invoice.total / 100).toFixed(2), 'usd', invoice.status].join(',');
          output += line + '\n';
        });

        url += encodeURIComponent(output);

        button.setAttribute('href', url);
        button.setAttribute('download', 'paypal-mass-payment-' + this.get('model.payment.name').slugify() + '.csv');

        document.body.appendChild(button);
        button.click();
        document.body.removeChild(button);
      },
      toggleInvoicePaid: function toggleInvoicePaid(invoice) {
        invoice.toggleProperty('paidDialog');
      },
      setInvoicePaid: function setInvoicePaid(invoice) {
        invoice.set('settingPaid', true);

        var paypalTransactionId = invoice.get('paypalTransactionId');
        invoice.setAsPaid(paypalTransactionId).then(function () {
          invoice.set('settingPaid', false);
          invoice.set('paidDialog', false);
        });
      },
      togglePaypalDetails: function togglePaypalDetails(invoice) {
        invoice.toggleProperty('paypalDetailsDialog');
      },
      toggleInvoiceReject: function toggleInvoiceReject(invoice) {
        invoice.toggleProperty('rejectDialog');
      },
      rejectInvoice: function rejectInvoice(invoice) {
        var requiredError = true;
        invoice.get('rejectReasons').forEach(function (reason) {
          if (reason.checked) {
            requiredError = false;
          }
        });

        invoice.set('reasonsRequired', requiredError);
        if (requiredError) {
          return;
        }

        invoice.set('rejecting', true);
        var reason = '\n        <p>Al procesar tu pago se detectaron los siguientes problemas:</p>\n        <ul>';
        invoice.get('rejectReasons').forEach(function (r) {
          if (r.checked) {
            reason += '\n            <li>' + r.phrase + '</li>';
          }
        });
        reason += '\n        </ul>';

        if (invoice.get('rejectComment')) {
          // Replace new lines for <br /> tags
          var comment = invoice.rejectComment.replace(/(?:\r\n|\r|\n)/g, '<br />');

          reason += '\n        <p></p>\n        <p><strong>Comentarios: </strong><br /> ' + comment + '</p>';
        }

        invoice.reject(reason).then(function () {
          invoice.set('rejecting', false);
          invoice.set('rejectDialog', false);
          invoice.set('rejectComment', '');
          // FIXME: following line not working, meant to uncheck for if you reopen the reject dialog,
          // not a big deal though
          // invoice.get('rejectReasons').forEach(reason => reason.checked = false);
        });
      },
      toggleInvoiceRejectionDetails: function toggleInvoiceRejectionDetails(invoice) {
        invoice.toggleProperty('rejectionDetailsDialog');
      },
      search: function search(_search) {
        var params = {
          search: _search
        };
        this.transitionToRoute('international-payments.show', { queryParams: params });
      }
    }
  });
});