define('postedin/services/content-articles', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'content-article',
    revisions: function revisions(contentArticle) {
      var _this = this;

      return this.request('content-articles/' + contentArticle.id + '/revisions').then(function (response) {
        response.data.forEach(function (record) {
          if (record.reviews) {
            record.reviews.forEach(function (r) {
              return r._emberModel = 'content-review';
            });
          }
        });

        return _this.pushResponse(response, 'content-article-revision');
      });
    },
    createNewRevision: function createNewRevision(contentArticle, title, body, metaDescription, uploads, attachments) {
      var _this2 = this;

      var data = {
        title: title,
        body: body,
        metaDescription: metaDescription,
        uploads: uploads,
        attachments: attachments
      };

      return this.post('content-articles/' + contentArticle.id + '/revisions', data).then(function (record) {
        return _this2.push(record, 'content-article-revision');
      });
    }
  });
});