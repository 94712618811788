define('postedin/controllers/admin/categories/edit', ['exports', 'postedin/mixins/validator', 'postedin/mixins/ui-promise'], function (exports, _validator, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validator.default, _uiPromise.default, {
    categories: Ember.inject.service(),
    validate: function validate(updates) {
      return this.validatorCheck(updates, {
        name: 'required',
        betaCategoryId: 'integer'
      });
    },

    actions: {
      betaCategoryDidChange: function betaCategoryDidChange(betaCategory) {
        this.set('updates.betaCategoryId', betaCategory.id);
        this.set('selectedBetaCategory', betaCategory);
      },
      updateCategory: function updateCategory(model, updates) {
        if (this.validate(updates)) {
          var promise = this.get('model').update(updates);

          this.uiPromise(promise, 'updating');
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});