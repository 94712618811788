define('postedin/controllers/suggestions/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      projectId: 'project',
      duplicateId: 'duplicate'
    },
    project: null,
    source: null,
    projectId: 0,
    duplicateId: 0,
    keywords: [],
    projectDidChange: Ember.observer('project', function () {
      var _this = this;

      this.set('projectId', this.get('project.id'));
      this.set('keywords', []);
      this.get('project').request('profile').then(function (profile) {
        _this.set('keywords', profile.get('keywords'));
      });
    }),
    actions: {
      suggestionSaved: function suggestionSaved(suggestion, redirect) {
        if (redirect) {
          return this.transitionToRoute('suggestions.show', suggestion.id);
        }
      }
    }
  });
});