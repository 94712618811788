define('postedin/services/ajax', ['exports', 'moment', 'ember-ajax/services/ajax'], function (exports, _moment, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),
    localState: Ember.inject.service(),
    namespace: '/api',
    headers: Ember.computed({
      get: function get() {
        var headers = {};
        if (this.get('session').authorize) {
          this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
            headers[headerName] = headerValue;
          });
        }

        if (this.get('localState.impersonating')) {
          headers.impersonating = this.get('localState.impersonating');
        }

        headers['Time-Zone'] = _moment.default.tz.guess();

        return headers;
      }
    }).volatile(),
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 401) {
        this.get('session').invalidate();
      }

      if (this.get('session.user') && headers.impersonating) {
        this.get('localState').set('impersonating', headers.impersonating);
      }

      return this._super(status, headers, payload, requestData);
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      var data = false;

      if ((typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object') {
        data = payload;
      } else {
        try {
          data = JSON.parse(payload);
        } catch (e) {
          //
        }
      }

      var errors = false;
      if (data && data.errors) {
        errors = data.errors;
      }

      return {
        status: status,
        raw: payload,
        data: data,
        errors: errors,
        headers: headers,
        hasError: function hasError(key) {
          return errors && errors[key];
        }
      };
    }
  });
});