define('postedin/controllers/invite', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      switchToRegister: function switchToRegister() {
        this.set('showRegister', true);
      },
      switchToLogin: function switchToLogin() {
        this.set('showRegister', false);
      }
    }
  });
});