define('postedin/components/star-rating', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['star-rating'],
    value: 0,
    max: 5,
    stars: Ember.computed('value', 'max', function () {
      var value = this.get('value');
      var stars = [];
      for (var i = 0; i < this.get('max'); i++) {
        stars.push(value > i);
      }

      return stars;
    })
  });
});