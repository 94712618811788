define('postedin/components/dropdown-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'dropdown-container',
    toggle: true,
    toggleIcon: 'icon-more2',
    toggleText: '',
    showMenu: false,
    init: function init() {
      this._super();
      // click event name that is namespaced to our component instance, so multiple dropdowns do not interfere
      // with each other
      this.clickEventName = 'click.' + this.get('elementId');
    },
    willDestroyElement: function willDestroyElement() {
      this._super();
      Ember.$(document).off(this.clickEventName);
    },

    handleClickEvents: Ember.observer('showMenu', function () {
      if (this.get('showMenu')) {
        Ember.$(document).on(this.clickEventName, Ember.run.bind(this, this.closeOnClickHandler));
      } else {
        Ember.$(document).off(this.clickEventName);
      }
    }),
    closeOnClickHandler: function closeOnClickHandler(e) {
      var $target = Ember.$(e.target);

      if (this.get('isDestroyed')) {
        return;
      }

      if ($target.closest(this.$()).length > 0) {
        return;
      }

      this.toggleMenu(this, false);
    },
    toggleMenu: function toggleMenu() {
      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this;
      var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (force !== null) {
        context.set('showMenu', force);
        return;
      }

      context.toggleProperty('showMenu');
    },
    open: function open() {
      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this;

      return this.toggleMenu(context, true);
    },
    close: function close() {
      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this;

      return this.toggleMenu(context, false);
    },

    actions: {
      toggleMenu: function toggleMenu() {
        var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        this.toggleMenu(this, force);
      }
    }
  });
});