define('postedin/models/issue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    issues: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    comment: function comment(body, attachments) {
      return this.get('issues').comment(this, body, attachments);
    },
    open: function open(comment, attachments) {
      return this.get('issues').open(this, comment, attachments);
    },
    close: function close(comment, attachments) {
      return this.get('issues').close(this, comment, attachments);
    },
    transitionToRoute: function transitionToRoute() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.get('router').transitionTo('issue-permalink', [this.id], queryParams);
    },

    // alias for post-message component
    body: Ember.computed.alias('description')
  });
});