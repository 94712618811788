define('postedin/models/content-request', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apiUrl: '/content-requests',
    router: Ember.inject.service('-routing'),
    contentRequests: Ember.inject.service(),
    tones: ['funny', 'casual', 'neutral', 'formal', 'technical'],
    toneIndex: Ember.computed('tone', function () {
      var tones = this.get('tones');

      var index = 0;
      for (var tone in tones) {
        if (!tones.hasOwnProperty(tone)) {
          continue;
        }

        if (tone === this.get('tone')) {
          return index;
        }

        index++;
      }

      return 2;
    }),
    toneName: Ember.computed('toneIndex', function () {
      return this.get('tones')[this.get('toneIndex')] || '';
    }),
    hasMission: Ember.computed.bool('betaMissionUrl'),
    transitionToRoute: function transitionToRoute() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.get('router').transitionTo('content-requests.show', [this.id], queryParams);
    },
    transitionToIssueRoute: function transitionToIssueRoute(issue, queryParams) {
      this.get('router').transitionTo('content-requests.show.issues.show', [this.id, issue.id], queryParams);
    },
    reload: function reload() {
      return this.get('contentRequests').findById(this.id);
    },
    update: function update(updates) {
      return this.get('contentRequests').update(this, updates);
    },
    delete: function _delete() {
      return this.get('contentRequests').remove(this);
    },
    addCustomExtra: function addCustomExtra(credits, description) {
      return this.get('contentRequests').addCustomExtra(this, credits, description);
    },
    adminUpdate: function adminUpdate(updates) {
      return this.get('contentRequests').adminUpdate(this, updates);
    },
    changeStatus: function changeStatus(status) {
      return this.get('contentRequests').changeStatus(this, status);
    },
    unlinkMission: function unlinkMission() {
      return this.get('contentRequests').unlinkMission(this);
    },
    updateOption: function updateOption(option, value) {
      var options = Ember.copy(this.get('options'));
      options[option] = value;

      return this.customUpdate('admin', { options: options });
    },
    checkout: function checkout() {
      return this.get('contentRequests').checkout(this);
    },
    comment: function comment(_comment, attachments) {
      return this.get('contentRequests').comment(this, _comment, attachments);
    },
    submitIssue: function submitIssue(title, body, attachments) {
      return this.get('contentRequests').submitIssue(this, title, body, attachments);
    },
    loadCounts: function loadCounts() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('contentRequests').loadCounts(params);
    },
    loadComments: function loadComments() {
      return this.get('contentRequests').loadComments(this);
    },
    loadReviews: function loadReviews() {
      return this.get('contentRequests').loadReviews(this);
    },
    loadOpenIssues: function loadOpenIssues() {
      return this.get('contentRequests').loadIssues(this, 'open');
    },
    loadClosedIssues: function loadClosedIssues() {
      return this.get('contentRequests').loadIssues(this, 'closed');
    },
    loadTransactions: function loadTransactions(page, perPage) {
      return this.get('contentRequests').loadTransactions(this, page, perPage);
    },
    loadAuditLog: function loadAuditLog(page) {
      return this.get('contentRequests').loadAuditLog(this, page);
    },
    loadNextDeadline: function loadNextDeadline() {
      return this.get('contentRequests').loadNextDeadline(this);
    },
    updateLabel: function updateLabel(projectLabel) {
      return this.get('contentRequests').updateLabel(this, projectLabel);
    },
    deleteLabel: function deleteLabel() {
      return this.get('contentRequests').deleteLabel(this);
    }
  });
});