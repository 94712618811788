define('postedin/routes/release-notes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    releaseNotes: Ember.inject.service(),
    unreadReleaseNotes: Ember.inject.service(),
    scrollToOnActivate: true,
    model: function model() {
      return this.get('releaseNotes').find();
    },
    afterModel: function afterModel() {
      this.get('unreadReleaseNotes').set('count', 0);
    }
  });
});