define('postedin/routes/admin-projects/show/suspend', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    projects: Ember.inject.service(),
    model: function model() {
      var project = this.modelFor('admin-projects.show');
      return Ember.RSVP.hash({
        model: project,
        users: this.get('projects').users(project)
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.model);
      var form = {
        suspending: hash.model.get('suspending'),
        suspended: hash.model.get('suspended') ? 'yes' : 'no'
      };
      controller.set('form', form);
      var users = hash.users.filter(function (user) {
        return user.tempType === 'partner';
      });
      users = users.map(function (user) {
        return Ember.Object.create({
          id: user.id,
          name: user.name,
          email: user.email,
          isChecked: true
        });
      });
      controller.set('users', users);
      controller.set('saving', false);
    }
  });
});