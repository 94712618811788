define('postedin/controllers/editors/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    withCreditsOnly: false,
    filtered: Ember.computed('model.length', 'withCreditsOnly', function () {
      var creditsOnly = this.get('withCreditsOnly');
      var model = [].concat(_toConsumableArray(this.get('model').values()));

      if (!creditsOnly) {
        return model.map(function (group) {
          Ember.set(group, 'filteredProjects', group.projects);

          return group;
        });
      }

      return model.filter(function (group) {
        Ember.set(group, 'filteredProjects', group.projects.filter(function (project) {
          return project.get('account.balance') >= 15;
        }));

        return group.filteredProjects.length;
      });
    })
  });
});