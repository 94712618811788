define('postedin/components/calendar/daily-requests', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CalendarDay = Ember.Component.extend({
    classNames: ['calendar-daily-requests'],
    projects: Ember.computed('day', 'events', function () {
      var date = this.get('day.date').format('YYYY-MM-DD');
      var projects = this.get('events.' + date + '.projects');

      if (projects && projects.length) {
        projects.forEach(function (project) {
          Ember.set(project, 'contentRequests', []);

          Object.keys(project.statuses).forEach(function (status) {
            var statusRequests = project.statuses[status].map(function (request) {
              request._status = status.dasherize();
              return request;
            });

            Ember.set(project, 'contentRequests', project.contentRequests.concat(statusRequests));
          });
        });
      }
      return projects;
    })
  });

  CalendarDay.reopenClass({
    positionalParams: ['day', 'events', 'holidays']
  });

  exports.default = CalendarDay;
});