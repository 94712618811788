define('postedin/controllers/admin/release-notes/edit', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    releaseNotes: Ember.inject.service(),
    queryParams: ['delete'],
    delete: 0,
    validate: function validate(data) {
      var rules = {
        title: 'required',
        description: 'required'
      };

      return this.validatorCheck(data, rules);
    },

    actions: {
      toggleDelete: function toggleDelete() {
        this.set('delete', this.get('delete') ? 0 : 1);
      },
      saveReleaseNote: function saveReleaseNote(note, updates) {
        var _this = this;

        if (this.validate(updates)) {
          this.uiPromise(note.update(updates.title, updates.description), 'saveReleaseNote').then(function () {
            _this.transitionToRoute('admin.release-notes.show', note.id);
          });
        } else {
          this.scrollToFirstError();
        }
      },
      deleteReleaseNote: function deleteReleaseNote(note) {
        var _this2 = this;

        this.uiPromise(note.remove(), 'deleteReleaseNote').then(function () {
          _this2.transitionToRoute('admin.release-notes');
        });
      }
    }
  });
});