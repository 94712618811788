define('postedin/routes/admin/legacy-content-types/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    legacyContentTypeGroups: Ember.inject.service(),
    model: function model() {
      var factory = Ember.getOwner(this).factoryFor('model:legacy-content-type');
      return Ember.RSVP.hash({
        model: factory.create({
          active: 'yes',
          hide: 'no',
          importsBetaSources: 'yes',
          minimumDays: 3,
          includesMetaDescription: 'no',
          includesExtraPictures: 'no',
          disableMetaDescription: 'no',
          disableExtraPictures: 'no',
          pictures: 0,
          words: 0
        }),
        groups: this.get('legacyContentTypeGroups').all()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.model);
      controller.resetErrors();
      controller.set('languages', ['spanish', 'english', 'portuguese']);
      controller.set('groups', hash.groups);
      controller.set('saving', false);
    }
  });
});