define('postedin/mixins/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cleanString: function cleanString(string) {
      // because I moved what was done here to the `searchable` method on all String objects
      return string.searchable();
    }
  });
});