define('postedin/routes/admin-missions', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    betaMissions: Ember.inject.service(),
    queryParams: {
      month: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.set('params', params);
      var data = decodeURIComponent(Ember.$.param(params)) || {};

      return this.get('betaMissions').find(data);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var totalCLP = 0;
      var totalUSD = 0;
      model.forEach(function (mission) {
        if (mission.completedRewardUsd !== null) {
          totalUSD += mission.completedRewardUsd;
        } else {
          totalCLP += mission.completedReward;
        }
      });
      controller.set('totalUSD', totalUSD);
      controller.set('totalCLP', totalCLP);

      var now = (0, _moment.default)();
      var months = [];
      for (var i = 0; i < 13; i++) {
        months.push({
          label: now.format('MMMM YYYY'),
          value: i.toString()
        });
        now.subtract(1, 'months');
      }
      controller.set('months', months);

      var filter = {
        month: this.get('params.month') ? this.get('params.month') : 0
      };
      controller.set('filter', filter);
    }
  });
});