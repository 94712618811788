define('postedin/services/publishers/hubspot', ['exports', 'postedin/services/publishers/publisher', 'postedin/mixins/validator'], function (exports, _publisher, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _publisher.default.extend(_validator.default, {
    validateForSendRules: function validateForSendRules(data) {
      var rules = {
        contentGroupId: 'required',
        name: 'required',
        blogAuthorId: 'required',
        metaDescription: 'required'
      };

      if (data.useFeaturedImage) {
        rules.featuredImageId = 'required';
      }

      return rules;
    },
    validateForSend: function validateForSend(data) {
      return this.validator(data, this.validateForSendRules(data)).passes();
    }
  });
});