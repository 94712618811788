define('postedin/components/sticky-content', ['exports', 'postedin/mixins/component-window-details'], function (exports, _componentWindowDetails) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentWindowDetails.default, {
    topMargin: 10,
    scrollObserver: Ember.observer('windowDetails.updated', function () {
      var currentOffset = this.get('calculatedStyles.offset.top') - (this.get('topMargin') + this.get('windowDetails.scrollPosition.top'));
      this.set('currentOffset', currentOffset);
    }),
    offsetObserver: Ember.observer('currentOffset', function () {
      if (this.get('currentOffset') <= 0) {
        this.fix();
      } else if (this.get('fixed')) {
        this.unfix();
      }
    }),
    fix: function fix() {
      this.$().css({
        position: 'fixed',
        top: this.get('topMargin'),
        left: this.get('calculatedStyles.offset.left'),
        width: this.get('calculatedStyles.width'),
        'z-index': 99
      });
      this.set('fixed', true);
      this.get('placeholder').show();
    },
    unfix: function unfix() {
      this.$().css(this.get('defaultStyles'));
      this.set('fixed', false);
      this.get('placeholder').hide();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var placeholder = Ember.$('<div></div>');

      this.set('placeholder', placeholder);
      this.set('currentOffset', this.$().offset().top);
      this.set('defaultStyles', this.$().css(['width', 'top', 'left', 'position', 'z-index']));
      this.set('calculatedStyles', {
        height: this.$().css('height'),
        width: this.$().outerWidth(),
        offset: this.$().offset(),
        margin: this.$().css('margin')
      });

      placeholder.hide().css(this.get('defaultStyles')).css('height', this.get('calculatedStyles.height'));
      this.$().after(placeholder);
    }
  });
});