define('postedin/components/pagination-links', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'pagination',
    t: function () {
      return new Date().getTime();
    }.property(),
    pages: function () {
      var _this = this;

      var pages = [];
      var currentPage = parseInt(this.get('currentPage'));
      for (var page = 1; page <= this.get('lastPage'); page++) {
        pages.push({
          number: page,
          active: page === currentPage
        });
      }

      var start = currentPage - 4;
      var end = Math.min(currentPage + 3, parseInt(this.get('lastPage')));
      if (start <= 0) {
        end -= start;
        start = 0;
      }

      Ember.run.once(function () {
        _this.set('showFirstPage', start > 0);
      });

      return pages.slice(start, end);
    }.property('currentPage', 'lastPage'),
    actions: {
      scroll: function scroll() {
        var _this2 = this;

        this.set('loading', true);

        if (this.get('scrollTo')) {
          Ember.run.later(function () {
            Ember.$('html, body').animate({
              scrollTop: Ember.$(_this2.get('scrollTo')).offset().top
            }, 100);
          }, 250);
        }
      }
    }
  });
});