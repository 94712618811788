define('postedin/components/publishers/send-hubspot', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SendHubspotComponent = Ember.Component.extend(_uiPromise.default, {
    type: 'draft',
    date: null,
    time: null,
    submitButtonPhrase: Ember.computed('type', function () {
      switch (this.get('type')) {
        case 'draft':
          return 'publishers.send_draft';
        case 'publish':
          return 'publishers.publish';
        case 'schedule':
          return 'publishers.schedule';
      }

      return false;
    }),
    scheduling: Ember.computed.equal('type', 'schedule'),
    actions: {
      send: function send(publication, type, date, time) {
        var _this = this;

        this.set('error');
        var send = false;

        if (type === 'draft') {
          send = function send() {
            return publication.sendDraft();
          };
        }

        if (type === 'publish') {
          send = function send() {
            return publication.publish();
          };
        }

        if (type === 'schedule') {
          send = function send() {
            return publication.schedule(date, time);
          };
        }

        if (!send) {
          return;
        }

        var promise = send();
        if (!promise) {
          return false;
        }

        this.uiPromise(promise).then(function () {
          _this.sendAction('sent');
        }, function (response) {
          if (response.status === 400) {
            _this.set('error', response.responseJSON);
          }
        });
      }
    }
  });

  SendHubspotComponent.reopenClass({
    positionalParams: ['publisher', 'revision', 'publication']
  });

  exports.default = SendHubspotComponent;
});