define('postedin/routes/impersonate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    users: Ember.inject.service(),
    scrollToOnActivate: true,
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.user.isAdmin')) {
        transition.abort();
        return this.transitionTo('/');
      }
    },
    model: function model() {
      return this.get('users').find();
    },
    setupController: function setupController(controller, users) {
      var model = {
        partners: users.filter(function (u) {
          return u.tempType === 'partner';
        }),
        editors: users.filter(function (u) {
          return u.tempType === 'editor';
        }),
        admins: users.filter(function (u) {
          return u.tempType === 'admin';
        })
      };

      this._super(controller, model);
      controller.set('selectedUser');
      controller.set('impersonating');
    }
  });
});