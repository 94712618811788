define('postedin/models/model', ['exports', 'postedin/app'], function (exports, _app) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Object.extend({
    api: Ember.inject.service(),
    request: function request(urlSuffix, queryParams) {
      var url = [this.get('apiUrl')];

      var id = this.get('id');
      if (id) {
        url.push(id);
      }

      url.push(urlSuffix);

      return this.get('api.requestTask').perform(url.join('/'), 'get', queryParams);
    },
    fresh: function fresh(queryParams) {
      if (!this.get('id')) {
        return _app.default.notFound();
      }

      var url = [this.get('apiUrl'), this.get('id')];

      return this.get('api.requestTask').perform(url.join('/'), 'get', queryParams);
    },
    save: function save(data) {
      var url = [this.get('apiUrl')];
      var method = 'post';

      var id = this.get('id');
      if (id) {
        url.push(id);
        method = 'put';
      }

      return this.get('api.requestTask').perform(url.join('/'), method, data);
    },
    delete: function _delete() {
      var url = [this.get('apiUrl')];
      var method = 'post';

      var id = this.get('id');
      if (id) {
        url.push(id);
        method = 'delete';
      }

      return this.get('api.requestTask').perform(url.join('/'), method);
    },
    customCreate: function customCreate(type, data) {
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'post';

      return this.customUpdate(type, data, method);
    },
    customUpdate: function customUpdate(type, data) {
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'put';

      var url = [this.get('apiUrl'), this.get('id'), type].join('/');
      method = this.getMethod(method, type);

      return this.get('api.requestTask').perform(url, method, data);
    },
    getMethod: function getMethod(method, type) {
      return this.getWithDefault('customUpdateMethods.' + type, method);
    },

    // Get all properties except ID to use in forms for updating.
    updatesHash: function updatesHash() {
      var _this = this;

      var includeObjects = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var exclude = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var updates = {};
      Object.keys(this).forEach(function (key) {
        // skip id and keys starting with '_'
        if (key === 'id' || key.startsWith('_')) {
          return;
        }

        if (exclude.includes(key)) {
          return;
        }

        var value = _this.get(key);

        if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && !Ember.isArray(value)) {
          var includeFound = false;
          var includeExtras = [];
          includeObjects.forEach(function (include) {
            var parts = include.split('.');
            if (key === parts[0]) {
              includeFound = true;
              parts.shift();
              includeExtras.push(parts.join('.'));
            }
          });

          if (includeFound) {
            if (typeof value.updatesHash === 'function') {
              value = value.updatesHash(includeExtras);
            }
          } else {
            return;
          }
        }

        updates[key] = value;
      });

      return updates;
    }
  });
});