define('postedin/models/legacy-content-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    legacyContentTypes: Ember.inject.service(),
    save: function save() {
      if (this.get('id')) {
        return this.get('legacyContentTypes').update(this);
      }

      return this.get('legacyContentTypes').create(this);
    },
    delete: function _delete() {
      if (this.get('id')) {
        return this.get('legacyContentTypes').destroy(this);
      }

      throw new Error("Can't delete a content type that doesn't exist.");
    }
  });
});