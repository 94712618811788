define('postedin/controllers/international-payments/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    betaUsers: Ember.inject.service(),
    queryParams: ['user'],
    user: 0,
    confirmationModal: false,
    actions: {
      closeConfirmationModal: function closeConfirmationModal() {
        this.set('confirmationModal', false);
      },
      confirmSendPaymentEmails: function confirmSendPaymentEmails() {
        this.set('confirmationModal', true);
      },
      createPayment: function createPayment() {
        var _this = this;

        var users = this.get('model.users');
        users = users.map(function (user) {
          return {
            betaUserId: user.id,
            payment: user.earnings,
            missions: user.missions
          };
        });

        this.set('sendingEmails', true);
        this.get('betaUsers').createPayment(this.get('name'), this.get('billableUntil'), users, 'international').then(function () {
          _this.set('sendingEmails', false);
          _this.set('emailsSent', true);
          _this.transitionToRoute('international-payments', { queryParams: { status: 'success' } });
        }, function () {
          _this.set('sendingEmails', false);
          _this.set('sendingError', true);
          _this.set('confirmationModal', false);
        });
      }
    }
  });
});