define('postedin/services/projects', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'project',
    projectImages: Ember.inject.service(),
    all: function all() {
      var _this = this;

      return this.cachePromise('projects:all', function () {
        return _this.request('projects');
      }).then(function (response) {
        return _this.pushAll(response.data);
      });
    },
    trashed: function trashed() {
      var _this2 = this;

      return this.request('projects/trashed').then(function (response) {
        return _this2.pushResponse(response, 'trashed-project');
      });
    },
    pushAll: function pushAll(projects) {
      projects.forEach(function (project) {
        project.labels.forEach(function (label) {
          label._emberModel = 'project-label';
        });

        project.profile.images.forEach(function (i) {
          return i._emberModel = i._model;
        });
      });

      return this.pushArray(projects);
    },
    findById: function findById(id) {
      var _this3 = this;

      return this.request('projects/' + id).then(function (record) {
        return _this3.push(record);
      });
    },
    remove: function remove(project) {
      var _this4 = this;

      if (!project.id) {
        return false;
      }

      return this.delete('projects/' + project.id).then(function () {
        return _this4.removeObject(project.id);
      });
    },
    restore: function restore(project) {
      var _this5 = this;

      return this.post('projects/' + project.id + '/restore').then(function (record) {
        _this5.removeObject(project.id, 'trashed-project');
        return _this5.push(record);
      });
    },
    assignmentTypes: function assignmentTypes(id) {
      var _this6 = this;

      return this.request('projects/' + id + '/assignment-types').then(function (response) {
        return _this6.pushResponse(response, 'assignment-type');
      });
    },
    projectLegacyContentTypes: function projectLegacyContentTypes(id) {
      var _this7 = this;

      return this.request('projects/' + id + '/project-legacy-content-types').then(function (response) {
        return _this7.pushResponse(response, 'project-legacy-content-type');
      });
    },
    legacyContentTypes: function legacyContentTypes(id) {
      var _this8 = this;

      return this.request('projects/' + id + '/legacy-content-types').then(function (response) {
        return _this8.pushResponse(response, 'legacy-content-type');
      });
    },
    createContentRequest: function createContentRequest(project, contentType, legacyContentType, contentCategory) {
      var _this9 = this;

      if (legacyContentType && legacyContentType.id) {
        legacyContentType = legacyContentType.id;
      }

      if (contentCategory && contentCategory.id) {
        contentCategory = contentCategory.id;
      }

      return this.post('projects/' + project.id + '/content-requests', { contentType: contentType, legacyContentType: legacyContentType, contentCategory: contentCategory }).then(function (record) {
        return _this9.push(record, 'content-request');
      });
    },
    createContentSuggestion: function createContentSuggestion(project, contentTypeGroup, summary, goal, focusPoints, targetAudience, duplicateId, keywords, sources) {
      var _this10 = this;

      var data = {
        contentTypeGroup: contentTypeGroup, summary: summary, goal: goal, focusPoints: focusPoints, targetAudience: targetAudience, duplicateId: duplicateId, keywords: keywords, sources: sources
      };

      return this.post('projects/' + project.id + '/content-suggestions', data).then(function (record) {
        return _this10.push(record, 'content-suggestion');
      });
    },
    addAssignmentType: function addAssignmentType(id, typeId) {
      var _this11 = this;

      return this.post('projects/' + id + '/assignment-types', { assignmentTypeId: typeId }).then(function (record) {
        return _this11.push(record, 'assignment-type');
      });
    },
    addLegacyContentType: function addLegacyContentType(id, typeId) {
      var _this12 = this;

      return this.post('projects/' + id + '/legacy-content-types', { legacyContentTypeId: typeId }).then(function (record) {
        return _this12.push(record, 'legacy-content-type');
      });
    },
    removeLegacyContentType: function removeLegacyContentType(id, typeId) {
      var _this13 = this;

      return this.delete('projects/' + id + '/legacy-content-types/' + typeId).then(function () {
        return _this13.removeObject(typeId, 'legacy-content-type');
      });
    },
    profile: function profile(id) {
      var _this14 = this;

      return this.request('projects/' + id + '/profile').then(function (record) {
        return _this14.push(record, 'project-profile');
      });
    },
    updateProfile: function updateProfile(id, profile) {
      var _this15 = this;

      return this.put('projects/' + id + '/profile', profile).then(function (record) {
        return _this15.push(record, 'project-profile');
      });
    },
    updateSettings: function updateSettings(id, settings) {
      var _this16 = this;

      return this.put('projects/' + id + '/settings', settings).then(function (record) {
        _this16.push(record);
      });
    },
    updates: function updates(project) {
      var _this17 = this;

      return this.request('projects/' + project.id + '/updates').then(function (response) {
        return _this17.pushResponse(response, 'update');
      });
    },
    warn: function warn(id, data) {
      var _this18 = this;

      return this.put('projects/' + id + '/warn', data).then(function (record) {
        return _this18.push(record);
      });
    },
    suspend: function suspend(id, data) {
      var _this19 = this;

      return this.put('projects/' + id + '/suspend', data).then(function (record) {
        return _this19.push(record);
      });
    },
    labels: function labels(project) {
      var _this20 = this;

      return this.request('projects/' + project.get('id') + '/labels').then(function (response) {
        return _this20.pushResponse(response, 'project-label');
      });
    },
    createLabel: function createLabel(project, name, color) {
      var _this21 = this;

      var label = {
        name: name,
        color: color
      };

      return this.post('projects/' + project.id + '/labels', label).then(function (record) {
        return _this21.push(record, 'project-label');
      });
    },
    referenceSites: function referenceSites(project) {
      var _this22 = this;

      return this.request('projects/' + project.id + '/reference-sites').then(function (response) {
        return _this22.pushResponse(response, 'project-reference-site');
      });
    },
    createReferenceSite: function createReferenceSite(project, name, url, description) {
      var _this23 = this;

      var site = {
        name: name,
        url: url,
        description: description
      };
      return this.post('projects/' + project.id + '/reference-sites', site).then(function (record) {
        return _this23.push(record, 'project-reference-site');
      });
    },
    forbiddenSources: function forbiddenSources(project) {
      var _this24 = this;

      return this.request('projects/' + project.id + '/forbidden-sources').then(function (response) {
        return _this24.pushResponse(response, 'project-forbidden-source');
      });
    },
    createForbiddenSource: function createForbiddenSource(project, name, url, description) {
      var _this25 = this;

      var site = {
        name: name,
        url: url,
        description: description
      };
      return this.post('projects/' + project.id + '/forbidden-sources', site).then(function (record) {
        return _this25.push(record, 'project-forbidden-source');
      });
    },
    keywords: function keywords(project) {
      var _this26 = this;

      return this.request('projects/' + project.id + '/keywords').then(function (response) {
        return _this26.pushResponse(response, 'project-keyword');
      });
    },
    createKeyword: function createKeyword(project, name) {
      var _this27 = this;

      return this.post('projects/' + project.id + '/keywords', { name: name }).then(function (record) {
        return _this27.push(record, 'project-keyword');
      });
    },
    createKeywords: function createKeywords(project, keywords) {
      var _this28 = this;

      return this.post('projects/' + project.id + '/keywords', { keywords: keywords }).then(function (record) {
        return _this28.pushArray(record.keywords, 'project-keyword');
      });
    },
    imageRequirements: function imageRequirements(project) {
      var _this29 = this;

      return this.request('projects/' + project.id + '/images').then(function (response) {
        return _this29.pushResponse(response, 'project-image');
      });
    },
    createImageRequirement: function createImageRequirement(project, name, licenses, stockPhotoSites, dimensions, additionalInformation, assignmentTypeIds) {
      var _this30 = this;

      var imageLicenses = this.get('projectImages.licenses');
      var imageStockPhotoSites = this.get('projectImages.stockPhotoSites');

      var preparedLicenses = {};
      Object.keys(licenses).forEach(function (license) {
        if (licenses[license] && imageLicenses.includes(license)) {
          preparedLicenses[license] = true;
        }
      });

      var preparedStockSites = {};
      Object.keys(stockPhotoSites).forEach(function (site) {
        if (stockPhotoSites[site] && imageStockPhotoSites.includes(site)) {
          preparedStockSites[site] = true;
        }
      });

      var data = {
        name: name,
        licenses: preparedLicenses,
        stockPhotoSites: preparedStockSites,
        dimensions: dimensions,
        additionalInformation: additionalInformation,
        assignmentTypeIds: assignmentTypeIds
      };

      return this.post('projects/' + project.id + '/images', data).then(function (record) {
        return _this30.push(record, 'project-image');
      });
    },
    notes: function notes(id) {
      var _this31 = this;

      return this.request('projects/' + id + '/notes').then(function (response) {
        return _this31.pushResponse(response, 'project-note');
      });
    },
    saveNote: function saveNote(id, input, publicationId, referenceableType, referenceableId) {
      var _this32 = this;

      publicationId = publicationId ? publicationId : null;
      referenceableType = referenceableType ? referenceableType : null;
      referenceableId = referenceableId ? referenceableId : null;
      var data = {
        body: input.body,
        public: input.public,
        publication_id: publicationId,
        referenceable_type: referenceableType,
        referenceable_id: referenceableId
      };

      return this.post('projects/' + id + '/notes', data).then(function (record) {
        return _this32.push(record, 'project-note');
      });
    },
    createNote: function createNote(project, data) {
      var _this33 = this;

      return this.post('projects/' + project.get('id') + '/notes', data).then(function (record) {
        return _this33.push(record, 'project-note');
      });
    },
    updateNote: function updateNote(note, body) {
      var _this34 = this;

      var updates = {
        body: body,
        public: note.public
      };

      return this.put('project-notes/' + note.get('id'), updates).then(function (record) {
        return _this34.push(record, 'project-note');
      });
    },
    removeNote: function removeNote(note) {
      var _this35 = this;

      return this.delete('project-notes/' + note.get('id')).then(function () {
        _this35.removeObject(note.get('id'), 'project-note');
      });
    },
    noteComments: function noteComments(note) {
      var _this36 = this;

      return this.request('project-notes/' + note.id + '/comments').then(function (response) {
        return _this36.pushResponse(response, 'comment');
      });
    },
    createCommentNote: function createCommentNote(note, body) {
      var _this37 = this;

      return this.post('project-notes/' + note.get('id') + '/comments', { body: body }).then(function (record) {
        return _this37.push(record, 'comment');
      });
    },
    users: function users(project) {
      var _this38 = this;

      return this.request('projects/' + project.get('id') + '/users').then(function (response) {
        return _this38.pushResponse(response, 'project-user');
      });
    },
    managers: function managers(project) {
      var _this39 = this;

      return this.request('organizations/' + project.get('organization.id') + '/managers').then(function (response) {
        return _this39.pushResponse(response, 'user');
      });
    },
    admins: function admins(project) {
      var _this40 = this;

      return this.request('organizations/' + project.get('organization.id') + '/admins').then(function (response) {
        return _this40.pushResponse(response, 'user');
      });
    },
    addUser: function addUser(project, user, roles) {
      var _this41 = this;

      return this.post('projects/' + project.get('id') + '/users', {
        userId: user.get('id'),
        roles: roles
      }).then(function (record) {
        return _this41.push(record, 'project-user');
      });
    },
    inviteUser: function inviteUser(project, email, roles) {
      var _this42 = this;

      return this.post('projects/' + project.get('id') + '/invites', {
        email: email,
        roles: roles
      }).then(function (record) {
        return _this42.push(record, 'invite');
      });
    },
    invites: function invites(project) {
      var _this43 = this;

      return this.request('projects/' + project.get('id') + '/invites').then(function (response) {
        return _this43.pushResponse(response, 'invite');
      });
    },
    editors: function editors(project) {
      var _this44 = this;

      return this.request('projects/' + project.get('id') + '/editors').then(function (response) {
        return _this44.pushResponse(response, 'user');
      });
    },
    addEditor: function addEditor(project, editor) {
      return this.post('projects/' + project.get('id') + '/editors', {
        userId: editor.get('id')
      });
    },
    removeEditor: function removeEditor(project, editor) {
      if (editor.id) {
        return this.delete('projects/' + project.get('id') + '/editors/' + editor.id);
      }
    },
    publishers: function publishers(id) {
      var _this45 = this;

      return this.request('projects/' + id + '/publishers').then(function (response) {
        return _this45.pushResponse(response, 'project-publisher');
      });
    },
    addPublisher: function addPublisher(publisher, data) {
      var _this46 = this;

      return this.post('projects/' + publisher.project.id + '/publishers', data).then(function (record) {
        return _this46.push(record, 'project-publisher');
      });
    },
    changeOrganization: function changeOrganization(project, newOrganization) {
      var _this47 = this;

      return this.put('projects/' + project.id + '/admin-transfer', { organizationId: newOrganization.id }).then(function (record) {
        return _this47.push(record);
      });
    },
    loadAuditLog: function loadAuditLog(project, params) {
      var _this48 = this;

      var query = {};
      if (params) {
        if (params.page) {
          params.page = parseInt(params.page);
          if (params.page > 1) {
            query.page = params.page;
          }
        }
      }

      return this.request('projects/' + project.get('id') + '/audit-log?' + Ember.$.param(query)).then(function (response) {
        var auditLog = _this48.pushResponse(response, 'audit-log');
        delete response.data;

        return { auditLog: auditLog, meta: response };
      });
    },
    selectAccount: function selectAccount(project, account) {
      var _this49 = this;

      return this.post('projects/' + project.get('id') + '/select-account', { accountId: account.get('id') }).then(function (response) {
        return _this49.push(response);
      });
    },
    removeAccount: function removeAccount(project) {
      var _this50 = this;

      return this.delete('projects/' + project.get('id') + '/select-account').then(function (response) {
        return _this50.push(response);
      });
    },
    reviewers: function reviewers(project) {
      var _this51 = this;

      return this.request('projects/' + project.id + '/reviewers').then(function (response) {
        return _this51.pushResponse(response, 'user');
      });
    },
    addReviewer: function addReviewer(project, user) {
      var _this52 = this;

      return this.post('projects/' + project.id + '/reviewers', { userId: user.id }).then(function (record) {
        return _this52.push(record, 'user');
      });
    },
    removeReviewer: function removeReviewer(user) {
      var _this53 = this;

      return this.delete('project-reviewers/' + user.get('reviewer.id')).then(function (record) {
        return _this53.push(record, 'user');
      });
    },
    transactions: function transactions(project, params) {
      var _this54 = this;

      var query = {};
      if (params.page) {
        params.page = parseInt(params.page);
        if (params.page > 1) {
          query.page = params.page;
        }
      }

      return this.request('projects/' + project.get('id') + '/transactions?' + Ember.$.param(query)).then(function (response) {
        var transactions = _this54.pushResponse(response);
        delete response.data;

        return { transactions: transactions, meta: response };
      });
    }
  });
});