define('postedin/components/file-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    imageTypes: ['image/jpg', 'image/png', 'image/gif'],
    typeIcons: {
      'image/jpg': 'icon-image2',
      'image/png': 'icon-image2',
      'image/gif': 'icon-image2',
      'application/pdf': 'icon-file-pdf'
    },
    isImage: Ember.computed('upload.format', function () {
      var type = this.get('upload.format');
      var imageTypes = this.get('imageTypes');

      return imageTypes.includes(type);
    }),
    thumbnailIcon: Ember.computed('thumbnailImage', function () {
      return !this.get('thumbnailImage');
    }),
    thumbnailImage: Ember.computed('isImage', 'upload.url', function () {
      return this.get('isImage') && this.get('upload.url');
    }),
    typeIconClass: Ember.computed('upload.format', function () {
      var type = this.get('upload.format');
      var typeIcons = this.get('typeIcons');

      var icon = typeIcons[type] || 'icon-file-plus';

      return icon;
    }),
    actions: {
      cancel: function cancel(upload) {
        upload.set('canceled', true);
        upload.set('uploading', false);
        upload.set('speed', false);
        upload.set('eta', false);
        this.sendAction('cancelUpload', upload);
      },
      retry: function retry(upload) {
        upload.set('canceled', false);
        upload.set('speed', false);
        upload.set('eta', false);
        this.sendAction('retryUpload', upload);
      },
      remove: function remove(upload) {
        upload.set('remove', true);
      },
      undoRemove: function undoRemove(upload) {
        upload.set('remove', false);
      }
    }
  });
});