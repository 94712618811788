define('postedin/components/calendar/content-request', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CalendarContentRequest = Ember.Component.extend({
    classNames: ['calendar-content-request'],
    uiPopup: Ember.inject.service(),
    dasherizedStatus: Ember.computed('contentRequest.status', function () {
      return this.get('contentRequest.status').dasherize();
    }),
    mouseEnter: function mouseEnter() {
      var contentRequest = this.get('contentRequest');
      this.get('uiPopup').show(this.$(), 'content-request-popup', { contentRequest: contentRequest });
    },
    mouseLeave: function mouseLeave() {
      this.get('uiPopup').cancelShow();
    }
  });

  CalendarContentRequest.reopenClass({
    positionalParams: ['contentRequest']
  });

  exports.default = CalendarContentRequest;
});