define('postedin/routes/faq', ['exports', 'postedin/helpers/slug-id'], function (exports, _slugId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    faq: Ember.inject.service(),
    scrollToOnActivate: true,
    model: function model(params) {
      var _this = this;

      if (params.a) {
        var model = this.get('faq').find();
        var id = parseInt(params.a.split('-')[1]);
        model.then(function (questions) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = questions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var question = _step.value;

              if (question.get('id') === id) {
                _this.transitionTo('help.article', (0, _slugId.slugId)(question.question, question.id));
                return;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _this.transitionTo('help');
        }, function () {
          _this.transitionTo('help');
        });

        return model;
      }

      this.transitionTo('help');
    }
  });
});