define('postedin/components/content-article/revision-form', ['exports', 'postedin/mixins/validator'], function (exports, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_validator.default, {
    froalaOptions: {
      imageUpload: true,
      imageUploadURL: '/api/uploads',
      fileUploadURL: '/api/uploads',
      videoUploadURL: '/api/uploads'
    },
    images: Ember.computed('revision.attachments.length', 'revision.uploads.length', function () {
      var attachments = this.get('revision.attachments');
      var uploads = this.get('revision.uploads');

      return attachments.concat(uploads).filter(function (file) {
        return file.isImage;
      });
    }),
    imagesCount: Ember.computed('revision.attachments.length', 'revision.uploads.length', function () {
      return this.getWithDefault('revision.attachments.length', 0) + this.getWithDefault('revision.uploads.length', 0);
    }),
    validate: function validate() {
      return this.validatorCheck(this.get('revision'), {
        title: 'required',
        body: 'required',
        'uploads.*.license.type': 'required|in:original,cc,cc0,stock',
        'uploads.*.license.source': 'required_unless:uploads.*.license.type,original|url'
      });
    },

    actions: {
      removeAttachment: function removeAttachment(attachment) {
        var upload = Ember.get(attachment, 'upload');
        var attachments = this.get('revision.attachments');

        this.send('removeUpload', upload);
        this.set('revision.attachments', attachments.filter(function (a) {
          return a.id !== attachment.id;
        }));
      },
      removeUpload: function removeUpload(upload) {
        var revision = this.get('revision');
        var froalaElement = Ember.$('#' + this.get('froala.elementId'));
        var image = Ember.$('img[src=\'' + upload.link + '\']', froalaElement);
        var uploads = this.get('revision.uploads');

        this.get('froala._editor.image').remove(image);
        Ember.set(revision, 'uploads', uploads.filter(function (u) {
          return u.link !== upload.link;
        }));
      },
      submitForm: function submitForm(revision) {
        if (this.validate()) {
          this.sendAction('submit', revision);
        }
      },
      imageUploaded: function imageUploaded(editor, response) {
        this.get('revision.uploads').pushObject(JSON.parse(response));

        return response;
      },
      imageRemoved: function imageRemoved(editor, img) {
        var uploads = this.get('revision.uploads');
        var attachments = this.get('revision.attachments');

        this.set('revision.attachments', attachments.filter(function (a) {
          return a.upload.link !== img.attr('src');
        }));
        this.set('revision.uploads', uploads.filter(function (u) {
          return u.link !== img.attr('src');
        }));
      },
      froalaInitialized: function froalaInitialized(editor) {
        this.set('froala', editor);
      }
    }
  }).reopenClass({
    positionalParams: ['revision']
  });
});