define('postedin/helpers/project-user-roles-grouped', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.projectUserRolesGrouped = projectUserRolesGrouped;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // phrase_for_group: [ roles ... ],
  var groupedRoles = {
    project: {
      roles: ['updateProfile']
    },
    contentRequests: {
      roles: ['viewContentRequests', { role: 'requestContent', requires: 'viewContentRequests' }, { role: 'viewContentComments', requires: 'viewContentRequests' }]
    },
    suggestions: {
      roles: ['suggestContent', { role: 'judgeSuggestedContent', requires: 'requestContent' }]
    },
    contentRevisions: {
      roles: ['viewLatestContentRevision', 'viewAllContentRevisions', 'judgeContentRevisions', 'createContentRevisionNotes', 'viewContentRevisionComments']
    },
    contentReviews: {
      roles: ['viewAllContentReviews']
    },
    publishing: {
      roles: [{ role: 'publishContent', requires: 'viewContentRequests' }],
      hidden: function hidden(projectUserRoles) {
        return !projectUserRoles.viewContentRequests;
      }
    },
    credits: {
      roles: ['viewCreditsUsageAndBalance'],
      hidden: function hidden(projectUserRoles) {
        return projectUserRoles.requestContent;
      }
    }
  };

  var otherGroupPhrase = 'project-role-groups.other';

  function projectUserRolesGrouped(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1),
        projectUserRoles = _ref2[0];

    if (!projectUserRoles) {
      projectUserRoles = {};
    }

    var otherRoles = Object.assign({}, projectUserRoles);
    var first = true;

    var groups = Object.keys(groupedRoles).map(function (groupKey) {
      var roles = groupedRoles[groupKey].roles.filter(function (role) {
        var requires = false;
        if ((typeof role === 'undefined' ? 'undefined' : _typeof(role)) === 'object') {
          requires = role.requires;
          role = role.role;
        }

        if (typeof options.access !== 'undefined') {
          return projectUserRoles[role] === options.access;
        }

        if (requires) {
          var requiredEnabled = projectUserRoles[requires];

          if (!requiredEnabled) {
            delete otherRoles[role];
            return false;
          }
        }

        return true;
      }).map(function (role) {
        if ((typeof role === 'undefined' ? 'undefined' : _typeof(role)) === 'object') {
          role = role.role;
        }

        delete otherRoles[role];

        return {
          name: role,
          access: !!projectUserRoles[role]
        };
      });

      var group = {
        first: first,
        phrase: 'project-roles-groups.' + groupKey.underscore(),
        roles: roles,
        hide: groupedRoles[groupKey].hidden ? groupedRoles[groupKey].hidden(projectUserRoles) : false
      };

      first = false;

      return group;
    });

    otherRoles = Object.keys(otherRoles).filter(function (role) {
      if (typeof options.access !== 'undefined') {
        return projectUserRoles[role] === options.access;
      }

      return true;
    }).map(function (role) {
      return {
        name: role,
        access: !!projectUserRoles[role]
      };
    });

    groups.push({
      first: false,
      hide: !otherRoles.length,
      phrase: otherGroupPhrase,
      roles: otherRoles
    });

    return groups;
  }

  exports.default = Ember.Helper.helper(projectUserRolesGrouped);
});