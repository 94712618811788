define('postedin/components/sources-picker', ['exports', 'postedin/mixins/validator'], function (exports, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_validator.default, {
    tagName: '',
    sources: [],
    canAdd: true,
    canRemove: true,
    disableButton: Ember.computed('newSource', function () {
      return !this.validate(this.get('newSource'));
    }),
    validate: function validate(source) {
      return this.validatorCheck({ source: source }, {
        source: 'url|required'
      });
    },

    actions: {
      addSource: function addSource(source) {
        if (this.validate(source)) {
          this.getWithDefault('sources', []).pushObject({ url: source });
          this.set('newSource', '');
        }
      },
      removeSource: function removeSource(url) {
        this.set('sources', this.get('sources').filter(function (source) {
          return source.url !== url;
        }));
      }
    }
  });
});