define('postedin/helpers/branding', ['exports', 'postedin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.branding = branding;


  var brands = {
    'postedin': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/v1475609349/postedin_alt_oj4vlx.svg',
      name: 'Postedin',
      cssClass: '',
      mail: 'soporte@postedin.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'initiative': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_lpad,f_auto,h_24/initiative-logo_zjcdbd.png',
      name: 'Initiative',
      cssClass: 'initiative-cl',
      mail: 'gustavoadolfo.rothkegel@initiative.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'pagoranking': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_lpad,f_auto,h_30,e_colorize,co_white/v1468255833/pagoranking_e8wpni.png',
      name: 'PagoRanking',
      cssClass: 'pagoranking',
      mail: 'clientes@pagoranking.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'qupertino': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_lpad,f_auto,h_24,e_colorize,co_white/v1476306566/qupertino-logo_ooaeum.png',
      name: 'Qupertino',
      cssClass: 'qupertino',
      mail: 'cdubo@qupertino.com',
      froalaKey: 'EupvbzA-63cecA6mg1B-8=='
    },
    'society-mx': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_lpad,h_30/v1482401790/society-mx_qxzj6v.png',
      name: 'Society Agency México',
      cssClass: 'society-mx',
      mail: 'michelle.chavez@societyagency.com',
      froalaKey: 'gsnajaqD-16foldhuA3md1C-13D1F-11k=='
    },
    'maxus': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_24/v1488892692/postedin/logo-inverse_en-GB.svg',
      name: 'Maxus',
      cssClass: 'maxus',
      mail: 'contact@maxusglobal.com.mx',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'media-sci': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_24/v1488892797/postedin/1459961225.png',
      name: 'Media Science',
      cssClass: 'media-sci',
      mail: 'a.galvan@mediasci.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'terminalcreativa': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_50/v1488980974/postedin/terminalcreativa-logo-top.png',
      name: 'Terminal Creativa',
      cssClass: 'terminalcreativa',
      mail: 'contacto@terminalcreativa.com',
      froalaKey: '0h1pdzqwB-11wC-9oH-8I-7thtbF4fij=='
    },
    'storylab': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_24/v1489756605/postedin/storylab-black_c8tlwb.png',
      name: 'The Story Lab',
      cssClass: 'storylab',
      mail: 'hello@storylab.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'dragons': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_70/v1489757507/postedin/dragons-logo.png',
      name: 'Dragons',
      cssClass: 'dragons',
      mail: 'soporte@postedin.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'socialdecode': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_40/v1490127588/postedin/logo.png',
      name: 'SocialDecode',
      cssClass: 'socialdecode',
      mail: 'info@socialdecode.mx',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'mimanzana': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_40/v1496674008/postedin/logo-mimanzana_small.png',
      name: 'Mi Manzana',
      cssClass: 'mimanzana',
      mail: 'contacto@mimanzana.com',
      froalaKey: 'QB-8xJ-7rzowyyG2ytq=='
    },
    'bancomer': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_35/v1503066231/postedin/bbva_ef.png',
      name: 'BBVA Bancomer',
      cssClass: 'bancomer',
      mail: 'monica.romerob@bbva.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'edufindigital': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_35/v1503066231/postedin/bbva_ef.png',
      name: 'BBVA Bancomer',
      cssClass: 'edufindigital',
      mail: 'monica.romerob@bbva.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'edufindigital2': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_35/v1503066231/postedin/bbva_ef.png',
      name: 'BBVA Bancomer',
      cssClass: 'edufindigital',
      mail: 'monica.romerob@bbva.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'educacionfinanciera2': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_35/v1503066231/postedin/bbva_ef.png',
      name: 'BBVA Bancomer',
      cssClass: 'edufindigital',
      mail: 'monica.romerob@bbva.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'grou': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_35/v1510771547/postedin/file-1728207387.png',
      name: 'Grou',
      cssClass: 'grou',
      mail: 'marketing@grou.com.mx',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'movistar': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_35/v1512586184/layout_set_logo_vckdwb.png',
      name: 'Movistar',
      cssClass: 'movistar',
      mail: 'soporte@movistar.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'home-depot': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_50,q_90/v1517428284/https___corporate.homedepot.com_sites_default_files_image_gallery_THD_logo_k39cay.jpg',
      name: 'The Home Depot',
      cssClass: 'home-depot',
      mail: 'callcenter@homedepot.com.mx',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'interius': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_50/v1517924376/postedin/logo_mobile.png',
      name: 'Interius',
      cssClass: 'interius',
      mail: 'marketing@interius.com.mx',
      froalaKey: '3B-21vmF-11iuhmE2cnoE4iA-9=='
    },
    'devlyn': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_30/v1518441646/postedin/devlin.png',
      name: 'Devlyn',
      cssClass: 'devlyn',
      mail: 'promosdevlyn@gmail.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'contenidos-digital': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_60/v1518788501/postedin/Logo_Contenidos_Digital.png',
      name: 'Contenidos Digital',
      cssClass: 'contenidos-digital',
      mail: 'contacto@terminalcreativa.com',
      froalaKey: 'KidbykayuH-7b1C3qE-13pqmH-8w=='
    },
    'freelan': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_70/v1521727616/0_ifgh2m.png',
      name: 'Freelan',
      cssClass: 'freelan',
      mail: 'soporte@postedin.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'walmart': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_70/v1522182355/logo-walmart_ftibx1.png',
      name: 'Walmart',
      cssClass: 'walmart',
      mail: 'soporte@postedin.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'superama': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_70/v1522182554/logo-superama_vacem3.png',
      name: 'Superama',
      cssClass: 'superama',
      mail: 'soporte@postedin.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'xcaret': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_70,f_png/v1523374259/exx-h_feyawf.svg',
      name: 'Xcaretexperiencias',
      cssClass: 'xcaret',
      mail: 'soporte@postedin.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    },
    'sodimac-mx': {
      logo: 'https://res.cloudinary.com/postedin/image/upload/c_scale,h_70/v1523375961/Sodimac-BRANDBOOK-Oct-2014_tnnsr3_nnbpgs.jpg',
      name: 'Sodimac Mexico',
      cssClass: 'sodimac-mx',
      mail: 'soporte@postedin.com',
      froalaKey: 'SbA-32gassqwF2xsp=='
    }
  };

  function branding(params /* , hash*/) {
    var attribute = params[0];
    var brand = _environment.default.branding;

    if (!brands.hasOwnProperty(brand)) {
      // default back to postedin
      brand = 'postedin';
    }

    if (brands[brand].hasOwnProperty(attribute)) {
      return brands[brand][attribute];
    }

    return '';
  }

  exports.default = Ember.Helper.helper(branding);
});