define('postedin/components/ember-modal-dialog-positioned-container', ['exports', 'ember-modal-dialog/components/positioned-container'], function (exports, _positionedContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _positionedContainer.default.extend({
    didInsertElement: function didInsertElement() {
      this.updatePositioning();
      this.updateOnResize();
    },
    didUpdate: function didUpdate() {
      this.updatePositioning();
    },

    isPositionedDidChange: Ember.observer('isPositioned', function () {
      this.updatePositioning();
    }),
    alignCenter: function alignCenter() {
      var elementWidth = this.$().outerWidth();
      var elementHeight = this.$().outerHeight();

      this.$().css('left', '50%').css('top', '50%').css('margin-left', elementWidth * -0.5).css('margin-top', elementHeight * -0.6);
    },
    updatePositioning: function updatePositioning() {
      if (this._state !== 'inDOM') {
        return;
      }

      if (this.get('isPositioned')) {
        // This height hack is because I want our styles to both have a max height and overflow on the `ember-modal-dialog__body`
        // and you can't do that as the overflow requires a set height because CSS sucks. So we use the max height and then
        // this code sets the height so that the overflow works. We disable is each time we position again so that the
        // max height is still deciding the actual height.
        var $el = this.$();
        $el.css('height', '');
        this.updateTargetAttachment();
        $el.css('height', $el.innerHeight() + parseInt($el.css('borderTopWidth')) + parseInt($el.css('borderBottomWidth')));
      }
    },
    updateOnResize: function updateOnResize() {
      var _this = this;

      Ember.$(window).on('resize', function () {
        return _this.updatePositioning();
      });
      this.$('img').on('load', function () {
        return _this.updatePositioning();
      });
    }
  });
});