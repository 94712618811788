define('postedin/services/days', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'day',
    find: function find() {
      var _this = this;

      var month = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var query = '';
      if (month) {
        query = '?month=' + month;
      }

      return this.request('days' + query).then(function (response) {
        return _this.pushResponse(response);
      });
    },
    update: function update(day, holiday, capacity) {
      var _this2 = this;

      var params = {};
      if (Ember.isPresent(holiday)) {
        params.holiday = !!holiday;
      }

      if (Ember.isPresent(capacity)) {
        params.contentRequestCapacity = capacity;
      }

      return this.put('days/' + day.id, params).then(function (record) {
        return _this2.push(record);
      });
    },
    getNextAvailableDay: function getNextAvailableDay() {
      return this.request('days/next-available');
    }
  });
});