define('postedin/components/account-transaction-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showDetails: false,
    showAuditLog: false,
    hasDetails: Ember.computed('transaction', function () {
      return this.get('transaction.invoice.id');
    }),
    hasSecondRow: Ember.computed.or('transaction.auditLog.id', 'showId', 'showDate', 'hasDetails'),
    hasSecondRowOrDescription: Ember.computed.or('hasSecondRow', 'transaction.descriptionTemplate', 'transaction.note'),
    actions: {
      toggleShowDetails: function toggleShowDetails() {
        this.toggleProperty('showDetails');
        this.set('showAuditLog', false);
      },
      toggleShowAuditLog: function toggleShowAuditLog() {
        this.toggleProperty('showAuditLog');
        this.set('showDetails', false);
      }
    }
  }).reopenClass({
    positionalParams: ['transaction']
  });
});