define('postedin/components/power-select/before-options', ['exports', 'ember-power-select/components/power-select/before-options'], function (exports, _beforeOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _beforeOptions.default.extend({
    focusInput: function focusInput() {
      this.input = Ember.$('.ember-power-select-search-input:visible');
      if (this.input.length) {
        Ember.run.scheduleOnce('afterRender', this.input[0], 'focus');
      }
    }
  });
});