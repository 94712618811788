define('postedin/routes/login/registration', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      // registration disabled for now
      this.transitionTo('login');
    },
    model: function model() {
      return {
        name: null,
        email: null,
        password: null,
        password_confirmation: null
      };
    },
    setupController: function setupController(controller, model) {
      controller.set('errors', {});
      controller.set('saving', false);

      return this._super(controller, model);
    }
  });
});