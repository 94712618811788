define('postedin/helpers/topic-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.topicStatusLabel = topicStatusLabel;
  exports.topicStatusPath = topicStatusPath;
  function topicStatusLabel(status) {
    if (status === 'canceled' || status === 'rejected') {
      return 'rejected_canceled';
    }

    if (status === 'created' || status === 'assigned') {
      return 'created_assigned';
    }

    return status;
  }

  function topicStatusPath(status) {
    if (status === 'canceled' || status === 'rejected') {
      return 'rejected';
    }

    if (status === 'created' || status === 'assigned') {
      return 'created';
    }

    return status;
  }
});