define('postedin/helpers/class-scan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.classScan = classScan;


  function contains(string, substring) {
    return string.indexOf(substring) > -1;
  }

  function classScan(params /* , hash*/) {
    var action = params[0];

    if (contains(action, 'AssignmentType')) {
      return 'assignment_type';
    } else if (contains(action, 'Label')) {
      return 'label';
    } else if (contains(action, 'Note')) {
      return 'note';
    } else if (contains(action, 'Publisher')) {
      return 'publisher';
    } else if (contains(action, 'ReferenceSite')) {
      return 'reference_site';
    } else if (contains(action, 'ProjectUser')) {
      return 'project_user';
    } else if (contains(action, 'Account')) {
      return 'account';
    } else if (contains(action, 'OrganizationAdmin')) {
      return 'organization_admin';
    } else if (contains(action, 'OrganizationUser')) {
      return 'organization_user';
    } else if (contains(action, 'Project')) {
      return 'project';
    } else {
      return 'something';
    }
  }

  exports.default = Ember.Helper.helper(classScan);
});