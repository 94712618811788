define('postedin/models/invite', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    invites: Ember.inject.service(),
    resend: function resend() {
      return this.get('invites').resend(this);
    },
    cancel: function cancel() {
      return this.get('invites').cancel(this);
    },
    registerUser: function registerUser(data) {
      return this.get('invites').createPartner(this, data);
    },
    claim: function claim() {
      return this.get('invites').claim(this);
    }
  });
});