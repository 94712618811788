define('postedin/controllers/admin/organizations/edit', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    queryParams: ['delete'],
    delete: 0,
    organizations: Ember.inject.service(),
    reviewConcludableStatuses: ['expired', 'approved', 'rejected'],
    validate: function validate(data) {
      var rules = {
        name: 'required',
        maxProjects: 'required|min:1',
        newProjectsHideTypes: 'required|boolean',
        expireReviews: 'required|boolean',
        expireReviewsOnWeekdays: 'required|boolean',
        expireReviewsDays: ['integer', { min: 1 }, { required_if: ['expireReviews', true] }],
        expireReviewsSecondWarning: ['integer', { min: 0 }, { required_if: ['expireReviews', true] }],
        expireReviewsExpire: ['integer', { min: 1 }, { required_if: ['expireReviews', true] }],
        expireReviewsStatus: [{ required_if: ['expireReviews', true] }, 'in:expired,approved,rejected'],
        autoApprove: 'required|boolean',
        autoApproveDays: ['min:1', 'integer', { required_if: ['autoApprove', true] }],
        autoApproveSecondWarningDays: ['integer', 'min:0', { required_if: ['autoApprove', true] }],
        revisionsWaitOnReviews: ['boolean', { required_if: ['autoApprove', true] }],
        rejectedReviewRejectsRevision: 'boolean',
        approvedReviewsApproveRevision: 'boolean'
      };

      return this.validatorCheck(data, rules);
    },

    actions: {
      toggleDelete: function toggleDelete() {
        this.set('delete', this.get('delete') ? 0 : 1);
      },
      saveOrganization: function saveOrganization(organization, updates) {
        if (this.validate(updates)) {
          this.uiPromise(organization.update(updates.name, updates.maxProjects, updates.newProjectsHideTypes, updates.expireReviews, updates.expireReviewsOnWeekdays, updates.expireReviewsDays, updates.expireReviewsSecondWarning, updates.expireReviewsExpire, updates.expireReviewsStatus, updates.autoApprove, updates.autoApproveDays, updates.autoApproveSecondWarningDays, updates.revisionsWaitOnReviews, updates.rejectedReviewRejectsRevision, updates.approvedReviewsApproveRevision, updates.reviewRequestedSubject, updates.reviewRequestedLine1Manual, updates.reviewRequestedLine1Auto, updates.reviewRequestedLine2, updates.reviewReminderSubject, updates.reviewReminderLine1, updates.reviewReminderLine2, updates.reviewExpiredSubject, updates.reviewExpiredLine1, updates.reviewExpiredLine2), 'saveOrganization');
        } else {
          this.scrollToFirstError();
        }
      },
      deleteOrganization: function deleteOrganization(organization) {
        var _this = this;

        this.uiPromise(organization.delete(), 'deleteOrganization').then(function () {
          _this.transitionToRoute('admin.organizations');
        });
      }
    }
  });
});