define('postedin/components/content-request-article-guidelines-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('contentRequest.type.site', this.get('contentRequest.project.profile.website'));
      this.set('contentRequest.inputSeoKeywords', this.get('contentRequest.type.seoKeywords').join(', '));
    },


    requestSummaryDidChange: Ember.on('init', Ember.observer('contentRequest.summary', function () {
      if (!this.get('titleIsEdited')) {
        this.set('contentRequest.type.title', this.get('contentRequest.summary'));
      }
    })),

    inputSeoKeywordsDidChange: Ember.on('init', Ember.observer('contentRequest.inputSeoKeywords', function () {
      var keywords = this.get('contentRequest.inputSeoKeywords').split(',').map(function (keyword) {
        return keyword.trim();
      });

      this.set('contentRequest.type.seoKeywords', keywords);
    })),

    actions: {
      lockTitle: function lockTitle() {
        this.set('titleIsEdited', true);
      }
    }
  });
});