define('postedin/controllers/dashboard/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    showWhatsNext: Ember.computed('model.organizations.length', function () {
      if (this.get('session.user.isAdmin')) {
        return false;
      }

      if (this.get('projectsNeedingProfile.length')) {
        return true;
      }

      return false;
    }),
    canViewContentRequests: Ember.computed('session.projects', function () {
      return !!this.get('session.projects').some(function (project) {
        return project.canViewContentRequests;
      });
    }),
    projectsNeedingProfile: Ember.computed('model.organizations.length', function () {
      var projects = [];

      this.get('model.organizations').forEach(function (organization) {
        organization.get('projects').forEach(function (project) {
          if (!project.get('testing') && project.get('canUpdateProfile') && project.get('profile.createdAt') === project.get('profile.updatedAt')) {
            projects.push(project);
          }
        });
      });

      return projects;
    }),
    projectsWithoutBrand: Ember.computed('model.organizations.length', function () {
      var projects = [];

      this.get('model.organizations').forEach(function (organization) {
        organization.get('projects').forEach(function (project) {
          if (project.get('betaBrandMissing') && !project.get('testing')) {
            projects.push(project);
          }
        });
      });

      // order by createdAt so that newly created come first because other ones without it for a long time
      // are probably just test ones
      return projects.sort(function (a, b) {
        // unlikely they will be created exact same time so lets ignore that case
        return a.createdAt > b.createdAt ? -1 : 1;
      });
    }),
    organizationsAndProjects: Ember.computed('model.organizations.length', function () {
      var organizationsAndProjects = [];
      this.get('model.organizations').forEach(function (organization) {
        var projects = organization.get('projects').filter(function (project) {
          return !project.get('testing');
        });

        if (projects.get('length')) {
          organizationsAndProjects.push({
            organization: organization,
            projects: projects
          });
        }
      });

      return organizationsAndProjects;
    }),
    priorityQueues: Ember.computed('model.reviews.length', 'model.contentRequestsWithIssues.length', 'model.contentRequestsRejected.length', 'model.contentRequestsRequested.length', 'model.contentRequestsWithoutDeadlines.length', function () {
      var queues = [];

      if (this.get('model.reviews.length') || !this.get('canViewContentRequests')) {
        queues.push(this.createQueue('model.reviews', 'content-reviews-list', {
          titlePhrase: 'pending_reviews',
          routeTo: 'reviews',
          icon: 'icon-emphasized icon-file-eye'
        }));
      }

      if (this.get('model.requestedContent.length')) {
        queues.push(this.createQueue('model.requestedContent', 'content-requests-list', {
          titlePhrase: 'new_content_requests',
          icon: 'icon-emphasized icon-clock',
          customAction: function customAction(request) {
            return request.createBetaMissionUrl || false;
          }
        }));
      }

      if (this.get('model.contentRequestsRejected.length')) {
        queues.push(this.createQueue('model.contentRequestsRejected', 'content-requests-list', {
          titlePhrase: 'rejected_content',
          routeTo: 'revision',
          icon: 'icon-danger icon-cancel-circle',
          timestamp: 'updatedAt',
          classes: {
            title: 'title--class',
            list: 'list--danger',
            item: 'list__item--danger'
          }
        }));
      }

      if (this.get('model.contentRequestsWithoutDeadlines.length')) {
        queues.push(this.createQueue('model.contentRequestsWithoutDeadlines', 'content-requests-list', {
          titlePhrase: 'requests_without_deadlines',
          routeTo: '',
          icon: 'icon-warning'
        }));
      }

      if (this.get('model.contentRequestsWithIssues.length')) {
        queues.push(this.createQueue('model.contentRequestsWithIssues', 'content-requests-list', {
          titlePhrase: 'open_issues',
          routeTo: 'issues',
          icon: 'icon-warning icon-notification'
        }));
      }

      return queues;
    }),
    standaloneReviews: Ember.computed('priorityQueues.length', function () {
      if (this.get('priorityQueues.length') !== 1) {
        return false;
      }

      if (this.get('priorityQueues')[0].component !== 'content-reviews-list') {
        return false;
      }

      this.get('priorityQueues')[0].options.hideTitle = true;

      return this.get('priorityQueues')[0];
    }),
    createQueue: function createQueue(data, component) {
      var _this = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (typeof data === 'string') {
        data = this.get(data);
      }

      if (typeof options.customAction === 'function') {
        data.forEach(function (request) {
          Ember.set(request, 'customAction', options.customAction.call(_this, request));
        });
      }

      return {
        component: component,
        data: data,
        options: options
      };
    }
  });
});