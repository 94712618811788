define('postedin/components/power-select-typeahead/trigger', ['exports', 'ember-power-select-typeahead/components/power-select-typeahead/trigger'], function (exports, _trigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _trigger.default;
    }
  });
});