define('postedin/routes/dashboard/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service('simple-store'),
    organizations: Ember.inject.service(),
    contentRequests: Ember.inject.service(),
    scrollToOnActivate: true,

    model: function model() {
      return Ember.RSVP.hash({
        organizations: this.get('store').find('organization').sortBy('name'),
        reviews: this.get('session.user').loadAllPendingReviews(),
        contentRequestsWithIssues: this.get('contentRequests').allWithIssues(),
        contentRequestsRejected: this.get('contentRequests').allRejectedRequiringAttention(),
        contentRequestsWithoutDeadlines: this.get('contentRequests').allWithoutDeadlines(),
        requestedContent: this.get('contentRequests').allRequested()
      });
    }
  });
});