define('postedin/routes/account/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    app: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('app.admin')) {
        this.transitionTo('account.settings');
      }
    }
  });
});