define('postedin/controllers/beta-users/show/index', ['exports', 'postedin/mixins/validation-rules'], function (exports, _validationRules) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationRules.default, {
    betaUsers: Ember.inject.service(),
    errorsPassword: {},
    observeNewPassword: function () {
      this.set('errorsPassword.passwordTooShort', false);
      this.set('errorsPassword.newPasswordRequired', false);
      this.set('errorsPassword.newPasswordsDontMatch', false);
    }.observes('newPassword'),
    observeNewPasswordRepeated: function () {
      this.set('errorsPassword.newPasswordsDontMatch', false);
    }.observes('newPasswordRepeated'),
    actions: {
      toggleInfoEdit: function toggleInfoEdit() {
        this.set('editingInfo', !this.get('editingInfo'));
        if (this.get('editingInfo')) {
          this.set('info.name', this.get('model.name'));
          this.set('info.email', this.get('model.email'));
        }
      },
      toggleProfileEdit: function toggleProfileEdit() {
        this.set('editingProfile', !this.get('editingProfile'));
        if (this.get('editingProfile')) {
          this.set('profile.country', this.get('model.profile.country'));
          this.set('profile.genre', this.get('model.profile.genre'));
          this.set('profile.university', this.get('model.profile.university'));
          this.set('profile.carrer', this.get('model.profile.carrer'));
          this.set('profile.birthday', this.get('model.profile.birthday'));
          this.set('profile.biography', this.get('model.profile.biography'));
          this.set('profile.twitter', this.get('model.profile.twitter'));
          this.set('profile.linkedin', this.get('model.profile.linkedin'));
        }
      },
      saveInfo: function saveInfo() {
        var _this = this;

        this.set('savingInfo', true);
        this.get('betaUsers').save(this.get('model.id'), this.get('info')).then(function (record) {
          _this.set('editingInfo', false);
          _this.set('model', record);
        }).finally(function () {
          _this.set('savingInfo', false);
        });
      },
      saveProfile: function saveProfile() {
        var _this2 = this;

        this.set('savingProfile', true);
        this.get('betaUsers').saveProfile(this.get('model.id'), this.get('profile')).then(function (record) {
          _this2.set('editingProfile', false);
          _this2.set('model', record);
        }).finally(function () {
          _this2.set('savingProfile', false);
        });
      },
      savePassword: function savePassword() {
        var _this3 = this;

        this.set('errorPassword', false);
        this.set('errorFieldsPassword', false);

        var newPassword = this.get('newPassword');
        var newPasswordRepeated = this.get('newPasswordRepeated');
        var valid = true;

        if (!newPassword) {
          this.set('errorsPassword.newPasswordRequired', true);
          valid = false;
        } else if (newPassword.length < 6) {
          this.set('errorsPassword.passwordTooShort', true);
          valid = false;
        }

        if (valid && newPassword !== newPasswordRepeated) {
          this.set('errorsPassword.newPasswordsDontMatch', true);
          valid = false;
        }

        if (!valid) {
          return;
        }

        this.set('savingPassword', true);
        this.get('betaUsers').updatePassword(this.get('model.id'), { newPassword: newPassword }).then(function () {
          _this3.set('savingPassword', false);
          _this3.set('savedPassword', true);
          Ember.run.later(function () {
            return _this3.set('savedPassword', false);
          }, 4000);
          _this3.set('newPassword', '');
          _this3.set('newPasswordRepeated', '');
        }, function (respond) {
          _this3.set('savingPassword', false);
          if (respond.status === 422) {
            if (respond.responseJSON.error && respond.responseJSON.error === 'incorrect_password') {
              _this3.set('errorsPassword.incorrectPassword', true);
            }
          } else {
            _this3.set('errorPassword', true);
          }
        });
      }
    }
  });
});