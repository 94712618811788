define('postedin/mixins/ui-promise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    resetUiPromise: function resetUiPromise() {
      var stateName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'state';
      var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this;

      if (!context.get(stateName)) {
        context.set(stateName, {});
      }

      var reset = function reset(param) {
        context.set(stateName + '.' + param, false);
      };

      reset('waiting');
      reset('saved');
      reset('success');
      reset('error');
      reset('waitingAndSaved');
    },
    uiPromise: function uiPromise(promise) {
      var stateName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'state';
      var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this;

      var set = function set(param, value) {
        if (!(Ember.get(context, 'isDestroyed') || Ember.get(context, 'isDestroying'))) {
          Ember.set(context, stateName + '.' + param, value);
        }
      };

      var get = function get(param) {
        Ember.get(context, stateName + '.' + param);
      };

      if (!Ember.get(context, stateName)) {
        Ember.set(context, stateName, {});
      }

      set('waiting', true);
      set('saved', false);
      set('success', false);
      set('error', false);
      // sometimes we don't want to re-enable the form after saved and this makes it cleaner
      set('waitingAndSaved', true);
      set('promise', promise);
      Ember.run.cancel(get('saveTimer'));

      promise.then(function () {
        set('waiting', false);
        set('success', true);
        set('saved', true);
        set('saveTimer', Ember.run.later(function () {
          set('saved', false);
        }, 2000));
      }, function () {
        set('waiting', false);
        set('waitingAndSaved', false);
        set('error', true);
      });

      return promise;
    }
  });
});