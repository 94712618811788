define('postedin/helpers/is-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isAction = isAction;
  function isAction(params /* , hash*/) {
    var action = params[1];
    var log = params[0];

    return action === log.action;
  }

  exports.default = Ember.Helper.helper(isAction);
});