define('postedin/components/expand-pane', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    tagName: 'div',
    classNames: ['expandable'],
    classNameBindings: ['isCollapsed:expandable--collapsed', 'isExpanded:expandable--expanded', 'collapsible:expandable--collapsible'],
    isExpanded: Ember.computed.not('isCollapsed'),
    showCollapseLabel: Ember.computed.and('isExpanded', 'collapsible'),
    showExpandLabel: Ember.computed.alias('isCollapsed'),
    expandLabelCopy: Ember.computed('expandLabel', function () {
      return this.get('expandLabel') || this.get('i18n').t('show_more');
    }),
    collapseLabelCopy: Ember.computed('collapseLabel', function () {
      return this.get('collapseLabel') || this.get('i18n').t('show_less');
    }),
    expandIconClass: Ember.computed('expandIcon', function () {
      if (this.get('expandIcon') === false) {
        return false;
      }

      return this.get('expandIcon') || 'icon-arrow-down2';
    }),
    collapseIconClass: Ember.computed('collapseIcon', function () {
      if (this.get('collapseIcon') === false) {
        return false;
      }

      return this.get('collapseIcon') || 'icon-arrow-up2';
    }),
    didInsertElement: function didInsertElement() {
      if (this.$().height() > this.get('height')) {
        this.send('collapse');
      } else {
        this.set('collapsible', false);
      }
    },


    actions: {
      expand: function expand() {
        this.set('isCollapsed', false);
        this.$().height('auto');
      },
      collapse: function collapse() {
        this.set('isCollapsed', true);
        this.$().height(this.get('height'));
      }
    }
  });
});