define('postedin/helpers/calendar-weekdays', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calendarWeekdays = calendarWeekdays;
  function calendarWeekdays() {
    var i = 0;
    var day = (0, _moment.default)().startOf('isoWeek');
    var weekdays = [];
    while (i < 7) {
      weekdays.push((0, _moment.default)(day));
      day.add(1, 'day');

      i++;
    }

    return weekdays;
  }

  exports.default = Ember.Helper.helper(calendarWeekdays);
});