define('postedin/components/upload-box', ['exports', 'postedin/mixins/validation-rules', 'postedin/models/attachment'], function (exports, _validationRules, _attachment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_validationRules.default, {
    ajax: Ember.inject.service(),
    validate: function validate(file) {
      var documentFormats = ['pdf', 'ppt', 'pptx', 'doc', 'docx', 'psd', 'ai', 'avi', 'wmv', 'mov', 'mp4'];
      return !file || this.imageValidation(file) || this.fileValidation(file, documentFormats);
    },
    doUpload: function doUpload(upload) {
      var formData = new FormData();
      formData.append('file', upload.get('file'));

      upload.set('uploading', true);
      upload.set('failed', false);

      var startTime = new Date().getTime();
      var previousChunkPercent = 0;
      return this.get('ajax').post('/files', {
        xhr: function xhr() {
          var xhr = Ember.$.ajaxSettings.xhr();
          upload.set('xhr', xhr);
          if (xhr.upload) {
            upload.set('progress', 0.1);
            xhr.upload.addEventListener('progress', function (event) {
              if (event.lengthComputable) {
                // NOTE: we only got to 92% leaving the last 8% for the server uploading to the cloud
                var percentage = Math.floor(event.loaded / event.total * 92);
                upload.set('progress', percentage);
                upload.set('size', event.total);

                var now = new Date().getTime();
                var elapsedTime = (now - startTime) / 1000;

                var chunkPercent = (percentage - previousChunkPercent) / 100;
                var chunk = chunkPercent * event.total;
                var speed = chunk / elapsedTime;
                upload.set('speed', speed.toFixed(2));

                var eta = (event.total - event.loaded) / speed;
                // NOTE: 2 seconds added here, similar to the 92% above for the server processing
                upload.set('eta', Math.round(eta) + 2);
              }
            }, false);

            return xhr;
          }
        },
        contentType: false,
        data: formData,
        cache: false,
        processData: false,
        dataType: 'json'
      }).then(function (uploadedFile) {
        upload.set('progress', 100);
        upload.set('eta', false);
        upload.set('uuid', uploadedFile.uuid);
        upload.set('url', uploadedFile.url);
        upload.set('new', true);
        upload.set('uploading', false);
      }, function () {
        upload.set('failed', true);
        upload.set('uploading', false);
      });
    },
    addUpload: function addUpload(uploads, file) {
      var isValid = this.validate(file);

      var upload = _attachment.default.create({
        name: file.name,
        type: file.format,
        uuid: null,
        component: 'file-upload',
        progress: false,
        uploading: true,
        file: file
      });

      uploads.pushObject(upload);

      if (!isValid) {
        upload.set('uploading', false);
        upload.set('failed', true);
        upload.set('invalid', true);

        return;
      }

      return this.doUpload(upload);
    },

    actions: {
      startUpload: function startUpload() {
        var identifier = this.get('identifier');
        var uploads = this.get('uploads');

        // TODO: use jquery instead, had a bug with the order of nodes changing which broke this
        var selectedFiles = document.getElementsByClassName(identifier)[0].childNodes[0].files;

        for (var i = 0; i < selectedFiles.length; i++) {
          this.addUpload(uploads, selectedFiles[i]);
        }
      },
      cancelUpload: function cancelUpload(upload) {
        upload.get('xhr').abort();
      },
      retryUpload: function retryUpload(upload) {
        this.doUpload(upload);
      }
    }
  });
});