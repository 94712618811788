define('postedin/helpers/automatically-modified', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.automaticallyModified = automaticallyModified;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function automaticallyModified(stage, time, i18n) {
    var lastWarning = (0, _moment.default)(time);
    if (!lastWarning.isValid()) {
      return false;
    }

    var expires = (0, _moment.default)(lastWarning);
    switch (stage) {
      case '1_week':
        expires = expires.add(1, 'week');
        break;
      case '2_days':
        expires = expires.add(2, 'days');
        break;
      case '1_day':
        expires = expires.add(1, 'day');
        break;
    }

    var hoursToGo = expires.diff((0, _moment.default)(), 'hours');
    if (hoursToGo < 23) {
      if (hoursToGo > 12) {
        return i18n.t('in_less_than_a_day');
      }

      if (hoursToGo > 5) {
        return i18n.t('today');
      }

      if (hoursToGo > 1) {
        return i18n.t('in_the_coming_hours');
      }

      return i18n.t('now');
    }

    return expires.fromNow();
  }

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service('i18n'),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          stage = _ref2[0],
          time = _ref2[1];

      if (!stage || !time) {
        return;
      }

      return automaticallyModified(stage, time, this.get('i18n'));
    }
  });
});