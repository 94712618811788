define('postedin/components/annotated-html', ['exports', 'postedin/mixins/ui-promise', 'postedin/libs/annotatable-node', 'npm:tether'], function (exports, _uiPromise, _annotatableNode, _npmTether) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global rangy */

  var $ = Ember.$;

  var rangyTextHighlight = rangy.createClassApplier('annotation-text-highlight');

  exports.default = Ember.Component.extend(_uiPromise.default, {
    annotatableNodes: [],
    windowDetails: {},
    tetheredRange: false,
    showAnnotationForm: false,
    annotations: [],
    canAnnotate: true,
    hideResolved: true,
    windowDetailsDidChange: Ember.observer('selectedAnnotation', 'windowDetails.scrollPos.top', 'windowDetails.height', function () {
      Ember.run.later(function () {
        var $selectedAnnotation = $('[data-selected-annotation]');
        if ($selectedAnnotation.length) {
          var $parent = $selectedAnnotation.parent();
          $parent.css({
            minHeight: $selectedAnnotation.offset().top + $selectedAnnotation.height() - $parent.offset().top + 300
          });
        }
      });
    }),
    tetherToRange: Ember.computed('tetheredRange', 'windowDetails.height', function () {
      var range = this.get('tetheredRange');
      if (range) {
        var windowDetails = this.get('windowDetails');
        var rect = range.nativeRange.getClientRects()[0];

        return {
          bottom: windowDetails.height - windowDetails.scrollPos.top - rect.bottom + rect.height,
          left: windowDetails.scrollPos.left + rect.left
        };
      }

      return false;
    }),
    tetherToRangeStyle: Ember.computed('tetherToRange', function () {
      var pos = this.get('tetherToRange');
      return Ember.String.htmlSafe('position: absolute; bottom: ' + pos.bottom + 'px; left: ' + pos.left + 'px; z-index: 1');
    }),
    tetherToImageStyle: Ember.computed('tetherToImage', function () {
      var $img = this.get('tetherToImage');

      if (!$img) {
        return false;
      }

      var pos = $img.offset();

      pos.top -= 40;
      pos.left += $img.outerWidth() - 40;

      return Ember.String.htmlSafe('position: absolute; top: ' + pos.top + 'px; left: ' + pos.left + 'px; z-index: 1');
    }),
    tetherFormToImageStyle: Ember.computed('tetherFormToImage', function () {
      var $img = this.get('tetherFormToImage');

      if (!$img) {
        return false;
      }

      var pos = $img.offset();

      pos.left += $img.outerWidth();

      return Ember.String.htmlSafe('position: absolute; top: ' + pos.top + 'px; left: ' + pos.left + 'px; z-index: 1');
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.updateWindowDetails();
      this.set('_updateWindowDetailsHandler', function (e) {
        return _this.updateWindowDetails(e);
      });
      window.addEventListener('resize', this.get('_updateWindowDetailsHandler'));
      window.addEventListener('scroll', this.get('_updateWindowDetailsHandler'));
    },

    annotationsChanged: Ember.observer('annotations.length', 'annotations.@each.resolvedAt', function () {
      var _this2 = this;

      if (this.get('ready')) {
        // NOTE: needs to run next cycle so it doesn't break from an existing range used while
        // the form is shown
        Ember.run.later(function () {
          // we might have just resolved an annotation
          _this2.get('annotations').forEach(function (annotation) {
            if (annotation.get('resolvedAt')) {
              $('.annotated-text-' + annotation.id).removeClass('annotated-text-' + annotation.id).unbind('mouseover').unbind('mouseout').unbind('click');

              $('[data-annotated-image=' + annotation.id + ']').removeAttr('data-annotated-image').unbind('mouseover').unbind('mouseout').unbind('click');
            }
          });

          _this2.setupImageAnnotations();
          _this2.setupExistingAnnotations();
        });
      }
    }),
    selectedAnnotationChanged: Ember.observer('selectedAnnotation', function () {
      this.applySelectedAnnotationClass(this.get('selectedAnnotation'));
    }),
    updateWindowDetails: function updateWindowDetails() {
      this.set('windowDetails', {
        // window.innerHeight worked for this one day and then suddenly not, I have no idea why
        // now works with the documents height
        // height: window.innerHeight,
        height: $('body').height(),
        scrollPos: {
          left: window.scrollX,
          top: window.scrollY
        }
      });
    },
    showAnnotationTether: function showAnnotationTether(annotatableNode) {
      if (!this.get('showAnnotateForm')) {
        this.set('showAnnotateButton', true);

        var startNode = annotatableNode.getSelectionRange().startContainer.annotatableNode;
        this.set('$startNode', startNode.$node);
        this.set('startNode', this.get('annotatableNodes').indexOf(startNode));

        var endNode = annotatableNode.getSelectionRange().endContainer.annotatableNode;
        this.set('$endNode', endNode.$node);
        this.set('endNode', this.get('annotatableNodes').indexOf(endNode));

        this.set('annotatableNode', annotatableNode);
        this.set('selection', annotatableNode.getSelection());
        this.set('tetheredRange', annotatableNode.getSelectionRange());

        this.updateWindowDetails();
      }
    },
    hideAnnotationTether: function hideAnnotationTether() {
      this.set('showAnnotateButton', false);
    },
    setup: function setup() {
      var _this3 = this;

      this.formatContentTable();

      Ember.run.later(function () {
        _this3.set('ready', false);

        _this3.setupImageAnnotations();

        var blockElements = ['address', 'article', 'aside', 'blockquote', 'dd', 'div', 'dl', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'li', 'main', 'nav', 'ol', 'output', 'p', 'pre', 'section', 'table', 'tfoot', 'ul', 'video',
        // include td and th, not block but we need to account for it
        'th', 'td'];

        // we go through and find all blocks of text. first we get a block element and then see if it has no
        // other block elements, if it doesn't we use it... assumes HTML isn't terrible
        var $body = _this3.$('.annotated-html');
        if (_this3.get('legacyAnnotations')) {
          $body = _this3.$();
        }

        $body.find(blockElements.join(', ')).each(function (_, node) {
          var $node = $(node);
          if (!$node.text().trim()) {
            return;
          }

          // needs at least 1 direct text node with text or inline element with text
          var hasText = $node.contents().filter(function (_, node) {
            return node.nodeType === 3 && node.nodeValue.trim();
          }).length;
          if (!hasText) {
            var inlineElements = ['b', 'big', 'i', 'small', 'tt', 'abbr', 'acronym', 'cite', 'code', 'dfn', 'em', 'kbd', 'strong', 'time', 'var', 'a', 'span', 'sub', 'sup'];
            hasText = $node.find(inlineElements.join(', ')).contents().filter(function (_, node) {
              return node.nodeType === 3 && node.nodeValue.trim();
            }).length;
          }

          if (hasText) {
            var annotatableNode = new _annotatableNode.default($node);
            annotatableNode.on('showAnnotatableTether', function (event) {
              _this3.showAnnotationTether(annotatableNode, event);
            });
            annotatableNode.on('hideAnnotatableTether', function (event) {
              _this3.hideAnnotationTether(annotatableNode, event);
            });

            _this3.get('annotatableNodes').push(annotatableNode);
          }

          _this3.set('ready', true);
        });

        _this3.setupExistingAnnotations();
        _this3.set('ready', true);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setup();
    },

    // This is so we can reset everything when the route doesn't fully change (just model for example)
    // but the state does, in revisions I send in model.id to this to trigger.
    idDidChange: Ember.observer('id', function () {
      this.cleanup();
      this.setup();
    }),
    setupImageAnnotations: function setupImageAnnotations() {
      var _this4 = this;

      this.$('.annotated-html img').each(function (_, img) {
        var $img = $(img);

        $img.on('mouseover', function () {
          return _this4.mouseOverImage($img);
        });
      });
    },
    mouseOverImage: function mouseOverImage($img) {
      var _this5 = this;

      if ($img.attr('data-annotated-image')) {
        this.set('showImageAnnotationTrigger', false);

        return;
      }

      this.set('showImageAnnotationTrigger', true);
      this.set('tetherToImage', $img);

      if (!this.get('showImageAnnotateForm')) {
        this.set('annotationImage', $img);
      }

      var hideTrigger = function hideTrigger(event) {
        if (event.target === $img[0]) {
          $(document).one('click', hideTrigger);
          return;
        }

        _this5.set('showImageAnnotationTrigger', false);
      };

      $(document).one('click', hideTrigger);
    },
    cleanup: function cleanup() {
      this.set('ready', false);

      this.get('annotations').forEach(function (a) {
        return a.set('_setup');
      });

      this.get('annotatableNodes').forEach(function (a) {
        a.destroy();
      });
      this.get('annotatableNodes').clear();
      window.removeEventListener('resize', this.get('_updateWindowDetailsHandler'));
      window.removeEventListener('scroll', this.get('_updateWindowDetailsHandler'));
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    setupExistingAnnotations: function setupExistingAnnotations() {
      var _this6 = this;

      this.get('annotations').forEach(function (annotation) {
        if (annotation.get('_setup')) {
          return;
        }

        if (_this6.get('hideResolved') && annotation.get('resolvedAt')) {
          return;
        }

        if (typeof annotation.get('location.imageIndex') !== 'undefined') {
          _this6.setupImageAnnotation(annotation);
        } else {
          _this6.setupTextAnnotation(annotation);
        }
      });

      // since we have some new textNodes that need reference back to annotatableNode instances...
      this.get('annotatableNodes').forEach(function (annotatable) {
        annotatable.addTextNodeReferences();
      });
    },
    setupTextAnnotation: function setupTextAnnotation(annotation) {
      var _this7 = this;

      var startResult = { count: 0 };
      var endResult = { count: 0 };

      if (annotation.location.legacyNotePosition) {
        var positionIndex = annotation.location.legacyNotePosition - 1;
        var $body = this.$().find('.annotated-html');
        var nodes = [];
        // get the legacy indexes and use it to find the index of the new system
        $body.children().each(function () {
          var $child = $(this);
          var $clone = $child.clone();
          $clone.find('script').remove();
          if ($clone.html().trim()) {
            nodes.push($child);
          }
        });

        var $node = nodes[positionIndex];

        if (!$node) {
          return;
        }

        var endOffset = 0;
        var findEndOffset = function findEndOffset(contents) {
          contents.each(function (_, node) {
            if (node.annotatableNode) {
              endOffset += node.length;
            }

            var c = $(node).contents();
            if (c.length) {
              findEndOffset(c);
            }
          });
        };

        findEndOffset($node.contents());

        if (!endOffset) {
          if ($node.children().length === 1 && $node.children()[0].tagName === 'IMG') {
            this.setupImageAnnotation(annotation, $node);
          }

          return;
        }

        this.findTextNodeByTotalOffset($node, 0, startResult);
        this.findTextNodeByTotalOffset($node, endOffset, endResult);
      } else {
        var $startNode = this.get('annotatableNodes')[annotation.location.startNode].$node;
        this.findTextNodeByTotalOffset($startNode, annotation.location.startOffset, startResult);

        var $endNode = this.get('annotatableNodes')[annotation.location.endNode].$node;
        this.findTextNodeByTotalOffset($endNode, annotation.location.endOffset, endResult);
      }

      var range = rangy.createRange();
      range.setStartAndEnd(startResult.node, startResult.offset, endResult.node, endResult.offset);
      // NOTE: we use the `annotated-text-{id}` here to workaround an issue where rangy is "too smart" to
      // overlap classes and we want them overlapping to indicate an annotation inside another. This also
      // requires a hacky (wildcard) CSS selector.
      // https://github.com/timdown/rangy/issues/327
      // NOTE 2: we actually use the id in other areas now for here, so if removing this it needs to be added
      // as a data variable for the tethers
      var rangyAnnotatedText = rangy.createClassApplier('annotated-text-' + annotation.id, {
        onElementCreate: function onElementCreate(element) {
          var $element = $(element);
          $element.on('mouseover', function () {
            return _this7.togglePreviewAnnotation(true, annotation);
          });
          $element.on('mouseout', function () {
            return _this7.togglePreviewAnnotation(false, annotation);
          });
          $element.on('click', function () {
            return _this7.toggleSelectAnnotation($element, annotation);
          });
        }
      });
      rangyAnnotatedText.applyToRange(range);

      annotation.set('_setup', true);
    },
    setupImageAnnotation: function setupImageAnnotation(annotation) {
      var _this8 = this;

      var $node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (annotation.get('_setup')) {
        return;
      }

      if (!$node) {
        $node = this.$().find('.annotated-html img').eq(annotation.get('location.imageIndex'));
      }

      if (!$node || !$node.length) {
        return;
      }

      $node.attr('data-annotated-image', annotation.id);

      $node.on('mouseover', function () {
        return _this8.togglePreviewAnnotation(true, annotation);
      });
      $node.on('mouseout', function () {
        return _this8.togglePreviewAnnotation(false, annotation);
      });
      $node.on('click', function () {
        return _this8.toggleSelectAnnotation($node, annotation);
      });

      annotation.set('_setup', true);
    },
    toggleSelectAnnotation: function toggleSelectAnnotation($target, annotation) {
      var oldSelectedAnnotation = this.get('selectedAnnotation');
      if (oldSelectedAnnotation && oldSelectedAnnotation.id === annotation.id) {
        // deselect
        this.set('selectedAnnotation');
        return;
      }

      this.set('selectedAnnotation', annotation);

      // if a selected one already shown lets just move it right away with new data
      if (oldSelectedAnnotation) {
        var $selectedAnnotation = $('[data-selected-annotation=' + oldSelectedAnnotation.id + ']');
        if ($selectedAnnotation.length) {
          $selectedAnnotation.animate({
            top: $target.offset().top
          });

          return;
        }
      }

      var $annotationComment = $('[data-annotation-comment=' + annotation.id + ']');
      var top = 0;
      if ($annotationComment.length) {
        top = $annotationComment.offset().top;
      }

      Ember.run.next(function () {
        Ember.run.next(function () {
          var $selectedAnnotation = $('[data-selected-annotation=' + annotation.id + ']');
          if ($selectedAnnotation.length) {
            top = top || $selectedAnnotation.parent().offset().top;
            $selectedAnnotation.css({
              position: 'absolute',
              top: top,
              width: $selectedAnnotation.parent().width()
            });
            $selectedAnnotation.animate({
              top: $target.offset().top
            });
          }
        });
      });
    },
    applySelectedAnnotationClass: function applySelectedAnnotationClass(annotation) {
      $('.annotation-selected').removeClass('annotation-selected');

      if (annotation) {
        $('.annotated-text-' + annotation.id).addClass('annotation-selected');
      }
    },
    togglePreviewAnnotation: function togglePreviewAnnotation(show, annotation) {
      if (show) {
        var annotationPreview = this.get('annotationPreview');
        if (annotationPreview && annotationPreview.id !== annotation.id) {
          return;
        }

        annotation.set('showPreview', true);
        this.set('annotationPreview', annotation);

        var $actualTarget = void 0;

        if (typeof annotation.get('location.imageIndex') !== 'undefined') {
          $actualTarget = $('[data-annotated-image=' + annotation.id + ']');
        } else {
          var $targets = $('.annotated-text-' + annotation.id);

          // because of multiline inline elements we need to get the first of our targets
          // and insert a new target so we can tether the correct position
          // see: http://stackoverflow.com/a/995891/1262923
          $actualTarget = $('<span>');
          $targets.first().prepend($actualTarget);
        }

        Ember.run.later(function () {
          // we should now have the preview shown
          var $preview = $('[data-annotation-preview-id=' + annotation.id + ']');
          if ($preview.length) {
            new _npmTether.default({
              element: $preview,
              target: $actualTarget,
              attachment: 'bottom left',
              targetAttachment: 'top left',
              offset: '0 0'
            });
          }
        });
      } else {
        annotation.set('showPreview', false);
        this.set('annotationPreview');

        // There is a bug that never happened when making this but does now,
        // so either a browser, ember or tether update must have caused it...
        // basically the element is never removed from the DOM when using tether,
        // it is like ember forgets about it, but we never forget! nuke it
        $('[data-annotation-preview-id=' + annotation.id + ']').remove();
      }
    },
    findTextNodeByTotalOffset: function findTextNodeByTotalOffset(node, totalOffset, result) {
      var _this9 = this;

      $(node).contents().each(function (_, n) {
        if (result.node) {
          return false;
        }

        if (n.nodeType === 3 && result.count + n.nodeValue.length >= totalOffset) {
          result.node = n;
          result.offset = totalOffset - result.count;
          return false;
        }

        if (n.nodeType === 3) {
          result.count += n.nodeValue.length;
        } else {
          _this9.findTextNodeByTotalOffset(n, totalOffset, result);
        }
      });
    },
    findTotalOffsetInParentNode: function findTotalOffsetInParentNode(node, textNode, result) {
      var _this10 = this;

      $(node).contents().each(function (_, n) {
        if (n === textNode) {
          result.totalOffset += result.count;
          return false;
        }

        if (n.nodeType === 3) {
          result.count += n.nodeValue.length;
        } else {
          _this10.findTotalOffsetInParentNode(n, textNode, result);
        }
      });
    },
    toggleAnnotateForm: function toggleAnnotateForm(force) {
      if (force === true || force === false) {
        this.set('showAnnotateForm', force);
      } else {
        this.toggleProperty('showAnnotateForm');
      }

      if (this.get('showAnnotateForm')) {
        var range = this.get('tetheredRange');
        rangyTextHighlight.applyToRange(range);

        var result = { totalOffset: range.startOffset, count: 0 };
        this.findTotalOffsetInParentNode(this.get('$startNode'), range.startContainer, result);
        this.set('startOffset', result.totalOffset);

        result = { totalOffset: range.endOffset, count: 0 };
        this.findTotalOffsetInParentNode(this.get('$endNode'), range.endContainer, result);
        this.set('endOffset', result.totalOffset);

        this.set('selectedText', range.toString());

        Ember.run.later(function () {
          $('.annotation-form textarea').focus();
        });
      } else {
        rangyTextHighlight.undoToRange(this.get('tetheredRange'));
        this.set('annotationComment', '');
        this.set('showAnnotateButton', false);
        this.set('tetheredRange', false);
      }
    },
    toggleImageAnnotateForm: function toggleImageAnnotateForm(force) {
      if (force === true || force === false) {
        this.set('showImageAnnotateForm', force);
      } else {
        this.toggleProperty('showImageAnnotateForm');
      }

      if (this.get('showImageAnnotateForm')) {
        this.set('tetherFormToImage', this.get('tetherToImage'));
      } else {
        this.set('showImageAnnotationTrigger', false);
        this.set('tetherToImage', false);
        this.set('annotationImage', false);
        this.set('annotationImageComment', '');
      }
    },
    formatContentTable: function formatContentTable() {
      var tables = this.$('table');

      tables.each(function (_, table) {
        if (Ember.$(table).find('tr').length < 2) {
          return;
        }

        Ember.$(table).find('th, td').css('width', '');

        var colCount = Ember.$('tr', table).first().find('td, th').length;

        Ember.$(table).css('table-layout', 'fixed');

        Ember.$('tr:eq(0) td', table).addClass('table-header');

        Ember.$('img', table).css('min-width', 96);

        while (colCount) {
          // Collect cells by column. All but header row.
          var cells = Ember.$('tr:not(:first-child) td:nth-child(' + colCount + ')', table);

          // Column has only numbers?
          var isNumbers = colCount === 1 && cells.get().every(function (cell) {
            return !RegExp(/[^0-9.,:()]/).test(cell.innerText);
          });

          // Column has only links?
          var isLinks = cells.get().every(function (cell) {
            return Ember.$(cell).text().trim() === Ember.$('a', cell).text().trim();
          });

          // Add .is-number class, standarize header
          if (isNumbers) {
            cells.addClass('is-number').attr('width', 30);
            $('tr:first-child td:nth-child(' + colCount + ')').text('#').addClass('is-number').attr('width', 30);
          }

          // Wrap links and make them open in new window
          if (isLinks) {
            cells.addClass('is-link');
            $('tr:first-child td:nth-child(' + colCount + ')').addClass('is-link');

            $('.revision-content td.is-link a:not(.wrapped)').each(function (index, link) {
              link = Ember.$(link);

              link.text('Open').attr('target', '_blank').addClass('wrapped').wrap('<span class="link-wrap"></span>');
            });
          }

          colCount--;
        }
      });
    },


    actions: {
      saveAnnotation: function saveAnnotation(comment, selectedText, startNode, endNode, startOffset, endOffset) {
        var _this11 = this;

        if (!comment.trim()) {
          return;
        }

        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this11.sendAction('saveAnnotation', comment, selectedText, startNode, endNode, startOffset, endOffset, { resolve: resolve, reject: reject });
        });

        this.uiPromise(promise).then(function () {
          _this11.toggleAnnotateForm(false);
        });
      },
      toggleAnnotateForm: function toggleAnnotateForm(force) {
        this.toggleAnnotateForm(force);
      },
      saveImageAnnotation: function saveImageAnnotation(comment, $img) {
        var _this12 = this;

        if (!comment.trim()) {
          return;
        }

        var imageIndex = this.$('.annotated-html img').index($img);

        if (imageIndex < 0) {
          return;
        }

        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this12.sendAction('saveImageAnnotation', comment, '<img src="' + $img.attr('src') + '">', imageIndex, { resolve: resolve, reject: reject });
        });

        this.uiPromise(promise).then(function () {
          _this12.toggleImageAnnotateForm(false);
        });
      },
      toggleImageAnnotateForm: function toggleImageAnnotateForm(force) {
        this.toggleImageAnnotateForm(force);
      }
    }
  });
});