define('postedin/routes/admin-organizations/show/suspend', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    organizations: Ember.inject.service(),
    model: function model() {
      var organization = this.modelFor('admin-organizations.show');
      return Ember.RSVP.hash({
        model: organization,
        users: this.get('organizations').admins(organization.id)
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.model);
      var form = {
        suspending: hash.model.get('suspending'),
        suspended: hash.model.get('suspended') ? 'yes' : 'no'
      };
      controller.set('form', form);
      var users = hash.users.map(function (user) {
        return Ember.Object.create({
          id: user.id,
          name: user.name,
          email: user.email,
          tempType: user.tempType,
          isChecked: true
        });
      });
      controller.set('users', users);
      controller.set('saving', false);
    }
  });
});