define('postedin/services/legacy-content-types', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'legacy-content-type',
    all: function all() {
      var _this = this;

      return this.request('legacy-content-types').then(function (response) {
        return _this.pushResponse(response);
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('legacy-content-types/' + id).then(function (record) {
        return _this2.push(record);
      });
    },
    create: function create(type) {
      var _this3 = this;

      return this.post('legacy-content-types/', type).then(function (record) {
        return _this3.push(record);
      });
    },
    update: function update(type) {
      var _this4 = this;

      return this.put('legacy-content-types/' + type.get('id'), type).then(function (record) {
        return _this4.push(record);
      });
    },
    destroy: function destroy(type) {
      var _this5 = this;

      return this.delete('legacy-content-types/' + type.get('id')).then(function () {
        return _this5.removeObject(type.get('id'));
      });
    }
  });
});