define('postedin/components/publishers/publisher-send-radio-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PublisherSendRadioButtonComponent = Ember.Component.extend({
    isChecked: Ember.computed('checked', 'value', function () {
      return this.get('checked') === this.get('value');
    }),
    click: function click() {
      this.set('checked', this.get('value'));
    },
    keyUp: function keyUp(event) {
      // space pressed when element :active should check it like a normal radio input
      if (event.keyCode === 32) {
        this.click();
      }
    }
  });

  PublisherSendRadioButtonComponent.reopenClass({
    positionalParams: []
  });

  exports.default = PublisherSendRadioButtonComponent;
});