define('postedin/services/release-notes', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'release-note',
    find: function find() {
      var _this = this;

      return this.request('release-notes').then(function (response) {
        return _this.pushResponse(response);
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('release-notes/' + id).then(function (record) {
        return _this2.push(record);
      });
    },
    create: function create(title, description) {
      var _this3 = this;

      return this.post('release-notes', { title: title, description: description }).then(function (record) {
        return _this3.push(record);
      });
    },
    update: function update(_ref, title, description) {
      var id = _ref.id;

      var _this4 = this;

      return this.put('release-notes/' + id, { title: title, description: description }).then(function (record) {
        return _this4.push(record);
      });
    },
    remove: function remove(_ref2) {
      var _this5 = this;

      var id = _ref2.id;

      return this.delete('release-notes/' + id).then(function () {
        return _this5.removeObject(id);
      });
    },
    unreadCount: function unreadCount() {
      return this.request('release-notes/unread-count').then(function (response) {
        return response.count;
      });
    }
  });
});