define('postedin/helpers/slug-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.slugId = slugId;
  function slugId(name, id) {
    if (!name || !name.slugify) {
      // Ember.Logger.warn('Can\'t slugify given parameter. (' + typeof(name) + ')');
      return '';
    }

    return name.slugify() + '_' + id;
  }

  exports.default = Ember.Helper.helper(function (params) {
    return slugId(params[0], params[1]);
  });
});