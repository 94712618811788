define('postedin/services/content-article-revisions', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'content-article-revision',
    find: function find(id) {
      var _this = this;

      return this.request('content-article-revisions/' + id).then(function (record) {
        return _this.push(record);
      });
    },
    update: function update(revision, title, body, metaDescription, uploads, attachments) {
      var _this2 = this;

      var data = {
        title: title,
        body: body,
        metaDescription: metaDescription,
        uploads: uploads,
        attachments: attachments
      };

      return this.put('content-article-revisions/' + revision.id, data).then(function (record) {
        return _this2.push(record);
      });
    },
    adminUpdate: function adminUpdate(revision, updates) {
      var _this3 = this;

      return this.put('content-article-revisions/' + revision.id + '/admin', updates).then(function (revision) {
        return _this3.push(revision);
      });
    },
    publications: function publications(revision) {
      var _this4 = this;

      return this.request('content-article-revisions/' + revision.id + '/publications').then(function (response) {
        return _this4.pushResponse(response, 'publisher-publication');
      });
    },
    resetAutoConclude: function resetAutoConclude(revision) {
      var _this5 = this;

      return this.post('content-article-revisions/' + revision.id + '/auto-conclude').then(function (record) {
        return _this5.push(record);
      });
    },
    disableAutoConclude: function disableAutoConclude(revision) {
      var _this6 = this;

      return this.delete('content-article-revisions/' + revision.id + '/auto-conclude').then(function (record) {
        return _this6.push(record);
      });
    },
    approve: function approve(revision, comment, rating, attachments) {
      var _this7 = this;

      var data = {
        comment: comment,
        rating: rating,
        attachments: attachments
      };

      return this.post('content-article-revisions/' + revision.id + '/approve', data).then(function (record) {
        return _this7.push(record);
      });
    },
    reject: function reject(revision, comment, attachments, revisionRequested) {
      var _this8 = this;

      var data = {
        comment: comment,
        attachments: attachments,
        revisionRequested: revisionRequested
      };

      return this.post('content-article-revisions/' + revision.id + '/reject', data).then(function (record) {
        return _this8.push(record);
      });
    },
    requestReview: function requestReview(revision, reviewers) {
      var _this9 = this;

      var data = {
        users: reviewers.map(function (user) {
          return user.get('id');
        })
      };

      return this.post('content-article-revisions/' + revision.id + '/reviews', data).then(function (record) {
        record.reviews.forEach(function (r) {
          return r._emberModel = 'content-review';
        });

        return _this9.push(record);
      });
    },
    comment: function comment(revision, _comment, attachments) {
      var _this10 = this;

      var data = {
        body: _comment,
        attachments: attachments
      };

      return this.post('content-article-revisions/' + revision.id + '/comments', data).then(function (record) {
        return _this10.push(record, 'comment');
      });
    },
    annotate: function annotate(revision, comment, text, location) {
      var _this11 = this;

      var data = {
        comment: comment,
        text: text,
        location: location
      };

      return this.post('content-article-revisions/' + revision.id + '/annotations', data).then(function (record) {
        return _this11.push(record, 'annotation');
      });
    },
    flagAsPublished: function flagAsPublished(revision, comment) {
      var _this12 = this;

      return this.post('content-article-revisions/' + revision.id + '/published', { comment: comment }).then(function (record) {
        return _this12.push(record);
      });
    },
    addUpload: function addUpload(revision, upload) {
      var _this13 = this;

      return this.post('content-article-revisions/' + revision.id + '/uploads', upload).then(function (revision) {
        return _this13.push(revision);
      });
    }
  });
});