define('postedin/helpers/css-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cssClass = cssClass;
  function cssClass(str) {
    if (str instanceof Array) {
      str = str[0];
    }

    return str ? Ember.String.dasherize(str) : '';
  }

  exports.default = Ember.Helper.helper(cssClass);
});