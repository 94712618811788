define('postedin/services/faq', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'faq-question',
    find: function find() {
      var _this = this;

      return this.request('faq-questions').then(function (response) {
        return _this.pushResponse(response);
      });
    },
    findAll: function findAll() {
      var _this2 = this;

      return this.request('faq-questions/all').then(function (response) {
        return _this2.pushResponse(response);
      });
    },
    findById: function findById(id) {
      var _this3 = this;

      return this.request('faq-questions/' + id).then(function (record) {
        return _this3.push(record);
      });
    },
    create: function create(question, shortAnswer, active, category, answer) {
      var _this4 = this;

      var data = {
        question: question, shortAnswer: shortAnswer, answer: answer, active: active,
        faqCategoryId: category.id
      };

      return this.post('faq-questions', data).then(function (record) {
        return _this4.push(record);
      });
    },
    update: function update(_ref, question, shortAnswer, active, category, answer) {
      var id = _ref.id;

      var _this5 = this;

      var data = {
        question: question, shortAnswer: shortAnswer, answer: answer, active: active,
        faqCategoryId: category.id
      };

      return this.put('faq-questions/' + id, data).then(function (record) {
        return _this5.push(record);
      });
    },
    remove: function remove(_ref2) {
      var _this6 = this;

      var id = _ref2.id;

      return this.delete('faq-questions/' + id).then(function () {
        return _this6.removeObject(id);
      });
    },
    swapOrder: function swapOrder(_ref3, displayOrder) {
      var _this7 = this;

      var id = _ref3.id;

      return this.post('faq-questions/' + id + '/swap-order', { displayOrder: displayOrder }).then(function (record) {
        return _this7.push(record);
      });
    },
    findCategory: function findCategory(id) {
      var _this8 = this;

      return this.request('faq-categories/' + id).then(function (record) {
        return _this8.push(record, 'faq-category');
      });
    },
    findCategories: function findCategories() {
      var _this9 = this;

      return this.request('faq-categories').then(function (response) {
        return _this9.pushResponse(response, 'faq-category');
      });
    },
    createCategory: function createCategory(name, active, type) {
      var _this10 = this;

      var data = { name: name, active: active, type: type };

      return this.post('faq-categories', data).then(function (record) {
        return _this10.push(record, 'faq-category');
      });
    },
    updateCategory: function updateCategory(_ref4, name, active, type) {
      var id = _ref4.id;

      var _this11 = this;

      var data = { name: name, active: active, type: type };

      return this.put('faq-categories/' + id, data).then(function (record) {
        return _this11.push(record, 'faq-category');
      });
    },
    removeCategory: function removeCategory(_ref5) {
      var _this12 = this;

      var id = _ref5.id;

      return this.delete('faq-categories/' + id).then(function () {
        return _this12.removeObject(id, 'faq-category');
      });
    },
    swapCategoryOrder: function swapCategoryOrder(_ref6, displayOrder) {
      var _this13 = this;

      var id = _ref6.id;

      return this.post('faq-categories/' + id + '/swap-order', { displayOrder: displayOrder }).then(function (record) {
        return _this13.push(record, 'faq-category');
      });
    }
  });
});