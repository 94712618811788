define('postedin/services/project-assignment-types', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    remove: function remove(id) {
      return this.delete('project-assignment-types/' + id);
    }
  });
});