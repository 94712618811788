define('postedin/routes/admin/release-notes/create', ['exports', 'postedin/models/release-note'], function (exports, _releaseNote) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return _releaseNote.default.create({ title: '', description: '' });
    }
  });
});