define('postedin/components/docode-verified', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'docode-verified',
    didRender: function didRender() {
      var tooltip = this.get('tooltipText');
      if (tooltip) {
        this.set('tooltip', new Tooltip({
          target: this.$()[0],
          content: tooltip.toString(),
          classes: 'tooltip-theme-twipsy',
          position: this.get('position') || 'top center'
        }));
      }
    },
    willDestroy: function willDestroy() {
      var tooltip = this.get('tooltip');
      if (tooltip) {
        tooltip.remove();
      }
    }
  });
});