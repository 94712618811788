define('postedin/components/project-user-roles/preset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      selectPreset: function selectPreset(preset) {
        this.sendAction('selectPreset', preset);
      },
      selectCustomPreset: function selectCustomPreset() {
        this.sendAction('selectCustomPreset');
      }
    }
  });
});