define('postedin/helpers/t-stage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tStage = tStage;
  function tStage(params /* , hash*/) {
    var status = params[0];
    var stages = {
      planning: ['suggested', 'requested', 'checking_sources'],
      content: ['searching', 'assigned', 'quality_assurance', 'submitted', 'writing', 'reviewing'],
      done: ['written', 'published'],
      closed: ['rejected', 'canceled', 'expired']
    };

    if (stages.planning.indexOf(status) !== -1) {
      return 'planning';
    }

    if (stages.content.indexOf(status) !== -1) {
      return 'content';
    }

    if (stages.done.indexOf(status) !== -1) {
      return 'done';
    }

    if (stages.closed.indexOf(status) !== -1) {
      return 'closed';
    }

    return status;
  }

  exports.default = Ember.Helper.helper(tStage);
});