define('postedin/controllers/beta-users/show/payment-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    betaUsers: Ember.inject.service(),
    international: Ember.computed('model', function () {
      return this.get('model.outsideOfChile') === 1;
    }),

    actions: {
      toggleEdit: function toggleEdit() {
        this.set('editing', !this.get('editing'));
      },
      save: function save() {
        var _this = this;

        this.set('saving', true);
        this.get('betaUsers').savePaymentData(this.get('model.id'), this.get('model')).then(function (record) {
          _this.set('editing', false);
          _this.set('model', record);
        }).finally(function () {
          _this.set('saving', false);
        });
      }
    }
  });
});