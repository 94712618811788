define('postedin/components/keywords-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['keyword-picker'],
    // filteredOptions: Ember.computed.filter('options', function(option) {
    //   console.log(option, this.get('selected'));
    //   return !this.get('selected').map(keyword => keyword.name).includes(option.name);
    // }),
    tooltip: Ember.computed('selected', function () {
      switch (this.get('filteredSelected.length')) {
        case 0:
          return { show: false };
        case 1:
          return {
            show: true,
            class: 'icon-checkmark-circle icon-success',
            message: 'content.seo_keyword_1_message'
          };
        case 2:
          return {
            show: true,
            class: 'icon-warning2 icon-warning',
            message: 'content.seo_keyword_2_message'
          };
        default:
          return {
            show: true,
            class: 'icon-notification2 icon-danger',
            message: 'content.seo_keyword_3_message'
          };
      }
    }),
    filteredSelected: Ember.computed('selected', function () {
      if (!this.get('selected')) {
        return [];
      }

      var cache = [];

      return this.get('selected').filter(function (keyword) {
        var isNew = cache.includes(keyword.name);
        cache.push(keyword.name);

        return !isNew;
      });
    }),
    addKeyword: function addKeyword(term, select) {
      var keyword = { name: term };
      var previous = select.selected;
      var isDuplicate = previous.map(function (keyword) {
        return keyword.name;
      }).includes(term);
      var isFull = this.get('filteredSelected.length') >= 3;

      if (!isDuplicate && !isFull) {
        previous.push(keyword);
        select.actions.select(previous);
        this.set('selected', previous);
      }

      return;
    },

    actions: {
      keydown: function keydown(select, event) {
        var term = Ember.get(select, 'searchText').replace(/[,!@%^*()={};~`<>?\\|]/g, '').trim();
        var delimiters = [13, 188]; // 13: Enter; 188: Comma

        if (!select.highlighted && term.length && delimiters.includes(event.keyCode)) {
          this.addKeyword(term, select);
          Ember.set(select, 'searchText', '');
          event.preventDefault();
        }
      },
      blur: function blur(select) {
        var term = Ember.get(select, 'searchText').replace(/[,!@%^*()={};~`<>?\\|]/g, '').trim();

        if (term.length) {
          this.addKeyword(term, select);
        }
      },
      select: function select(selection) {
        this.set('selected', selection.slice(0, 3));
      }
    }
  });
});