define('postedin/helpers/slug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.slug = slug;
  function slug(params) {
    return params.join('').slugify();
  }

  exports.default = Ember.Helper.helper(slug);
});