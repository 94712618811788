define('postedin/controllers/editors/faq/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    waiting: false,
    typesDidChange: Ember.observer('types.@each.active', function () {
      var type = this.get('types').find(function (t) {
        return t.active;
      });

      if (type) {
        this.set('activeType', type.name);
      }
    }),
    groupedByCategories: Ember.computed('activeType', 'model.@each.displayOrder', function () {
      var _this = this;

      var groupedByCategories = this.get('model').reduce(function (groups, question) {
        question.set('isFirst', false);
        question.set('isLast', false);

        var group = groups.find(function (group) {
          return group.category.id === question.category.id;
        });

        if (group) {
          group.questions.push(question);
        } else {
          groups.push({
            category: question.category,
            questions: [question]
          });
        }

        return groups;
      }, []);

      groupedByCategories.sort(function (a, b) {
        return a.category.displayOrder - b.category.displayOrder;
      });

      groupedByCategories.forEach(function (group) {
        group.questions = group.questions.sort(function (a, b) {
          return a.get('displayOrder') - b.get('displayOrder');
        });

        group.questions[0].set('isFirst', true);
        group.questions[group.questions.length - 1].set('isLast', true);
      });

      return groupedByCategories.filter(function (group) {
        return group.category.type === _this.get('activeType');
      });
    }),
    actions: {
      filterType: function filterType(type) {
        this.get('types').forEach(function (t) {
          return Ember.set(t, 'active', false);
        });

        Ember.set(type, 'active', true);
      },
      scrollTo: function scrollTo(selector) {
        var $selected = Ember.$('#' + selector);
        Ember.run.once(function () {
          Ember.$('html, body').animate({
            scrollTop: $selected.offset().top - 10
          }, 100);
        });
      },
      moveQuestionUp: function moveQuestionUp(question) {
        var _this2 = this;

        if (question.get('isFirst')) {
          return;
        }

        this.set('waiting', true);

        var group = this.get('groupedByCategories').find(function (g) {
          return g.category.id === question.get('category.id');
        });
        var questionIndex = group.questions.findIndex(function (q) {
          return q.get('id') === question.get('id');
        });
        var questionAbove = group.questions[questionIndex - 1];
        var questionOrder = question.get('displayOrder');

        question.swapOrder(questionAbove).then(function () {
          questionAbove.set('displayOrder', questionOrder);
        }).finally(function () {
          _this2.set('waiting', false);
        });
      },
      moveQuestionDown: function moveQuestionDown(question) {
        var _this3 = this;

        if (question.get('isLast')) {
          return;
        }

        this.set('waiting', true);

        var group = this.get('groupedByCategories').find(function (g) {
          return g.category.id === question.get('category.id');
        });
        var questionIndex = group.questions.findIndex(function (q) {
          return q.get('id') === question.get('id');
        });
        var questionBelow = group.questions[questionIndex + 1];
        var questionOrder = question.get('displayOrder');

        question.swapOrder(questionBelow).then(function () {
          questionBelow.set('displayOrder', questionOrder);
        }).finally(function () {
          _this3.set('waiting', false);
        });
      }
    }
  });
});