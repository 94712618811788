define('postedin/services/repository', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var setHashData = function setHashData(hash, data) {
    hash = hash || {};

    if (hash.data === undefined) {
      hash.data = JSON.stringify(data, function (key, value) {
        if (key.startsWith('_') || key.includes(' ')) {
          return;
        }

        return value;
      });
    }

    if (hash.contentType === undefined) {
      hash.contentType = 'application/json; charset=utf-8';
    }

    return hash;
  };

  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    storage: Ember.inject.service('simple-store'),
    ajax: Ember.inject.service(),
    promises: {},
    cachePromise: function cachePromise(key, createPromise) {
      var _this = this;

      key = 'promises.' + key;
      var cachedPromise = this.get(key);
      if (!cachedPromise) {
        cachedPromise = createPromise();

        this.set(key, cachedPromise);
        cachedPromise.finally(function () {
          _this.set(key);
        });
      }

      return cachedPromise;
    },
    post: function post(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return this.get('ajax').post(url, setHashData(hash, data));
    },
    put: function put(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return this.get('ajax').put(url, setHashData(hash, data));
    },
    patch: function patch(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return this.get('ajax').patch(url, setHashData(hash, data));
    },
    delete: function _delete(url) {
      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('ajax').delete(url, hash);
    },
    request: function request(url) {
      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('ajax').request(url, hash);
    },
    modelExists: function modelExists(model, pluralized) {
      if (pluralized) {
        model = _emberInflector.default.inflector.singularize(model);
      }

      model = model.dasherize();

      if (Ember.getOwner(this).lookup('model:' + model)) {
        return model;
      }

      return false;
    },

    // This method converts an API responses json into models (which are just Ember Objects `Ember.Object`).
    // This means if a response just has the ID of a project as `{project: id}` and we already have that
    // project loaded it will convert it into the proper model. If the project contained additional data
    // then all model instances would have that data updated as well.
    push: function push(data) {
      var _this2 = this;

      var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('model');
      var useServerModel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (data instanceof Ember.Object) {
        return data;
      }

      Object.keys(data).forEach(function (key) {
        var value = data[key];
        if (!value) {
          return;
        }

        if (useServerModel && value._model) {
          value._emberModel = value._model;
        }

        if (value.id && value._emberModel && _this2.modelExists(value._emberModel)) {
          data[key] = _this2.push(value, false, useServerModel);
        } else {
          if (value.id && _this2.modelExists(key)) {
            data[key] = _this2.push(value, key.dasherize(), useServerModel);
          }

          if (Ember.isArray(value) && value.length && Ember.typeOf(value[0]) === 'object' && value[0].id) {
            var _model = _this2.modelExists(key, true);
            if (_model) {
              data[key] = _this2.pushArray(value, _this2.modelExists(key, true), useServerModel);
            } else if (value[0]._emberModel) {
              data[key] = _this2.pushArray(value, false, useServerModel);
            }
          }
        }
      });

      if (data.id && data._emberModel && this.modelExists(data._emberModel)) {
        return this.get('storage').push(data._emberModel, data);
      }

      if (model) {
        return this.get('storage').push(model, data);
      }

      return data;
    },
    pushArray: function pushArray(arr, model) {
      var _this3 = this;

      var useServerModel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var records = [];
      arr.forEach(function (record) {
        records.push(_this3.push(record, model, useServerModel));
      });

      return records;
    },
    pushResponse: function pushResponse(response, model) {
      var useServerModel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (response.data instanceof Array) {
        response = response.data;
      }

      return this.pushArray(response, model, useServerModel);
    },
    removeObject: function removeObject(record, model) {
      this.get('storage').remove(model || this.get('model'), record);
    }
  });
});