define('postedin/controllers/beta-users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    betaUsers: Ember.inject.service(),
    suspend: false,
    delete: false,
    actions: {
      toggleSuspend: function toggleSuspend() {
        this.set('suspend', !this.get('suspend'));
      },
      toggleUnsuspend: function toggleUnsuspend() {
        this.set('unsuspend', !this.get('unsuspend'));
      },
      toggleDelete: function toggleDelete() {
        this.set('delete', !this.get('delete'));
      },
      toggleValidate: function toggleValidate() {
        this.set('validate', !this.get('validate'));
      },
      suspendUser: function suspendUser(user) {
        var _this = this;

        this.get('betaUsers').suspend(user.get('id'), user.get('suspendedNote')).then(function () {
          _this.set('suspend', false);
          _this.transitionToRoute('beta-users.show', user);
        });
      },
      unsuspendUser: function unsuspendUser(user) {
        var _this2 = this;

        this.get('betaUsers').unsuspend(user.get('id')).then(function () {
          _this2.set('unsuspend', false);
          _this2.transitionToRoute('beta-users.show', user);
        });
      },
      deleteUser: function deleteUser(user) {
        var _this3 = this;

        this.get('betaUsers').remove(user.get('id')).then(function () {
          _this3.set('delete', false);
          _this3.transitionToRoute('beta-users.deleted', { queryParams: { descending: 1, sort: 'deleted' } });
        });
      },
      validateUser: function validateUser(user) {
        var _this4 = this;

        this.get('betaUsers').validate(user.get('id')).then(function () {
          _this4.set('validate', false);
          _this4.transitionToRoute('beta-users.show', user);
        });
      }
    }
  });
});