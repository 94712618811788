define('postedin/services/content-suggestions', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var paramsToQuery = function paramsToQuery(params, page) {
    var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;

    params = params || {};

    var query = {};
    if (page !== false) {
      query.page = page ? Math.max(1, parseInt(page)) : 1;
    }

    query.perpage = perPage ? Math.max(1, parseInt(perPage)) : 20;

    if (params.statuses && !Array.isArray(params.statuses)) {
      params.statuses = [params.statuses];
    }

    if (params.statuses) {
      query.statuses = params.statuses;
    }

    if (params.includeArchived) {
      query.includeArchived = params.includeArchived;
    }

    if (params.search) {
      query.search = params.search;
    }

    if (params.projects) {
      query.projects = params.projects.map(function (p) {
        return parseInt(p);
      }).filter(function (p) {
        return p;
      }).uniq();
    }

    return decodeURIComponent(Ember.$.param(query));
  };

  exports.default = _repository.default.extend({
    model: 'content-suggestion',

    find: function find(params, page, perPage) {
      var _this = this;

      return this.request('content-suggestions?' + paramsToQuery(params, page, perPage)).then(function (response) {
        var contentSuggestions = _this.pushResponse(response);
        delete response.data;

        return { contentSuggestions: contentSuggestions, meta: response };
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('content-suggestions/' + id).then(function (record) {
        return _this2.push(record);
      });
    },
    update: function update(contentSuggestion, updates) {
      var _this3 = this;

      return this.put('content-suggestions/' + contentSuggestion.id, updates).then(function (record) {
        return _this3.push(record);
      });
    },
    remove: function remove(contentSuggestion) {
      var _this4 = this;

      return this.delete('content-suggestions/' + contentSuggestion.id).then(function () {
        _this4.removeObject(contentSuggestion.id);
      });
    },
    confirm: function confirm(contentSuggestion) {
      var _this5 = this;

      return this.post('content-suggestions/' + contentSuggestion.id + '/suggest').then(function (record) {
        return _this5.push(record);
      });
    },
    loadStats: function loadStats() {
      var _this6 = this;

      return this.request('content-suggestions/summary').then(function (response) {
        return response.map(function (record) {
          record.project = _this6.push(record.project, 'project');

          return record;
        });
      });
    },
    comment: function comment(contentSuggestion, _comment, attachments) {
      var _this7 = this;

      var data = {
        body: _comment,
        attachments: attachments
      };

      return this.post('content-suggestions/' + contentSuggestion.id + '/comments', data).then(function (record) {
        return _this7.push(record, 'comment');
      });
    },
    accept: function accept(contentSuggestion, contentType, legacyContentType) {
      var _this8 = this;

      if (legacyContentType && legacyContentType.id) {
        legacyContentType = legacyContentType.id;
      }

      return this.post('content-suggestions/' + contentSuggestion.id + '/content-request', { contentType: contentType, legacyContentType: legacyContentType }).then(function (record) {
        return _this8.push(record);
      });
    },
    reject: function reject(contentSuggestion, comment) {
      var _this9 = this;

      return this.post('content-suggestions/' + contentSuggestion.id + '/reject', { comment: comment }).then(function (suggestion) {
        return _this9.push(suggestion);
      });
    }
  });
});