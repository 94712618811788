define('postedin/helpers/image-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageUrl = imageUrl;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var providers = {
    cloudinary: function cloudinary(image, options) {
      var url = 'https://res.cloudinary.com/postedin/image/upload';

      if (options.download) {
        return url + '/fl_attachment/' + image;
      }

      if (options.full) {
        return url + '/' + image;
      }

      var params = [];

      params.push('q_auto');
      params.push('d_No_image_available_ttllfn.jpg');

      // NOTE: format actually means type of crop, this is named badly and needs a refactor
      if (options.format) {
        params.push('c_' + options.format);
        params.push('f_auto');
      } else {
        params.push('f_auto');
        params.push('c_pad');
      }

      if (options.smartCrop) {
        params.push('g_auto');
      }

      if (options.width) {
        params.push('w_' + options.width);
      }

      if (options.height) {
        params.push('h_' + options.height);
      }

      if (options.background) {
        params.push('b_' + options.background);
      }

      if (params.length) {
        url += '/' + params.join(',');
      }

      url += '/' + image;

      return url;
    }
  };

  function imageUrl(image, options) {
    if (!image || (typeof image === 'undefined' ? 'undefined' : _typeof(image)) !== 'object' || !image.id && !image.uuid) {
      return providers.cloudinary('No_image_available_ttllfn', options);
    }

    if (image.disk && image.path && providers[image.disk]) {
      return providers[image.disk](image.path, options);
    }

    if (image.provider && image.image && providers[image.provider]) {
      return providers[image.provider](image.image, options);
    }

    return image.image || image.url;
  }

  exports.default = Ember.Helper.helper(function (_ref, hash) {
    var _ref2 = _slicedToArray(_ref, 1),
        image = _ref2[0];

    return imageUrl(image, hash);
  });
});