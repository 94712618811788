define('postedin/routes/comment-permalink', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    comments: Ember.inject.service(),
    model: function model(params) {
      return this.get('comments').find(params.id);
    },
    afterModel: function afterModel(comment) {
      if (typeof comment.get('commentable').transitionToRoute === 'function') {
        var commentId = comment.id;

        // if we already have a comment query param keep it
        if (this.router.targetState.routerJsState.fullQueryParams.comment) {
          commentId = this.router.targetState.routerJsState.fullQueryParams.comment;
        }

        return comment.get('commentable').transitionToRoute({ comment: commentId });
      }

      this.transitionTo('index');
    }
  });
});