define('postedin/routes/beta-users/show/payment-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('beta-users.show').loadProfile();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('editing', false);
      controller.set('saving', false);
    }
  });
});