define('postedin/routes/admin-faq/categories/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    faq: Ember.inject.service(),
    model: function model() {
      return this.get('faq').findCategories();
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('confirmationModal', false);
      controller.set('deletingCategory', false);
      controller.set('category', null);

      model.forEach(function (category) {
        return category.newName = category.name;
      });
    }
  });
});