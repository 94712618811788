define('postedin/controllers/admin/categories/index', ['exports', 'postedin/mixins/validator', 'postedin/mixins/ui-promise'], function (exports, _validator, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validator.default, _uiPromise.default, {
    categories: Ember.inject.service(),
    validate: function validate(newCategory) {
      return this.validatorCheck(newCategory, {
        name: 'required',
        betaCategoryId: 'integer'
      });
    },

    actions: {
      betaCategoryDidChange: function betaCategoryDidChange(betaCategory) {
        this.set('newCategory.betaCategoryId', betaCategory.id);
        this.set('selectedBetaCategory', betaCategory);
      },
      createCategory: function createCategory(newCategory) {
        var _this = this;

        if (this.validate(newCategory)) {
          var promise = this.get('categories').create(newCategory);
          this.uiPromise(promise, 'saving').then(function (category) {
            _this.get('model').pushObject(category);
            _this.set('model', _this.get('model').sortBy('name'));

            category.set('_highlight', true);
            Ember.run.later(function () {
              category.set('_highlight', false);
            }, 500);

            _this.setProperties({
              newCategory: Ember.Object.create(),
              selectedBetaCategory: null
            });
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});