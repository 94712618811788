define('postedin/routes/admin/organizations/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    organizations: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        model: this.get('organizations').all(),
        trashed: this.get('organizations').trashed()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.model);

      controller.set('trashedOrganizations', hash.trashed);
    }
  });
});