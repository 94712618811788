define('postedin/routes/admin-release-notes/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('releaseNote', {});
      controller.set('confirmationModal', false);
    }
  });
});