define('postedin/services/invites', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'invite',
    findByUuid: function findByUuid(uuid) {
      var _this = this;

      return this.request('invites/' + uuid).then(function (record) {
        return _this.push(record);
      });
    },
    resend: function resend(invite) {
      return this.put('invites/' + invite.id + '/resend');
    },
    cancel: function cancel(invite) {
      var _this2 = this;

      return this.delete('invites/' + invite.id).then(function (record) {
        return _this2.push(record);
      });
    },
    claim: function claim(invite) {
      var _this3 = this;

      return this.post('invites/' + invite.get('uuid') + '/claim').then(function (record) {
        return _this3.push(record);
      });
    },
    createPartner: function createPartner(invite, register) {
      if (!invite.id) {
        return false;
      }
      return this.post('invites/' + invite.uuid + '/register', register);
    }
  });
});