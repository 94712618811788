define('postedin/components/holiday-switch', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, {
    tagName: 'button',
    classNames: ['btn'],
    attributeBindings: ['disabled'],
    isVisible: Ember.computed('day.updatedAt', function () {
      var day = this.get('day');
      if (!day) {
        return false;
      }

      return ![0, 6].includes(this.get('date').day());
    }),
    disabled: Ember.computed.oneWay('state.waiting'),
    day: Ember.computed('date', 'days.@each.holiday', function () {
      return this.get('days').findBy('id', this.get('date').format('YYYY-MM-DD'));
    }),
    click: function click() {
      var day = this.get('day');

      if (day.get('holiday')) {
        this.disable(day);
      } else {
        this.enable(day);
      }
    },
    enable: function enable(day) {
      this.uiPromise(day.updateHoliday(true));
    },
    disable: function disable(day) {
      this.uiPromise(day.updateHoliday(false));
    }
  });
});