define('postedin/controllers/chile-payments/show', ['exports', 'postedin/mixins/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_utils.default, {
    queryParams: ['search', 'sort', 'descending', 'status'],
    search: '',
    sort: '',
    status: 'all',
    descending: 0,
    rejectionModal: false,
    rejectionReason: null,
    currentInvoice: null,
    selectedReason: null,
    isSending: false,
    meta: Ember.computed('sort', 'descending', 'search', function () {
      var sort = {};
      sort[this.get('sort')] = this.get('descending') === 1 ? 'descending' : 'ascending';

      return {
        sort: sort
      };
    }),
    invoicesTotal: Ember.computed('invoiceSet', function () {
      return this.get('invoiceSet').reduce(function (carry, current) {
        return carry + current.total;
      }, 0);
    }),
    invoicesAcceptedCount: Ember.computed('model.payment.invoices.@each.status', function () {
      return this.get('model.payment.invoices').filter(function (invoice) {
        return invoice.status === 'invoice_received';
      }).length;
    }),
    invoicesNotAcceptedCount: Ember.computed('invoicesAcceptedCount', function () {
      return this.get('model.payment.invoices').length - this.get('invoicesAcceptedCount');
    }),
    invoicesAcceptedTotal: Ember.computed('model.payment.invoices.@each.status', function () {
      return this.get('model.payment.invoices').filter(function (invoice) {
        return invoice.status === 'invoice_received';
      }).reduce(function (carry, current) {
        return carry + current.total;
      }, 0);
    }),
    invoicesNotAcceptedTotal: Ember.computed('model.payment.invoices.@each.status', function () {
      return this.get('model.payment.invoices').filter(function (invoice) {
        return invoice.status !== 'invoice_received';
      }).reduce(function (carry, current) {
        return carry + current.total;
      }, 0);
    }),
    sortedInvoices: Ember.computed('sort', 'descending', 'invoiceSet', 'search', function () {
      var sort = this.get('sort');
      var order = this.get('descending') === 1 ? -1 : 1;
      var search = this.get('search');
      var invoices = this.get('invoiceSet');

      if (search) {
        invoices = this.filterInvoices(invoices, search);
      }

      switch (sort) {
        case 'id':
          return this.sortById(invoices, order);
        case 'name':
          return this.sortByName(invoices, order);
        case 'email':
          return this.sortByEmail(invoices, order);
      }

      return invoices;
    }),
    statuses: Ember.computed('model.payment.invoices.@each.status', function () {
      var invoices = this.get('model.payment.invoices');
      var statuses = {
        all: {
          active: true,
          invoices: invoices,
          name: 'All'
        }
      };

      invoices.forEach(function (invoice) {
        if (!statuses[invoice.status]) {
          statuses[invoice.status] = {
            name: invoice.status.capitalize().replace(/_/g, ' '),
            active: false,
            invoices: []
          };
        }

        statuses[invoice.status].invoices.push(invoice);
      });

      if (!this.get('status') || !statuses[this.get('status')]) {
        this.set('status', 'all');
      }

      this.set('invoiceSet', statuses[this.get('status')].invoices);
      return statuses;
    }),
    filterInvoices: function filterInvoices(invoices, query) {
      var utils = this.get('utils');
      query = query.toLowerCase();

      return invoices.filter(function (invoice) {
        if (utils.cleanString(invoice.betaUser.name).includes(query)) {
          return true;
        }

        if (utils.cleanString(invoice.betaUser.email).includes(query)) {
          return true;
        }

        if (invoice.id === query) {
          return true;
        }

        return false;
      });
    },
    sortByEmail: function sortByEmail(invoices, order) {
      return invoices.sort(function (a, b) {
        return (a.betaUser.email > b.betaUser.email ? 1 : -1) * order;
      });
    },
    sortByName: function sortByName(invoices, order) {
      return invoices.sort(function (a, b) {
        return (a.betaUser.name > b.betaUser.name ? 1 : -1) * order;
      });
    },
    sortById: function sortById(invoices, order) {
      return invoices.sort(function (a, b) {
        return (a.id - b.id) * order;
      });
    },

    actions: {
      setCurrentStatus: function setCurrentStatus(key, value) {
        this.set('status', key);
        this.set('invoiceSet', value.invoices);
      },
      exportInvoices: function exportInvoices() {
        var invoices = this.get('statuses.all.invoices');
        var button = document.createElement('a');
        var output = '';
        var url = 'data:text/plain;charset=utf-8,';
        var headings = ['Nº Cuenta de Cargo', 'Nº Cuenta de Destino', 'Banco Destino', 'Rut Beneficiario', 'Dig. Verif. Beneficiario', 'Nombre Beneficiario ', 'Monto Transferencia', 'Nro.Factura Boleta (1)', 'Nº Orden de Compra(1)', 'Tipo de Pago(2)', 'Mensaje Destinatario (3)', 'Email Destinatario(3)', 'Cuenta Destino inscrita como(4)'];

        output += headings.join(',') + '\n';

        invoices.forEach(function (invoice) {
          if (!invoice.betaUser.chileBankAccount) {
            // TODO: bugsnag notify
            return;
          }

          if (invoice.status !== 'invoice_received' && invoice.status !== 'paid') {
            return;
          }

          var rut = invoice.betaUser.chileBankAccount.rut.replace(/[.-]/g, '').split('');
          var rutLastNumber = rut.pop();
          rut = rut.join('');
          var line = [61366765, invoice.betaUser.chileBankAccount.accountNumber, invoice.betaUser.chileBankAccount.bank, rut, rutLastNumber, invoice.betaUser.name, invoice.total, invoice.invoiceNumber, ' ', 'OTR', 'Postedin Pago Boleta ' + invoice.invoiceNumber, invoice.betaUser.email, ' '].join(',');
          output += line + '\n';
        });

        url += encodeURIComponent(output);

        button.setAttribute('href', url);
        button.setAttribute('download', 'chile-payment-' + this.get('model.payment.name').slugify() + '.csv');

        document.body.appendChild(button);
        button.click();
        document.body.removeChild(button);
      },
      toggleInvoiceAccept: function toggleInvoiceAccept(invoice) {
        invoice.toggleProperty('acceptDialog');
      },
      acceptInvoice: function acceptInvoice(invoice) {
        invoice.set('accepting', true);
        invoice.updateInvoiceNumber(invoice.get('newInvoiceNumber')).then(function () {
          invoice.set('accepting', false);
          invoice.set('acceptDialog', false);
        });
      },
      toggleInvoiceReject: function toggleInvoiceReject(invoice) {
        invoice.toggleProperty('rejectDialog');
      },
      rejectInvoice: function rejectInvoice(invoice) {
        var requiredError = true;

        invoice.get('rejectReasons').forEach(function (reason) {
          if (reason.checked) {
            requiredError = false;
          }
        });

        invoice.set('reasonsRequired', requiredError);
        if (requiredError) {
          return;
        }

        invoice.set('rejecting', true);
        var reason = '\n        <p>Durante el proceso de pagos de Postedin, encontramos que tu boleta presenta los siguientes problemas:</p>\n        <ul>';
        invoice.get('rejectReasons').forEach(function (r) {
          if (r.checked) {
            reason += '\n            <li>' + r.phrase + '</li>';
          }
        });
        reason += '\n        </ul>';

        if (invoice.get('rejectComment')) {
          // Replace new lines for <br /> tags
          var comment = invoice.rejectComment.replace(/(?:\r\n|\r|\n)/g, '<br />');

          reason += '\n        <p></p>\n        <p><strong>Comentarios: </strong><br /> ' + comment + '</p>';
        }

        invoice.reject(reason).then(function () {
          invoice.set('rejecting', false);
          invoice.set('rejectDialog', false);
          invoice.set('rejectComment', '');
          // FIXME: following line not working, meant to uncheck for if you reopen the reject dialog,
          // not a big deal though
          // invoice.get('rejectReasons').forEach(reason => reason.checked = false);
        });
      },
      toggleInvoiceRejectionDetails: function toggleInvoiceRejectionDetails(invoice) {
        invoice.toggleProperty('rejectionDetailsDialog');
      },
      toggleFinish: function toggleFinish() {
        this.toggleProperty('finishDialog');
      },
      finish: function finish(payment) {
        var _this = this;

        this.set('finishError', false);
        payment.finish().then(function () {
          _this.send('refresh');
          _this.set('finishDialog', false);
        }, function () {
          _this.set('finishError', true);
        });
      },
      resendrejectionEmail: function resendrejectionEmail(invoice) {
        invoice.set('resendrejectionEmailFailed', false);
        invoice.set('resendingrejectionEmail', true);
        invoice.reject(invoice.rejectionReason).then(function () {
          invoice.set('resendingrejectionEmail', false);
        }, function () {
          invoice.set('resendingrejectionEmail', false);
          invoice.set('resendrejectionEmailFailed', true);
        });
      },
      resendEmail: function resendEmail(invoice) {
        invoice.set('resendFailed');
        invoice.set('resendingEmail', true);
        invoice.resendEmail().then(function () {
          invoice.set('resendingEmail');
        }, function () {
          invoice.set('resendingEmail');
          invoice.set('resendFailed', true);
        });
      },
      search: function search(_search) {
        var params = {
          search: _search
        };
        this.transitionToRoute('international-payments.show', { queryParams: params });
      }
    }
  });
});