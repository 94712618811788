define('postedin/components/project-incomplete-information', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    requiredFields: [{
      name: 'description',
      route: 'project.settings.profile.project'
    }, {
      name: 'goals',
      route: 'project.settings.profile.project'
    }, {
      name: 'targetAudience',
      route: 'project.settings.style'
    }, {
      name: 'writingStyle',
      route: 'project.settings.style'
    }, {
      name: 'website',
      route: 'project.settings.profile.contact'
    }],
    hasImageRequirements: Ember.computed('project.profile.images.length', function () {
      return !!this.get('project.profile.images').filter(function (image) {
        if (image.get) {
          return image.get('dimensions');
        }

        // for some reason this isn't being turned into a model and waste of time dealing with that now
        return image.dimensions;
      }).length;
    }),
    hasSeoSettings: Ember.computed('project.profile.seoUserUpdated', function () {
      return !!this.get('project.profile.seoUserUpdated');
    }),
    profileFields: Ember.computed('project.profile.updatedAt', function () {
      var _this = this;

      return this.get('requiredFields').map(function (field) {
        return {
          phrase: 'profile.' + field.name.underscore(),
          missing: !(_this.get('project.profile.' + field.name) || '').trim(),
          name: field.name,
          route: field.route
        };
      });
    })
  }).reopenClass({
    positionalParams: ['project']
  });
});