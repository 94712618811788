define('postedin/components/resource-table-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ResourceTableFieldsComponent = Ember.Component.extend({
    tagName: ''
  });

  ResourceTableFieldsComponent.reopenClass({
    positionalParams: ['resource', 'fields']
  });

  exports.default = ResourceTableFieldsComponent;
});