define('postedin/routes/admin/categories/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    categories: Ember.inject.service(),
    betaCategories: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        model: this.get('categories').findById(params.id),
        betaCategories: this.get('betaCategories').all()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash);

      controller.set('updates', hash.model.updatesHash());
      controller.set('model', hash.model);
      controller.set('betaCategories', hash.betaCategories);
      controller.setupErrorClearingObservers('updates', ['name', 'betaCategoryId']);

      if (hash.model.betaCategory.id) {
        controller.set('selectedBetaCategory', hash.betaCategories.find(function (cat) {
          return cat.id === hash.model.betaCategory.id;
        }));
      } else {
        controller.set('selectedBetaCategory');
      }
    }
  });
});