define('postedin/components/organizations-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HOVER_DELAY = 200;

  exports.default = Ember.Component.extend({
    classNames: ['organizations-select'],
    selectSearch: '',
    selectTriggerClasses: Ember.computed('triggerClass', 'isOpen', function () {
      var classes = ['organizations-select-trigger'];

      if (this.get('triggerClass')) {
        classes.push(this.get('triggerClass'));
      }

      if (this.get('toggleOnHover') && this.get('isOpen')) {
        classes.push('organizations-select-trigger--open');
      }

      return classes.join(' ');
    }),
    selectDropdownClasses: Ember.computed('dropdownClass', 'isOpen', function () {
      var classes = ['organizations-select-dropdown'];

      if (this.get('toggleOnHover')) {
        classes.push('organizations-select-dropdown--in-place');

        if (!this.get('isOpen')) {
          classes.push('organizations-select-dropdown--closed');
        }
      }

      if (this.get('dropdownClass')) {
        classes.push(this.get('dropdownClass'));
      }

      return classes.join(' ');
    }),
    calculatePosition: Ember.computed('toggleOnHover', function () {
      if (this.get('toggleOnHover')) {
        return function () {
          return {
            horizontalPosition: 'left'
          };
        };
      }
    }),
    registerHoverEvents: function registerHoverEvents() {
      var _this = this;

      this.$().on('mouseenter', function () {
        return _this.open();
      });
      this.$().on('mouseleave', function () {
        return _this.close();
      });
    },
    open: function open() {
      var _this2 = this;

      var select = this.get('select');

      if (!select.isOpen) {
        select.actions.open();
      }

      var timeout = this.get('closeTimeout');
      if (timeout) {
        Ember.run.cancel(timeout);
        this.set('closeTimeout');

        return;
      }

      this.set('openTimeout', Ember.run.later(function () {
        _this2.set('isOpen', true);
        _this2.set('openTimeout');

        Ember.run.later(function () {
          _this2.$().find('input').focus();
        });
      }, HOVER_DELAY));
    },
    close: function close() {
      var _this3 = this;

      var select = this.get('select');

      if (!select.isOpen) {
        select.actions.open();
      }

      var timeout = this.get('openTimeout');
      if (timeout) {
        Ember.run.cancel(timeout);
        this.set('openTimeout');

        return;
      }

      this.set('closeTimeout', Ember.run.later(function () {
        select.actions.search('');
        _this3.set('isOpen', false);
        _this3.set('closeTimeout');
      }, HOVER_DELAY));
    },

    actions: {
      setupSelect: function setupSelect(select) {
        if (this.get('setup')) {
          return;
        }

        this.set('select', select);

        if (this.get('toggleOnHover')) {
          this.registerHoverEvents();
        }

        this.set('setup', true);
      },
      selectOrganization: function selectOrganization(organization) {
        if (this.get('links')) {
          this.set('value');
        } else {
          this.set('value', organization);

          this.sendAction('onchange', organization);
        }
      },
      selectOnClose: function selectOnClose() {
        var select = this.get('select');

        this.set('isOpen', false);

        Ember.run.later(function () {
          select.actions.open();
          Ember.run.later(function () {
            select.actions.reposition();
          });
        }, 100);
      },
      selectOnInput: function selectOnInput(term) {
        this.set('searching', term);
      },
      selectOnMouseDown: function selectOnMouseDown() {
        if (this.get('toggleOnHover')) {
          return false;
        }
      }
    }
  });
});