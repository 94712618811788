define('postedin/routes/editors/faq/create', ['exports', 'postedin/models/faq-question'], function (exports, _faqQuestion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    faq: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        model: _faqQuestion.default.create({ active: false }),
        categories: this.get('faq').findCategories()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash);

      controller.set('model', hash.model);
      controller.set('categories', hash.categories);
    }
  });
});