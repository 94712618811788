define('postedin/routes/admin/users/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    users: Ember.inject.service(),
    model: function model(params) {
      return this.get('users').findById(params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('updates', model.updatesHash());
    }
  });
});