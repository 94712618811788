define('postedin/helpers/phrase', ['exports', 'shared/helpers/phrase'], function (exports, _phrase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _phrase.default;
    }
  });
  Object.defineProperty(exports, 'phrase', {
    enumerable: true,
    get: function () {
      return _phrase.phrase;
    }
  });
});