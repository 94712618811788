define('postedin/components/attachment-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AttachmentImage = Ember.Component.extend({
    classNames: ['attachment-image']
  });

  AttachmentImage.reopenClass({
    positionalParams: ['attachment']
  });

  exports.default = AttachmentImage;
});