define('postedin/routes/suggestions/show/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('suggestions.show');
    },
    afterModel: function afterModel(model) {
      if (!model.get('canUpdate')) {
        return this.transitionTo('suggestions.show.index', model);
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('updates', model.updatesHash(['project']));
      controller.set('updates.keywords', controller.get('updates.keywords').map(function (k) {
        return { name: k };
      }));
      controller.set('keywords', model.project.loadKeywords());
      controller.resetUiPromise('updateSuggestion');
    }
  });
});