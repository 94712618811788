define('postedin/routes/suggestions/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    contentTypes: Ember.inject.service(),
    contentSuggestions: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        model: this.modelFor('suggestions.show'),
        suggestions: this.get('contentSuggestions').find(),
        contentTypes: this.get('contentTypes').find()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.model);

      // we use this to send the user to the next suggestion after rejecting, it is just
      // the first suggestion found that isn't this one
      controller.set('nextSuggestion', hash.suggestions.contentSuggestions.find(function (suggestion) {
        return hash.model.id !== suggestion.id;
      }));

      controller.set('selectedContentType', null);
      controller.set('selectedLegacyContentType', null);
      controller.set('contentTypes', hash.contentTypes);
      controller.set('rejectComment', '');
      controller.set('duplicateProject', null);

      controller.resetUiPromise('accept');
      controller.resetUiPromise('reject');

      if (!hash.model.get('canJudge')) {
        Ember.run.later(function () {
          return controller.set('showAcceptModal', 0);
        });
      }

      if (!hash.model.get('canDuplicate')) {
        Ember.run.later(function () {
          return controller.set('showDuplicateModal', 0);
        });
      }

      if (hash.contentTypes.length === 1 && hash.contentTypes[0].id === 'legacy_article') {
        controller.set('selectedContentType', hash.contentTypes[0]);
      }

      this.controllerFor('application').set('projectContext', hash.model.get('project'));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          showRejectModal: 0,
          showAcceptModal: 0,
          showDuplicateModal: 0
        });
      }
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('projectContext', null);
    }
  });
});