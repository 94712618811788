define('postedin/controllers/admin-missions', ['exports', 'moment', 'postedin/helpers/currency'], function (exports, _moment, _currency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['month'],
    month: null,
    params: {},
    transition: function transition() {
      this.transitionToRoute('admin-missions', { queryParams: this.get('params') });
    },

    actions: {
      changeMonth: function changeMonth() {
        var params = this.get('params');
        params.month = this.get('filter.month');
        this.set('params', params);

        this.transition();
      },
      exportCSV: function exportCSV() {
        var _this = this;

        var missions = this.get('model');
        var button = document.createElement('a');
        var output = '';
        var url = 'data:text/plain;charset=utf-8,';
        var headings = ['Month', 'Date', 'Freelancer', 'Freelancer Email', 'Freelancer Country', 'Brand', 'Title', 'ID', 'URL', 'Reward CLP', 'Reward USD', 'Note', 'Late', 'Suggested'];

        output += headings.join(',') + '\n';

        missions.forEach(function (mission) {
          var line = ['"' + (0, _moment.default)(mission.completedAt).format('MMMM') + '"', '"' + mission.completedAt + '"', '"' + _this.escapeQuotes(mission.freelancer.name) + '"', '"' + mission.freelancer.email + '"', '"' + _this.escapeQuotes(mission.freelancer.country) + '"', '"' + _this.escapeQuotes(mission.brand.name) + '"', '"' + _this.escapeQuotes(mission.title) + '"', '"' + mission.id + '"', '"http://content.postedin.com/missions/' + mission.id + '"', '"' + (0, _currency.currency)([mission.completedReward, 'CLP', true]) + '"', '"' + (0, _currency.currency)([mission.completedRewardUsd, 'USD', true]) + '"', '"' + _this.escapeQuotes(mission.completedNote) + '"', '"' + mission.late + '"', '"' + mission.suggested + '"'].join(',');
          output += line + '\n';
        });

        url += encodeURIComponent(output);

        button.setAttribute('href', url);
        var now = (0, _moment.default)().subtract(this.get('filter.month'), 'months').format('MMMM-YYYY');
        button.setAttribute('download', 'missions_report-' + now + '.csv');

        document.body.appendChild(button);
        button.click();
        document.body.removeChild(button);
      }
    },
    escapeQuotes: function escapeQuotes(text) {
      text = text.replace(/"/g, "'");
      text = text.replace(/\n/g, ' ');
      text = text.replace(/\t/g, ' ');
      return text;
    }
  });
});