define('postedin/helpers/t', ['exports', 'ember-i18n/helper'], function (exports, _helper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _helper.default.extend({
    i18n: Ember.inject.service(),

    compute: function compute(params, hash) {
      return this.get('i18n').t(params.join(''), hash);
    }
  });
});