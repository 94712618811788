define('postedin/controllers/admin/users/edit', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    users: Ember.inject.service(),
    temporaryTypes: ['partner', 'editor', 'admin'],
    validate: function validate(data) {
      var rules = {
        name: 'required',
        email: 'required|email',
        tempType: 'required|in:editor,partner,admin'
      };

      return this.validatorCheck(data, rules);
    },

    actions: {
      saveUser: function saveUser(user, updates) {
        var _this = this;

        if (this.validate(user)) {
          var globalRoles = {
            isAdmin: updates.isAdmin,
            isGlobalEditor: updates.isGlobalEditor,
            isGlobalManager: updates.isGlobalManager,
            isGlobalOrganizationManager: updates.isGlobalOrganizationManager
          };

          this.uiPromise(user.update(updates.name, updates.email, updates.password, updates.tempType, globalRoles), 'saveUser').then(function () {
            _this.transitionToRoute('admin.users.show', user.id);
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});