define('postedin/routes/admin-faq/questions/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    faq: Ember.inject.service(),
    model: function model(params) {
      return this.get('faq').findById(params.id);
    }
  });
});