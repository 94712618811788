define('postedin/components/post-form', ['exports', 'postedin/mixins/attachments', 'postedin/mixins/ui-promise'], function (exports, _attachments, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_attachments.default, _uiPromise.default, {
    session: Ember.inject.service(),
    open: false,
    comment: false,
    title: '',
    body: '',
    uploads: [],
    optionalTitle: false,
    isOpen: Ember.computed('comment', 'open', function () {
      if (this.get('open')) {
        return true;
      }

      return !this.get('comment') && !this.get('reply');
    }),
    replyPlaceholder: Ember.computed('reply', 'isOpen', function () {
      return this.get('reply') && !this.get('isOpen');
    }),
    showSecondarySave: Ember.computed.and('secondarySave', 'secondarySaveText'),
    titleChanged: Ember.observer('title', function () {
      this.set('titleRequired');
    }),
    bodyChanged: Ember.observer('body', function () {
      this.set('bodyRequired');
    }),
    validate: function validate(title, body, secondary) {
      if (this.get('isUploading')) {
        return false;
      }

      if (this.get('comment') || this.get('reply')) {
        if (secondary && this.get('optionalBody')) {
          return true;
        }

        // comment we just want a body filled and if it isn't just don't process the form
        return !!body;
      }

      var passed = true;

      if (!this.get('optionalTitle') && !title.trim()) {
        this.set('titleRequired', true);
        passed = false;
      }

      if (!this.get('optionalBody') && !body.trim()) {
        this.set('bodyRequired', true);
        passed = false;
      }

      return passed;
    },

    actions: {
      textareaFocused: function textareaFocused() {
        this.set('open', true);
      },
      savePost: function savePost(title, body, secondary) {
        var _this = this;

        if (!this.validate(title, body, secondary)) {
          return;
        }

        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          var attachments = _this.get('disallowAttachments') ? false : _this.extractAttachments();
          var secondaryAction = secondary ? 'secondarySave' : false;

          if (_this.get('comment') || _this.get('reply')) {
            _this.sendAction(secondaryAction || 'saveComment', body, attachments, { resolve: resolve, reject: reject });
          } else {
            _this.sendAction(secondaryAction || 'savePost', title, body, attachments, { resolve: resolve, reject: reject });
          }
        });

        this.uiPromise(promise).then(function () {
          _this.set('title', '');
          _this.set('body', '');
          _this.set('uploads', []);
        });
      }
    }
  });
});