define('postedin/helpers/audit-log-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.auditLogType = auditLogType;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function auditLogType(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        type = _ref2[0];

    Ember.assert('Param passed to auditLogType helper must be a string', typeof type === 'string');

    var _type$split = type.split('.'),
        _type$split2 = _slicedToArray(_type$split, 2),
        resource = _type$split2[0],
        action = _type$split2[1];

    Ember.assert('Param passed to auditLogType helper must be a string with a dot separating resource from action. eg "project.action"', action);

    resource = Ember.Handlebars.Utils.escapeExpression(resource);
    var safeAction = Ember.Handlebars.Utils.escapeExpression(action);

    switch (action) {
      case 'create':
      case 'add':
      case 'invite':
      case 'publish':
      case 'revision_create':
      case 'revision_approve':
        action = '<ins>' + safeAction + '</ins>';
        break;
      case 'delete':
      case 'remove':
      case 'close':
      case 'cancel':
      case 'revision_reject':
        action = '<del>' + safeAction + '</del>';
        break;
      case 'update':
      case 'review':
      case 'assign':
        action = '<u>' + safeAction + '</u>';
        break;
      default:
        action = '<strong>' + safeAction + '</strong>';
        break;
    }

    return Ember.String.htmlSafe(resource + '.' + action);
  }

  exports.default = Ember.Helper.helper(auditLogType);
});