define('postedin/components/content-request/label-picker', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, {
    selectedProjectLabel: Ember.computed('contentRequest.projectLabel', function () {
      if (this.get('contentRequest.projectLabel.name')) {
        return this.get('contentRequest.projectLabel');
      }
    }),
    projectLabels: Ember.computed.alias('contentRequest.project.labels'),
    validate: function validate(label) {
      return label && parseInt(label.id) && this.get('contentRequest.project.labels').includes(label);
    },

    actions: {
      stopEditing: function stopEditing() {
        if (!this.get('updateLabel.working')) {
          this.set('editing', false);
        }
      },
      setProjectLabel: function setProjectLabel(label) {
        var _this = this;

        if (this.get('updateLabel.working')) {
          return;
        }

        if (label) {
          return this.uiPromise(this.get('contentRequest').updateLabel(label), 'updateLabel').then(function () {
            _this.set('editing', false);
          });
        } else {
          return this.uiPromise(this.get('contentRequest').deleteLabel(), 'updateLabel').then(function () {
            _this.set('editing', false);
          });
        }
      },
      toggleEdit: function toggleEdit() {
        this.toggleProperty('editing');
      }
    }
  }).reopenClass({
    positionalParams: ['contentRequest']
  });
});