define('postedin/controllers/admin/tools', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, {
    ajax: Ember.inject.service(),
    loadStats: function loadStats() {
      var _this = this;

      this.get('ajax').request('/admin-custom-stats').then(function (stats) {
        return _this.set('stats', stats);
      });
    },

    actions: {
      importAbcdinCsv: function importAbcdinCsv() /* csv */{
        // this.uiPromise(this.get('ajax').post('/content-requests/batch-import', { data: { csv } })).then((response) => {
        //   this.set('abcdinCsvImported', true);
        //   this.set('abcdinBatch', response.batch);
        // });
      },
      importLegacyTypes: function importLegacyTypes(csv) {
        var _this2 = this;

        this.uiPromise(this.get('ajax').post('/legacy-content-types/batch-import', { data: { csv: csv } })).then(function () {
          _this2.set('legacyTypesCsvImported', true);
        });
      }
    }
  });
});