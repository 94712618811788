define('postedin/controllers/admin-faq/categories/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    faq: Ember.inject.service(),
    actions: {
      toggleEditingName: function toggleEditingName(category) {
        category.toggleProperty('editingName');
      },
      changeName: function changeName(category) {
        var oldName = category.name;
        category.set('changingName', true);
        category.set('name', category.newName);
        this.get('faq').updateCategory(category).then(function (record) {
          category.set('name', record.name);
          category.set('nameChangeError', false);
        }, function () {
          category.set('name', oldName);
          category.set('nameChangeError', true);
        }).finally(function () {
          category.set('changingName', false);
          category.set('editingName', false);
        });
      },
      changeType: function changeType(category) {
        var oldType = category.type;
        var newType = category.type === 'public' ? 'private' : 'public';
        category.set('changingType', true);
        category.set('type', newType);
        this.get('faq').updateCategory(category).then(function (record) {
          category.set('type', record.type);
          category.set('typeChangeError', false);
        }, function () {
          category.set('type', oldType);
          category.set('typeChangeError', true);
        }).finally(function () {
          category.set('changingType', false);
        });
      },
      changeActive: function changeActive(category) {
        var oldActive = category.active;
        category.set('changingActive', true);
        category.set('active', !category.active);
        this.get('faq').updateCategory(category).then(function (record) {
          category.set('active', record.active);
          category.set('activeChangeError', false);
        }, function () {
          category.set('active', oldActive);
          category.set('activeChangeError', true);
        }).finally(function () {
          category.set('changingActive', false);
        });
      },
      closeConfirmationModal: function closeConfirmationModal() {
        this.set('category', null);
        this.set('confirmationModal', false);
      },
      confirmDelete: function confirmDelete(category) {
        this.set('category', category);
        this.set('confirmationModal', true);
      },
      deleteCategory: function deleteCategory() {
        var _this = this;

        var category = this.get('category');
        this.set('deletingCategory', true);
        this.get('faq').removeCategory(category).then(function () {
          var newModel = _this.get('model').rejectBy('id', category.id);

          _this.set('model', newModel);
          _this.set('deletingCategory', false);
          _this.set('category', null);
          _this.set('confirmationModal', false);
        }, function () {
          _this.set('deletingCategory', false);
          _this.set('deletingError', true);
        });
      }
    }
  });
});