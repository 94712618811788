define('postedin/routes/admin/organizations/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.transitionTo('clients').then(function (route) {
        route.controller.set('showCreateOrganizationMessage', true);
      });

      // return Organization.create({
      //   maxProjects: 5,
      //   newProjectsHideTypes: false,
      //   expireReviews: true,
      //   expireReviewsOnWeekdays: false,
      //   expireReviewsDays: 7,
      //   expireReviewsSecondWarning: 1,
      //   expireReviewsExpire: 3,
      //   expireReviewsStatus: 'expired',
      //   autoApprove: true,
      //   autoApproveDays: 7,
      //   autoApproveSecondWarningDays: 5,
      //   revisionsWaitOnReviews: false,
      //   rejectedReviewRejectsRevision: false,
      //   approvedReviewsApproveRevision: false,
      //   reviewRequestedSubject: null,
      //   reviewRequestedLine1Manual: null,
      //   reviewRequestedLine1Auto: null,
      //   reviewRequestedLine2: null,
      //   reviewReminderSubject: null,
      //   reviewReminderLine1: null,
      //   reviewReminderLine2: null,
      //   reviewExpiredSubject: null,
      //   reviewExpiredLine1: null,
      //   reviewExpiredLine2: null,
      // });
    }
  });
});