define('postedin/components/content-type-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    i18n: Ember.inject.service(),
    name: Ember.computed('type.name', 'type.legacyContentType', function () {
      return this.get('type.legacyContentType.name') || this.get('i18n').t('content-types.' + this.get('type.name') + '.name');
    })
  }).reopenClass({
    positionalParams: ['type']
  });
});