define('postedin/components/content-review-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    statuses: [],
    includeArchived: false,
    showArchived: Ember.computed.oneWay('includeArchived'),
    hideArchived: Ember.computed.not('showArchived'),
    status: {
      pending: Ember.Object.create({ checked: false }),
      approved: Ember.Object.create({ checked: false }),
      rejected: Ember.Object.create({ checked: false }),
      expired: Ember.Object.create({ checked: false })
    },
    statusesDidChange: Ember.on('init', Ember.observer('statuses.@each', function () {
      var _this = this;

      var statusFilter = this.get('statuses');
      Object.keys(this.get('status')).forEach(function (name) {
        var status = _this.get('status.' + name);

        status.set('checked', statusFilter.includes(name));
      });
    })),
    refreshContent: function refreshContent() {
      // because things get buggy with arrays we workaround by changing a timestamp which will be sent to
      // this as `bust` and cause things to refresh
      this.set('bust', new Date().getTime());
      this.set('page', null);

      this.$().find('input, select').blur();
    },
    filterStatuses: function filterStatuses() {
      var _this2 = this;

      var statuses = Object.keys(this.get('status')).filter(function (name) {
        var status = _this2.get('status.' + name);

        return status.get('checked');
      });

      this.set('statuses', statuses);

      this.refreshContent();
    },

    actions: {
      filterStatus: function filterStatus() {
        var _this3 = this;

        Ember.run.later(function () {
          return _this3.filterStatuses();
        });
      },
      includeArchived: function includeArchived() {
        var _this4 = this;

        Ember.run.later(function () {
          _this4.set('includeArchived', _this4.get('showArchived') ? 1 : 0);

          var statuses = Object.keys(_this4.get('status'));

          if (_this4.get('includeArchived')) {
            // if at least 1 status has been filtered then lets filter the archived ones too
            var isFiltered = statuses.filter(function (name) {
              return _this4.get('status.' + name).get('checked');
            }).length;

            if (isFiltered) {
              statuses.forEach(function (name) {
                var status = _this4.get('status.' + name);

                if (status.get('archive')) {
                  status.set('checked', true);
                }
              });

              _this4.filterStatuses();
            } else {
              _this4.refreshContent();
            }

            return;
          }

          statuses.forEach(function (name) {
            var status = _this4.get('status.' + name);

            if (status.get('archive')) {
              status.set('checked', false);
            }
          });

          _this4.refreshContent();
        });
      }
    }
  });
});