define('postedin/components/content-request-draft', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, {
    i18n: Ember.inject.service(),
    classNames: ['content-request-draft'],
    title: Ember.computed('draft.id', 'draft.summary', function () {
      return this.get('draft.summary') || this.get('i18n').t('draft') + ' #' + this.get('draft.id');
    }),
    actions: {
      toggleDelete: function toggleDelete() {
        this.toggleProperty('deleting');
      },
      delete: function _delete(draft) {
        var _this = this;

        this.uiPromise(draft.delete(), 'delete').then(function () {
          _this.$().slideUp();
        });
      }
    }
  }).reopenClass({
    positionalParams: ['draft']
  });
});