define('postedin/router', ['exports', 'postedin/config/environment', 'postedin/mixins/google-pageview'], function (exports, _environment, _googlePageview) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_googlePageview.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    pageViews: Ember.inject.service(),
    session: Ember.inject.service(),
    uiPopup: Ember.inject.service(),
    beforePageviewToGA: function beforePageviewToGA(ga) {
      if (this.get('session.user.gaid')) {
        ga('set', 'userId', this.get('session.user.gaid'));
        ga('set', 'dimension1', this.get('session.user.gaid'));
      }
    },

    logPageView: Ember.on('didTransition', function () {
      if (this.get('session.user')) {
        this.get('pageViews').log(window.location.pathname, window.location.search, window.location.hash);
      }
    }),
    removeOrphanedPopup: Ember.on('didTransition', function () {
      this.get('uiPopup').removeOrphanedPopup();
    }),
    removeBootstrapLoading: Ember.on('didTransition', function () {
      Ember.$('.postedin-loading').remove();
    }),
    _hydrateUnsuppliedQueryParams: function _hydrateUnsuppliedQueryParams(state, queryParams) {
      if (queryParams._explicitQueryParams) {
        delete queryParams._explicitQueryParams;

        return queryParams;
      }

      return this._super(state, queryParams);
    }
  });

  Router.map(function () {
    if (_environment.default.build === 'admin') {
      this.route('admin-login', { path: '/login' });
      this.route('admin-dashboard', { path: '/dashboard' });
      this.route('admin-account', { path: '/account' });
      this.route('admin-release-notes', { path: 'release-notes' }, function () {
        this.route('new');
        this.route('show', { path: ':id' }, function () {
          this.route('edit');
        });
      });
      this.route('chile-payments', function () {
        this.route('new');
        this.route('show', { path: ':id' });
        this.route('single-new');
      });
      this.route('international-payments', function () {
        this.route('new');
        this.route('show', { path: ':id' });
        this.route('single-new');
      });
      this.route('beta-users', function () {
        this.route('show', { path: ':id' }, function () {
          this.route('content-stats');
          this.route('payment-data');
          this.route('user-notes');
          this.route('invoices');
        });
        this.route('suspended');
        this.route('deleted');
        this.route('international');
        this.route('national');
        this.route('all');
      });
      this.route('users', function () {
        this.route('show', { path: ':id' }, function () {});
      });
      this.route('admin-projects', function () {
        this.route('show', { path: ':id' }, function () {
          this.route('warn');
          this.route('suspend');
        });
        this.route('create');
      });
      this.route('admin-organizations', function () {
        this.route('show', { path: ':id' }, function () {
          this.route('suspend');
        });
        this.route('create');
      });
      this.route('invoices', function () {
        this.route('show', { path: ':id' }, function () {
          this.route('reject');
        });
      });
      this.route('admin-kpi');
      this.route('admin-calendar');

      this.route('admin-faq', function () {
        this.route('new-category');
        this.route('new-question');

        this.route('categories', function () {});

        this.route('questions', function () {
          this.route('show', { path: ':id' }, function () {
            this.route('edit');
          });
        });
      });
      this.route('admin-missions');
    }

    if (_environment.default.build === 'partner') {
      this.route('login', function () {
        this.route('forgot-password', { path: 'recover-password' });
        this.route('reset-password', { path: 'reset-password/:token' });
        this.route('registration');
      });
      this.route('invite', { path: '/i/:uuid' });
      this.route('impersonate');
      this.route('dashboard', function () {
        this.route('suggestions', function () {
          this.route('active');
          this.route('inactive');
          this.route('all');
        });
      });
      this.route('release-notes');
      this.route('calendar');
      this.route('content-requests', function () {
        this.route('show', { path: ':id' }, function () {
          this.route('issues', function () {
            this.route('show', { path: ':issueId' });
            this.route('closed');
            this.route('create');
          });
          this.route('audit-log');
          this.route('publish');
          this.route('article', function () {
            this.route('create');
            this.route('show', { path: ':revisionId' });
            this.route('edit', { path: ':revisionId/edit' });
            this.route('publish', { path: ':revisionId/publish' }, function () {
              this.route('manual');
              this.route('publisher', { path: ':publisherId' }, function () {
                this.route('connect');
                this.route('prepare');
                this.route('send');
              });
            });
          });
          this.route('admin');
          this.route('embed');
          this.route('revision');
          this.route('reviews', function () {
            this.route('show', { path: ':reviewId' });
          });
        });
      });
      this.route('organization', { path: '/organization/:id' }, function () {
        this.route('projects');
        this.route('people');
        this.route('accounts', function () {
          this.route('show', { path: ':accountId' }, function () {
            this.route('edit');
            this.route('analyze');
          });
          this.route('create');
          this.route('analyze');
        });
        this.route('settings', function () {
          this.route('profile');
          this.route('audit-log');
          this.route('projects');
        });
        this.route('editors');
        this.route('content');
      });
      this.route('project', { path: '/project/:id' }, function () {
        this.route('profile', function () {
          this.route('updates');
        });
        this.route('content-types');
        this.route('account');
        this.route('dashboard');
        this.route('notes');
        this.route('editors');
        this.route('users', function () {
          this.route('show', { path: ':userId' });
          this.route('add');
          this.route('invite');
          this.route('invites');
        });
        this.route('reviewing');
        this.route('settings', function () {
          this.route('options', function () {
            this.route('suggestions');
            this.route('reviewing');
            this.route('labels');
          });
          this.route('profile', function () {
            this.route('project');
            this.route('localization');
            this.route('contact');
          });
          this.route('publishers', function () {
            this.route('show', { path: ':publisherId' }, function () {
              this.route('edit');
            });
            this.route('create');
          });
          this.route('seo');
          this.route('labels');
          this.route('audit-log');
          this.route('transfer');
          this.route('reference-sites', function () {
            this.route('show', { path: ':referenceSiteId' }, function () {
              this.route('edit');
            });
          });
          this.route('keywords', function () {
            this.route('bulk');
          });
          this.route('forbidden-sources', function () {
            this.route('show', { path: ':forbiddenSourceId' }, function () {
              this.route('edit');
            });
          });
          this.route('admin');
          this.route('images', function () {
            this.route('new');
            this.route('show', { path: ':imageId' });
            this.route('edit', { path: ':imageId/edit' });
          });
          this.route('style');
          this.route('disallowed-words');
        });
        this.route('content');
        this.route('suggestions');

        this.route('show', function () {
          this.route('settings', function () {});
        });
        this.route('publish', function () {
          this.route('show', { path: ':revisionId' });
        });
      });
      this.route('content-types', function () {
        this.route('show', { path: ':slugId' });
      });
      this.route('request-content', function () {
        this.route('show', { path: ':id' }, function () {
          this.route('guidelines');
          this.route('type-extras');
          this.route('checkout');
        });
      });
      this.route('account', function () {
        this.route('email');
        this.route('password');
        this.route('notifications');
      });
      this.route('faq');
      this.route('note-permalink', { path: '/note-permalink/:id' });
      this.route('help', function () {
        this.route('article', { path: 'articles/:slugId' });
      });
    }

    this.route('not-found', { path: '/*path' });
    this.route('server-not-found');
    this.route('forbidden');
    this.route('server-error');
    this.route('admin-missions');
    this.route('request-content', function () {
      this.route('show', { path: ':id' }, function () {
        this.route('guidelines');
        this.route('type-guidelines');
        this.route('type-extras');
        this.route('checkout');
      });
    });
    this.route('suggestions', function () {
      this.route('show', { path: ':id' }, function () {
        this.route('edit');
      });
      this.route('create');
    });
    this.route('comment-permalink', { path: '/comments/:id' });
    this.route('content-article-revision-permalink', { path: '/content-article-revisions/:id' });
    this.route('issue-permalink', { path: '/issues/:id' });
    this.route('editors', function () {
      this.route('capacity');
      this.route('faq', function () {
        this.route('create');
        this.route('show', { path: ':id' });
        this.route('edit', { path: ':id/edit' });
      });
      this.route('faq-categories', function () {
        this.route('create');
        this.route('show', { path: ':id' });
        this.route('edit', { path: ':id/edit' });
      });
      this.route('analyze-accounts');
    });
    this.route('admin', function () {
      this.route('settings');
      this.route('organizations', function () {
        this.route('create');
        this.route('edit', { path: ':id/edit' });
      });
      this.route('projects', function () {});
      this.route('users', function () {
        this.route('create');
        this.route('show', { path: ':id' });
        this.route('edit', { path: ':id/edit' });
      });
      this.route('release-notes', function () {
        this.route('create');
        this.route('show', { path: ':id' });
        this.route('edit', { path: ':id/edit' });
      });
      this.route('categories', function () {
        this.route('edit', { path: ':id/edit' });
      });
      this.route('legacy-content-types', function () {
        this.route('create');
        this.route('edit', { path: ':id' });
      });
      this.route('legacy-content-type-groups', function () {
        this.route('create');
      });
      this.route('beta-users', function () {
        this.route('show', { path: ':id' });
        this.route('edit', { path: ':id/edit' });
      });
      this.route('managers', function () {
        this.route('create');
      });
      this.route('tools');
    });
    this.route('revisions', function () {
      this.route('show-article', { path: 'article/:id' });
    });
    this.route('content-reviews', function () {
      this.route('show', { path: ':id' }, function () {
        this.route('more');
      });
    });
    this.route('content-review-permalink', { path: '/content-reviews/:id' });
    this.mount('dev');
    this.mount('clients');
    this.mount('master-accounts');
  });

  exports.default = Router;
});