define('postedin/components/client-create-organization-form', ['exports', 'account-components/components/client-create-organization-form'], function (exports, _clientCreateOrganizationForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clientCreateOrganizationForm.default;
    }
  });
});