define('postedin/routes/admin/legacy-content-types/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    legacyContentTypes: Ember.inject.service(),
    legacyContentTypeGroups: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        model: this.get('legacyContentTypes').findById(params.id),
        groups: this.get('legacyContentTypeGroups').all()
      });
    },
    setupController: function setupController(controller, hash) {
      hash.model.set('active', hash.model.get('active') ? 'yes' : 'no');
      hash.model.set('hide', hash.model.get('hide') ? 'yes' : 'no');
      hash.model.set('autoCreateMissions', hash.model.get('autoCreateMissions') ? 'yes' : 'no');
      hash.model.set('editorsOnly', hash.model.get('editorsOnly') ? 'yes' : 'no');
      hash.model.set('importsBetaSources', hash.model.get('importsBetaSources') ? 'yes' : 'no');
      hash.model.set('includesMetaDescription', hash.model.get('includesMetaDescription') ? 'yes' : 'no');
      hash.model.set('includesExtraPictures', hash.model.get('includesExtraPictures') ? 'yes' : 'no');
      hash.model.set('disableMetaDescription', hash.model.get('disableMetaDescription') ? 'yes' : 'no');
      hash.model.set('disableExtraPictures', hash.model.get('disableExtraPictures') ? 'yes' : 'no');
      hash.model.set('complex', hash.model.get('complex') ? 'yes' : 'no');
      hash.model.set('legacyContentTypeGroup', hash.groups.findBy('id', hash.model.assignmentTypeGroup.id));

      this._super(controller, hash.model);

      controller.set('groups', hash.groups);
      controller.set('originalName', hash.model.name);
      controller.set('languages', ['spanish', 'english', 'portuguese']);

      controller.resetErrors();

      controller.set('saving', false);
      controller.set('delete', false);
      controller.set('deleting', false);
    }
  });
});