define('postedin/controllers/editors/faq-categories/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    waiting: false,
    sorted: Ember.computed('model.@each.displayOrder', function () {
      var sorted = this.get('model').sort(function (a, b) {
        return a.get('displayOrder') - b.get('displayOrder');
      });

      sorted.forEach(function (category) {
        category.set('isFirst', false);
        category.set('isLast', false);
      });

      sorted[0].set('isFirst', true);
      sorted[sorted.length - 1].set('isLast', true);

      return sorted;
    }),
    actions: {
      moveCategoryUp: function moveCategoryUp(category) {
        var _this = this;

        if (category.get('isFirst')) {
          return;
        }

        this.set('waiting', true);

        var sorted = this.get('sorted');
        var categoryIndex = sorted.findIndex(function (c) {
          return c.get('id') === category.get('id');
        });
        var categoryAbove = sorted[categoryIndex - 1];
        var categoryOrder = category.get('displayOrder');

        category.swapOrder(categoryAbove).then(function () {
          categoryAbove.set('displayOrder', categoryOrder);
        }).finally(function () {
          _this.set('waiting', false);
        });
      },
      moveCategoryDown: function moveCategoryDown(category) {
        var _this2 = this;

        if (category.get('isLast')) {
          return;
        }

        this.set('waiting', true);

        var sorted = this.get('sorted');
        var categoryIndex = sorted.findIndex(function (c) {
          return c.get('id') === category.get('id');
        });
        var categoryBelow = sorted[categoryIndex + 1];
        var categoryOrder = category.get('displayOrder');

        category.swapOrder(categoryBelow).then(function () {
          categoryBelow.set('displayOrder', categoryOrder);
        }).finally(function () {
          _this2.set('waiting', false);
        });
      }
    }
  });
});