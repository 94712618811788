define('postedin/models/beta-user', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    betaUsers: Ember.inject.service(),
    loadProfile: function loadProfile() {
      return this.get('betaUsers').profile(this.get('id'));
    },
    loadStats: function loadStats() {
      return this.get('betaUsers').stats(this.get('id'));
    },
    loadUserNotes: function loadUserNotes() {
      return this.get('betaUsers').userNotes(this.get('id'));
    },
    loadPaymentData: function loadPaymentData() {
      return this.get('betaUsers').paymentData(this.get('id'));
    },
    loadInvoices: function loadInvoices() {
      return this.get('betaUsers').invoices(this.get('id'));
    },
    updatePaymentRequired: function updatePaymentRequired(required) {
      return this.get('betaUsers').updatePaymentRequired(this, required);
    },
    update: function update(name, email) {
      return this.get('betaUsers').update(this, name, email);
    }
  });
});