define('postedin/components/content-suggestion-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    organizationRepository: Ember.inject.service('organizations'),
    store: Ember.inject.service('simple-store'),
    search: '',
    organizations: [],
    projectsFilter: [],
    statuses: [],
    includeArchived: false,
    showArchived: Ember.computed.oneWay('includeArchived'),
    hideArchived: Ember.computed.not('showArchived'),
    status: {
      suggested: Ember.Object.create({ checked: false }),
      accepted: Ember.Object.create({ checked: false }),
      rejected: Ember.Object.create({ checked: false }),
      expired: Ember.Object.create({ checked: false }),
      duplicated: Ember.Object.create({ checked: false })
    },
    showRejected: Ember.computed('session.user', function () {
      return this.get('session.user.isAdmin') || this.get('session.user.tempType') === 'editor';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('singleProject')) {
        // if we already have projects no need to block the UI with loading,
        // it will just update it if it finds anything different
        var organizations = this.get('store').find('organization').sortBy('name');
        if (organizations.get('length')) {
          // all organizations have projects key
          if (!organizations.filter(function (o) {
            return !o.get('projects');
          }).length) {
            this.set('organizations', organizations);
          }
        }

        if (this.get('organizations.length')) {
          this.loadProjects();
        } else {
          this.uiPromise(this.loadProjects(), 'projectsLoading');
        }
      }
    },

    selectedProjects: Ember.computed('organizations.length', 'projectsFilter.length', function () {
      var projectIds = this.get('projectsFilterIds');

      var selected = [];
      this.get('organizations').forEach(function (organization) {
        var projects = organization.get('projects').filter(function (project) {
          return projectIds.includes(project.get('id'));
        });

        if (projects.length) {
          selected.push({
            organization: organization,
            projects: projects
          });
        }
      });

      return selected;
    }),
    projectsFilterIds: Ember.computed('organizations.length', 'projectsFilter.length', 'projectLabelsFilter.length', function () {
      return this.get('projectsFilter').map(function (p) {
        return parseInt(p);
      });
    }),
    statusesDidChange: Ember.on('init', Ember.observer('statuses.@each', function () {
      var _this = this;

      var statusFilter = this.get('statuses');
      Object.keys(this.get('status')).forEach(function (name) {
        var status = _this.get('status.' + name);

        status.set('checked', statusFilter.includes(name));
      });
    })),
    searchChanged: Ember.on('init', Ember.observer('search', function () {
      var search = this.get('search');
      if (search !== this.get('newSearch')) {
        this.set('newSearch', search);
      }
    })),
    loadProjects: function loadProjects() {
      var _this2 = this;

      return this.get('organizationRepository').allWithProjects().then(function (organizations) {
        _this2.set('organizations', organizations);
      });
    },
    refreshContent: function refreshContent() {
      // because things get buggy with arrays we workaround by changing a timestamp which will be sent to
      // this as `bust` and cause things to refresh
      this.set('bust', new Date().getTime());
      this.set('page', null);

      this.$().find('input, select').blur();
    },
    filterProject: function filterProject(project) {
      if (!project) {
        return;
      }

      var projects = this.get('projectsFilter');
      projects.push(project.get('id'));
      this.set('projectsFilter', projects.uniq());

      this.set('selectedProject');
    },
    filterStatuses: function filterStatuses() {
      var _this3 = this;

      var statuses = Object.keys(this.get('status')).filter(function (name) {
        var status = _this3.get('status.' + name);

        return status.get('checked');
      });

      this.set('statuses', statuses);

      this.refreshContent();
    },

    actions: {
      search: function search(newSearch) {
        this.set('search', newSearch);

        this.refreshContent();
      },
      clearSearch: function clearSearch() {
        this.set('search', '');

        this.refreshContent();
      },
      filterProject: function filterProject(project) {
        this.filterProject(project);

        this.refreshContent();
      },
      removeProjectFilter: function removeProjectFilter(project) {
        var projects = this.get('projectsFilter');
        this.set('projectsFilter', projects.without(project.get('id')).without(project.get('id') + '').uniq());

        this.refreshContent();
      },
      filterStatus: function filterStatus() {
        var _this4 = this;

        Ember.run.later(function () {
          return _this4.filterStatuses();
        });
      },
      includeArchived: function includeArchived() {
        var _this5 = this;

        Ember.run.later(function () {
          _this5.set('includeArchived', _this5.get('showArchived') ? 1 : 0);

          var statuses = Object.keys(_this5.get('status'));

          if (_this5.get('includeArchived')) {
            // if at least 1 status has been filtered then lets filter the archived ones too
            var isFiltered = statuses.filter(function (name) {
              return _this5.get('status.' + name).get('checked');
            }).length;

            if (isFiltered) {
              statuses.forEach(function (name) {
                var status = _this5.get('status.' + name);

                if (status.get('archive')) {
                  status.set('checked', true);
                }
              });

              _this5.filterStatuses();
            } else {
              _this5.refreshContent();
            }

            return;
          }

          statuses.forEach(function (name) {
            var status = _this5.get('status.' + name);

            if (status.get('archive')) {
              status.set('checked', false);
            }
          });

          _this5.refreshContent();
        });
      }
    }
  });
});