define('postedin/controllers/account/settings', ['exports', 'postedin/mixins/validation-rules'], function (exports, _validationRules) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationRules.default, {
    users: Ember.inject.service(),
    session: Ember.inject.service(),
    app: Ember.inject.service(),
    emailErrors: {},
    errors: {},
    observeEmailPassword: function () {
      this.set('emailErrors.passwordRequired', false);
      this.set('emailErrors.incorrectPassword', false);
    }.observes('emailPassword'),
    observeEmail: function () {
      this.set('emailErrors.emailRequired', false);
      this.set('emailErrors.emailInvalid', false);
      this.set('emailErrors.emailTaken', false);
    }.observes('email'),
    observePassword: function () {
      this.set('errors.passwordRequired', false);
      this.set('errors.incorrectPassword', false);
    }.observes('password'),
    observeNewPassword: function () {
      this.set('errors.passwordTooShort', false);
      this.set('errors.newPasswordRequired', false);
      this.set('errors.newPasswordsDontMatch', false);
    }.observes('newPassword'),
    observeNewPasswordRepeated: function () {
      this.set('errors.newPasswordsDontMatch', false);
    }.observes('newPasswordRepeated'),
    actions: {
      saveEmail: function saveEmail() {
        var _this = this;

        var password = this.get('emailPassword');
        var email = this.get('email');
        var valid = true;

        if (!password) {
          this.set('emailErrors.passwordRequired', true);
          valid = false;
        }

        if (!email) {
          this.set('emailErrors.emailRequired', true);
          valid = false;
        } else if (!this.emailValidation(email)) {
          this.set('emailErrors.emailInvalid', true);
          valid = false;
        }

        if (!valid) {
          return;
        }

        this.set('savingEmail', true);
        this.get('users').updateEmail(this.get('session.user'), { password: password, email: email }).then(function () {
          _this.set('savingEmail', false);
          _this.set('emailSaved', true);
          Ember.run.later(function () {
            return _this.set('emailSaved', false);
          }, 1000);
          _this.set('emailPassword', '');
          _this.set('email', '');
        }, function (response) {
          _this.set('savingEmail', false);
          if (response.status === 422) {
            if (response.responseJSON.error && response.responseJSON.error === 'incorrect_password') {
              _this.set('emailErrors.incorrectPassword', true);
            }
            if (response.responseJSON.errors && response.responseJSON.errors.email) {
              if (response.responseJSON.errors.email[0] === 'The email has already been taken.') {
                _this.set('emailErrors.emailTaken', true);
              } else {
                _this.set('emailErrors.emailInvalid', true);
              }
            }
          } else {
            _this.set('emailErrors.server', true);
          }
        });
      },
      savePassword: function savePassword() {
        var _this2 = this;

        var password = this.get('password');
        var newPassword = this.get('newPassword');
        var newPasswordRepeated = this.get('newPasswordRepeated');
        var valid = true;

        if (!password) {
          this.set('errors.passwordRequired', true);
          valid = false;
        }

        if (!newPassword) {
          this.set('errors.newPasswordRequired', true);
          valid = false;
        } else if (newPassword.length < 6) {
          this.set('errors.passwordTooShort', true);
          valid = false;
        }

        if (valid && newPassword !== newPasswordRepeated) {
          this.set('errors.newPasswordsDontMatch', true);
          valid = false;
        }

        if (!valid) {
          return;
        }

        this.set('saving', true);
        this.get('users').updatePassword(this.get('session.user'), { password: password, newPassword: newPassword }).then(function () {
          _this2.set('saving', false);
          _this2.set('saved', true);
          Ember.run.later(function () {
            return _this2.set('saved', false);
          }, 1000);
          _this2.set('password', '');
          _this2.set('newPassword', '');
          _this2.set('newPasswordRepeated', '');
        }, function (respond) {
          _this2.set('saving', false);
          if (respond.status === 422) {
            if (respond.responseJSON.error && respond.responseJSON.error === 'incorrect_password') {
              _this2.set('errors.incorrectPassword', true);
            }
          } else {
            _this2.set('error', true);
          }
        });
      }
    }
  });
});