define('postedin/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    app: Ember.inject.service(),
    model: function model() {
      if (this.get('app.build') === 'partner') {
        this.transitionTo('dashboard');
      }

      if (this.get('app.build') === 'admin') {
        this.transitionTo('admin-dashboard');
      }
    }
  });
});