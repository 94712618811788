define('postedin/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _oauth2PasswordGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: '/api/oauth/token',
    makeRequest: function makeRequest(url, data) {
      var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!headers['X-Requested-With']) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
      }

      return this._super(url, data, headers);
    }
  });
});