define('postedin/controllers/admin-projects/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      filter: function filter(type) {
        this.set('filter.' + type, !this.get('filter.' + type));

        var projects = [];
        var filter = this.get('filter');
        this.get('model').forEach(function (organization) {
          organization.projects.forEach(function (project) {
            if (filter.warned && project.warned) {
              projects.push(project);
            }
            if (filter.suspended && project.suspended) {
              projects.push(project);
            }
            if (!filter.warned && !filter.suspended) {
              projects.push(project);
            }
          });
        });
        this.set('projects', projects);
      }
    }
  });
});