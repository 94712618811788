define('postedin/components/star-rating-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['star-rating', 'star-rating--input'],
    max: 5,
    preview: 0,
    value: 0,
    previewPhraseKey: null,
    valuePhraseKey: null,
    phraseKeys: ['regular', 'good', 'very_good', 'excellent', 'perfect'],
    stars: Ember.computed('preview', 'value', 'max', function () {
      var value = this.get('value');
      var preview = this.get('preview');
      if (preview > 0) {
        value = preview;
      }

      var stars = [];
      for (var i = 0; i < this.get('max'); i++) {
        stars.push(value > i);
      }

      return stars;
    }),
    mouseLeave: function mouseLeave() {
      this.set('preview', 0);
      this.set('previewPhraseKey', null);
    },

    actions: {
      previewStar: function previewStar(index) {
        this.set('preview', index + 1);
        this.set('previewPhraseKey', this.get('phraseKeys')[index]);
      },
      selectStar: function selectStar(index) {
        this.set('value', index + 1);
        this.set('valuePhraseKey', this.get('phraseKeys')[index]);
      }
    }
  });
});