define('postedin/controllers/admin/organizations/create', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    organizations: Ember.inject.service(),
    // note: this is just to avoid using a component instead of partial for form-fields.hbs
    updates: Ember.computed.alias('model'),
    reviewConcludableStatuses: ['expired', 'approved', 'rejected'],
    validate: function validate(data) {
      var rules = {
        name: 'required',
        maxProjects: 'required|min:1',
        newProjectsHideTypes: 'required|boolean',
        expireReviews: 'required|boolean',
        expireReviewsOnWeekdays: 'required|boolean',
        expireReviewsDays: ['integer', { min: 1 }, { required_if: ['expireReviews', true] }],
        expireReviewsSecondWarning: ['integer', { min: 0 }, { required_if: ['expireReviews', true] }],
        expireReviewsExpire: ['integer', { min: 1 }, { required_if: ['expireReviews', true] }],
        expireReviewsStatus: [{ required_if: ['expireReviews', true] }, 'in:expired,approved,rejected'],
        autoApprove: 'required|boolean',
        autoApproveDays: ['integer', 'min:1', { required_if: ['autoApprove', true] }],
        autoApproveSecondWarningDays: ['integer', 'min:0', { required_if: ['autoApprove', true] }],
        revisionsWaitOnReviews: ['boolean', { required_if: ['autoApprove', true] }],
        rejectedReviewRejectsRevision: 'required|boolean',
        approvedReviewsApproveRevision: 'required|boolean'
      };

      return this.validatorCheck(data, rules);
    },

    actions: {
      saveOrganization: function saveOrganization(organization) {
        var _this = this;

        if (this.validate(organization)) {
          this.uiPromise(this.get('organizations').createOrganization(organization), 'saveOrganization').then(function (organization) {
            _this.transitionToRoute('organization', organization.id);
          }, function (response) {
            _this.serverFailureToValidatorErrors(response);
            _this.scrollToFirstError();
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});