define('postedin/components/content-review/judge-buttons', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, {
    session: Ember.inject.service(),
    fixed: true,
    stopAtFormBottom: true,
    showApproveModal: false,
    showRejectModal: false,
    removeReviewComment: '',
    approveReviewComment: '',
    errors: {},
    disableSubmit: Ember.computed.or('rejectReview.waitingAndSaved', 'rejectReviewUploader.isUploading', 'emptyComment'),
    emptyComment: Ember.computed.not('removeReviewComment'),
    showButtons: Ember.computed.or('review.{canJudge,canSendNotification}'),
    actions: {
      toggleApproveModal: function toggleApproveModal() {
        this.toggleProperty('showApproveModal');
      },
      toggleRejectModal: function toggleRejectModal() {
        this.toggleProperty('showRejectModal');
      },
      sendNotification: function sendNotification() {
        this.uiPromise(this.get('review').notify(), 'sendNotification');
      },
      approveReview: function approveReview(contentReview, comment, uploads) {
        var _this = this;

        var promise = contentReview.approve(comment, uploads);

        this.uiPromise(promise, 'approveReview').then(function () {
          _this.sendAction('reviewJudged');

          _this.set('showApproveModal', false);
          contentReview.reload();
          _this.get('session').reload();
        });
      },
      rejectReview: function rejectReview(contentReview, comment, uploads) {
        var _this2 = this;

        this.set('errors.comment', false);

        if (!this.get('removeReviewComment')) {
          this.set('errors.comment', true);
          return;
        }

        var promise = contentReview.reject(comment, uploads);

        this.uiPromise(promise, 'rejectReview').then(function () {
          _this2.sendAction('reviewJudged');

          _this2.set('showRejectModal', false);
          contentReview.reload();
          _this2.get('session').reload();
        });
      }
    }
  }).reopenClass({
    positionalParams: ['review']
  });
});