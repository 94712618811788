define('postedin/components/client-organizations-list-linked', ['exports', 'master-accounts/components/client-organizations-list-linked'], function (exports, _clientOrganizationsListLinked) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clientOrganizationsListLinked.default;
    }
  });
});