define('postedin/routes/admin/users/create', ['exports', 'postedin/models/user'], function (exports, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return _user.default.create();
    }
  });
});