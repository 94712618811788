define('postedin/helpers/lines-to-html', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.linesToHtml = linesToHtml;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function linesToHtml(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        text = _ref2[0];

    if (!text) {
      return '';
    }

    if (text.trim().startsWith('<p')) {
      // probably html already
      return text;
    }

    var html = text.replace(/\n{2}/g, '</p><p>');
    html = html.replace(/\n/g, '<br />');
    html = '<p class="no-margin">' + html + '</p>';

    return html;
  }

  exports.default = Ember.Helper.helper(linesToHtml);
});