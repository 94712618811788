define('postedin/services/project-keywords', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'project-keyword',
    update: function update(keyword, name) {
      var _this = this;

      return this.put('project-keywords/' + keyword.id, { name: name }).then(function (record) {
        return _this.push(record);
      });
    },
    remove: function remove(keyword) {
      var _this2 = this;

      return this.delete('project-keywords/' + keyword.id).then(function () {
        _this2.removeObject(keyword.id);
      });
    }
  });
});