define('postedin/controllers/admin-organizations/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    organizations: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('model.name')) {
          this.set('nameRequired', true);
          return;
        }

        this.set('saving', true);
        this.get('organizations').createOrganization(this.get('model')).then(function () {
          return _this.transitionToRoute('admin-organizations');
        }).finally(function () {
          return _this.set('saving', false);
        });
      }
    }
  });
});