define('postedin/components/project-label', ['exports', 'postedin/models/project-label'], function (exports, _projectLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProjectLabelComponent = Ember.Component.extend({
    tagName: 'span',
    style: Ember.computed('label.color', 'name', 'color', function () {
      if (!this.get('label') && this.get('name') && this.get('color')) {
        this.set('label', _projectLabel.default.create({
          name: this.get('name'),
          color: this.get('color')
        }));
      }

      var blackText = ['yellow', 'skyblue', 'pink', 'lime', 'orange', 'gold', 'violet', '#f1c232'];
      var color = Ember.Handlebars.Utils.escapeExpression(this.get('label.color'));
      var style = ['background-color: ' + color];

      if (blackText.includes(color.toLowerCase())) {
        style.push('color: black');
      }

      return Ember.String.htmlSafe(style.join(';'));
    })
  });

  ProjectLabelComponent.reopenClass({
    positionalParams: ['label']
  });

  exports.default = ProjectLabelComponent;
});