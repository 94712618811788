define('postedin/routes/admin/beta-users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    betaUsers: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      bustParam: {
        refreshModel: true
      }
    },
    refresh: function refresh() {
      if (this.controller) {
        this.controller.set('loading', true);
      }

      return this._super();
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        params: params,
        model: this.get('betaUsers').find({
          search: params.search,
          locality: params.locality,
          excludeActive: params.excludeActive,
          suspended: params.suspended,
          deleted: params.deleted
        }, Math.max(1, parseInt(params.page)))
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash);

      controller.set('users', hash.model.users);
      controller.set('meta', hash.model.meta);
      controller.set('params', hash.params);
      controller.set('loading', false);
      controller.set('search', hash.params.search);
      controller.set('locality', hash.params.locality);
      controller.set('excludeActive', hash.params.excludeActive);
      controller.set('suspended', hash.params.suspended);
      controller.set('deleted', hash.params.deleted);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('search', '');
        controller.set('locality', null);
        controller.set('excludeActive', null);
        controller.set('suspended', null);
        controller.set('deleted', null);
        controller.set('bustParam', null);
        controller.set('page', null);
      }
    }
  });
});