define('postedin/components/pikaday-input', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['readonly', 'disabled', 'placeholder', 'type', 'name', 'size', 'required'],
    type: 'text',
    disabledDates: [],
    fullDates: [],
    minDays: null,

    firstRender: true,

    setupPikaday: Ember.on('didRender', function () {
      if (this.get('firstRender')) {
        var that = this;
        var firstDay = this.get('firstDay');

        var options = {
          field: this.$()[0],
          onOpen: Ember.run.bind(this, this.onPikadayOpen),
          onClose: Ember.run.bind(this, this.onPikadayClose),
          onSelect: Ember.run.bind(this, this.onPikadaySelect),
          onDraw: Ember.run.bind(this, this.onPikadayRedraw),
          disableDayFn: Ember.run.bind(this, this.dayDisabled),
          firstDay: typeof firstDay !== 'undefined' ? parseInt(firstDay, 10) : 1,
          format: this.get('format') || 'YYYY-MM-DD',
          yearRange: that.determineYearRange(),
          minDate: this.get('minDate') || null,
          maxDate: this.get('maxDate') || null,
          disableWeekends: this.get('disableWeekends') || null,
          theme: this.get('theme') || null,
          i18n: {
            previousMonth: 'Mes anterior',
            nextMonth: 'Mes siguiente',
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sad']
          }
        };

        var pikaday = new Pikaday(options);

        // kinda hacky
        var $icon = Ember.$('<i class="icon-calendar2">');
        $icon.css({
          marginTop: -33,
          marginBottom: 19,
          marginLeft: 10,
          display: 'block',
          color: '#888',
          cursor: 'pointer'
        });
        $icon.click(function () {
          pikaday.show();
        });

        // now we really hacky
        if (this.$().hasClass('form__input')) {
          $icon.css({
            marginTop: -25
          });

          if (this.$().hasClass('form__input--large')) {
            $icon.css({
              marginTop: -28
            });
          }
        }

        this.$().css({
          paddingLeft: '2em',
          cursor: 'pointer'
        }).after($icon);

        this.set('pikaday', pikaday);
        this.setPikadayDate();

        this.addObserver('value', function () {
          that.setPikadayDate();
        });

        this.addObserver('minDate', function () {
          this.setMinDate();
        });

        this.addObserver('minDays', function () {
          var _this = this;

          Ember.run.later(function () {
            return _this.minDaysChanged();
          });
        });

        this.addObserver('maxDate', function () {
          this.setMaxDate();
        });

        this.set('firstRender', false);
      }
    }),

    teardownPikaday: Ember.on('willDestroyElement', function () {
      this.get('pikaday').destroy();
    }),

    minDaysChanged: function minDaysChanged() {
      if (this.get('value')) {
        var date = (0, _moment.default)(this.get('value'));
        while (this.dayDisabled(date)) {
          date.add(1, 'days');
        }
        this.get('pikaday').setMoment(date);
        this.userSelectedDate();
      }
    },
    isDisabledDate: function isDisabledDate(date) {
      date = (0, _moment.default)(date);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('disabledDates')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var disabledDate = _step.value;

          if (date.isSame(disabledDate)) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    },
    dayDisabled: function dayDisabled(date) {
      date = (0, _moment.default)(date);

      var disabledDates = [].concat(this.get('disabledDates'), this.get('fullDates'));

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = disabledDates[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var disabledDate = _step2.value;

          if (date.isSame(disabledDate, 'day')) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      if (this.get('disableWeekends') && (date.day() === 0 || date.day() === 6)) {
        return true;
      }

      var minDays = this.get('minDays');
      if (minDays === null) {
        return false;
      }

      var to = (0, _moment.default)();

      // if most of today is gone then add one
      if (to.hour() > 15) {
        to.add(1, 'day');
      }

      var days = 0;
      while (days < minDays) {
        to.add(1, 'day');
        // only apply working days here, disabled dates are assumed to be holidays
        if (to.day() > 0 && to.day() < 6 && !this.isDisabledDate(date)) {
          days++;
        }
      }

      return !date.isSameOrAfter(to);
    },
    setPikadayDate: function setPikadayDate() {
      var date = this.get('value');

      if (!date || this.dayDisabled(date)) {
        this.set('value', '');

        if (this.get('setFirstPossibleDate')) {
          // select first enabled date, start with today
          date = (0, _moment.default)();
          while (this.dayDisabled(date)) {
            date.add(1, 'd');
          }

          this.setValue(date);

          date = date.format('YYYY-MM-DD');
        }
      }

      this.get('pikaday').setDate(date + ' 00:00:00', true);
    },
    setMinDate: function setMinDate() {
      this.get('pikaday').setMinDate(this.get('minDate'));
    },
    setMaxDate: function setMaxDate() {
      this.get('pikaday').setMaxDate(this.get('maxDate'));
    },
    onPikadayOpen: function onPikadayOpen() {
      this.addFullDateStyles();
    },
    addFullDateStyles: function addFullDateStyles() {
      var $pikaday = Ember.$(this.get('pikaday').el);
      this.get('fullDates').forEach(function (date) {
        var _date$split = date.split('-'),
            _date$split2 = _slicedToArray(_date$split, 3),
            year = _date$split2[0],
            month = _date$split2[1],
            day = _date$split2[2];

        month = parseInt(month) - 1;
        day = parseInt(day);
        var $day = $pikaday.find('.pika-day[data-pika-year=' + year + '][data-pika-month=' + month + '][data-pika-day=' + day + ']');
        if ($day.length && !$day.closest('td').hasClass('is-full')) {
          $day.closest('td').addClass('is-full');
        }
      });
    },
    onPikadayClose: function onPikadayClose() {
      if (this.get('pikaday').getDate() === null || this.$() && Ember.isEmpty(this.$().val())) {
        this.set('value', null);
      }
    },
    onPikadaySelect: function onPikadaySelect() {
      this.userSelectedDate();
    },
    onPikadayRedraw: function onPikadayRedraw() {
      this.addFullDateStyles();
    },
    userSelectedDate: function userSelectedDate() {
      var date = this.get('pikaday').getDate();

      if (this.isDisabledDate(date)) {
        return;
      }

      date = [date.getFullYear(), date.getMonth(), date.getDate()];

      if (this.get('useUtc')) {
        date = _moment.default.utc(date);
      } else {
        date = (0, _moment.default)(date);
      }

      this.setValue(date);
    },
    setValue: function setValue(date) {
      if (this.get('valueFormat')) {
        this.set('value', date.format(this.get('valueFormat')));
      } else {
        this.set('value', date.toDate());
      }
    },
    determineYearRange: function determineYearRange() {
      var yearRange = this.get('yearRange');

      if (yearRange) {
        if (yearRange.indexOf(',') > -1) {
          var yearArray = yearRange.split(',');

          if (yearArray[1] === 'currentYear') {
            yearArray[1] = new Date().getFullYear();
          }

          return yearArray;
        } else {
          return yearRange;
        }
      } else {
        return 10;
      }
    },


    autoHideOnDisabled: Ember.observer('disabled', 'pikaday', function () {
      if (this.get('disabled') && this.get('pikaday')) {
        this.get('pikaday').hide();
      }
    })
  });
});