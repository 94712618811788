define('postedin/components/request-content-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    listedImageRequirements: Ember.computed('contentRequest.imageRequirements', function () {
      var isContentRequestRequirement = this.get('contentRequest.imageRequirements.firstObject.projectImage.id') ? true : false;
      var key = isContentRequestRequirement ? 'projectImage.id' : 'id';

      return this.get('contentRequest.imageRequirements').filter(function (img) {
        return img.id;
      }).uniqBy(key);
    })
  }).reopenClass({
    positionalParams: ['contentRequest']
  });
});