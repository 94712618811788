define('postedin/routes/help/article', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      var id = parseInt(params.slugId.split('_')[1]);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = _this.modelFor('help')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var question = _step.value;

            if (question.get('id') === id) {
              resolve(question);
              return;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        reject({
          status: 404
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var questions = this.modelFor('help');
      var siblings = questions.filter(function (question) {
        return question.category.id === model.category.id;
      });

      this.controller.set('category', model.category);
      this.controller.set('siblings', siblings);
    },

    actions: {
      didTransition: function didTransition() {
        Ember.run.next(this, function () {
          Ember.$('html, body').animate({
            scrollTop: 0
          }, 200);
        });
      }
    }
  });
});