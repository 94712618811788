define('postedin/mixins/authenticated-route-mixin', ['exports', 'ember-simple-auth/configuration'], function (exports, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    retryTransition: null,
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.isAuthenticated')) {
        transition.abort();
        this.get('session').set('attemptedTransition', transition);
        Ember.assert('The route configured as Configuration.authenticationRoute cannot implement the AuthenticatedRouteMixin mixin as that leads to an infinite transitioning loop!', this.get('routeName') !== _configuration.default.authenticationRoute);
        this.transitionTo(_configuration.default.authenticationRoute);
        return;
      }

      if (this.get('session.user')) {
        this.set('retryTransition', null);
        return this._super.apply(this, arguments);
      }

      transition.abort();
      this.set('retryTransition', function () {
        return transition.retry();
      });
    },

    watchSessionUser: function () {
      var retryTransition = this.get('retryTransition');
      if (this.get('session.user') && retryTransition) {
        retryTransition();
      }
    }.observes('session.user')
  });
});