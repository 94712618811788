define('postedin/helpers/ucfirst', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ucfirst = ucfirst;
  function ucfirst(string) {
    string += '';
    var f = string.charAt(0).toUpperCase();

    return f + string.substr(1);
  }

  exports.default = Ember.Helper.helper(ucfirst);
});