define('postedin/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    loggingIn: false,
    recoveringPassword: false,
    queryParams: 'unauthorized',
    unauthorized: null,
    currentYear: new Date().getFullYear(),
    masked: false,
    app: Ember.inject.service(),
    document: Ember.inject.service(),
    session: Ember.inject.service(),
    localState: Ember.inject.service(),
    projectContext: null,
    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});