define('postedin/routes/issue-permalink', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    issues: Ember.inject.service(),
    model: function model(params) {
      return this.get('issues').findById(params.id);
    },
    afterModel: function afterModel(issue, transition) {
      if (typeof issue.get('issuable').transitionToIssueRoute === 'function') {
        return issue.get('issuable').transitionToIssueRoute(issue, transition.queryParams);
      }

      this.transitionTo('index');
    }
  });
});