define('postedin/components/image-details-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImageDetailsCardComponent = Ember.Component.extend({
    classNames: ['image-details-card'],
    showInline: Ember.computed.or('inline', 'publishMode', 'featuredImageMode'),
    imageWidth: Ember.computed('inline', 'publishMode', 'featuredImageMode', function () {
      if (this.get('inline') || this.get('publishMode') || this.get('featuredImageMode')) {
        return 200;
      }

      return null;
    }),
    actions: {
      selectFeaturedImage: function selectFeaturedImage(image) {
        this.sendAction('selectFeaturedImage', image);
      }
    }
  });

  ImageDetailsCardComponent.reopenClass({
    positionalParams: ['image']
  });

  exports.default = ImageDetailsCardComponent;
});