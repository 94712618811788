define('postedin/controllers/editors/capacity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ['month'],
    month: ''
  });
});