define('postedin/components/publisher-wordpress', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    publishers: Ember.inject.service(),
    publisher: null,
    publication: null,
    publish: {},
    loadingCategories: true,
    loadingAuthors: true,
    categoriesError: false,
    authorsError: false,
    saving: false,
    initializeForm: function () {
      this.set('publish', {
        publicationId: this.get('publish.publicationId') ? this.get('publish.publicationId') : null,
        draft: this.get('publish.draft') ? this.get('publish.draft') : null,
        featuredImage: this.get('publish.featuredImage') ? this.get('publish.featuredImage') : null,
        author: this.get('publish.author') ? this.get('publish.author') : null,
        category: this.get('publish.category') ? this.get('publish.category') : null,
        tags: this.get('publish.tags') ? this.get('publish.tags') : [],
        tagsString: this.get('publish.tags') ? this.get('publish.tags').join(', ') : '',
        images: this.get('publish.images') ? this.get('publish.images') : [],
        postDate: this.get('publish.postDate') ? this.get('publish.postDate') : null
      });
      this.set('scheduleHour', '00');
      this.set('scheduleMinute', '00');
      if (this.get('publish.postDate')) {
        var datetime = (0, _moment.default)(this.get('publish.postDate'), 'YYYY-MM-DD HH:mm');
        this.set('scheduleDate', datetime.format('YYYY-MM-DD'));
        this.set('scheduleHour', datetime.format('HH'));
        this.set('scheduleMinute', datetime.format('mm'));
      }
      this.set('saving', false);
    }.on('init'),
    getAuthors: function () {
      var _this = this;

      this.set('loadingAuthors', true);
      this.set('authorError', false);
      this.get('publishers').browse(this.get('publisher.id'), 'authors').then(function (data) {
        _this.set('authors', data.result);
        _this.set('loadingAuthors', false);
      }, function () {
        _this.set('authorError', true);
      });
    }.on('init'),
    getCategories: function () {
      var _this2 = this;

      this.set('loadingCategories', true);
      this.set('categoriesError', false);
      this.get('publishers').browse(this.get('publisher.id'), 'categories').then(function (data) {
        _this2.set('categories', data.result);
        _this2.set('loadingCategories', false);
      }, function () {
        _this2.set('categoriesError', true);
      });
    }.on('init'),
    hours: Ember.computed(function () {
      return [].concat(_toConsumableArray(Array(24).keys())).map(function (hour) {
        if (hour < 10) {
          hour = '0' + hour;
        }

        return hour;
      });
    }),
    minutes: Ember.computed(function () {
      return [].concat(_toConsumableArray(Array(60).keys())).map(function (hour) {
        if (hour < 10) {
          hour = '0' + hour;
        }

        return hour;
      });
    }),
    setPublishDate: function () {
      if (this.get('scheduleDate')) {
        var scheduleDate = (0, _moment.default)(this.get('scheduleDate'));
        var datetime = (0, _moment.default)(scheduleDate.format('YYYY-MM-DD') + ' ' + this.get('scheduleHour') + ':' + this.get('scheduleMinute'), 'YYYY-MM-DD HH:mm');
        this.set('publish.postDate', datetime.utc().format('YYYY-MM-DD HH:mm:ss'));
      }
    }.observes('scheduleDate', 'scheduleTime'),
    setTags: Ember.observer('publish.tagsString', function () {
      if (this.get('publish.tagsString')) {
        this.set('publish.tags', Array.from(new Set(this.get('publish.tagsString').split(',').map(function (tag) {
          return tag.trim();
        }).filter(function (tag) {
          return tag;
        }))));
      }
    }),
    validate: function validate() {
      this.set('errors', {});
      var isValid = true;
      var publish = this.get('publish');

      if (!publish.draft) {
        this.set('errors.draft', true);
        isValid = false;
      }

      if (!publish.author) {
        this.set('errors.author', true);
        isValid = false;
      }

      return isValid;
    },

    actions: {
      addTag: function addTag() {
        var _this3 = this;

        var tags = this.get('newTag');
        if (tags) {
          tags = tags.split(',').forEach(function (tag) {
            tag = tag.trim();
            if (_this3.get('publish.tags').indexOf(tag) === -1) {
              _this3.get('publish.tags').pushObject(tag);
            }
          });

          this.set('newTag', '');
        }
      },
      removeTag: function removeTag(tag) {
        this.get('publish.tags').removeObject(tag);
      },
      toggleFeature: function toggleFeature(image) {
        if (this.get('publish.featuredImage') === image) {
          this.set('publish.featuredImage', null);
        } else {
          this.set('publish.featuredImage', image);
        }
      },
      retryCategories: function retryCategories() {
        this.loadCategories();
      },
      retryAuthors: function retryAuthors() {
        this.loadAuthors();
      },
      save: function save() {
        var _this4 = this;

        this.set('publish.publicationId', this.get('publication.id'));
        this.set('publish.images', this.get('publication.images'));
        this.set('publish.authorId', this.get('publish.author.id'));

        if (!this.validate()) {
          this.set('errorFields', true);
          Ember.run.later(function () {
            return _this4.set('errorFields', false);
          }, 2000);
          return;
        }

        this.set('saving', true);
        this.sendAction('action', this.get('publish'));
      }
    }
  });
});