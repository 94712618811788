define('postedin/components/project-radio-block', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'label',
    classNames: 'radio-block radio-block--project',
    checked: Ember.computed.equal('project.id', 'value.id'),
    click: function click() {
      this.set('value', this.get('project'));
    }
  }).reopenClass({
    positionalParams: ['project']
  });
});