define('postedin/services/organizations', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _repository.default.extend({
    model: 'organization',
    projectsRepository: Ember.inject.service('projects'),
    all: function all() {
      var _this = this;

      return this.cachePromise('organizations:all', function () {
        return _this.request('organizations');
      }).then(function (response) {
        return _this.pushAll(response.data);
      });
    },
    trashed: function trashed() {
      var _this2 = this;

      return this.request('organizations/trashed').then(function (response) {
        return _this2.pushResponse(response, 'trashed-organization');
      });
    },
    pushAll: function pushAll(organizations) {
      organizations.forEach(function (record) {
        if (record.profile.id) {
          record.profile._emberModel = 'organization-profile';
        }
      });

      return this.pushArray(organizations);
    },
    allWithProjects: function allWithProjects() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.RSVP.all([_this3.all(), _this3.get('projectsRepository').all()]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              organizations = _ref2[0],
              projects = _ref2[1];

          resolve(_this3.pushAllWithProjects(organizations, projects));
        }, reject);
      });
    },
    pushAllWithProjects: function pushAllWithProjects(organizations, projects) {
      organizations.forEach(function (organization) {
        if (!organization.get('projects')) {
          organization.set('projects', []);
        }
      });

      projects.forEach(function (project) {
        if (project.get('organization.name')) {
          if (!project.get('organization.projects').includes(project)) {
            project.get('organization.projects').push(project);
          }
        }
      });

      return organizations;
    },
    findById: function findById(id) {
      var _this4 = this;

      return this.request('organizations/' + id).then(function (record) {
        if (record.profile.id) {
          record.profile._emberModel = 'organization-profile';
        }

        return _this4.push(record);
      });
    },
    createOrganization: function createOrganization(org) {
      var _this5 = this;

      return this.post('organizations', org).then(function (record) {
        return _this5.push(record);
      });
    },
    updateOrganization: function updateOrganization(_ref3, name, maxProjects, newProjectsHideTypes, expireReviews, expireReviewsOnWeekdays, expireReviewsDays, expireReviewsSecondWarning, expireReviewsExpire, expireReviewsStatus, autoApprove, autoApproveDays, autoApproveSecondWarningDays, revisionsWaitOnReviews, rejectedReviewRejectsRevision, approvedReviewsApproveRevision, reviewRequestedSubject, reviewRequestedLine1Manual, reviewRequestedLine1Auto, reviewRequestedLine2, reviewReminderSubject, reviewReminderLine1, reviewReminderLine2, reviewExpiredSubject, reviewExpiredLine1, reviewExpiredLine2) {
      var id = _ref3.id;

      var _this6 = this;

      if (!id) {
        return false;
      }

      return this.put('organizations/' + id, { name: name, maxProjects: maxProjects, newProjectsHideTypes: newProjectsHideTypes, expireReviews: expireReviews, expireReviewsOnWeekdays: expireReviewsOnWeekdays, expireReviewsDays: expireReviewsDays, expireReviewsSecondWarning: expireReviewsSecondWarning, expireReviewsExpire: expireReviewsExpire, expireReviewsStatus: expireReviewsStatus, autoApprove: autoApprove, autoApproveDays: autoApproveDays, autoApproveSecondWarningDays: autoApproveSecondWarningDays, revisionsWaitOnReviews: revisionsWaitOnReviews, rejectedReviewRejectsRevision: rejectedReviewRejectsRevision, approvedReviewsApproveRevision: approvedReviewsApproveRevision, reviewRequestedSubject: reviewRequestedSubject, reviewRequestedLine1Manual: reviewRequestedLine1Manual, reviewRequestedLine1Auto: reviewRequestedLine1Auto, reviewRequestedLine2: reviewRequestedLine2, reviewReminderSubject: reviewReminderSubject, reviewReminderLine1: reviewReminderLine1, reviewReminderLine2: reviewReminderLine2, reviewExpiredSubject: reviewExpiredSubject, reviewExpiredLine1: reviewExpiredLine1, reviewExpiredLine2: reviewExpiredLine2 }).then(function (record) {
        return _this6.push(record);
      });
    },
    remove: function remove(organization) {
      var _this7 = this;

      if (!organization.id) {
        return false;
      }

      return this.delete('organizations/' + organization.id).then(function () {
        return _this7.removeObject(organization.id);
      });
    },
    restore: function restore(organization) {
      var _this8 = this;

      return this.post('organizations/' + organization.id + '/restore').then(function (record) {
        _this8.removeObject(organization.id, 'trashed-organization');

        return _this8.push(record);
      });
    },
    projects: function projects(id) {
      var _this9 = this;

      return this.request('organizations/' + id + '/projects').then(function (response) {
        return _this9.pushResponse(response, 'project');
      });
    },
    createProject: function createProject(organization, name) {
      var _this10 = this;

      return this.post('organizations/' + organization.get('id') + '/projects', { name: name }).then(function (record) {
        return _this10.push(record, 'project');
      });
    },
    accounts: function accounts(organization) {
      var _this11 = this;

      return this.request('organizations/' + organization.id + '/accounts').then(function (response) {
        return _this11.pushResponse(response, 'account');
      });
    },
    accountsAnalyze: function accountsAnalyze(organization) {
      return this.request('organizations/' + organization.id + '/accounts/analyze');
    },
    createAccount: function createAccount(organization, account) {
      var _this12 = this;

      return this.post('organizations/' + organization.id + '/accounts', account).then(function (record) {
        return _this12.push(record, 'account');
      });
    },
    transactions: function transactions(organization, params) {
      var _this13 = this;

      var query = {};
      if (params.page) {
        params.page = parseInt(params.page);
        if (params.page > 1) {
          query.page = params.page;
        }
      }

      return this.request('organizations/' + organization.get('id') + '/transactions?' + Ember.$.param(query)).then(function (response) {
        var transactions = _this13.pushResponse(response);
        delete response.data;

        return { transactions: transactions, meta: response };
      });
    },
    admins: function admins(organization) {
      var _this14 = this;

      return this.request('organizations/' + organization.id + '/admins').then(function (response) {
        return _this14.pushResponse(response, 'user');
      });
    },
    inviteAdmin: function inviteAdmin(organization, email) {
      var _this15 = this;

      return this.post('organizations/' + organization.get('id') + '/invite-admins', {
        email: email
      }).then(function (record) {
        return _this15.push(record, 'invite');
      });
    },
    addAdmin: function addAdmin(organization, admin) {
      var _this16 = this;

      return this.post('organizations/' + organization.get('id') + '/admins', {
        userId: admin.get('id')
      }).then(function (record) {
        return _this16.push(record, 'user');
      });
    },
    demoteAdmin: function demoteAdmin(user) {
      var _this17 = this;

      return this.put('organization-admins/' + user.organizationAdmin.id + '/demote').then(function (response) {
        _this17.pushResponse(response, 'user');
      });
    },
    removeAdmin: function removeAdmin(organization, admin) {
      // TODO: should use different endpoint
      return this.delete('organizations/' + organization.get('id') + '/admins/' + admin.get('id'));
    },
    managers: function managers(organization) {
      var _this18 = this;

      return this.request('organizations/' + organization.id + '/managers').then(function (response) {
        return _this18.pushResponse(response, 'user');
      });
    },
    inviteManager: function inviteManager(organization, email) {
      var _this19 = this;

      return this.post('organizations/' + organization.get('id') + '/invite-managers', {
        email: email
      }).then(function (record) {
        return _this19.push(record, 'invite');
      });
    },
    addManager: function addManager(organization, manager) {
      var _this20 = this;

      return this.post('organizations/' + organization.get('id') + '/managers', {
        userId: manager.get('id')
      }).then(function (record) {
        return _this20.push(record, 'user');
      });
    },
    promoteManager: function promoteManager(user) {
      var _this21 = this;

      return this.put('organization-managers/' + user.organizationManager.id + '/promote').then(function (response) {
        _this21.pushResponse(response, 'user');
      });
    },
    removeManager: function removeManager(organization, manager) {
      // TODO: should use different endpoint?
      return this.delete('organizations/' + organization.get('id') + '/managers/' + manager.get('id'));
    },
    editors: function editors(organization) {
      var _this22 = this;

      return this.request('organizations/' + organization.id + '/editors').then(function (response) {
        return _this22.pushResponse(response, 'user');
      });
    },
    addEditor: function addEditor(organization, user) {
      var _this23 = this;

      return this.post('organizations/' + organization.id + '/editors', {
        userId: user.id
      }).then(function (record) {
        return _this23.push(record, 'user');
      });
    },
    removeEditor: function removeEditor(organization, user) {
      return this.delete('organizations/' + organization.id + '/editors?userId=' + user.id);
    },
    invitations: function invitations(id) {
      var _this24 = this;

      return this.request('organizations/' + id + '/invites').then(function (response) {
        return _this24.pushResponse(response, 'invite');
      });
    },
    loadProfile: function loadProfile(organization) {
      var _this25 = this;

      return this.request('organizations/' + organization.id + '/profile').then(function (record) {
        return _this25.push(record, 'organization-profile');
      });
    },
    updateProfile: function updateProfile(organization, updates) {
      var _this26 = this;

      return this.put('organizations/' + organization.id + '/profile', updates).then(function (record) {
        return _this26.push(record, 'organization-profile');
      });
    },
    suspend: function suspend(id, data) {
      var _this27 = this;

      return this.put('organizations/' + id + '/suspend', data).then(function (record) {
        return _this27.push(record);
      });
    },
    loadAuditLog: function loadAuditLog(organization, params) {
      var _this28 = this;

      var query = {};
      if (params) {
        if (params.page) {
          params.page = parseInt(params.page);
          if (params.page > 1) {
            query.page = params.page;
          }
        }
      }

      return this.request('organizations/' + organization.get('id') + '/audit-log?' + Ember.$.param(query)).then(function (response) {
        var auditLog = _this28.pushResponse(response, 'audit-log');
        delete response.data;

        return { auditLog: auditLog, meta: response };
      });
    }
  });
});