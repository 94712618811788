define('postedin/components/status-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['classes'],
    classes: Ember.computed('status', function () {
      return 'label label--status-' + this.get('status');
    })
  }).reopenClass({
    positionalParams: ['status']
  });
});