define('postedin/controllers/admin-release-notes/show/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    releaseNotes: Ember.inject.service(),
    actions: {
      updateReleaseNote: function updateReleaseNote() {
        var _this = this;

        var releaseNote = this.get('model');

        this.set('updatingReleaseNote', true);
        this.get('releaseNotes').update(releaseNote).then(function () {
          _this.set('updatingReleaseNote', false);
          _this.transitionToRoute('admin-release-notes.show', releaseNote);
        }, function () {
          _this.set('updatingReleaseNote', false);
          _this.set('updatingError', true);
          _this.set('confirmationModal', false);
        });
      }
    }
  });
});