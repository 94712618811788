define('postedin/helpers/gravatar-url', ['exports', 'postedin/models/user'], function (exports, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gravatarUrl = gravatarUrl;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function createHashFromString(from) {
    var char = void 0;
    var hash = 0;
    if (from && from.length !== 0) {
      for (var i = 0; i < from.length; i++) {
        char = from.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
      }
    }

    if (hash < 0) {
      hash = hash * -1;
    }

    return hash;
  }

  function gravatarUrl(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        user = _ref2[0];

    if (!user) {
      return createHashFromString('0123456789');
    }

    // This shouldn't happen but some tests we use shortcuts that can cause this, also a fallback for
    // if models aren't initialized correctly.
    if (!user.get) {
      user = _user.default.create(user);
    }

    var hash = user.get('gravatarHash');
    if (!hash) {
      hash = createHashFromString(user.get('name'));
    }

    return 'https://www.gravatar.com/avatar/' + hash + '?d=retro&s=37';
  }

  exports.default = Ember.Helper.helper(gravatarUrl);
});