define('postedin/models/category', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    categories: Ember.inject.service(),
    update: function update(updates) {
      return this.get('categories').update(this, updates);
    }
  });
});