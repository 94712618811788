define('postedin/services/content-requests', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var paramsToQuery = function paramsToQuery(params, page) {
    var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;

    params = params || {};

    var query = {};
    if (page !== false) {
      query.page = page ? Math.max(1, parseInt(page)) : 1;
    }

    query.perpage = perPage ? Math.max(1, parseInt(perPage)) : 20;

    if (params.statuses && !Array.isArray(params.statuses)) {
      params.statuses = [params.statuses];
    }

    if (params.statuses) {
      query.statuses = params.statuses;
    }

    if (params.project) {
      query.project = params.project;
    }

    if (params.dueSoon) {
      query.dueSoon = params.dueSoon;
    }

    if (params.approveRequiresAttention) {
      query.approveRequiresAttention = params.approveRequiresAttention;
    }

    if (params.rejectRequiresAttention) {
      query.rejectRequiresAttention = params.rejectRequiresAttention;
    }

    if (params.includeArchived) {
      query.includeArchived = params.includeArchived;
    }

    if (params.search) {
      query.search = params.search;
    }

    if (params.revisions) {
      query.revisions = params.revisions;
    }

    if (params.issue) {
      query.issue = params.issue;
    }

    if (params.dueSoon) {
      query.dueSoon = params.dueSoon;
    }

    if (params.requiresAttention) {
      query.requiresAttention = params.requiresAttention;
    }

    if (params.batch) {
      query.batch = params.batch;
    }

    if (params.hasRejectedReviews) {
      query.hasRejectedReviews = params.hasRejectedReviews;
    }

    if (params.projects) {
      query.projects = params.projects.map(function (p) {
        return parseInt(p);
      }).filter(function (p) {
        return p;
      }).uniq();
    }

    if (params.labels) {
      query.labels = params.labels;
    }

    if (params.legacyContentTypeGroups) {
      query.groups = params.legacyContentTypeGroups;
    }

    if (params.ratings && !Array.isArray(params.ratings)) {
      params.ratings = [params.ratings];
    }

    if (params.ratings) {
      query.ratings = params.ratings;
    }

    if (params.dashboard) {
      query.dashboard = true;
    }

    if (params.calendar) {
      query.calendar = true;
    }

    if (/[0-9]{4}-[0-9]{2}/.test(params.month)) {
      query.month = params.month;
    }

    if (/[0-9]{4}-[0-9]{2}/.test(params.week)) {
      query.week = params.week;
    }

    return decodeURIComponent(Ember.$.param(query));
  };

  exports.default = _repository.default.extend({
    model: 'content-request',
    find: function find(params, page) {
      var _this = this;

      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;

      return this.request('content-requests?' + paramsToQuery(params, page, perPage)).then(function (response) {
        response.data = response.data.map(function (request) {
          request.type._emberModel = 'content-' + request.type.name.dasherize();

          return request;
        });

        var contentRequests = _this.pushResponse(response);
        delete response.data;

        return { contentRequests: contentRequests, meta: response };
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('content-requests/' + id).then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this2.push(record);
      });
    },
    loadCounts: function loadCounts() /* params = {}*/{
      var _this3 = this;

      return this.request('content-requests/count').then(function (response) {
        response.forEach(function (record) {
          record.project = _this3.push(record.project, 'project');
        });

        return response;
      });
    },
    allWithIssues: function allWithIssues() {
      var _this4 = this;

      return this.request('content-requests/open-issues').then(function (response) {
        return _this4.pushResponse(response);
      });
    },
    allRejectedRequiringAttention: function allRejectedRequiringAttention() {
      var _this5 = this;

      return this.request('content-requests/rejected-requiring-attention').then(function (response) {
        return _this5.pushResponse(response);
      });
    },
    allRequested: function allRequested() {
      var _this6 = this;

      return this.request('content-requests/requested').then(function (response) {
        return _this6.pushResponse(response);
      });
    },
    allWithoutDeadlines: function allWithoutDeadlines() {
      var _this7 = this;

      return this.request('content-requests/without-deadlines').then(function (response) {
        return _this7.pushResponse(response);
      });
    },
    loadDrafts: function loadDrafts() {
      var _this8 = this;

      return this.request('content-requests/drafts').then(function (response) {
        return _this8.pushResponse(response);
      });
    },
    comment: function comment(contentRequest, _comment, attachments) {
      var _this9 = this;

      var data = {
        body: _comment,
        attachments: attachments
      };

      return this.post('content-requests/' + contentRequest.get('id') + '/comments', data).then(function (record) {
        return _this9.push(record, 'comment');
      });
    },
    loadComments: function loadComments(contentRequest) {
      var _this10 = this;

      return this.request('content-requests/' + contentRequest.get('id') + '/comments').then(function (response) {
        return _this10.pushResponse(response, 'comment');
      });
    },
    loadReviews: function loadReviews(contentRequest) {
      var _this11 = this;

      return this.request('content-requests/' + contentRequest.id + '/reviews').then(function (response) {
        response.data.forEach(function (record) {
          if (record.reviewable.id) {
            record.reviewable._emberModel = record.reviewable._model;
          }
        });

        return _this11.pushResponse(response, 'content-review');
      });
    },
    update: function update(contentRequest, updates) {
      var _this12 = this;

      return this.put('content-requests/' + contentRequest.id, updates).then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this12.push(record);
      });
    },
    addCustomExtra: function addCustomExtra(contentRequest, credits, description) {
      var _this13 = this;

      return this.post('content-requests/' + contentRequest.id + '/custom-extras', { credits: credits, description: description }).then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this13.push(record);
      });
    },
    adminUpdate: function adminUpdate(contentRequest, updates) {
      var _this14 = this;

      return this.put('content-requests/' + contentRequest.id + '/admin', updates).then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this14.push(record);
      });
    },
    changeStatus: function changeStatus(contentRequest, status) {
      var _this15 = this;

      return this.post('content-requests/' + contentRequest.id + '/admin/status', { status: status }).then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this15.push(record);
      });
    },
    unlinkMission: function unlinkMission(contentRequest) {
      var _this16 = this;

      return this.post('content-requests/' + contentRequest.id + '/admin/unlink-mission').then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this16.push(record);
      });
    },
    remove: function remove(contentRequest) {
      var _this17 = this;

      return this.delete('content-requests/' + contentRequest.get('id')).then(function () {
        return _this17.removeObject(contentRequest.get('id'));
      });
    },
    checkout: function checkout(contentRequest) {
      var _this18 = this;

      return this.post('content-requests/' + contentRequest.id + '/checkout').then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this18.push(record);
      });
    },
    submitIssue: function submitIssue(contentRequest, title, body, attachments) {
      var _this19 = this;

      var data = {
        title: title,
        description: body,
        attachments: attachments
      };

      return this.post('content-requests/' + contentRequest.get('id') + '/issues', data).then(function (record) {
        if (record.issuable) {
          _this19.push(record.issuable);
        }

        return _this19.push(record, 'issue');
      });
    },
    loadIssues: function loadIssues(contentRequest, status) {
      var _this20 = this;

      var params = void 0;
      if (status) {
        params = '?status=' + status;
      }

      return this.request('content-requests/' + contentRequest.get('id') + '/issues' + params).then(function (response) {
        var issues = _this20.pushResponse(response, 'issue');
        delete response.data;

        return { issues: issues, meta: response };
      });
    },
    loadTransactions: function loadTransactions(contentRequest, page) {
      var _this21 = this;

      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      page = parseInt(page) || 1;
      if (perPage) {
        page += '&perpage=' + perPage;
      }

      return this.request('content-requests/' + contentRequest.id + '/transactions?page=' + page).then(function (response) {
        var transactions = _this21.pushResponse(response, 'account-transaction');
        delete response.data;

        return { transactions: transactions, meta: response };
      });
    },
    loadAuditLog: function loadAuditLog(contentRequest, page) {
      var _this22 = this;

      page = parseInt(page) || 1;

      return this.request('content-requests/' + contentRequest.id + '/audit-log?page=' + page).then(function (response) {
        var auditLog = _this22.pushResponse(response, 'audit-log');
        delete response.data;

        return { auditLog: auditLog, meta: response };
      });
    },
    loadNextDeadline: function loadNextDeadline(contentRequest) {
      return this.request('content-requests/' + contentRequest.id + '/next-deadline');
    },
    updateLabel: function updateLabel(contentRequest, projectLabel) {
      var _this23 = this;

      return this.post('content-requests/' + contentRequest.id + '/label', { projectLabelId: projectLabel.id }).then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this23.push(record);
      });
    },
    deleteLabel: function deleteLabel(contentRequest) {
      var _this24 = this;

      return this.delete('content-requests/' + contentRequest.id + '/label').then(function (record) {
        record.type._emberModel = 'content-' + record.type.name.dasherize();

        return _this24.push(record);
      });
    }
  });
});