define('postedin/components/admin/auto-conclude-stage-controls', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, {
    session: Ember.inject.service(),
    isVisible: Ember.computed.oneWay('session.user.isAdmin'),
    waiting: Ember.computed.or('reset.waiting', 'disable.waiting'),
    actions: {
      reset: function reset(model, dropdown) {
        if (this.get('reset.waiting')) {
          return;
        }

        if (typeof model.resetAutoConclude !== 'function') {
          throw new Error('`model.resetAutoConclude` is not a function.');
        }

        this.uiPromise(model.resetAutoConclude(), 'reset');

        dropdown.close();
      },
      disable: function disable(model, dropdown) {
        if (this.get('disable.waiting')) {
          return;
        }

        if (typeof model.disableAutoConclude !== 'function') {
          throw new Error('`model.disableAutoConclude` is not a function.');
        }

        this.uiPromise(model.disableAutoConclude(), 'disable');

        dropdown.close();
      }
    }
  }).reopenClass({
    positionalParams: ['model']
  });
});