define('postedin/components/basic-dropdown/content', ['exports', 'ember-basic-dropdown/components/basic-dropdown/content'], function (exports, _content) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _content.default.extend({
    open: function open() {
      if (!this.get('neverClose')) {
        return this._super();
      }

      var dropdown = this.get('dropdown');

      dropdown.actions.reposition();
    },
    close: function close() {
      if (!this.get('neverClose')) {
        this._super();
      }
    }
  });
});