define('postedin/components/calendar-day-capacity', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var htmlSafe = Ember.String.htmlSafe;

  exports.default = Ember.Component.extend(_uiPromise.default, {
    session: Ember.inject.service(),
    classNames: ['calendar-day-capacity'],
    editing: false,
    isVisible: Ember.computed('day.updatedAt', function () {
      var day = this.get('day');
      if (!day) {
        return false;
      }

      if (day.get('holiday') || [0, 6].includes(this.get('date').day())) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.keys(this.get('day.contentRequestCapacityTaken'))[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var taken = _step.value;

            if (this.get('day.contentRequestCapacityTaken.' + taken)) {
              return true;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return false;
      }

      return true;
    }),
    day: Ember.computed('date', 'days', function () {
      return this.get('days').findBy('id', this.get('date').format('YYYY-MM-DD'));
    }),
    contentTypes: Ember.computed('day.updatedAt', function () {
      var day = this.get('day');

      if (!day) {
        return [];
      }

      var capacities = day.get('contentRequestCapacity');
      var capacityTaken = day.get('contentRequestCapacityTaken');

      return Object.keys(capacities).map(function (type) {
        var capacity = capacities[type];
        var taken = capacityTaken[type];
        var percent = taken / Math.max(capacity, 1);
        var hue = Math.max(0, (1 - percent) * 120).toString(10);

        percent = Math.round(percent * 100);
        var percentLimited = Math.min(percent, 100);

        var textBottom = percentLimited - 30;
        if (percent < 35) {
          textBottom = 70;
        }

        return {
          day: day,
          type: type,
          capacity: capacity,
          capacityTaken: taken,
          percent: percent + '%',
          barStyle: htmlSafe('height: ' + percentLimited + '%; background-color: hsl(' + hue + ', 100%, 50%);'),
          barPercentStyle: htmlSafe('bottom: ' + percentLimited + '%;'),
          barTextStyle: htmlSafe('bottom: ' + textBottom + '%;'),
          updatedCapacity: capacity
        };
      });
    }),
    click: function click() {
      if ([0, 6].includes(this.get('date').day())) {
        return;
      }

      if (this.get('session.user.isAdmin')) {
        this.set('editing', true);
      }
    },

    actions: {
      cancelEdit: function cancelEdit() {
        var _this = this;

        Ember.run.later(function () {
          return _this.set('editing', false);
        });
      },
      updateCapacity: function updateCapacity(day, type, capacity) {
        var _this2 = this;

        this.uiPromise(day.updateCapacity(_defineProperty({}, type, parseInt(capacity))), 'updateCapacity').then(function () {
          _this2.set('editing', false);
        });
      }
    }
  });
});