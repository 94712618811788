define('postedin/components/dynamic-compile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DynamicCompileComponent = Ember.Component.extend({
    layout: Ember.computed('template', 'attrs', function () {
      return Ember.HTMLBars.compile(this.get('template'), this.get('attrs'));
    }).volatile()
  });

  DynamicCompileComponent.reopenClass({
    positionalParams: ['template']
  });

  exports.default = DynamicCompileComponent;
});