define('postedin/components/content-reviews-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hideTitle: Ember.computed('options.hideTitle', function () {
      return this.get('options.hideTitle');
    }),
    icon: Ember.computed('options.icon', function () {
      return this.get('options.icon');
    })
  }).reopenClass({
    positionalParams: ['reviews']
  });
});