define('postedin/controllers/components', ['exports', 'dev/controllers/components'], function (exports, _components) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _components.default;
    }
  });
});