define('postedin/components/tone-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    tones: ['funny', 'casual', 'neutral', 'formal', 'technical'],

    toneIndexDidChange: Ember.on('init', Ember.observer('toneIndex', function () {
      var tone = this.get('tones')[this.get('toneIndex')];
      this.set('tone', tone);
    })),

    labels: Ember.computed(function () {
      var _this = this;

      return this.get('tones').map(function (tone) {
        return _this.get('i18n').t('content-form.tone.' + tone);
      }).join(',');
    }),

    ticks: Ember.computed(function () {
      return [].concat(_toConsumableArray(this.get('tones').keys())).join(',');
    }),

    toneIndex: Ember.computed('value', {
      get: function get() {
        return this.get('tones').indexOf(this.get('tone'));
      },
      set: function set(key, value) {
        this.set('tone', this.get('tones')[value]);

        return value;
      }
    }),

    didInsertElement: function didInsertElement() {
      Ember.run.next(this, function () {
        this.$('.slider-tick-label').each(function () {
          var text = Ember.$(this).text();
          Ember.$(this).html('<span class=\'slider-tick-label-inner\'>' + text + '</span>');
        });
      });
    }
  });
});