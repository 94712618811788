define('postedin/helpers/lisp-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lispCase = lispCase;
  function lispCase(strings) {
    return strings.join('').dasherize();
  }

  exports.default = Ember.Helper.helper(lispCase);
});