define('postedin/helpers/feed-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.feedIcon = feedIcon;
  function feedIcon(params /* , hash*/) {
    switch (params[0].action) {
      case 'topic_created':
      case 'topic_suggested':
        return 'icon-pen-plus';
      case 'topic_edited':
        return 'icon-pen';
      case 'topic_approved':
        return 'icon-checkmark3';
      case 'topic_rejected':
        return 'icon-cross2';
      case 'topic_canceled':
        return 'icon-pen-minus';
      case 'topic_undid_cancellation':
      case 'topic_undid_rejection':
      case 'topic_undid_approval':
        return 'icon-undo';
      case 'topic_created_assignment':
        return 'icon-file-plus';
      case 'topic_completed':
        return 'icon-file-check';
      case 'topic_assigned':
        return 'icon-user-plus2';
    }

    return 'icon-' + params[0].action;
  }

  exports.default = Ember.Helper.helper(feedIcon);
});