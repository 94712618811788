define('postedin/routes/account/notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    setupController: function setupController(controller) {
      var _this = this;

      controller.set('digestFrequency', this.get('session.user.preferences.digestFrequency'));

      ['emailSubmittedPublication', 'emailAutoApprovalPublication', 'emailTopicComment', 'emailTopicSuggested', 'emailTopicRequested', 'emailTopicRejected', 'emailPublicationNote', 'emailPublicationApproved', 'emailPublicationRejected', 'emailNotifications'].forEach(function (notification) {
        var on = !!_this.get('session.user.preferences.' + notification);
        controller.set(notification, on);
      });
    }
  });
});