define('postedin/controllers/admin/beta-users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    profileKeys: ['country', 'gender', 'university', 'career', 'birthdate', 'biography', 'twitter', 'linkedin'],
    statsKeys: ['missionCount', 'last30DaysMissionCount', 'earnings', 'averageEarnings', 'averageRating', 'skills'],
    sanitizedUser: Ember.computed('model', function () {
      var user = this.get('model');
      var profileKeys = this.get('profileKeys');
      var statsKeys = this.get('statsKeys');

      // Sanitize profile

      if (!user.get('profile')) {
        user.set('profile', {});
      }

      profileKeys.forEach(function (key) {
        var value = user.get('profile.' + key);
        if (Ember.isBlank(value)) {
          user.set('profile.' + key, '--');
        }
      });

      // Sanitize stats

      if (!user.get('stats')) {
        user.set('stats', {});
      }

      statsKeys.forEach(function (key) {
        var value = user.get('stats.' + key);
        if (Ember.isBlank(value)) {
          user.set('stats.' + key, 'skills' == key ? [] : 0);
        }
      });

      return user;
    }),
    actions: {
      scrollTo: function scrollTo(selector) {
        var $selected = Ember.$('#' + selector);
        Ember.run.once(function () {
          Ember.$('html, body').animate({
            scrollTop: $selected.offset().top - 10
          }, 100);
        });
      },
      onDelete: function onDelete() {
        this.transitionToRoute('admin.beta-users');
      }
    }
  });
});