define('postedin/components/ico-moon', ['exports', 'shared/components/ico-moon'], function (exports, _icoMoon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _icoMoon.default;
    }
  });
});