define('postedin/components/content-request-legacy-article/guidelines-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    summaryDidChange: Ember.observer('updates.summary', function () {
      if (!this.get('titleIsLocked')) {
        this.set('updates.type.title', this.get('updates.summary'));
      }
    }),

    titleCanChangeDidChange: function () {
      if (!this.get('updates.type.titleCanChange')) {
        this.set('updates.type.keywordInTitle', false);
      }
    }.observes('updates.type.titleCanChange'),

    keywordInTitleDidChange: function () {
      if (this.get('updates.type.keywordInTitle')) {
        this.set('updates.type.titleKeyword', this.get('seoKeywords.firstObject.name'));
      } else {
        this.set('updates.type.titleKeyword', null);
      }
    }.observes('updates.type.keywordInTitle'),

    initKeywords: Ember.on('init', function () {
      this.set('seoKeywords', this.get('updates.type.seoKeywords').map(function (k) {
        return { name: k.trim() };
      }));
    }),
    seoKeywordsDidChange: Ember.on('init', Ember.observer('seoKeywords', 'seoKeywords.@each.name', function () {
      if (!this.get('seoKeywords')) {
        return;
      }

      var keywords = this.getWithDefault('seoKeywords', []);
      if (!keywords.length || keywords[keywords.length - 1].name.trim() && keywords.length < 3) {
        keywords.pushObject({ name: '' });
      }

      keywords = keywords.map(function (k) {
        return k.name.trim();
      }).filter(Boolean);
      this.set('updates.type.seoKeywords', keywords);

      if (!keywords.length) {
        this.set('updates.type.keywordInTitle', false);
        this.set('updates.type.titleKeyword', null);
      }
    })),
    disableKeywordsInTitleCheckbox: Ember.computed('seoKeywords.length', 'updates.type.titleCanChange', function () {
      if (!this.get('seoKeywords').filter(function (k) {
        return k.name.trim();
      }).length || !this.get('updates.type.titleCanChange')) {
        return true;
      }

      return false;
    }),
    titleIsDisabled: true,
    didInsertElement: function didInsertElement() {
      if (!this.get('updates.type.title')) {
        this.set('updates.type.title', this.get('updates.summary'));
      }

      if (!this.get('updates.type.goals')) {
        this.set('updates.type.goals', Ember.Object.create());
      }

      if (!this.get('seoKeywords.length')) {
        this.set('updates.type.keywordInTitle', false);
      }

      if (this.get('updates.type.titleCanChange') && this.get('updates.type.keywordInTitle') && this.get('seoKeywords.length') && !this.get('seoKeywords').map(function (k) {
        return k.name;
      }).includes(this.get('updates.type.titleKeyword'))) {
        this.set('updates.type.titleKeyword', this.get('seoKeywords.firstObject.name'));
      }
    },

    actions: {
      lockTitle: function lockTitle() {
        this.set('titleIsLocked', true);
      },
      enableTitle: function enableTitle() {
        this.set('titleIsDisabled', false);
      }
    }
  });
});