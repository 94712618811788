define('postedin/routes/help/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('help');
    },
    setupController: function setupController(controller, questions) {
      var categories = questions.reduce(function (categories, question) {
        var questionCategory = question.get('category');
        var category = categories.find(function (category) {
          return category.id === questionCategory.id;
        });

        if (category) {
          category.articles.push(question);
        } else {
          questionCategory.set('articles', [question]);
          categories.push(questionCategory);
        }

        return categories;
      }, []);

      categories.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });

      categories.forEach(function (category) {
        category.articles.sort(function (a, b) {
          return a.get('displayOrder') - b.get('displayOrder');
        });
      });

      this._super(controller, categories);
    }
  });
});