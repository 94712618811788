define('postedin/services/settings', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'setting',
    find: function find() {
      var _this = this;

      return this.request('settings').then(function (response) {
        return _this.pushResponse(response);
      });
    },
    update: function update(setting, value) {
      var _this2 = this;

      return this.put('settings/' + setting.id, { value: value }).then(function (record) {
        return _this2.push(record);
      });
    }
  });
});