define('postedin/components/task-error', ['exports', 'shared/components/task-error'], function (exports, _taskError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _taskError.default;
    }
  });
});