define('postedin/components/faq-categories', ['exports', 'postedin/mixins/scroll-to'], function (exports, _scrollTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_scrollTo.default, {
    tagName: 'ul',
    classNames: ['faq-list'],
    deserializeAnchor: function deserializeAnchor(anchor) {
      var categories = this.get('categories');

      var _anchor$split = anchor.split('-'),
          _anchor$split2 = _slicedToArray(_anchor$split, 2),
          type = _anchor$split2[0],
          id = _anchor$split2[1];

      var values = {};

      id = parseInt(id, 10);

      if (type === 'category') {
        var category = categories.findBy('id', id);
        values = { category: category };
      }

      if (type === 'question') {
        categories.forEach(function (category) {
          var question = category.questions.findBy('id', id);

          if (question) {
            values = {
              category: category,
              question: question
            };
          }
        });
      }

      return values;
    },

    scrollToAnchor: function () {
      var anchor = this.get('anchor');

      if (anchor) {
        var _deserializeAnchor = this.deserializeAnchor(anchor),
            category = _deserializeAnchor.category,
            question = _deserializeAnchor.question;

        if (category) {
          category.set('showQuestions', true);
        }

        if (question) {
          question.set('showFullAnswer', true);
        }

        this.scrollTo('#' + anchor);
      }
    }.observes('anchor').on('didInsertElement'),
    actions: {
      toggleCategory: function toggleCategory(category) {
        category.toggleProperty('showQuestions');
      },
      toggleFullAnswer: function toggleFullAnswer(question) {
        var anchor = this.get('anchor');
        if (question.answer && question.link !== anchor) {
          question.toggleProperty('showFullAnswer');
        }
      }
    }
  });
});