define('postedin/mixins/component-window-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.updateWindowDetails();

      this.set('_updateWindowDetailsHandler', function (e) {
        return _this.updateWindowDetails(e);
      });
      window.addEventListener('resize', this.get('_updateWindowDetailsHandler'));
      window.addEventListener('scroll', this.get('_updateWindowDetailsHandler'));
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this.get('_updateWindowDetailsHandler'));
      window.removeEventListener('scroll', this.get('_updateWindowDetailsHandler'));
    },
    updateWindowDetails: function updateWindowDetails() {
      this.set('windowDetails', {
        updated: new Date().getTime(),
        // window.innerHeight worked for this one day and then suddenly not, I have no idea why
        // now works with the documents height
        // height: window.innerHeight,
        height: Ember.$('body').height(),
        scrollPosition: {
          left: window.scrollX,
          right: window.scrollX + window.innerWidth,
          top: window.scrollY,
          bottom: window.scrollY + window.innerHeight
        }
      });
    }
  });
});