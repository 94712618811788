define('postedin/components/post-message', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PostMessageComponent = Ember.Component.extend(_uiPromise.default, {
    routing: Ember.inject.service('-routing'),
    tagName: 'article',
    classNames: 'post-message',
    classNameBindings: ['post.isReply:post-message--no-bottom-spacing'],
    linkedComment: Ember.computed.alias('routing.router.currentState.routerJsState.fullQueryParams.linkedComment'),
    linked: Ember.computed('post.id', 'linkedComment', function () {
      return this.get('post.id') === this.get('linkedComment');
    }),
    highlight: Ember.computed.or('post.highlight', 'linked'),
    repliesActionContext: Ember.computed('actionContext', 'post.extra.actionContext', function () {
      var postActionContext = this.get('post.extra.actionContext');
      if (postActionContext) {
        return postActionContext;
      }

      return this.get('actionContext');
    }),
    hasContent: Ember.computed.or('post.body', 'post.extra.rating'),
    showReplyList: Ember.computed('allowReplies', 'showReplies', function () {
      if (this.get('allowReplies')) {
        return true;
      }

      return this.get('showReplies');
    }),
    showReplyForm: Ember.computed('allowReplies', 'post.canReply', 'post.comments.length', 'replyOpened', function () {
      if (!this.get('allowReplies')) {
        return false;
      }

      if (!this.get('post.canReply')) {
        return false;
      }

      return this.get('post.comments.length') || this.get('replyOpened');
    }),
    showReplyLink: Ember.computed('allowReplies', 'post.canReply', 'replyOpened', 'showReplyForm', function () {
      if (!this.get('allowReplies')) {
        return false;
      }

      if (!this.get('post.canReply')) {
        return false;
      }

      if (this.get('showReplyForm')) {
        return false;
      }

      return !this.get('replyOpened');
    }),
    action: Ember.computed('post.extra', function () {
      // we might have a certain context, this is just to keep things backwards compatable really
      if (this.get('actionContext')) {
        var action = this.get(this.get('actionContext') + 'ContextAction');
        if (action) {
          return action;
        }
      }

      return false;
    }),
    actions: {
      resolveAnnotation: function resolveAnnotation(annotation) {
        var _this = this;

        this.uiPromise(annotation.resolve(), 'resolveAnnotation').then(function () {
          _this.sendAction('reload');
        });
      },
      openReply: function openReply() {
        var _this2 = this;

        this.set('replyOpened', true);
        Ember.run.later(function () {
          return _this2.$('textarea').focus();
        });
      },
      saveReply: function saveReply(comment, attachments, promise) {
        var _this3 = this;

        this.get('post').comment(comment, attachments).then(function (comment) {
          comment.flashHighlight();
          _this3.get('post.comments').pushObject(comment);
          promise.resolve();
        }, promise.reject);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this4 = this;

      if (this.get('linked')) {
        Ember.run.later(function () {
          Ember.$('html, body').animate({
            scrollTop: _this4.$().offset().top - 10
          }, 200);
        }, 300);
      }
    }
  });

  PostMessageComponent.reopenClass({
    positionalParams: ['post']
  });

  exports.default = PostMessageComponent;
});