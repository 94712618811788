define('postedin/models/publisher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    publishers: Ember.inject.service(),
    createPublication: function createPublication(publishable) {
      return this.get('publishers').createPublication(this, publishable);
    },
    update: function update() {
      return this.get('publishers').update(this);
    },
    delete: function _delete() {
      return this.get('publishers').remove(this);
    },
    load: function load(cmd) {
      return this.get('publishers').load(this, cmd);
    }
  });
});