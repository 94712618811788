define('postedin/controllers/suggestions/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'projects', 'search', 'statuses', { includeArchived: 'include-archived' }, { bustParam: '_' }],
    bustParam: null,
    page: null,
    projects: [],
    search: '',
    statuses: [],
    includeArchived: 0
  });
});