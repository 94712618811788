define('postedin/components/publisher-hubspot', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    publishers: Ember.inject.service(),
    publisher: null,
    publication: null,
    publish: {},
    loadingBlogs: true,
    loadingAuthors: true,
    loadingTopics: true,
    blogError: false,
    authorError: false,
    topicError: false,
    scheduleDate: null,
    scheduleTime: null,
    saving: false,
    initializeForm: function () {
      // let firstP = null;
      // this.get('publication.body').replace(/(\r\n|\n|\r)/gm, '').match(/<p\b[^>]*>(.*?)<\/p>/gmi).forEach(p => {
      //  p = p.replace(/<(?:.|\n)*?>/gmi, '');
      //  p = p.replace(/[\-]/gm, '');
      //  p = p.trim();
      //  if (p && ! firstP) {
      //    firstP = p;
      //  }
      // });
      this.set('publish', {
        title: this.get('publish.title') || this.get('publication.title'),
        publicationId: this.get('publish.publicationId') ? this.get('publish.publicationId') : null,
        blogAuthorId: this.get('publish.blogAuthorId') ? this.get('publish.blogAuthorId') : null,
        contentGroupId: this.get('publish.contentGroupId') ? this.get('publish.contentGroupId') : null,
        useFeaturedImage: this.get('publish.useFeaturedImage') ? this.get('publish.useFeaturedImage') : false,
        featuredImage: this.get('publish.featuredImage') ? this.get('publish.featuredImage') : null,
        postSummary: this.get('publish.postSummary') ? this.get('publish.postSummary') : null,
        metaDescription: this.get('publish.metaDescription') ? this.get('publish.metaDescription') : null,
        images: this.get('publish.images') ? this.get('publish.images') : [],
        draft: this.get('publish.draft') ? this.get('publish.draft') : null,
        publishDate: this.get('publish.publishDate') ? this.get('publish.publishDate') : null,
        publishImmediately: this.get('publish.publishImmediately') ? this.get('publish.publishImmediately') : false,
        topics: this.get('publish.topics') ? this.get('publish.topics') : [],
        keywords: this.get('publish.keywords') ? this.get('publish.keywords') : []
      });
      this.set('scheduleHour', '00');
      this.set('scheduleMinute', '00');
      if (this.get('publish.publishDate')) {
        var datetime = (0, _moment.default)(this.get('publish.publishDate'), 'x');
        this.set('scheduleDate', datetime.format('YYYY-MM-DD'));
        this.set('scheduleHour', datetime.format('HH'));
        this.set('scheduleMinute', datetime.format('mm'));
      }
      this.set('saving', false);
    }.on('init'),
    getBlogs: function () {
      var _this = this;

      this.set('loadingBlogs', true);
      this.set('blogError', false);
      this.get('publishers').browse(this.get('publisher.id'), 'blogs').then(function (data) {
        _this.set('blogs', data.response.objects);
        _this.set('loadingBlogs', false);
      }, function () {
        _this.set('blogError', true);
      });
    }.on('init'),
    getAuthors: function () {
      var _this2 = this;

      this.set('loadingAuthors', true);
      this.set('authorError', false);
      this.get('publishers').browse(this.get('publisher.id'), 'authors').then(function (data) {
        _this2.set('authors', data.response.objects);
        if (_this2.get('publish.blogAuthorId')) {
          _this2.get('authors').forEach(function (author) {
            if (author.id === _this2.get('publish.blogAuthorId')) {
              _this2.set('publish.blogAuthor', author);
            }
          });
        }
        _this2.set('loadingAuthors', false);
      }, function () {
        _this2.set('authorError', true);
      });
    }.on('init'),
    getTopics: function () {
      var _this3 = this;

      this.set('loadingTopics', true);
      this.set('topicError', false);
      this.get('publishers').browse(this.get('publisher.id'), 'topics').then(function (data) {
        _this3.set('topics', data.response.objects);
        var selectedTopics = [];
        _this3.get('topics').forEach(function (topic) {
          _this3.get('publish.topics').forEach(function (t) {
            if (t.id === topic.id) {
              selectedTopics.push(topic);
            }
          });
        });
        _this3.set('publish.topics', selectedTopics);

        _this3.set('loadingTopics', false);
      }, function () {
        _this3.set('topicError', true);
      });
    }.on('init'),
    getKeywords: function () {
      var _this4 = this;

      this.set('loadingKeywords', true);
      this.set('keywordError', false);
      this.get('publishers').browse(this.get('publisher.id'), 'keywords').then(function (data) {
        _this4.set('keywords', data.response.keywords);
        var selectedKeywords = [];
        _this4.get('keywords').forEach(function (keyword) {
          _this4.get('publish.keywords').forEach(function (k) {
            if (k.id === keyword.id) {
              selectedKeywords.push(keyword);
            }
          });
        });
        _this4.set('publish.keywords', selectedKeywords);

        _this4.set('loadingKeywords', false);
      }, function () {
        _this4.set('keywordError', true);
      });
    }.on('init'),
    hours: Ember.computed(function () {
      return [].concat(_toConsumableArray(Array(24).keys())).map(function (hour) {
        if (hour < 10) {
          hour = '0' + hour;
        }

        return hour;
      });
    }),
    minutes: Ember.computed(function () {
      return [].concat(_toConsumableArray(Array(60).keys())).map(function (hour) {
        if (hour < 10) {
          hour = '0' + hour;
        }

        return hour;
      });
    }),
    setPublishDate: function () {
      if (this.get('scheduleDate')) {
        var scheduleDate = (0, _moment.default)(this.get('scheduleDate'));
        var datetime = (0, _moment.default)(scheduleDate.format('YYYY-MM-DD') + ' ' + this.get('scheduleHour') + ':' + this.get('scheduleMinute'), 'YYYY-MM-DD HH:mm');
        this.set('publish.publishDate', datetime.format('x'));
      }
    }.observes('scheduleDate', 'scheduleHour', 'scheduleMinute'),
    validate: function validate() {
      this.set('errors', {});
      var isValid = true;
      var publish = this.get('publish');

      if (!publish.blogAuthor) {
        this.set('errors.blogAuthor', true);
        isValid = false;
      }

      if (!publish.title) {
        this.set('errors.title', true);
        isValid = false;
      }

      if (!publish.contentGroupId) {
        this.set('errors.contentGroupId', true);
        isValid = false;
      }

      if (!publish.draft) {
        this.set('errors.draft', true);
        isValid = false;
      }

      if (publish.draft !== 'yes') {
        if (!publish.metaDescription) {
          this.set('errors.metaDescription', true);
          isValid = false;
        }
      }

      return isValid;
    },

    actions: {
      setKeywords: function setKeywords(keywords) {
        this.set('publish.keywords', keywords);
      },
      setTopics: function setTopics(topics) {
        this.set('publish.topics', topics);
      },
      toggleFeature: function toggleFeature(image) {
        if (this.get('publish.featuredImage') === image) {
          this.set('publish.featuredImage', null);
          this.set('publish.useFeaturedImage', false);
        } else {
          this.set('publish.featuredImage', image);
          this.set('publish.useFeaturedImage', true);
        }
      },
      retryBlogs: function retryBlogs() {
        this.getBlogs();
      },
      retryAuthors: function retryAuthors() {
        this.getAuthors();
      },
      retryTopics: function retryTopics() {
        this.getTopics();
      },
      save: function save() {
        var _this5 = this;

        this.set('publish.blogAuthorId', this.get('publish.blogAuthor.id'));
        this.set('publish.publicationId', this.get('publication.id'));
        this.set('publish.images', this.get('publication.images'));
        this.set('publish.publishImmediately', this.get('publish.draft') === 'no' ? true : false);
        this.set('publish.publishDate', this.get('publish.draft') === 'schedule' ? this.get('publish.publishDate') : null);

        if (!this.validate()) {
          this.set('errorFields', true);
          Ember.run.later(function () {
            return _this5.set('errorFields', false);
          }, 2000);
          return;
        }

        this.set('saving', true);
        this.sendAction('action', this.get('publish'));
      }
    }
  });
});