define('postedin/models/content-suggestion', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    router: Ember.inject.service('-routing'),
    contentSuggestions: Ember.inject.service(),
    transitionToRoute: function transitionToRoute() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.get('router').transitionTo('suggestions.show', [this.id], queryParams);
    },
    reload: function reload() {
      return this.get('contentSuggestions').findById(this.id);
    },
    update: function update(updates) {
      return this.get('contentSuggestions').update(this, updates);
    },
    delete: function _delete() {
      return this.get('contentSuggestions').remove(this);
    },
    confirm: function confirm() {
      return this.get('contentSuggestions').confirm(this);
    },
    comment: function comment(_comment, attachments) {
      return this.get('contentSuggestions').comment(this, _comment, attachments);
    },
    accept: function accept(contentType, legacyContentType) {
      return this.get('contentSuggestions').accept(this, contentType, legacyContentType);
    },
    reject: function reject(comment) {
      return this.get('contentSuggestions').reject(this, comment);
    }
  });
});