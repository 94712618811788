define('postedin/controllers/admin/users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    globalUsers: Ember.computed('admins.length', 'globalEditors.length', 'globalManagers.length', 'globalOrganizationManagers.length', function () {
      return [{ phrase: 'admin.admins', users: this.get('admins') }, { phrase: 'admin.global_editors', users: this.get('globalEditors') }, { phrase: 'admin.global_managers', users: this.get('globalManagers') }, { phrase: 'admin.global_organization_managers', users: this.get('globalOrganizationManagers') }];
    })
  });
});