define('postedin/components/user-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    search: '',
    locality: null,
    excludeActive: null,
    suspended: null,
    deleted: null,
    localityInputs: ['national', 'international'],

    active: Ember.computed.not('excludeActive'),

    refreshUsers: function refreshUsers() {
      this.set('bust', new Date().getTime());
      this.set('page', null);
    },


    actions: {
      search: function search(newSearch) {
        this.set('search', newSearch);

        this.refreshUsers();
      },
      filterLocality: function filterLocality(newLocality) {
        this.set('locality', newLocality);

        this.refreshUsers();
      },
      filterActive: function filterActive() {
        this.set('excludeActive', this.get('excludeActive') ? null : true);

        this.refreshUsers();
      },
      filterSuspended: function filterSuspended() {
        this.set('suspended', this.get('suspended') ? null : true);

        this.refreshUsers();
      },
      filterDeleted: function filterDeleted() {
        this.set('deleted', this.get('deleted') ? null : true);

        this.refreshUsers();
      }
    }
  });
});