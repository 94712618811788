define('postedin/components/content-request-article/admin-dropdown', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, {
    actions: {
      revertStatus: function revertStatus() {
        if (this.get('revision.canRevertToPending')) {
          var promise = this.get('revision').adminUpdate({ status: 'pending' });
          this.uiPromise(promise, 'reverting');
        }
      }
    }
  });
});