define('postedin/components/client-organizations-list', ['exports', 'account-components/components/client-organizations-list'], function (exports, _clientOrganizationsList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clientOrganizationsList.default;
    }
  });
});