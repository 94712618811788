define('postedin/routes/login/reset-password', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    enter: function enter() {
      this.controllerFor('application').set('recoveringPassword', true);
      return this._super();
    },
    exit: function exit() {
      this.controllerFor('application').set('recoveringPassword', false);
      return this._super();
    },
    model: function model(params) {
      return params.token;
    },
    setupController: function setupController(controller, model) {
      controller.set('token', model);
      controller.set('emailError', false);
      controller.set('passwordError', false);
      controller.set('email', '');
      controller.set('password', '');
      return this._super(controller, model);
    }
  });
});