define('postedin/components/image-attachment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    filename: Ember.computed('image.name', 'image.originalFilename', function () {
      if (this.get('image.name')) {
        return this.get('image.name') + '.' + this.get('image.format');
      } else {
        return this.get('image.originalFilename');
      }
    }),
    dimensions: Ember.computed('image.metadata.width', 'image.metadata.height', 'image.width', 'image.height', function () {
      return {
        width: this.getWithDefault('image.width', this.get('image.metadata.width')),
        height: this.getWithDefault('image.height', this.get('image.metadata.height'))
      };
    }),
    licenseType: Ember.computed('image.metadata.licenseType', 'image.license.type', function () {
      return this.getWithDefault('image.license.type', this.get('image.metadata.licenseType'));
    }),
    source: Ember.computed('image.metadata.licenseSource', 'image.license.source', function () {
      return this.getWithDefault('image.license.source', this.get('image.metadata.licenseSource'));
    }),
    author: Ember.computed('image.metadata.licenseAuthor', 'image.license.author', function () {
      return this.getWithDefault('image.license.author', this.get('image.metadata.licenseAuthor'));
    }),
    actions: {
      sendRemove: function sendRemove(image) {
        this.sendAction('onRemove', image);
      }
    }
  }).reopenClass({
    positionalParams: ['image']
  });
});