define('postedin/services/ui-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    show: function show(target, component, options) {
      this.trigger('show', target, component, options);
    },
    cancelShow: function cancelShow() {
      this.trigger('cancelShow');
    },
    removeOrphanedPopup: function removeOrphanedPopup() {
      this.trigger('removeOrphanedPopup');
    }
  });
});