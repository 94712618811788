define('postedin/components/faq-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'aside',
    classNames: ['faq-sidebar']
  });
});