define('postedin/routes/suggestions/index', ['exports', 'postedin/mixins/route-query-params'], function (exports, _routeQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeQueryParams.default, {
    contentSuggestions: Ember.inject.service(),
    store: Ember.inject.service('simple-store'),
    queryParams: {
      page: {
        refreshModel: true
      },
      // because things get buggy with arrays we workaround by changing a timestamp which will be sent to
      // this as and cause things to refresh reliably
      bustParam: {
        refreshModel: true
      }
    },
    refresh: function refresh() {
      if (this.controller) {
        this.controller.set('loading', true);
      }

      return this._super();
    },
    model: function model(params) {
      this.set('projectId', params.projects && params.projects.length === 1 ? params.projects[0] : false);

      return Ember.RSVP.hash({
        params: params,
        model: this.get('contentSuggestions').find(params, params.page)
      });
    },
    afterModel: function afterModel(hash) {
      var projectId = false;
      if (hash.params.projects && hash.params.projects.length === 1) {
        projectId = hash.params.projects[0];
      }

      this.updateProjectContext(projectId);
    },
    setupController: function setupController(controller, hash) {
      controller.set('model', hash.model.contentSuggestions);
      controller.set('meta', hash.model.meta);
      controller.set('params', hash.params);
      controller.set('loading', false);

      controller.set('search', hash.params.search);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('projects', []);
        controller.set('search', '');
        controller.set('statuses', []);
        controller.set('includeArchived', 0);
        controller.set('bustParam', null);
        controller.set('page', null);
      }
    },
    updateProjectContext: function updateProjectContext(projectId) {
      var _this = this;

      if (projectId) {
        var project = this.get('store').find('project', projectId);

        if (project.get('id')) {
          Ember.run.next(function () {
            _this.controllerFor('application').set('projectContext', project);
          });

          return;
        }
      }

      this.controllerFor('application').set('projectContext', null);
    }
  });
});