define('postedin/routes/login/index', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('passwordReset', false);
        controller.set('passwordResetEmail', '');
      }
    }
  });
});