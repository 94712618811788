define('postedin/components/time-relative', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TimeRelativeComponent = Ember.Component.extend({
    tagName: 'span',
    tooltip: null,
    didRender: function didRender() {
      var title = this.$('time').attr('title');
      if (title) {
        this.set('tooltip', new Tooltip({
          target: this.$()[0],
          content: title,
          classes: 'tooltip-theme-twipsy',
          position: 'top left'
        }));

        this.$('time').attr('title', '');
      }
    },
    willDestroy: function willDestroy() {
      var tooltip = this.get('tooltip');
      if (tooltip) {
        tooltip.destroy();
      }
    }
  }); /* global Tooltip*/

  TimeRelativeComponent.reopenClass({
    positionalParams: ['date']
  });

  exports.default = TimeRelativeComponent;
});