define('postedin/helpers/is-phrase', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPhrase = isPhrase;
  function isPhrase(params) {
    var phrase = params[0];

    return phrase.substring(0, 2) === 't:';
  }

  exports.default = Ember.Helper.helper(isPhrase);
});