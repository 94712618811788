define('postedin/controllers/admin-kpi', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['type', 'month', 'start', 'end'],
    type: null,
    month: null,
    start: null,
    end: null,
    params: {},
    transition: function transition() {
      this.transitionToRoute('admin-kpi', { queryParams: this.get('params') });
    },

    actions: {
      changeFilter: function changeFilter() {
        var params = this.get('params');
        params.type = this.get('filter.type') ? this.get('filter.type') : null;
        this.set('params', params);

        if (params.type === 'otherMonth' || params.type === 'custom') {
          return;
        } else {
          this.get('filter.month', null);
          this.get('filter.startDate', null);
          this.get('filter.endDate', null);

          params.month = null;
          params.start = null;
          params.end = null;

          this.transition();
        }
      },
      changeMonth: function changeMonth() {
        var params = this.get('params');
        if (params.type !== 'otherMonth' || !this.get('filter.month')) {
          return;
        }

        params.month = this.get('filter.month');
        this.get('filter.startDate', null);
        this.get('filter.endDate', null);
        params.start = null;
        params.end = null;
        this.set('params', params);

        this.transition();
      },
      customDates: function customDates() {
        var params = this.get('params');
        if (params.type !== 'custom') {
          return;
        }
        params.start = this.get('filter.startDate');
        params.end = this.get('filter.endDate');
        this.get('filter.month', null);
        params.month = null;

        this.set('params', params);

        this.transition();
      }
    }
  });
});