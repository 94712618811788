define('postedin/controllers/beta-users/suspended', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'search', 'sort', 'descending'],
    page: null,
    search: '',
    sort: '',
    descending: 0,
    actions: {
      search: function search() {
        var params = {
          page: null,
          search: this.get('newSearch')
        };

        this.transitionToRoute('beta-users.suspended', { queryParams: params });
      }
    }
  });
});