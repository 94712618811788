define('postedin/components/content-requests-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    routeTo: Ember.computed('options.routeTo', function () {
      if (this.get('options.routeTo')) {
        return 'content-requests.show.' + this.get('options.routeTo');
      }

      return 'content-requests.show';
    }),
    preparedTitle: Ember.computed('options.title', 'options.titlePhrase', function () {
      if (this.get('options.title')) {
        return this.get('options.title');
      }

      if (this.get('options.titlePhrase')) {
        return this.get('i18n').t(this.get('options.titlePhrase'));
      }

      return false;
    }),
    icon: Ember.computed.alias('options.icon'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('options.timestamp')) {
        var key = this.get('options.timestamp');

        this.set('contentRequests', this.get('contentRequests').map(function (contentRequest) {
          contentRequest.set('timestamp', contentRequest.get(key));
          return contentRequest;
        }));
      }
    }
  }).reopenClass({
    positionalParams: ['contentRequests']
  });
});