define('postedin/components/registration-form', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, _validator.default, {
    session: Ember.inject.service(),
    users: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('user', Ember.Object.create({}));
      this.set('errors', Ember.Object.create({}));
      this.setupErrorClearingObservers('user', ['email', 'name', 'password', 'password_confirmation']);
    },
    validate: function validate(user) {
      return this.validatorCheck(user, {
        email: 'required|email',
        name: 'required',
        password: 'required|confirmed|min:6',
        password_confirmation: 'required'
      });
    },


    actions: {
      register: function register(user) {
        var _this = this;

        if (this.validate(user)) {
          var register = this.get('users').register(user, this.get('invite'));

          this.uiPromise(register, 'register').then(function () {
            _this.get('session').authenticate('authenticator:oauth2', user.get('email'), user.get('password')).then(function () {
              // clear login data, don't want that in memory
              _this.set('user', {});
            }, function () {
              // login failed with the new user, send them to login to try again
              _this.transitionTo('login');
            });
          }, function (response) {
            _this.serverFailureToValidatorErrors({
              responseJSON: {
                errors: response.errors[0].detail.errors
              },
              status: 422
            });
            _this.scrollToFirstError();
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});