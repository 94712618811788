define('postedin/components/post-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PostListComponent = Ember.Component.extend({
    classNames: ['post-list']
  });

  PostListComponent.reopenClass({
    positionalParams: ['posts']
  });

  exports.default = PostListComponent;
});