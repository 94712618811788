define('postedin/helpers/content-requests-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contentRequestQueryParams = contentRequestQueryParams;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function contentRequestQueryParams(stat) {
    var organization = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var project = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    var queryParams = {};
    switch (stat) {
      case 'checkingSources':
      case 'submitted':
      case 'reviewing':
      case 'written':
      case 'requested':
        queryParams.statuses = [stat.underscore()];
        break;
      case 'hasIssue':
        queryParams.hasIssue = 1;
        break;
      case 'dueSoon':
        queryParams.dueSoon = 1;
        break;
      case 'approvedRequiresAttention':
        queryParams.approveRequiresAttention = 1;
        queryParams.includeArchived = 1;
        break;
      case 'rejectedRequiresAttention':
        queryParams.rejectRequiresAttention = 1;
        queryParams.includeArchived = 1;
        break;
    }

    var projectIds = [];

    if (project) {
      projectIds.push(project.id);
    } else if (organization) {
      projectIds = organization.get('projects').map(function (p) {
        return p.id;
      });
    }

    if (projectIds.length) {
      queryParams.projects = projectIds;
    }

    queryParams._explicitQueryParams = true;

    return queryParams;
  }

  exports.default = Ember.Helper.extend({
    router: Ember.inject.service('-routing'),
    compute: function compute(_ref, hash) {
      var _ref2 = _slicedToArray(_ref, 1),
          stat = _ref2[0];

      var queryParams = contentRequestQueryParams(stat, hash.organization, hash.project);

      return this.get('router').generateURL('content-requests', [], queryParams);
    }
  });
});