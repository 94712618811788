define('postedin/helpers/index-of', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.indexOf = indexOf;
  function indexOf(params) {
    if (Array.isArray(params[0]) && params[1]) {
      return params[0].indexOf(params[1]);
    }
  }

  exports.default = Ember.Helper.helper(indexOf);
});