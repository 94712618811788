define('postedin/components/project-label-color-select', ['exports', 'postedin/models/project-label'], function (exports, _projectLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['value', 'disabled'],
    i18n: Ember.inject.service(),
    observerValue: Ember.on('init', Ember.observer('value', function () {
      var _this = this;

      if (this.get('value')) {
        this.get('possibleLabels').forEach(function (label) {
          if (label.get('color') === _this.get('value')) {
            _this.set('selectedLabel', label);
          }
        });
      } else {
        this.set('selectedLabel', false);
      }
    })),
    colors: ['red', 'blue', 'green', 'yellow', 'skyblue', 'violet', 'teal', 'purple', 'orange', 'pink', 'lime', 'gold', 'darkred', 'coral', 'grey'],
    possibleLabels: Ember.computed('labelName', function () {
      var _this2 = this;

      return this.get('colors').map(function (color) {
        return _projectLabel.default.create({
          color: color,
          name: _this2.get('labelName') || _this2.get('i18n').t('label')
        });
      });
    }),
    actions: {
      selectLabel: function selectLabel(label) {
        this.set('value', label.get('color'));
        this.set('selectedLabel', label);
      }
    }
  });
});