define('postedin/models/publisher-publication', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    publisherPublications: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.getPublisherService();
    },
    getPublisherService: function getPublisherService(throwIfFailed) {
      var type = this.get('publisher.type');
      if (!type) {
        if (throwIfFailed) {
          throw new Error('Publisher type not defined.');
        }

        return false;
      }

      var typeProperty = type.camelize() + 'Publisher';
      var service = this.get(typeProperty);
      if (!service) {
        this.set(typeProperty, Ember.inject.service('publishers/' + type.dasherize()));
        service = this.get(typeProperty);
      }

      if (!service && throwIfFailed) {
        throw new Error('Publisher service for type "' + type + '" not found.');
      }

      return service;
    },
    connect: function connect() {
      return this.get('publisherPublications').connect(this);
    },
    prepare: function prepare(data) {
      return this.get('publisherPublications').prepare(this, data);
    },
    sendDraft: function sendDraft() {
      return this.get('publisherPublications').sendDraft(this);
    },
    publish: function publish() {
      return this.get('publisherPublications').publish(this);
    },
    schedule: function schedule(date, time) {
      date = (0, _moment.default)(date);
      time = (0, _moment.default)(time, 'HH:mm');

      if (!date.isValid() || !time.isValid()) {
        return false;
      }

      date.startOf('day');
      date.hour(time.hour());
      date.minute(time.minute());

      return this.get('publisherPublications').schedule(this, date.valueOf());
    },
    validateForSendRules: function validateForSendRules(data) {
      return this.getPublisherService(true).validateForSendRules(data);
    },
    validateForSend: function validateForSend() {
      return this.getPublisherService(true).validateForSend(this.get('preparedData'));
    }
  });
});