define('postedin/components/inline-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    cancel: function cancel(event) {
      if (this.get('escape-press') && this.get('escape-press-param')) {
        return this.sendAction('escape-press', this.get('escape-press-param'), this.get('value'));
      }

      return this._super(event);
    },
    insertNewline: function insertNewline(event) {
      if (this.get('enter') && this.get('enter-param')) {
        return this.sendAction('enter', this.get('enter-param'), this.get('value'));
      }

      return this._super(event);
    }
  });
});