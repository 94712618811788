define('postedin/routes/application', ['exports', 'postedin/config/environment', 'ember-ajax/errors', 'postedin/mixins/secure-default-route-factory', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _environment, _errors, _secureDefaultRouteFactory, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  Ember.Route.reopenClass(_secureDefaultRouteFactory.default);

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    unreadReleaseNotes: Ember.inject.service(),
    session: Ember.inject.service(),
    localState: Ember.inject.service(),
    locale: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    organizations: Ember.inject.service(),
    projects: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.pingLoop();

      if (_environment.default.environment !== 'development') {
        Ember.$(window).on('focus', function () {
          return _this.ping();
        });
      }
    },
    beforeModel: function beforeModel() {
      if (_environment.default.environment !== 'test') {
        return this.get('locale').selectDefaultLocale();
      }
    },
    model: function model() {
      if (this.get('session.isAuthenticated')) {
        return this.prepareDataFromSession(this.get('session'));
      }

      return [];
    },
    setupController: function setupController(controller, _ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          model = _ref2[0];

      this._super(controller, model);

      if (!this.get('session.isAuthenticated')) {
        this.get('localState').set('impersonating');
      }

      controller.set('canChangeLanguage', _environment.default.i18n.allowChange);
    },
    prepareDataFromSession: function prepareDataFromSession(session) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        session.get('loadingSessionPromise').then(function () {
          resolve(_this2.addSessionDataToStores(session));
        });
      });
    },
    addSessionDataToStores: function addSessionDataToStores(session) {
      var projects = this.get('projects').pushAll(session.get('projects'));
      var organizations = this.get('organizations').pushAll(session.get('organizations'));

      return this.get('organizations').pushAllWithProjects(organizations, projects);
    },
    ping: function ping() {
      var _this3 = this,
          _arguments = arguments;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.Offline.check();

        Ember.$.ajax({
          method: 'GET',
          url: '/api/build',
          success: function success(data) {
            _this3.maintenance(false);

            if (!_this3.controllerFor('application').get('build')) {
              _this3.controllerFor('application').set('build', data);
            }

            _this3.controllerFor('application').set('latestBuild', data);

            resolve(data);
          },
          error: function error(response) {
            if (response.status >= 500) {
              _this3.maintenance(true);
              reject.apply(undefined, _arguments);
            }
          }
        });
      });
    },
    pingLoop: function pingLoop() {
      var _this4 = this;

      var ping = this.ping();

      if (_environment.default.environment !== 'development') {
        ping.finally(function () {
          Ember.run.later(_this4, _this4.pingLoop, 60000);
        });
      }

      return ping;
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this.controllerFor('application').set('model', this.prepareDataFromSession(this.get('session')));
      this.set('session.reloadOrganizations', new Date().getTime());

      var attemptedTransition = this.get('session.attemptedTransition');
      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else {
        this.transitionTo('index');
      }
    },
    maintenance: function maintenance(show) {
      if (!show && this.controllerFor('application').get('maintenance')) {
        this.get('session').loadSessionData();
      }

      Ember.$('#maintenance').toggle(show);
      this.controllerFor('application').set('maintenance', show);
    },
    getUrlFromTransition: function getUrlFromTransition(transition) {
      var routing = this.get('routing');
      var params = Object.values(transition.params).filter(function (param) {
        return Object.values(param).length;
      });

      try {
        return routing.generateURL(transition.targetName, params, transition.queryParams);
      } catch (e) {
        return false;
      }
    },

    actions: {
      loading: function loading(transition, originRoute) {
        // if we have a loading template then we need to remove the bootstrapping logo as
        // we will be rendering but not have triggered the route transitioned event yet
        var container = Ember.getOwner(this);

        if (container.lookup('template:' + originRoute.fullRouteName + '-loading')) {
          Ember.$('.postedin-loading').remove();
        }

        return true;
      },
      error: function error(_error, transition) {
        var _this5 = this;

        var errorRoute = false;

        if (_error instanceof _errors.UnauthorizedError || _error.status === 401) {
          this.get('session').invalidate();
        }

        if (_error instanceof _errors.ForbiddenError || _error.status === 403) {
          errorRoute = 'forbidden';
        }

        if (_error instanceof _errors.NotFoundError || _error.status === 404) {
          errorRoute = 'server-not-found';
        }

        if (_error.status === 503 || Ember.isArray(_error.errors) && _error.errors.length && _error.errors[0].status === 503) {
          this.maintenance(true);
        } else if (_error instanceof _errors.ServerError || _error.status >= 500) {
          errorRoute = 'server-error';
        }

        if (errorRoute) {
          this.transitionTo(errorRoute).promise.then(function () {
            // we update the url so users can link here for support if needed or for in development
            // when we get a 404 we don't want to have to go back all the time after we fix the issues
            var url = _this5.getUrlFromTransition(transition);
            if (url) {
              window.history.replaceState({}, '', url);
            }
          });

          return;
        }

        throw _error;
      }
    }
  });
});