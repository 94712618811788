define('postedin/models/content-review', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    router: Ember.inject.service('-routing'),
    contentReviews: Ember.inject.service(),
    transitionToRoute: function transitionToRoute() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.get('router').transitionTo('content-review-permalink', [this.id], queryParams);
    },
    reload: function reload() {
      return this.get('contentReviews').findById(this.id);
    },
    approve: function approve(comment, attachments) {
      return this.get('contentReviews').approve(this, comment, attachments);
    },
    reject: function reject(comment, attachments) {
      return this.get('contentReviews').reject(this, comment, attachments);
    },
    comment: function comment(_comment, attachments) {
      return this.get('contentReviews').comment(this, _comment, attachments);
    },
    annotate: function annotate(comment, text, location) {
      return this.get('contentReviews').annotate(this, comment, text, location);
    },
    notify: function notify() {
      return this.get('contentReviews').notify(this);
    },

    isPending: Ember.computed.equal('status', 'pending'),
    isApproved: Ember.computed.equal('status', 'approved'),
    isRejected: Ember.computed.equal('status', 'rejected'),
    isExpired: Ember.computed.equal('status', 'expired')
  });
});