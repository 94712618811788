define('postedin/components/publishers/prepare-hubspot', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PrepareHubspotComponent = Ember.Component.extend(_uiPromise.default, _validator.default, {
    loading: true,
    selectedTopics: [],
    preparedSlug: Ember.computed('prepared.name', 'prepared.slug', function () {
      var slug = this.get('prepared.slug');
      return slug || this.get('prepared.name').dasherize();
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.prepare();

      this.setupErrorClearingObservers('prepared', ['contentGroupId', 'name', 'blogAuthorId', 'metaDescription', 'featuredImageId']);

      this.set('loaders', [Ember.Object.create({
        name: 'blogs',
        icon: 'icon-blog',
        load: function load() {
          return _this.loadBlogs();
        }
      }), Ember.Object.create({
        name: 'authors',
        icon: 'icon-users',
        load: function load() {
          return _this.loadAuthors();
        }
      }), Ember.Object.create({
        name: 'topics',
        icon: 'icon-menu7',
        load: function load() {
          return _this.loadTopics();
        }
      })]);
    },
    prepare: function prepare() {
      this.set('prepared', Ember.Object.create({
        name: this.getWithDefault('publication.preparedData.name', this.get('revision.title')),
        slug: this.getWithDefault('publication.preparedData.slug', ''),
        postSummary: this.getWithDefault('publication.preparedData.postSummary', ''),
        metaDescription: this.getWithDefault('publication.preparedData.metaDescription', this.get('revision.metaDescription')),
        useFeaturedImage: this.getWithDefault('publication.preparedData.useFeaturedImage', !!this.get('revision.attachments.length'))
      }));

      var isUuId = this.get('publication.preparedData.featuredImageId.length') === 36;
      if (isUuId) {
        this.selectFeaturedImageByUuId(this.get('publication.preparedData.featuredImageId'));
      } else {
        this.selectFeaturedImageById(this.get('publication.preparedData.featuredImageId'));
      }

      if (!this.get('loading')) {
        this.selectBlogById(this.get('publication.preparedData.contentGroupId'));
        this.selectAuthorById(this.get('publication.preparedData.blogAuthorId'));
        this.selectTopicsByIds(this.get('publication.preparedData.topicIds'));
      }
    },
    loadBlogs: function loadBlogs() {
      var _this2 = this;

      var promise = this.get('publisher').load('blogs');
      promise.then(function (blogs) {
        _this2.set('blogs', blogs);
        _this2.selectBlogById(_this2.get('publication.preparedData.contentGroupId'));
      });

      return promise;
    },
    loadAuthors: function loadAuthors() {
      var _this3 = this;

      var promise = this.get('publisher').load('authors');
      promise.then(function (authors) {
        _this3.set('authors', authors);
        _this3.selectAuthorById(_this3.get('publication.preparedData.blogAuthorId'));
      });

      return promise;
    },
    loadTopics: function loadTopics() {
      var _this4 = this;

      var promise = this.get('publisher').load('topics');
      promise.then(function (topics) {
        _this4.set('topics', topics);
        _this4.selectTopicsByIds(_this4.get('publication.preparedData.topicIds'));
      });

      return promise;
    },
    selectBlogById: function selectBlogById(id) {
      var blog = this.get('blogs').findBy('id', id);
      if (blog) {
        this.selectBlog(blog);
      }
    },
    selectBlog: function selectBlog(blog) {
      this.set('selectedBlog', blog);
      this.set('prepared.contentGroupId', blog.id);
    },
    selectAuthorById: function selectAuthorById(id) {
      var author = this.get('authors').findBy('id', id);
      if (author) {
        this.selectAuthor(author);
      }
    },
    selectAuthor: function selectAuthor(author) {
      this.set('selectedAuthor', author);
      this.set('prepared.blogAuthorId', author.id);
    },
    selectTopicsByIds: function selectTopicsByIds(ids) {
      if (!ids) {
        return;
      }

      var topics = this.get('topics').filter(function (topic) {
        return ids.includes(topic.id);
      });

      this.selectTopics(topics);
    },
    selectTopics: function selectTopics(topics) {
      this.set('selectedTopics', topics);
      this.set('prepared.topicIds', topics.map(function (t) {
        return t.id;
      }));
    },
    selectFeaturedImageById: function selectFeaturedImageById(id) {
      var image = this.get('revision.attachments').findBy('id', id);
      if (image) {
        this.selectFeaturedImage(image);
      }
    },
    selectFeaturedImageByUuId: function selectFeaturedImageByUuId(uuid) {
      var images = this.get('revision.attachments').concat(this.get('revision.uploads'));
      var image = images.findBy('uuid', uuid);

      if (image) {
        this.selectFeaturedImage(image);
      }
    },
    selectFeaturedImage: function selectFeaturedImage(image) {
      this.set('selectedFeaturedImage', image);

      if (image._model === 'file') {
        this.set('prepared.featuredImageId', image.id);
      } else {
        this.set('prepared.featuredImageId', image.uuid);
      }
    },
    validateForSend: function validateForSend(publication, data) {
      return this.validatorCheck(data, publication.validateForSendRules(data));
    },
    sendNextAction: function sendNextAction() {
      if (this.get('next')) {
        this.sendAction('next');
      }
    },

    actions: {
      selectBlog: function selectBlog(blog) {
        this.selectBlog(blog);
      },
      selectAuthor: function selectAuthor(author) {
        this.selectAuthor(author);
      },
      selectTopics: function selectTopics(topics) {
        this.selectTopics(topics);
      },
      updateSlug: function updateSlug(slug) {
        this.set('prepared.slug', slug);
      },
      toggleSelectFeaturedImage: function toggleSelectFeaturedImage() {
        if (this.get('save.waiting')) {
          return;
        }

        this.toggleProperty('showSelectFeaturedImage');
      },
      selectFeaturedImage: function selectFeaturedImage(image) {
        this.selectFeaturedImage(image);
        this.set('showSelectFeaturedImage', false);
      },
      save: function save(publication, prepared) {
        var _this5 = this;

        var nextAfterSave = this.get('nextAfterSave');

        var data = prepared.getProperties(['contentGroupId', 'name', 'slug', 'blogAuthorId', 'topicIds', 'metaDescription', 'postSummary', 'useFeaturedImage', 'featuredImageId']);

        this.uiPromise(publication.prepare(data), 'save').then(function (publication) {
          _this5.prepare();

          if (nextAfterSave && _this5.validateForSend(publication, data)) {
            _this5.sendNextAction();
          }
        });
      },
      nextAfterSave: function nextAfterSave(send) {
        this.set('nextAfterSave', send);
      }
    }
  });

  PrepareHubspotComponent.reopenClass({
    positionalParams: ['publisher', 'revision', 'publication']
  });

  exports.default = PrepareHubspotComponent;
});