define('postedin/models/organization', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    organizations: Ember.inject.service(),
    loadTransactions: function loadTransactions(params) {
      return this.get('organizations').transactions(this, params);
    },
    loadInvitations: function loadInvitations() {
      return this.get('organizations').invitations(this.get('id'));
    },
    loadAdmins: function loadAdmins() {
      return this.get('organizations').admins(this);
    },
    loadManagers: function loadManagers() {
      return this.get('organizations').managers(this);
    },
    loadEditors: function loadEditors() {
      return this.get('organizations').editors(this);
    },
    loadProjects: function loadProjects() {
      return this.get('organizations').projects(this.get('id'));
    },
    loadAccounts: function loadAccounts() {
      return this.get('organizations').accounts(this);
    },
    accountsAnalyze: function accountsAnalyze() {
      return this.get('organizations').accountsAnalyze(this);
    },
    createAccount: function createAccount(account) {
      return this.get('organizations').createAccount(this, account);
    },
    loadAuditLog: function loadAuditLog(params) {
      return this.get('organizations').loadAuditLog(this, params);
    },
    loadProfile: function loadProfile() {
      return this.get('organizations').loadProfile(this);
    },
    updateProfile: function updateProfile(update) {
      return this.get('organizations').updateProfile(this, update);
    },
    createProject: function createProject(name) {
      return this.get('organizations').createProject(this, name);
    },
    inviteAdmin: function inviteAdmin(email) {
      return this.get('organizations').inviteAdmin(this, email);
    },
    addAdmin: function addAdmin(user) {
      return this.get('organizations').addAdmin(this, user);
    },
    demoteAdmin: function demoteAdmin(user) {
      return this.get('organizations').demoteAdmin(user);
    },
    removeAdmin: function removeAdmin(user) {
      return this.get('organizations').removeAdmin(this, user);
    },
    inviteManager: function inviteManager(email) {
      return this.get('organizations').inviteManager(this, email);
    },
    addManager: function addManager(user) {
      return this.get('organizations').addManager(this, user);
    },
    promoteManager: function promoteManager(user) {
      return this.get('organizations').promoteManager(user);
    },
    removeManager: function removeManager(user) {
      return this.get('organizations').removeManager(this, user);
    },
    addEditor: function addEditor(user) {
      return this.get('organizations').addEditor(this, user);
    },
    removeEditor: function removeEditor(user) {
      return this.get('organizations').removeEditor(this, user);
    },
    update: function update(name, maxProjects, hideTypes, expireReviews, expireReviewsOnWeekdays, expireReviewsDays, expireReviewsSecondWarning, expireReviewsExpire, expireReviewsStatus, autoApprove, autoApproveDays, autoApproveSecondWarningDays, revisionsWaitOnReviews, rejectedReviewRejectsRevision, approvedReviewsApproveRevision, reviewRequestedSubject, reviewRequestedLine1Manual, reviewRequestedLine1Auto, reviewRequestedLine2, reviewReminderSubject, reviewReminderLine1, reviewReminderLine2, reviewExpiredSubject, reviewExpiredLine1, reviewExpiredLine2) {
      return this.get('organizations').updateOrganization(this, name, maxProjects, hideTypes, expireReviews, expireReviewsOnWeekdays, expireReviewsDays, expireReviewsSecondWarning, expireReviewsExpire, expireReviewsStatus, autoApprove, autoApproveDays, autoApproveSecondWarningDays, revisionsWaitOnReviews, rejectedReviewRejectsRevision, approvedReviewsApproveRevision, reviewRequestedSubject, reviewRequestedLine1Manual, reviewRequestedLine1Auto, reviewRequestedLine2, reviewReminderSubject, reviewReminderLine1, reviewReminderLine2, reviewExpiredSubject, reviewExpiredLine1, reviewExpiredLine2);
    },
    delete: function _delete() {
      return this.get('organizations').remove(this);
    }
  });
});