define('postedin/controllers/suggestions/show/index', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, {
    session: Ember.inject.service(),
    queryParams: {
      showRejectModal: 'reject',
      showAcceptModal: 'accept',
      showDuplicateModal: 'duplicate'
    },
    showRejectModal: 0,
    showAcceptModal: 0,
    showDuplicateModal: 0,
    legacyContentType: Ember.computed.equal('selectedContentType.type', 'legacy_article'),
    isRejected: Ember.computed('model.status', function () {
      return this.get('model.status') === 'rejected';
    }),
    isAccepted: Ember.computed('model.status', function () {
      return this.get('model.status') === 'accepted';
    }),
    showBackToSuggestions: Ember.computed.or('model.canView', 'nextSuggestion'),
    actions: {
      toggleRejectModal: function toggleRejectModal() {
        this.set('showRejectModal', this.get('showRejectModal') ? 0 : 1);
      },
      toggleAcceptModal: function toggleAcceptModal() {
        this.set('showAcceptModal', this.get('showAcceptModal') ? 0 : 1);
      },
      toggleDuplicateModal: function toggleDuplicateModal() {
        this.set('showDuplicateModal', this.get('showDuplicateModal') ? 0 : 1);
      },
      reject: function reject(suggestion, comment) {
        var _this = this;

        this.uiPromise(suggestion.reject(comment), 'reject').then(function () {
          if (!_this.get('showBackToSuggestions')) {
            Ember.run.later(function () {
              _this.transitionToRoute('suggestions');
            }, 700);

            return;
          }
        });
      },
      accept: function accept(suggestion, contentType, legacyContentType) {
        var _this2 = this;

        this.uiPromise(suggestion.accept(contentType.get('type'), legacyContentType), 'accept').then(function (contentRequest) {
          _this2.transitionToRoute('request-content.show', contentRequest.id);
        }, 'accept');
      },
      duplicate: function duplicate(suggestion, project) {
        var queryParams = {
          project: project.id,
          duplicate: suggestion.id
        };

        this.transitionToRoute('suggestions.create', { queryParams: queryParams });
      },
      saveComment: function saveComment(comment, attachments, promise) {
        var _this3 = this;

        this.get('model').comment(comment, attachments).then(function (comment) {
          comment.flashHighlight();
          _this3.get('model.comments').pushObject(comment);
          promise.resolve();
        }, promise.reject);
      }
    }
  });
});