define('postedin/controllers/admin-content-types/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    contentTypes: Ember.inject.service(),
    actions: {
      showExtra: function showExtra(id) {
        var extra = this.getExtra(id);
        if (extra) {
          this.set('extra', extra);
          this.set('partial', 'show');
        }
      },
      browseExtras: function browseExtras() {
        this.set('extra', {});
        this.set('partial', 'browse');
      },
      createExtra: function createExtra() {
        this.set('extra', {});
        this.set('partial', 'create');
      },
      editExtra: function editExtra(id) {
        var extra = this.getExtra(id);
        if (extra) {
          this.set('extra', extra);
          this.set('partial', 'edit');
        }
      },
      saveExtra: function saveExtra() {
        var _this = this;

        var extra = this.get('extra');
        if (extra.limitTypeSelected === 'off') {
          extra.limit = 0;
        } else if (extra.limitTypeSelected === 'infinite') {
          extra.limit = -1;
        }
        if (extra.id) {
          this.get('contentTypes').editExtra(extra).then(function (respond) {
            _this.set('model', respond);
            _this.send('browseExtras');
          });
        } else {
          this.get('contentTypes').addExtra(this.get('model.id'), extra).then(function (respond) {
            _this.set('model', respond);
            _this.send('browseExtras');
          });
        }
      },
      deleteExtra: function deleteExtra(id) {
        var _this2 = this;

        this.get('contentTypes').deleteExtra(id).then(function () {
          _this2.transitionToRoute('admin-content-types');
        });
      }
    },
    getExtra: function getExtra(id) {
      var respond = null;
      this.get('model.extras').forEach(function (extra) {
        if (id === extra.id) {
          if (extra.limit === 0) {
            extra.limitTypeSelected = 'off';
          } else if (extra.limit === -1) {
            extra.limitTypeSelected = 'infinite';
          } else {
            extra.limitTypeSelected = 'limited';
          }
          respond = extra;
        }
      });
      return respond;
    }
  });
});