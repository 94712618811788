define('postedin/components/attachment-default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AttachmentDefault = Ember.Component.extend({
    classNames: ['attachment-default'],
    guessIcon: Ember.computed('attachment.format', function () {
      var format = this.get('attachment.format');
      var formatIcons = {
        pdf: 'icon-file-pdf',
        ppt: 'icon-file-presentation',
        pptx: 'icon-file-presentation',
        docx: 'icon-file-word',
        doc: 'icon-file-text',
        psd: 'icon-file-picture',
        ai: 'icon-file-picture',
        avi: 'icon-file-video',
        wmv: 'icon-file-video',
        mov: 'icon-file-video',
        mp4: 'icon-file-video'
      };

      if (formatIcons[format]) {
        return formatIcons[format];
      }

      return 'icon-file-empty';
    })
  });

  AttachmentDefault.reopenClass({
    positionalParams: ['attachment']
  });

  exports.default = AttachmentDefault;
});