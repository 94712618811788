define('postedin/components/toggle-wrap', ['exports', 'toggle-wrap/components/toggle-wrap'], function (exports, _toggleWrap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _toggleWrap.default;
    }
  });
});