define('postedin/controllers/admin-projects/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    projects: Ember.inject.service(),
    actions: {
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.set('confirmationModal', !this.get('confirmationModal'));
      },
      delete: function _delete() {
        var _this = this;

        this.set('saving', true);
        this.get('projects').deleteProject(this.get('model')).then(function () {
          return _this.transitionToRoute('admin-projects');
        }).finally(function () {
          return _this.set('saving', false);
        });
      }
    }
  });
});