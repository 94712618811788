define('postedin/helpers/credits', ['exports', 'credits/helpers/credits'], function (exports, _credits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _credits.default;
    }
  });
  Object.defineProperty(exports, 'credits', {
    enumerable: true,
    get: function () {
      return _credits.credits;
    }
  });
});