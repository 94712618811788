define('postedin/controllers/account/email', ['exports', 'postedin/mixins/validation-rules'], function (exports, _validationRules) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationRules.default, {
    users: Ember.inject.service(),
    session: Ember.inject.service(),
    app: Ember.inject.service(),
    emailErrors: {},
    errors: {},
    observeEmailPassword: function () {
      this.set('emailErrors.passwordRequired', false);
      this.set('emailErrors.incorrectPassword', false);
    }.observes('emailPassword'),
    observeEmail: function () {
      this.set('emailErrors.emailRequired', false);
      this.set('emailErrors.emailInvalid', false);
      this.set('emailErrors.emailTaken', false);
    }.observes('email'),

    actions: {
      saveEmail: function saveEmail() {
        var _this = this;

        var password = this.get('emailPassword');
        var email = this.get('email');
        var valid = true;

        if (!password) {
          this.set('emailErrors.passwordRequired', true);
          valid = false;
        }

        if (!email) {
          this.set('emailErrors.emailRequired', true);
          valid = false;
        } else if (!this.emailValidation(email)) {
          this.set('emailErrors.emailInvalid', true);
          valid = false;
        }

        if (!valid) {
          return;
        }

        this.set('emailErrors.server', false);
        this.set('savingEmail', true);
        this.get('users').updateEmail(this.get('session.user'), { password: password, email: email }).then(function () {
          _this.set('savingEmail', false);
          _this.set('emailSaved', true);
          Ember.run.later(function () {
            return _this.set('emailSaved', false);
          }, 1000);
          _this.set('emailPassword', '');
          _this.set('email', '');
        }, function (response) {
          _this.set('savingEmail', false);

          if (response.payload.code === 403) {
            _this.set('emailErrors.incorrectPassword', true);
          } else if (response.payload.hasError('email')) {
            _this.set('emailErrors.emailTaken', true);
          } else {
            _this.set('emailErrors.server', true);
          }
        });
      }
    }
  });
});