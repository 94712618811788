define('postedin/services/publishers', ['exports', 'postedin/services/repository', 'postedin/models/publisher'], function (exports, _repository, _publisher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'publisher',
    create: function create() {
      return _publisher.default.create();
    },
    findById: function findById(id) {
      var _this = this;

      return this.request('publishers/' + id).then(function (record) {
        return _this.push(record);
      });
    },
    update: function update(publisher) {
      var _this2 = this;

      return this.put('publishers/' + publisher.get('id'), publisher).then(function (record) {
        return _this2.push(record);
      });
    },
    remove: function remove(publisher) {
      var _this3 = this;

      return this.delete('publishers/' + publisher.get('id')).then(function () {
        _this3.removeObject(publisher.get('id'), 'project-publisher');
        return _this3.removeObject(publisher.get('id'));
      });
    },
    createPublication: function createPublication(publisher, publishable) {
      var _this4 = this;

      var debugContainerKey = Object.getPrototypeOf(publishable)._debugContainerKey || publishable._debugContainerKey;
      var type = debugContainerKey.split(':')[1];

      return this.post('publishers/' + publisher.id + '/publication?publishableType=' + type + '&publishableId=' + publishable.id).then(function (record) {
        return _this4.push(record, 'publisher-publication');
      });
    },

    // categories(id) {
    //   return this.request('publishers/' + id + '/categories').then(response => {
    //     return response;
    //   });
    // },
    // authors(id) {
    //   return this.request('publishers/' + id + '/authors').then(response => {
    //     return response;
    //   });
    // },
    // publishPublication(publisher, publication, data) {
    //   return this.post('publishers/' + publisher + '/publication/' + publication, data).then(response => {
    //     return response;
    //   });
    // },
    // sendImage(publisher, image) {
    //   return this.post('publishers/' + publisher + '/image/' + image).then(response => {
    //     return response;
    //   });
    // },
    // publish(publisher, publication, step, publishData) {
    //   let params = '';
    //   if (step) {
    //     params = '?step=' + step;
    //   }
    //   return this.put('publishers/' + publisher.id + '/publish/' + publication.id + params, publishData).then(response => {
    //     return response;
    //   });
    // },
    testConnection: function testConnection(publisher) {
      var data = {
        type: publisher.get('type'),
        url: publisher.get('url'),
        username: publisher.get('username'),
        secret: publisher.get('secret')
      };

      if (publisher.get('id')) {
        return this.post('publishers/' + publisher.get('id') + '/test-connection', data).then(function (response) {
          return response;
        });
      }

      return this.post('publishers/test-connection', data).then(function (response) {
        return response;
      });
    },
    load: function load(publisher, _load) {
      return this.request('publishers/' + publisher.id + '/load?resource=' + _load).then(function (response) {
        return response;
      });
    }
  }
  // browse(id, cmd) {
  //   return this.request('publishers/' + id + '/get?cmd=' + cmd).then(response => {
  //     return response;
  //   });
  // },
  // sendPost(id, cmd, data) {
  //   return this.post('publishers/' + id + '/post?cmd=' + cmd, data).then(response => {
  //     return response;
  //   });
  // },
  );
});