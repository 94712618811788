define('postedin/controllers/impersonate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    organizations: Ember.inject.service(),
    projects: Ember.inject.service(),
    localState: Ember.inject.service(),
    actions: {
      selectUser: function selectUser(user) {
        var _this = this;

        if (this.get('selectedUser') && user && this.get('selectedUser') !== user) {
          Ember.$('#SelectUserToImpersonate > *').css('opacity', 1).animate({
            opacity: 0
          }, 300, function () {
            _this.set('selectedUser', user);
            Ember.$('#SelectUserToImpersonate > *').animate({
              opacity: 1
            }, 200);
          });
        } else {
          this.set('selectedUser', user);
        }
      },
      impersonate: function impersonate(user) {
        var _this2 = this;

        this.set('impersonating', true);
        user.impersonate().then(function () {
          window.location.reload(true);
        }, function () {
          _this2.set('impersonating', false);
        });
      },
      showAccessableProjects: function showAccessableProjects(user) {
        var _this3 = this;

        this.set('impersonating', true);
        user.impersonate().then(function () {
          Ember.run.later(function () {
            _this3.get('organizations').all().then(function (organizations) {
              user.set('accessableProjects', organizations);
              var promises = [];
              organizations.forEach(function (organization) {
                organization.get('projects').forEach(function (project) {
                  var promise = _this3.get('projects').findById(project.id);
                  promises.push(promise);

                  project.set('passed');
                  project.set('failed');
                  promise.then(function () {
                    project.set('passed', true);
                  }, function () {
                    project.set('failed', true);
                  });
                });
              });
              Ember.RSVP.all(promises).then(function () {
                _this3.set('impersonating', false);
                Ember.run.later(function () {
                  return _this3.get('localState').set('impersonating');
                });
              });
            });
          }, 100);
        });
      }
    }
  });
});