define('postedin/routes/admin/users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    users: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        model: this.get('users').find(),
        admins: this.get('users').find({ admin: 1 }),
        globalEditors: this.get('users').find({ global_editor: 1 }),
        globalManagers: this.get('users').find({ global_manager: 1 }),
        globalOrganizationManagers: this.get('users').find({ global_organization_manager: 1 })
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.model);

      controller.setProperties({
        admins: hash.admins,
        globalEditors: hash.globalEditors,
        globalManagers: hash.globalManagers,
        globalOrganizationManagers: hash.globalOrganizationManagers
      });
    }
  });
});