define('postedin/services/content-types', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'content-type',
    find: function find() {
      var _this = this;

      return this.request('content-types').then(function (response) {
        return _this.pushResponse(response);
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('content-types/' + id).then(function (record) {
        return _this2.push(record);
      });
    },
    create: function create(contentType) {
      var _this3 = this;

      return this.post('content-types', contentType).then(function (record) {
        return _this3.push(record);
      });
    },
    update: function update(contentType) {
      var _this4 = this;

      return this.put('content-types/' + contentType.get('id'), contentType).then(function (record) {
        return _this4.push(record);
      });
    },
    remove: function remove(contentType) {
      var _this5 = this;

      return this.delete('content-types/' + contentType.get('id')).then(function () {
        return _this5.removeObject(contentType.get('id'));
      });
    },
    addExtra: function addExtra(id, extra) {
      var _this6 = this;

      return this.post('content-types/' + id + '/extras', extra).then(function (record) {
        return _this6.push(record);
      });
    },
    editExtra: function editExtra(extra) {
      var _this7 = this;

      return this.put('content-type-extras/' + extra.id, extra).then(function (record) {
        return _this7.push(record);
      });
    },
    deleteExtra: function deleteExtra(id) {
      return this.delete('content-type-extras/' + id);
    }
  });
});