define('postedin/components/resource-table-fields-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ResourceTableFieldsEditComponent = Ember.Component.extend({
    tagName: '',
    actions: {
      updateField: function updateField(resource, field, event) {
        var update = event.target ? event.target.value : event;
        resource.get('_fields').forEach(function (data) {
          if (data.field === field) {
            data.update = update;
            Ember.set(data, 'error', false);
          }
        });
      }
    }
  });

  ResourceTableFieldsEditComponent.reopenClass({
    positionalParams: ['resource', 'fields']
  });

  exports.default = ResourceTableFieldsEditComponent;
});