define('postedin/utils/i18n/missing-message', ['exports', 'postedin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var idCounter = 0;

  function uniqueId() {
    return ++idCounter;
  }

  function i18nMissingMessage(locale, key, context) {
    if (_environment.default.environment === 'test') {
      return key;
    }

    var readable = key.split(':').pop().split('.').pop().replace(/_/g, ' ').capitalize();

    if (_environment.default.environment === 'development') {
      var values = Object.keys(context).map(function (key) {
        return key + '=' + context[key];
      }).join('; ');

      if (!values) {
        values = 'no params';
      }

      var id = uniqueId() + '_' + key;

      Ember.run.next(function () {
        var $phrase = Ember.$('[data-lang="' + id + '"]');
        if ($phrase.length && !$phrase.data('lang-setup')) {
          var showHelper = function showHelper() {
            Ember.run.cancel($phrase._hoverTimer);
            prompt(key, '"' + key + '": "' + readable.capitalize() + '",');
          };

          $phrase.find('[data-handle]').dblclick(function () {
            showHelper();
          });

          $phrase.hover(function () {
            Ember.run.cancel($phrase._hoverTimer);
            $phrase._hoverTimer = Ember.run.later(function () {
              showHelper();
            }, 2000);
          }, function () {
            Ember.run.cancel($phrase._hoverTimer);
          });
        }
      });

      return Ember.String.htmlSafe('\n      <span\n        data-lang="' + id + '"\n        title="Missing Phrase: ' + key + ' - ' + values + '"\n      >\n        <span data-handle style="font-size: 9px"> * </span>' + readable + '\n      </span>\n    ');
    }

    return '`' + readable;
  }

  exports.default = i18nMissingMessage;
});