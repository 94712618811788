define('postedin/components/paginator-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'pagination',
    pages: Ember.computed('currentPage', 'lastPage', function () {
      var _this = this;

      var pages = [];
      var currentPage = parseInt(this.get('currentPage'));
      for (var page = 1; page <= this.get('lastPage'); page++) {
        pages.push({
          number: page,
          active: page === currentPage
        });
      }

      var start = currentPage - 4;
      var end = Math.min(currentPage + 3, parseInt(this.get('lastPage')));
      if (start <= 0) {
        end -= start;
        start = 0;
      }

      Ember.run.once(function () {
        _this.set('showFirstPage', start > 0);
      });

      return pages.slice(start, end);
    }),
    scrollUp: function scrollUp() {
      var scrollElement = this.get('scrollTo');
      if (!scrollElement) {
        return;
      }

      var $scrollTo = Ember.$(scrollElement);
      if (!$scrollTo.length) {
        return;
      }

      Ember.run.later(function () {
        if ($scrollTo.offset()) {
          Ember.$('html, body').animate({
            scrollTop: $scrollTo.offset().top - 20
          }, 100);
        }
      }, 250);
    },

    actions: {
      page: function page(_page) {
        this.sendAction('goToPage', _page);
        this.scrollUp();
      }
    }
  });
});