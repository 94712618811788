define('postedin/components/login-form', ['exports', 'postedin/mixins/validator', 'postedin/mixins/ui-promise'], function (exports, _validator, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, _validator.default, {
    session: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('form', Ember.Object.create({
        email: this.get('email', '')
      }));
      this.setupErrorClearingObservers('form', ['email', 'password']);
    },
    validate: function validate(data) {
      var rules = {
        'email': 'required|email',
        'password': 'required'
      };

      return this.validatorCheck(data, rules);
    },
    parseServerError: function parseServerError(response) {
      if (response && response.error) {
        var errorMsg = void 0;

        switch (response.error.code) {
          case 'InvalidRequest':
            errorMsg = 'validation.invalid_request';
            break;
          case 'InvalidCredentials':
            errorMsg = 'validation.invalid_credentials';
            break;
          default:
            errorMsg = 'validation.server_error';
        }

        this.set('serverError', errorMsg);
      } else {
        this.set('serverError', false);
      }
    },


    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('serverError', false);

        var data = this.get('form').getProperties('email', 'password');

        if (this.validate(data)) {
          var authenticate = this.get('session').authenticate('authenticator:oauth2', data.email, data.password);
          this.uiPromise(authenticate, 'auth').then(function () {
            _this.set('loggedIn', true);
          }, function (response) {
            _this.parseServerError(response);
            _this.scrollToFirstError();
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});