define('postedin/routes/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    users: Ember.inject.service(),
    model: function model(params, transition) {
      if (!params.id) {
        transition.abort();
        this.transitionTo('users');
      }

      return this.get('users').findById(params.id);
    }
  });
});