define('postedin/routes/admin/organizations/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    organizations: Ember.inject.service(),
    model: function model(params) {
      return this.get('organizations').findById(params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('updates', model.updatesHash());
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('delete', 0);
      }
    }
  });
});