define('postedin/components/main-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['main-nav'],
    session: Ember.inject.service(),
    localState: Ember.inject.service(),
    store: Ember.inject.service('simple-store'),
    organizationsRepository: Ember.inject.service('organizations'),
    organizations: [],

    sessionDidChange: Ember.on('init', Ember.observer('session.isAuthenticated', 'session.reloadOrganizations', function () {
      var _this = this;

      if (!this.get('session.isAuthenticated')) {
        return;
      }

      this.set('organizations', this.get('store').find('organization').sortBy('name'));

      if (this.get('session.reloadOrganizations')) {
        this.get('organizationsRepository').allWithProjects().then(function (organizations) {
          _this.set('organizations', organizations);
        });
      }
    })),

    canViewContentRequests: Ember.computed('organizations.length', 'organizations.@each.projects', function () {
      if (!this.get('organizations.length')) {
        return false;
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('organizations').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var organization = _step.value;

          if (!organization.get('projects')) {
            continue;
          }

          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = organization.get('projects').toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var project = _step2.value;

              if (project.get('canViewContentRequests')) {
                return true;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    }),

    actions: {
      logout: function logout() {
        if (this.get('localState.impersonating')) {
          this.get('localState').set('impersonating');
          window.location.reload(true);

          return;
        }

        this.get('session').invalidate();
      }
    }
  });
});