define('postedin/services/beta-missions', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'beta-mission',
    find: function find(data) {
      var _this = this;

      return this.request('beta-missions?' + data).then(function (response) {
        return _this.pushResponse(response);
      });
    }
  });
});