define('postedin/controllers/admin-login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('authenticationFailed', false);
        this.set('identificationMissing', !this.get('identification'));
        this.set('passwordMissing', !this.get('password'));
        if (this.get('identification') && this.get('password')) {
          this.set('loggingIn', true);

          var _getProperties = this.getProperties('identification', 'password'),
              identification = _getProperties.identification,
              password = _getProperties.password;

          this.set('password', null);

          return this.get('session').authenticate('authenticator:oauth2', identification, password).catch(function () {
            _this.set('authenticationFailed', true);
            _this.set('loggingIn', false);
          });
        }
      }
    }
  });
});