define('postedin/services/app', ['exports', 'postedin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    build: _environment.default.build,
    admin: _environment.default.build === 'admin',
    isBranded: _environment.default.branding && _environment.default.branding !== 'postedin',
    branding: _environment.default.branding
  });
});