define('postedin/routes/admin-organizations/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('admin-organizations.show');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('confirmationModal', false);
      controller.set('saving', false);
    }
  });
});