define('postedin/services/legacy-topics', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    findById: function findById(id) {
      return this.request('legacy-topics/' + id);
    }
  });
});