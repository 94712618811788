define('postedin/libs/annotatable-node', ['exports', 'npm:rangy', 'npm:component-emitter'], function (exports, _npmRangy, _npmComponentEmitter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var $ = Ember.$;

  var $debugInfo = false;

  // Ember.runInDebug(() => {
  //   $debugInfo = $('<div>').css({
  //     background: 'black',
  //     color: '#00FF00',
  //     padding: '5px',
  //     position: 'absolute',
  //     fontFamily: '"Source Code Pro", "Courier New", Courier, monospace',
  //   });
  // });

  var _class = function (_Emitter) {
    _inherits(_class, _Emitter);

    function _class($node) {
      _classCallCheck(this, _class);

      var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this));

      _this.uid = Math.random().toString(36).substr(2, 9);
      _this.$node = $node;
      _this.registerEvents();

      // each text node needs to have a reference back to this instance so we can easily
      // check if selected textNodes are part of $node
      _this.addTextNodeReferences();
      return _this;
    }

    _createClass(_class, [{
      key: 'registerEvents',
      value: function registerEvents() {
        var _this2 = this;

        this._mouseUpEvent = function (e) {
          return _this2.mouseUp(e);
        };
        $(document).on('mouseup', this._mouseUpEvent);

        // things that could unselect outside of our mouseup
        this._recheckSelectionHandler = function (e) {
          return _this2.recheckSelection(e);
        };
        $(document).on('keyup', this._recheckSelectionHandler);
        $(document).on('contextmenu', this._recheckSelectionHandler);
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this.hideAnnotatableTether();

        $(document).unbind('mouseup', this._mouseUpEvent);

        $(document).unbind('keyup', this._recheckSelectionHandler);
        $(document).unbind('contextmenu', this._recheckSelectionHandler);
      }
    }, {
      key: 'addTextNodeReferences',
      value: function addTextNodeReferences() {
        var _this3 = this;

        var contents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (this.$node.prop('tagName') === 'IFRAME') {
          return;
        }

        if (!contents) {
          contents = this.$node.contents();
        }

        contents.each(function (_, node) {
          if (node.nodeType === 3 && node.nodeValue.trim()) {
            node.annotatableNode = _this3;
          }

          var $n = $(node);
          if ($n.prop('tagName') === 'IFRAME') {
            return;
          }

          var c = $n.contents();
          if (c.length) {
            _this3.addTextNodeReferences(c);
          }
        });
      }
    }, {
      key: 'mouseUp',
      value: function mouseUp(event) {
        var _this4 = this;

        // without this running on the next tick we have the stuff shown when selected text
        // is clicked to unselect it
        setTimeout(function () {
          if (_this4.hasSelectedText() && _this4.selectedTextWithinNode()) {
            if (_this4.selectedTextWithinThisNode()) {
              _this4.showAnnotatableTether();
            }
          } else {
            _this4.hideAnnotatableTether();
          }
        }, 1);

        if (event) {
          event.stopPropagation();
        }
      }
    }, {
      key: 'recheckSelection',
      value: function recheckSelection() {
        var range = this.getSelectionRange();
        if (range) {
          if (!range.startContainer.annotatableNode) {
            this.hideAnnotatableTether();
            return;
          }

          var node = range.startContainer.annotatableNode;
          if (!node.hasSelectedText() || !node.selectedTextWithinNode()) {
            node.hideAnnotatableTether();
          }
        }
      }
    }, {
      key: 'showAnnotatableTether',
      value: function showAnnotatableTether() {
        this.emit('showAnnotatableTether');

        this.showDebugInfo();
      }
    }, {
      key: 'hideAnnotatableTether',
      value: function hideAnnotatableTether() {
        this.emit('hideAnnotatableTether');

        this.hideDebugInfo();
      }
    }, {
      key: 'showDebugInfo',
      value: function showDebugInfo() {
        if (!$debugInfo) {
          return;
        }

        var range = this.getSelectionRange();
        if (!range) {
          return;
        }

        range = range.nativeRange;

        $debugInfo.detach();
        $debugInfo.appendTo(document.body);

        var rect = range.getClientRects()[0];

        $debugInfo.html('\n      uid: <strong>' + this.uid + '</strong><br>\n      node: <strong>' + this.$node[0] + '</strong><br>\n      startOffset: <strong>' + range.startOffset + '</strong><br>\n      endOffset: <strong>' + range.endOffset + '</strong><br>\n      clientRects: <strong>' + range.getClientRects().length + '</strong><br>\n    ');

        var top = window.scrollY + rect.top - $debugInfo.outerHeight() + (rect.bottom - rect.top) - 1;
        var left = window.scrollX + rect.left - $debugInfo.outerWidth() - 2;

        $debugInfo.css({
          top: top,
          left: left
        });
      }
    }, {
      key: 'hideDebugInfo',
      value: function hideDebugInfo() {
        if (!$debugInfo) {
          return;
        }

        $debugInfo.detach();
      }
    }, {
      key: 'getSelectionRange',
      value: function getSelectionRange() {
        var selection = this.getSelection();

        if (!selection || !selection.rangeCount) {
          return false;
        }

        return selection.getRangeAt(0);
      }
    }, {
      key: 'getSelection',
      value: function getSelection() {
        return _npmRangy.default.getSelection();
      }
    }, {
      key: 'hasSelectedText',
      value: function hasSelectedText() {
        var selection = this.getSelection();

        if (!selection) {
          return false;
        }

        return !!selection.toString().trim();
      }
    }, {
      key: 'selectedTextWithinNode',
      value: function selectedTextWithinNode() {
        var range = this.getSelectionRange();
        if (!range) {
          return false;
        }

        if (!range.startContainer.annotatableNode) {
          return false;
        }

        if (!range.endContainer.annotatableNode) {
          try {
            // We might have selected an element or whitespace after our elements so we try find
            // the last text node to use instead
            var element = range.endContainer.previousSibling;

            // When triple click selecting the last paragraph it for some reason includes the
            // tether control so we do a check for it
            if (range.endContainer.classList.contains('bubble-text')) {
              element = $('.annotated-html').children().last()[0];
            }

            if (element.nodeType !== 1) {
              element = element.previousSibling;
            }

            // Possible TODO: recurse for this one to get the first text node, only happens with more
            // than one element with links and highlights at the end of the text
            if (element.lastChild.nodeType === 1) {
              element = element.lastChild;
            }

            if (element.nodeType === 1 && element.lastChild.annotatableNode) {
              range.setEnd(element.lastChild, element.lastChild.length);
              var selection = _npmRangy.default.getSelection();
              selection.setSingleRange(range);

              this.mouseUp();
            }
          } catch (e) {
            //
          }

          return false;
        }

        return true;
      }
    }, {
      key: 'selectedTextWithinThisNode',
      value: function selectedTextWithinThisNode() {
        var range = this.getSelectionRange();
        if (!range) {
          return false;
        }

        return range.endContainer.annotatableNode && range.endContainer.annotatableNode === this;
      }
    }]);

    return _class;
  }(_npmComponentEmitter.default);

  exports.default = _class;
});