define('postedin/models/annotation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    annotations: Ember.inject.service(),
    resolve: function resolve() {
      return this.get('annotations').resolve(this);
    }
  });
});