define('postedin/services/project-forbidden-sources', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'project-forbidden-source',
    findById: function findById(id) {
      var _this = this;

      return this.request('project-forbidden-sources/' + id).then(function (record) {
        return _this.push(record);
      });
    },
    update: function update(forbiddenSource, name, url, description) {
      var _this2 = this;

      var site = {
        name: name,
        url: url,
        description: description
      };
      return this.put('project-forbidden-sources/' + forbiddenSource.id, site).then(function (record) {
        return _this2.push(record);
      });
    },
    remove: function remove(forbiddenSource) {
      var _this3 = this;

      return this.delete('project-forbidden-sources/' + forbiddenSource.id).then(function () {
        _this3.removeObject(forbiddenSource.id);
      });
    }
  });
});