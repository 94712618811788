define('postedin/components/language-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    i18n: Ember.inject.service(),
    locale: Ember.inject.service(),
    locales: {
      'en': 'English',
      'es': 'Español'
    },
    selectedLocale: Ember.computed('i18n.locale', function () {
      return this.get('i18n.locale');
    }),
    actions: {
      setLocale: function setLocale(select) {
        if (select.value) {
          this.get('locale').selectLocale(select.value);
        }

        select.selectedIndex = 0;
      }
    }
  });
});