define('postedin/models/day', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    days: Ember.inject.service(),
    updateHoliday: function updateHoliday(holiday) {
      return this.get('days').update(this, holiday);
    },
    updateCapacity: function updateCapacity(capacity) {
      return this.get('days').update(this, null, capacity);
    }
  });
});