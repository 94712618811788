define('postedin/components/error-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ErrorPopupComponent = Ember.Component.extend({
    drop: null,
    setup: Ember.on('didRender', Ember.observer('error', function () {
      var _this = this;

      Ember.run.once(function () {
        var error = _this.get('error');
        if (error) {
          var target = _this.$();
          if (_this.get('target')) {
            target = Ember.$(_this.get('target'));
          }

          _this.set('drop', new Drop({
            target: target[0],
            content: error.toString(),
            classes: 'drop-theme-twipsy',
            position: 'bottom left',
            openOn: 'always'
          }));
        }
      });
    })),
    willDestroy: function willDestroy() {
      var drop = this.get('drop');
      if (drop) {
        drop.destroy();
      }
    }
  }); /* global Drop*/

  ErrorPopupComponent.reopenClass({
    positionalParams: ['error']
  });

  exports.default = ErrorPopupComponent;
});