define('postedin/services/invoices', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'invoice',
    updateInvoiceNumber: function updateInvoiceNumber(invoice, number) {
      var _this = this;

      return this.put('invoices/' + invoice.get('id') + '/invoice-number', { invoiceNumber: number }).then(function (record) {
        return _this.push(record);
      });
    },
    resendEmail: function resendEmail(invoice) {
      return this.post('invoices/' + invoice.get('id') + '/resend-email');
    },
    reject: function reject(invoice, reason) {
      var _this2 = this;

      return this.put('invoices/' + invoice.get('id') + '/reject', { reason: reason }).then(function (record) {
        return _this2.push(record);
      });
    },
    setAsPaid: function setAsPaid(invoice, paypalTransactionId) {
      var _this3 = this;

      return this.put('invoices/' + invoice.get('id') + '/paid', { paypalTransactionId: paypalTransactionId }).then(function (record) {
        return _this3.push(record);
      });
    },
    downloadPdf: function downloadPdf(invoice) {
      return this.request('invoices/' + invoice.get('id') + '/pdf');
    }
  });
});