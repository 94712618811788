define('postedin/components/help-categories', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    scrollTo: function scrollTo(name) {
      var $target = Ember.$('[name=' + name + ']');
      if ($target.length) {
        Ember.$('html, body').animate({
          scrollTop: $target.offset().top
        });
      }
    },

    actions: {
      categoryLink: function categoryLink(category) {
        this.scrollTo(category.name.slugify());
      }
    }
  });
});