define('postedin/mixins/scroll-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    scrollTo: function scrollTo(target) {
      var animate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var $el = Ember.$(target);
      if ($el.length) {
        if (animate) {
          Ember.$('html, body').animate({
            scrollTop: $el.offset().top
          }, 200);
        } else {
          Ember.$('html, body').scrollTop($el.offset().top);
        }
      }
    }
  });
});