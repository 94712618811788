define('postedin/routes/chile-payments/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    payments: Ember.inject.service(),
    invoices: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        payment: this.get('payments').findById(params.id)
      });
    },
    setupController: function setupController(controller, model) {
      model.payment.invoices.forEach(function (invoice) {
        invoice.set('newInvoiceNumber', invoice.get('invoiceNumber'));
        invoice.set('rejectComment', '');
        invoice.set('acceptDialog', false);
        invoice.set('rejectDialog', false);
        invoice.set('rejectionDetailsDialog', false);

        invoice.set('rejectReasons', [{ checked: false, column: 1, phrase: 'La fecha de emisión de la boleta es incorrecta.' }, { checked: false, column: 1, phrase: 'El monto de la boleta es incorrecto.' }, { checked: false, column: 1, phrase: 'La glosa de la boleta es incorrecta.' }, { checked: false, column: 2, phrase: 'La boleta se encuentra duplicada.' }, { checked: false, column: 2, phrase: 'Los datos de Postedin en la boleta son incorrectos.' }, { checked: false, column: 2, phrase: 'La boleta fue emitida fuera del período permitido.' }]);
      });

      this._super(controller, model);

      controller.set('finishDialog', false);
      controller.set('finishError', false);
      controller.set('newSearch', controller.get('search'));
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});