define('postedin/services/publication-reviews', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    // NOTE: not using publication-review due to some limitations in this system we have not solved yet
    model: 'review',
    find: function find(params) {
      var _this = this;

      params = Ember.$.param(params);
      if (params) {
        params = '?' + params;
      }
      return this.request('publication-reviews' + params).then(function (response) {
        return _this.pushResponse(response);
      });
    }
  });
});