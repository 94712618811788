define('postedin/components/attachment-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AttachmentListComponent = Ember.Component.extend({
    classNames: ['attachment-list']
  });

  AttachmentListComponent.reopenClass({
    positionalParams: ['attachments']
  });

  exports.default = AttachmentListComponent;
});