define('postedin/models/organization-profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    update: function update(updates) {
      return this.get('organization').updateProfile(updates);
    }
  });
});