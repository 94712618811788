define('postedin/controllers/login/forgot-password', ['exports', 'postedin/mixins/validator', 'postedin/mixins/ui-promise'], function (exports, _validator, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validator.default, _uiPromise.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.setupErrorClearingObservers('form', ['email']);
      this.set('form', Ember.Object.create({}));
    },
    validate: function validate(form) {
      return this.validatorCheck(form, {
        'email': 'required|email'
      });
    },


    actions: {
      recover: function recover() {
        var _this = this;

        if (this.validate(this.get('form').getProperties('email'))) {

          var request = Ember.$.ajax('/api/password/email', {
            method: 'POST',
            data: {
              'email': this.get('form.email')
            }
          });

          return this.uiPromise(request, 'recover').then(function () {
            _this.set('sent', true);
          }, function (response) {
            _this.serverFailureToValidatorErrors(response);
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});