define('postedin/services/users', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var globalRolesToParams = function globalRolesToParams(globalRoles) {
    return {
      admin: !!globalRoles.isAdmin,
      globalEditor: !!globalRoles.isGlobalEditor,
      globalManager: !!globalRoles.isGlobalManager,
      globalOrganizationManager: !!globalRoles.isGlobalOrganizationManager
    };
  };

  exports.default = _repository.default.extend({
    model: 'user',
    find: function find() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var validParams = ['admin', 'global_editor', 'global_manager', 'global_organization_manager'];
      var queryParams = {};

      validParams.forEach(function (param) {
        if (params[param]) {
          queryParams[param] = params[param];
        }
      });

      return this.request('users?' + Ember.$.param(queryParams)).then(function (response) {
        return _this.pushResponse(response.data);
      });
    },
    search: function search(term) {
      var _this2 = this;

      return this.request('users?' + Ember.$.param({ search: term })).then(function (response) {
        return _this2.pushResponse(response);
      });
    },
    findById: function findById(id) {
      var _this3 = this;

      return this.request('users/' + id).then(function (response) {
        return _this3.push(response);
      });
    },
    create: function create(name, email, password, tempType, globalRoles) {
      var _this4 = this;

      var data = Ember.assign(globalRolesToParams(globalRoles), {
        name: name, email: email, password: password, tempType: tempType
      });

      return this.post('users/', data).then(function (record) {
        return _this4.push(record);
      });
    },
    update: function update(_ref, name, email, password, tempType, globalRoles) {
      var id = _ref.id;

      var _this5 = this;

      if (!id) {
        return false;
      }

      var data = Ember.assign(globalRolesToParams(globalRoles), {
        name: name, email: email, password: password, tempType: tempType
      });

      return this.put('users/' + id, data).then(function (record) {
        return _this5.push(record);
      });
    },
    loadAllPendingReviews: function loadAllPendingReviews(user) {
      var _this6 = this;

      return this.request('users/' + user.id + '/pending-reviews').then(function (response) {
        return _this6.pushResponse(response, 'content-review');
      });
    },
    loadReviews: function loadReviews(user) {
      var _this7 = this;

      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      params.page = Math.max(1, parseInt(params.page || 0));

      return this.request('users/' + user.id + '/reviews?' + decodeURIComponent(Ember.$.param(params))).then(function (response) {
        var reviews = _this7.pushResponse(response, 'content-review');
        delete response.data;

        return { reviews: reviews, meta: response };
      });
    },
    impersonate: function impersonate(user) {
      return this.post('users/' + user.get('id') + '/impersonate');
    },
    register: function register(user, invite) {
      if (invite) {
        return this.post('invites/' + invite.get('uuid') + '/register', user.getProperties(['email', 'name', 'password']));
      }

      // TODO: non invite register
    },
    updateEmail: function updateEmail(user, data) {
      if (!user.id) {
        return false;
      }
      return this.put('users/' + user.id + '/email', data).then(function (record) {
        return record;
      });
    },
    updatePassword: function updatePassword(user, data) {
      if (!user.id) {
        return false;
      }
      return this.put('users/' + user.id + '/password', data).then(function (record) {
        return record;
      });
    },
    updatePreferences: function updatePreferences(user, data) {
      if (!user.id) {
        return false;
      }
      return this.put('users/' + user.id + '/preferences', data).then(function (record) {
        return record;
      });
    },
    summary: function summary(data) {
      return this.request('users/summary?' + data).then(function (response) {
        return response;
      });
    },
    activeUsersToday: function activeUsersToday() {
      return this.request('users/active-users-today').then(function (response) {
        return response.data;
      });
    }
  });
});