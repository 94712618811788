define('postedin/components/froala-editor', ['exports', 'ember-froala-editor/components/froala-editor', 'postedin/helpers/branding'], function (exports, _froalaEditor, _branding) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var clearData = function clearData($file, response) {
    try {
      Object.keys(JSON.parse(response)).forEach(function (key) {
        $file.attr('data-' + key, null);
      });
    } catch (e) {
      //
    }
  };

  exports.default = _froalaEditor.default.extend({
    defaults: {
      key: (0, _branding.branding)(['froalaKey']),
      useClasses: false,
      imageDefaultWidth: 0,
      imageDefaultAlign: 'left',
      videoDefaultAlign: 'left',
      heightMin: 600,
      wordPasteModal: false,
      wordAllowedStyleProps: [],
      pasteAllowedStyleProps: [],
      htmlAllowedStyleProps: [],
      htmlAllowedAttrs: ['title', 'href', 'alt', 'src'],
      charCounterCount: false,
      linkInsertButtons: [],
      linkEditButtons: ['linkOpen', /* 'linkStyle',*/'linkEdit', 'linkRemove'],
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      entities: '',
      toolbarButtons: ['fullscreen', 'undo', 'redo', 'clearFormatting', '|', 'bold', 'italic', 'underline', 'strikeThrough', /* 'subscript', 'superscript',*/'fontFamily', 'fontSize', 'color', '-',
      /* 'color', 'emoticons', 'inlineStyle', 'paragraphStyle', '|',*/
      'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', 'insertHR', '|', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', 'html'],
      imageUpload: false
      // imageUploadURL: '/api/uploads',
      // fileUploadURL: '/api/uploads',
      // videoUploadURL: '/api/uploads',
    },
    init: function init() {
      this._super.apply(this, arguments);
      var options = Ember.$.extend(this.get('defaults'), this.getWithDefault('options', {}));

      this.set('options', options);
    },
    didInitEditor: function didInitEditor(event, editor) {
      this._super.apply(this, arguments);
      editor.events.on('image.inserted', clearData);
      editor.events.on('file.inserted', clearData);
      editor.events.on('video.inserted', clearData);
      this.set('instance', this);
    }
  });
});