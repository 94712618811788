define('postedin/services/local-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    _state: {},
    _updateStorage: false,
    init: function init() {
      this.updateFromLocalStorage();

      Ember.$(window).bind('storage', function () {
        // FIXME: this is off for now due to impersonation issues and it not watching correctly
        // this.updateFromLocalStorage();
      });
    },
    updateFromLocalStorage: function updateFromLocalStorage() {
      try {
        var state = JSON.parse(localStorage.getItem('state'));
        this.setProperties(state);
      } catch (e) {
        if (e instanceof SecurityError) {
          return;
        }

        throw e;
      }
    },
    set: function set(key, value) {
      this._super.apply(this, arguments);

      if (key.split('.')[0] !== '_state') {
        this.set('_state.' + key, value);
        this.updateLocalStorage();
      }
    },
    setProperties: function setProperties(hash) {
      this._super.apply(this, arguments);
      for (var prop in hash) {
        if (hash.hasOwnProperty(prop) && prop.split('.')[0] !== '_state') {
          this.set('_state.' + prop, hash[prop]);
        }
      }

      this.updateLocalStorage();
    },
    updateLocalStorage: function updateLocalStorage() {
      var json = JSON.stringify(this.get('_state'));
      window.localStorage.setItem('state', json);
    }
  });
});