define('postedin/controllers/admin/legacy-content-type-groups/create', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    legacyContentTypeGroups: Ember.inject.service(),
    updates: Ember.computed.alias('model'),
    validate: function validate(data) {
      var rules = {
        name: 'required'
      };

      return this.validatorCheck(data, rules);
    },

    actions: {
      saveGroup: function saveGroup(group) {
        var _this = this;

        if (this.validate(group)) {
          this.uiPromise(this.get('legacyContentTypeGroups').create(group.get('name')), 'savegroup').then(function () {
            _this.transitionToRoute('admin.legacy-content-type-groups');
          });
        }
      }
    }
  });
});