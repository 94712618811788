define('postedin/services/page-views', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'page-view',
    log: function log(path, query, fragment) {
      var log = {
        path: path,
        query: query,
        fragment: fragment
      };

      return this.post('log-view', log);
    },
    findByPath: function findByPath(path) {
      var _this = this;

      return this.request('page-views?' + Ember.$.param({ path: path })).then(function (response) {
        return _this.pushResponse(response);
      });
    }
  });
});