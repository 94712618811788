define('postedin/routes/admin', ['exports', 'postedin/app'], function (exports, _app) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      if (!this.get('session.user.isAdmin')) {
        return _app.default.forbidden();
      }
    }
  });
});