define('postedin/services/comments', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'comment',
    find: function find(id) {
      var _this = this;

      return this.request('comments/' + id).then(function (record) {
        record.commentable._emberModel = record.commentable._model;

        return _this.push(record);
      });
    },
    comment: function comment(_comment, reply, attachments) {
      var _this2 = this;

      var data = {
        body: reply,
        attachments: attachments
      };

      return this.post('comments/' + _comment.id + '/comments', data).then(function (record) {
        return _this2.push(record);
      });
    }
  });
});