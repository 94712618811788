define('postedin/components/url-unfurler', ['exports', 'ember-concurrency', 'postedin/mixins/validator'], function (exports, _emberConcurrency, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UrlUnfurler = Ember.Component.extend(_validator.default, {
    classNames: ['unfurler'],
    classNameBindings: ['parsed:unfurler--ready'],
    ajax: Ember.inject.service(),
    extractedUrl: Ember.computed('url', function () {
      if (!this.get('url')) {
        return false;
      }

      var regex = new RegExp(/(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?/gi);
      var urls = this.get('url').match(regex);

      if (urls && urls.length) {
        return urls[0].replace(/\s/g, '');
      } else {
        return false;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var extractedUrl = this.get('extractedUrl');

      if (extractedUrl) {
        this.get('fetchUrl').perform(extractedUrl);
      }
    },
    validateResponse: function validateResponse(response) {
      return this.validatorCheck(response, {
        title: 'required',
        url: 'required|url',
        desc: 'required'
      });
    },

    fetchUrl: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(url) {
      var queryUrl, options, parsed;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              queryUrl = '/unfurl?u=' + encodeURIComponent(url);
              options = { dataType: 'json' };
              parsed = void 0;
              _context.prev = 3;
              _context.next = 6;
              return this.get('ajax').request(queryUrl, options);

            case 6:
              parsed = _context.sent;
              _context.next = 12;
              break;

            case 9:
              _context.prev = 9;
              _context.t0 = _context['catch'](3);
              return _context.abrupt('return');

            case 12:

              if (this.validateResponse(parsed)) {
                this.set('parsed', parsed);
              }

            case 13:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[3, 9]]);
    })),

    actions: {
      sendRemoveAction: function sendRemoveAction(url) {
        this.sendAction('onRemove', url);
      }
    }
  });

  UrlUnfurler.reopenClass({
    positionalParams: ['url']
  });

  exports.default = UrlUnfurler;
});