define('postedin/routes/objects', ['exports', 'dev/routes/objects'], function (exports, _objects) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _objects.default;
    }
  });
});