define('postedin/routes/admin-kpi', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    betaUsers: Ember.inject.service(),
    users: Ember.inject.service(),
    publications: Ember.inject.service(),
    queryParams: {
      type: {
        refreshModel: true
      },
      month: {
        refreshModel: true
      },
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.set('params', params);
      params.type = params.type ? params.type : 'last30Days';
      var data = decodeURIComponent(Ember.$.param(params)) || {};
      return Ember.RSVP.hash({
        beta: this.get('betaUsers').summary(data),
        user: this.get('users').summary(data),
        activeUsersToday: this.get('users').activeUsersToday(),
        activeFreelancersToday: this.get('betaUsers').activeFreelancersToday(),
        starsSummary: this.get('publications').starsSummary(data),
        skillsCounter: this.get('betaUsers').skillsCounter()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash);

      var now = (0, _moment.default)();
      var months = [];
      for (var i = 1; i < 13; i++) {
        now.subtract(1, 'months');
        months.push({
          label: now.format('MMMM YYYY'),
          value: i.toString()
        });
      }
      controller.set('months', months);

      var filter = {
        type: this.get('params.type') ? this.get('params.type') : 'last30Days',
        month: this.get('params.month') ? this.get('params.month') : 1,
        startDate: this.get('params.start') ? this.get('params.start') : null,
        endDate: this.get('params.end') ? this.get('params.end') : null
      };
      controller.set('filter', filter);
    }
  });
});