define('postedin/controllers/beta-users/show/invoices', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, {
    actions: {
      updatePaymentRequired: function updatePaymentRequired(value) {
        this.uiPromise(this.get('user').updatePaymentRequired(value), 'updatePaymentRequired');
      }
    }
  });
});