define('postedin/models/faq-question', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    faq: Ember.inject.service(),
    update: function update(question, shortAnswer, active, category, answer) {
      return this.get('faq').update(this, question, shortAnswer, active, category, answer);
    },
    swapOrder: function swapOrder(_ref) {
      var displayOrder = _ref.displayOrder;

      return this.get('faq').swapOrder(this, displayOrder);
    }
  });
});