define('postedin/initializers/extend', ['exports', 'npm:speakingurl', 'ember-power-select/utils/group-utils'], function (exports, _npmSpeakingurl, _groupUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // this is just for any utils we want to be extending ember, jquery or native javascript objects
  function initialize() /* container, application */{
    String.prototype.slugify = function () {
      return (0, _npmSpeakingurl.default)(this, { lang: 'es' });
    };

    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
      };
    }

    if (!String.prototype.searchable) {
      String.prototype.searchable = function () {
        return (0, _groupUtils.stripDiacritics)(this).toUpperCase();
      };
    }

    Ember.Route.reopen({
      activate: function activate() {
        var _this = this;

        this._super();

        var scrollToOnActivate = this.get('scrollToOnActivate');

        if (scrollToOnActivate) {
          Ember.run.next(function () {
            _this.scrollTo(scrollToOnActivate);
          });
        }
      },
      scrollTo: function scrollTo(selector) {
        var top = 0;
        if (typeof selector === 'string') {
          var $target = Ember.$(selector);

          if ($target.length) {
            top = Math.max(0, $target.offset().top - 20);
          }
        }

        Ember.$('html, body').animate({
          scrollTop: top
        }, 100, 'linear');
      }
    });
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
  (function () {
    if (typeof window.CustomEvent === 'function') {
      return false;
    }

    function CustomEvent(event, params) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  })();

  exports.default = {
    name: 'extend',
    initialize: initialize
  };
});