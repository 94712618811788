define('postedin/components/p-calendar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    holidays: [],
    currentDate: Ember.computed('month', 'week', 'view', function () {
      var date = void 0;
      var period = this.get('view') == 'month' ? 'month' : 'isoWeek';

      switch (this.get('view')) {
        case 'month':
          date = this.get('month') ? (0, _moment.default)(this.get('month'), 'YYYY-MM') : (0, _moment.default)();
          break;
        case 'week':
          date = this.get('week') ? (0, _moment.default)(this.get('week'), 'YYYY-WW') : (0, _moment.default)();
          break;
      }

      if (date.isValid()) {
        return date.startOf(period);
      }

      return (0, _moment.default)().startOf(period);
    }),
    setMonth: function setMonth(month) {
      this.set('monthQueryParam', month.format('YYYY-MM'));
      this.set('weekQueryParam', null);
    },
    setWeek: function setWeek(week) {
      this.set('weekQueryParam', week.format('YYYY-WW'));
      this.set('monthQueryParam', null);
    },
    previousMonth: function previousMonth() {
      this.setMonth(this.get('currentDate').subtract(1, 'month'));
    },
    nextMonth: function nextMonth() {
      this.setMonth(this.get('currentDate').add(1, 'month'));
    },
    previousWeek: function previousWeek() {
      this.setWeek(this.get('currentDate').subtract(7, 'd'));
    },
    nextWeek: function nextWeek() {
      this.setWeek(this.get('currentDate').add(7, 'd'));
    },

    actions: {
      setView: function setView(view) {
        this.set('view', view);
      },
      today: function today() {
        switch (this.get('view')) {
          case 'month':
            this.setMonth((0, _moment.default)().startOf('month'));
            break;
          case 'week':
            this.setWeek((0, _moment.default)().startOf('isoWeek'));
            break;
        }
      },
      previous: function previous() {
        switch (this.get('view')) {
          case 'month':
            this.previousMonth();
            break;
          case 'week':
            this.previousWeek();
            break;
        }
      },
      next: function next() {
        switch (this.get('view')) {
          case 'month':
            this.nextMonth();
            break;
          case 'week':
            this.nextWeek();
            break;
        }
      }
    }
  });
});