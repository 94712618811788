define('postedin/routes/revisions/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    revisions: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    refresh: function refresh() {
      if (this.controller) {
        this.controller.set('loading', true);
      }

      return this._super();
    },
    model: function model(params) {
      return this.get('revisions').find(params.page);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.revisions);

      controller.set('meta', model.meta);

      controller.set('loading', false);
    }
  });
});