define('postedin/components/form-actions', ['exports', 'postedin/mixins/component-window-details'], function (exports, _componentWindowDetails) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentWindowDetails.default, {
    attributeBindings: ['style'],
    classNames: ['form-actions'],
    classNameBindings: ['isFixed:form-actions--fixed'],
    formBottomPadding: null,
    formSelector: false,
    stopAtFormBottom: true,
    isFixed: Ember.computed('fixed', function () {
      if (!this.get('fixed')) {
        return false;
      }

      return true;
    }),
    getForm: function getForm() {
      var formSelector = this.get('formSelector');

      if (formSelector) {
        return Ember.$(formSelector);
      }

      return this.$().parent('form');
    },

    updateStyles: Ember.on('didRender', Ember.observer('isFixed', 'windowDetails.updated', function () {
      var $form = this.getForm();

      if (!$form.length) {
        return;
      }

      if (this.get('formBottomPadding') === null && $form.length) {
        this.set('formBottomPadding', parseInt($form.css('padding-bottom')));
      }

      if (this.get('isFixed')) {
        $form.css('padding-bottom', this.get('formBottomPadding') + this.$().outerHeight());

        this.fixToBottom();

        return;
      }

      $form.css('padding-bottom', '');
      this.$().attr('style', '');
    })),
    fixToBottom: function fixToBottom() {
      var $form = this.getForm();

      if (!$form.length) {
        return;
      }

      var styles = {
        position: 'fixed',
        bottom: 0,
        width: $form.width() + 'px',
        left: $form.offset().left + 'px'
      };

      // we run twice so the form size is correct due to taking "out" this element
      this.applyStyles(styles);

      var breakPoint = void 0,
          top = void 0;
      // we either place actions at bottom of the form or we go with the page until the footer
      if (this.get('stopAtFormBottom')) {
        breakPoint = $form.offset().top + $form.height() + this.$().outerHeight() + parseInt(this.$().css('margin-top'));
        top = breakPoint - this.$().outerHeight() - parseInt(this.$().css('margin-top')) + 'px';
      } else {
        var $footer = Ember.$('footer.foot');
        breakPoint = $footer.offset().top - $footer.outerHeight() + 20;
        top = breakPoint - $footer.outerHeight() + 'px';
      }

      if (this.get('windowDetails.scrollPosition.bottom') >= breakPoint) {
        styles.position = 'absolute', styles.top = top;
        delete styles.bottom;

        this.applyStyles(styles);
      }
    },
    applyStyles: function applyStyles(styles) {
      this.$().attr('style', Object.keys(styles).map(function (style) {
        return style + ': ' + styles[style] + ';';
      }).join(' '));
    }
  });
});