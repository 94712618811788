define('postedin/mixins/organization-projects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    organizations: Ember.inject.service(),
    projects: Ember.inject.service(),
    loadOrganizations: function loadOrganizations() {
      return this.get('organizations').allWithProjects();
    },
    loadLabels: function loadLabels() {
      return this.get('projects').all();
    },
    loadOrganizationsProjects: function loadOrganizationsProjects(organizations) {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(organizations);
      });
    }
  });
});