define('postedin/models/project-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    projectLabels: Ember.inject.service(),
    update: function update(name, color) {
      return this.get('projectLabels').update(this, name, color);
    },
    remove: function remove() {
      return this.get('projectLabels').remove(this);
    }
  });
});