define('postedin/routes/beta-users/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('beta-users.show').loadProfile();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var info = Ember.Object.create({
        name: model.name,
        email: model.email
      });
      controller.set('info', info);
      controller.set('editingInfo', false);
      controller.set('savingInfo', false);

      var profile = void 0;
      if (model.profile) {
        profile = Ember.Object.create({
          country: model.profile.country,
          genre: model.profile.genre,
          university: model.profile.university,
          carrer: model.profile.carrer,
          birthday: model.profile.birthday,
          biography: model.profile.biography,
          twitter: model.profile.twitter,
          linkedin: model.profile.linkedin
        });
      } else {
        profile = Ember.Object.create();
      }

      controller.set('profile', profile);
      controller.set('editingProfile', false);
      controller.set('savingProfile', false);
      controller.set('savingPassword', false);
    }
  });
});