define('postedin/services/beta-users', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var paramsToQuery = function paramsToQuery(params, page) {
    params = params || {};

    var query = {};
    if (page !== false) {
      query.page = page ? Math.max(1, parseInt(page)) : 1;
    }

    if (params.search) {
      query.search = params.search;
    }

    if (params.sort) {
      query.sort = params.sort;
    }

    if (params.descending) {
      query.descending = params.descending;
    }

    if (params.excludeActive) {
      query.excludeActive = params.excludeActive;
    }

    if (params.suspended) {
      query.suspended = params.suspended;
    }

    if (params.deleted) {
      query.deleted = params.deleted;
    }

    if (params.locality) {
      query.locality = params.locality;
    }

    if (params.national) {
      query.national = params.national;
    }

    if (params.international) {
      query.international = params.international;
    }

    if (params.all) {
      query.all = params.all;
    }

    return decodeURIComponent(Ember.$.param(query));
  };

  var payableParams = function payableParams() {
    var minimum = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var billableUntilNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    var data = {};
    if (minimum) {
      data.minimum = parseInt(minimum);
    }

    if (billableUntilNow) {
      data.billableUntilNow = 1;
    }

    return Ember.$.param(data);
  };

  exports.default = _repository.default.extend({
    model: 'beta-user',
    getPayableFreelancers: function getPayableFreelancers() {
      var minimum = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var billableUntilNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var data = payableParams(minimum, billableUntilNow);
      return this.request('beta-users?payable-freelancers' + (data ? '&' + data : ''));
    },
    getPayableUsdFreelancers: function getPayableUsdFreelancers() {
      var minimum = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var billableUntilNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var data = payableParams(minimum, billableUntilNow);
      return this.request('beta-users?payable-usd-freelancers' + (data ? '&' + data : ''));
    },
    createPayment: function createPayment(name, billableUntil, users, type) {
      var data = {
        name: name,
        billableUntil: billableUntil,
        users: users
      };
      data.type = type || undefined;
      return this.post('payments', data);
    },
    find: function find(params, page) {
      var _this = this;

      return this.request('beta-users?' + paramsToQuery(params, page)).then(function (response) {
        var users = _this.pushResponse(response);
        delete response.data;

        return { users: users, meta: response };
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('beta-users/' + id).then(function (response) {
        return _this2.push(response);
      });
    },

    // Deprecated
    save: function save(id, data) {
      var _this3 = this;

      data = data.getProperties(['name', 'email']);
      return this.put('beta-users/' + id, data).then(function (record) {
        return _this3.push(record);
      });
    },
    update: function update(_ref, name, email) {
      var id = _ref.id;

      var _this4 = this;

      if (!id) {
        return false;
      }

      return this.put('beta-users/' + id, { name: name, email: email }).then(function (record) {
        return _this4.push(record);
      });
    },
    updatePaymentRequired: function updatePaymentRequired(user, required) {
      var _this5 = this;

      return this.put('beta-users/' + user.id + '/payment-required', { paymentRequired: required }).then(function (record) {
        return _this5.push(record);
      });
    },
    updatePassword: function updatePassword(id, data) {
      return this.put('beta-users/' + id + '/password', data);
    },
    profile: function profile(id) {
      return this.request('beta-users/' + id).then(function (response) {
        return response;
      });
    },
    saveProfile: function saveProfile(id, data) {
      var _this6 = this;

      data = data.getProperties(['country', 'genre', 'university', 'carrer', 'birthday', 'biography', 'twitter', 'linkedin']);
      return this.put('beta-users/' + id + '/profile', data).then(function (record) {
        return _this6.push(record);
      });
    },
    complete: function complete(id) {
      var _this7 = this;

      return this.request('beta-users/' + id + '/complete').then(function (response) {
        return _this7.push(response);
      });
    },
    stats: function stats(id) {
      return this.request('beta-users/' + id + '/stats').then(function (response) {
        return response;
      });
    },
    paymentData: function paymentData(id) {
      return this.request('beta-users/' + id + '/payment-data').then(function (response) {
        return response;
      });
    },
    savePaymentData: function savePaymentData(id, data) {
      var _this8 = this;

      return this.put('beta-users/' + id + '/payment-data', data).then(function (record) {
        return _this8.push(record);
      });
    },
    userNotes: function userNotes(id) {
      return this.request('beta-users/' + id + '/user-notes').then(function (response) {
        return response;
      });
    },
    invoices: function invoices(id) {
      return this.request('beta-users/' + id + '/invoices').then(function (response) {
        return response.data;
      });
    },
    validate: function validate(id) {
      var _this9 = this;

      return this.put('beta-users/' + id + '/validate').then(function (response) {
        return _this9.push(response);
      });
    },
    suspend: function suspend(id, note) {
      var _this10 = this;

      return this.put('beta-users/' + id + '/suspend', { note: note }).then(function (response) {
        return _this10.push(response);
      });
    },
    activate: function activate(id) {
      var _this11 = this;

      return this.put('beta-users/' + id + '/unsuspend').then(function (response) {
        return _this11.push(response);
      });
    },
    remove: function remove(id) {
      return this.delete('beta-users/' + id);
    },
    unconfirmed: function unconfirmed() {
      return this.request('beta-users/unconfirmed').then(function (response) {
        return response.data.map(function (item) {
          return Ember.Object.create(item);
        });
      });
    },
    confirm: function confirm(user) {
      return this.put('beta-users/' + user.id + '/confirm');
    },
    summary: function summary(data) {
      return this.request('beta-users/summary?' + data).then(function (response) {
        return response;
      });
    },
    activeFreelancersToday: function activeFreelancersToday() {
      return this.request('beta-users/active-users-today').then(function (response) {
        return response.data;
      });
    },
    skillsCounter: function skillsCounter() {
      return this.request('beta-users/skills-counter').then(function (response) {
        return response.data;
      });
    }
  });
});