define('postedin/routes/admin/legacy-content-type-groups/create', ['exports', 'postedin/models/legacy-content-type-group'], function (exports, _legacyContentTypeGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return _legacyContentTypeGroup.default.create({ name: '' });
    }
  });
});