define('postedin/services/publisher-publications', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'publisher-publication',
    connect: function connect(publication) {
      return this.post('publisher-publications/' + publication.id + '/connect');
    },
    prepare: function prepare(publication, data) {
      var _this = this;

      return this.post('publisher-publications/' + publication.id + '/prepare', data).then(function (record) {
        return _this.push(record);
      });
    },
    sendDraft: function sendDraft(publication) {
      var _this2 = this;

      return this.post('publisher-publications/' + publication.id + '/send-draft').then(function (record) {
        return _this2.push(record);
      });
    },
    publish: function publish(publication) {
      var _this3 = this;

      return this.post('publisher-publications/' + publication.id + '/publish').then(function (record) {
        return _this3.push(record);
      });
    },
    schedule: function schedule(publication, timestamp) {
      var _this4 = this;

      return this.post('publisher-publications/' + publication.id + '/schedule', { scheduleDate: timestamp }).then(function (record) {
        return _this4.push(record);
      });
    }
  });
});