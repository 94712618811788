define('postedin/routes/invite', ['exports', 'postedin/mixins/open-route-mixin'], function (exports, _openRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_openRouteMixin.default, {
    invites: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model(params) {
      return this.get('invites').findByUuid(params.uuid);
    },
    claim: function claim(model) {
      var _this = this;

      if (!model.get('pending')) {
        return;
      }

      if (this.get('session.isAuthenticated')) {
        this.set('retryClaim', false);
        model.set('claiming', true);
        var claim = model.claim();
        claim.then(function () {
          model.set('claiming', false);
          _this.set('session.reloadOrganizations', new Date().getTime());
        }, function () {
          model.set('failed', true);
        });
      } else {
        this.set('invite', model);
        this.set('retryClaim', true);
      }
    },

    userLoggedIn: function () {
      var _this2 = this;

      if (this.get('retryClaim') && this.get('session.user')) {
        Ember.run.later(function () {
          _this2.transitionTo('invite', _this2.get('invite.uuid'));
        });
        this.claim(this.get('invite'));
      }
    }.observes('session.user'),
    setupController: function setupController(controller, model) {
      this.claim(model);
      this._super(controller, model);
      controller.set('showRegister', true);
    }
  });
});