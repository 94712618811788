define('postedin/models/client', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apiUrl: 'clients',
    subscriber: Ember.computed.not('buyer')
  });
});