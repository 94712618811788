define('postedin/controllers/admin-faq/questions/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    faq: Ember.inject.service(),
    actions: {
      closeConfirmationModal: function closeConfirmationModal() {
        this.set('confirmationModal', false);
      },
      confirmDelete: function confirmDelete() {
        this.set('confirmationModal', true);
      },
      deleteQuestion: function deleteQuestion(question) {
        var _this = this;

        this.set('deletingQuestion', true);
        this.get('faq').remove(question).then(function () {
          _this.set('deletingQuestion', false);
          _this.transitionToRoute('admin-faq');
        }, function () {
          _this.set('deletingQuestion', false);
          _this.set('deletingError', true);
          _this.set('confirmationModal', false);
        });
      }
    }
  });
});