define('postedin/controllers/login/reset-password', ['exports', 'postedin/mixins/validator', 'postedin/mixins/ui-promise'], function (exports, _validator, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validator.default, _uiPromise.default, {
    token: null,
    clearPasswordError: function () {
      this.set('passwordError', false);
    }.observes('password'),
    clearEmailError: function () {
      this.set('emailError', false);
    }.observes('email'),
    validate: function validate(data) {
      return this.validatorCheck(data, {
        email: 'required|email',
        password: 'required|min:6',
        passwordRepeated: 'required|same:password'
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('form', Ember.Object.create({}));
      this.setupErrorClearingObservers('form', ['email', 'password', 'passwordRepeated']);
    },

    actions: {
      reset: function reset() {
        var _this = this;

        var data = this.get('form').getProperties('email', 'password', 'passwordRepeated');

        if (this.validate(data)) {
          data.token = this.get('token');
          var reset = Ember.$.ajax('/api/password/reset', {
            method: 'POST',
            data: data
          });

          this.uiPromise(reset, 'reset').then(function () {
            _this.transitionToRoute('login.index').then(function (route) {
              var controller = route.controllerFor('login.index');
              controller.set('passwordReset', true);
              controller.set('passwordResetEmail', data.email);
            });
          }, function (response) {
            var error = response.responseJSON.error;

            if (error === 'passwords.token') {
              _this.set('errors.token', 'passwords.this_password_reset_token_is_invalid');
            }

            if (error === 'passwords.user') {
              _this.set('errors.email.not_found', 'we_cant_find_an_account_with_that_email_address');
            }
          });
        }
      }
    }
  });
});