define('postedin/components/user-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-menu'],
    open: false,
    didInsertElement: function didInsertElement() {
      document.addEventListener('click', this.focusOut.bind(this));
    },
    focusOut: function focusOut(ev) {
      if (this.$().has(ev.target).length === 0) {
        this.set('open', false);
      }
    },


    actions: {
      toggle: function toggle() {
        this.set('open', !this.get('open'));
      },
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});