define('postedin/helpers/file-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fileUrl = fileUrl;
  function fileUrl(params) {
    if (!params.length) {
      return '';
    }

    var file = params[0];

    if (!file) {
      return '';
    }

    return file.url || '';
  }

  exports.default = Ember.Helper.helper(fileUrl);
});