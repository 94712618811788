define('postedin/components/legacy-content-type-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      expand: function expand() {
        this.set('collapsed', false);
      }
    }
  }).reopenClass({
    positionalParams: ['legacyType']
  });
});