define('postedin/components/content-request-type-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      selectContentType: function selectContentType(value) {
        this.set('value', value);
      }
    }
  });
});