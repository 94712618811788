define('postedin/services/project-labels', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'project-label',
    update: function update(label, name, color) {
      var _this = this;

      var data = {
        name: name,
        color: color
      };

      return this.put('project-labels/' + label.id, data).then(function (record) {
        return _this.push(record);
      });
    },
    remove: function remove(label) {
      var _this2 = this;

      return this.delete('project-labels/' + label.id).then(function () {
        _this2.removeObject(label.id);
      });
    }
  });
});