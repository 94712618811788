define('postedin/services/images', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'image',
    create: function create(data) {
      var _this = this;

      var hash = {
        contentType: false,
        data: data,
        cache: false,
        processData: false,
        dataType: 'json'
      };

      return this.post('images', data, hash).then(function (record) {
        return _this.push(record);
      });
    }
  });
});