define('postedin/routes/admin-projects/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    organizations: Ember.inject.service(),
    model: function model() {
      return this.get('organizations').allWithProjects();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var projects = [];
      model.forEach(function (organization) {
        organization.projects.forEach(function (project) {
          projects.push(project);
        });
      });
      controller.set('projects', projects);
      controller.set('filter', {
        warned: false,
        suspended: false
      });
    }
  });
});