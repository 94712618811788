define('postedin/components/audit-log', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var AuditLogComponent = Ember.Component.extend({
    i18n: Ember.inject.service(),
    showChanges: false,
    showDetails: false,
    showRequestLog: false,
    details: Ember.computed('auditLog.newValue', 'auditLog.oldValue', function () {
      if (this.get('auditLog.changes')) {
        return false;
      }

      var details = this.get('auditLog.newValue') || this.get('auditLog.oldValue');

      if (!details) {
        return false;
      }

      var type = this.get('auditLog.type').split('.')[0];
      var transformMethod = this['transform' + type.classify() + 'Details'];
      if (transformMethod) {
        return transformMethod.call(this, details);
      }

      return this.transformDetails(details);
    }),
    requestLog: Ember.computed('auditLog.requestLog', function () {
      var _this = this;

      var requestLog = this.get('auditLog.requestLog');
      return Object.keys(requestLog).map(function (attribute) {
        var value = _this.valueToHuman(requestLog[attribute]);

        return { attribute: attribute, value: value };
      });
    }),
    valueToHuman: function valueToHuman(value) {
      var _this2 = this;

      var translate = function translate(k) {
        return _this2.get('i18n').t(k);
      };

      if (value === true) {
        return translate('audit-log.yes');
      }

      if (value === false) {
        return translate('audit-log.no');
      }

      if (Ember.isEmpty(value)) {
        return '<span class="small muted">--</span>';
      }

      if (Ember.isArray(value)) {
        return value.map(function (v) {
          return _this2.valueToHuman(v);
        }).join('<hr>');
      }

      if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
        return Object.keys(value).map(function (attr) {
          return '\n          <dl class="audit-log__detail-list audit-log__detail-list--indent">\n            <dt class="audit-log__detail-list-label">' + attr + '</dt>\n            <dd class="audit-log__detail-list-value">' + _this2.valueToHuman(value[attr]) + '</dd>\n          </dl>\n        ';
        }).join('');
      }

      return value;
    },
    transformDetails: function transformDetails(details) {
      var _this3 = this;

      var filter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var attributes = Object.keys(details);
      if (filter) {
        attributes = attributes.filter(filter);
      }

      return attributes.map(function (attribute) {
        var value = _this3.valueToHuman(details[attribute]);

        return { attribute: attribute, value: value };
      });
    },
    transformContentRequestDetails: function transformContentRequestDetails(details) {
      return this.transformDetails(details, function (attr) {
        var value = details[attr];

        if (Ember.$.isNumeric(value) && !parseInt(value)) {
          return false;
        }

        if (Ember.isBlank(details[attr])) {
          return false;
        }

        return details[attr];
      });
    },

    actions: {
      toggleShowChanges: function toggleShowChanges() {
        this.set('showDetails', false);
        this.set('showRequestLog', false);
        this.toggleProperty('showChanges');
      },
      toggleShowDetails: function toggleShowDetails() {
        this.set('showChanges', false);
        this.set('showRequestLog', false);
        this.toggleProperty('showDetails');
      },
      toggleShowRequestLog: function toggleShowRequestLog() {
        this.set('showChanges', false);
        this.set('showDetails', false);
        this.toggleProperty('showRequestLog');
      }
    }
  });

  AuditLogComponent.reopenClass({
    positionalParams: ['auditLog']
  });

  exports.default = AuditLogComponent;
});