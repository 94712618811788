define('postedin/templates/icomoon', ['exports', 'dev/templates/icomoon'], function (exports, _icomoon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _icomoon.default;
    }
  });
});