define('postedin/components/trashed-resource-table', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_uiPromise.default, {
    prepareResourceFields: Ember.on('init', Ember.observer('resources.@each.updatedAt', 'resources.@each.deletedAt', 'fields.length', function () {
      var _this = this;

      this.get('resources').forEach(function (resource) {
        resource.set('_name', resource._debugContainerKey.split(':')[1]);

        resource.set('_fields', _this.get('fields').map(function (field) {
          var type = void 0;

          var _field$split = field.split(':');

          var _field$split2 = _slicedToArray(_field$split, 2);

          field = _field$split2[0];
          type = _field$split2[1];

          if (!type) {
            type = 'text';
          }

          var value = resource.get(field);

          return { field: field, type: type, value: value };
        }));
      });
    })),
    actions: {
      toggleRestore: function toggleRestore(resource) {
        resource.toggleProperty('_restoring');
      },
      restore: function restore(resource) {
        var _this2 = this;

        if (typeof resource.restore !== 'function') {
          throw new Error('Resource has no "restore" method.');
        }

        this.uiPromise(resource.restore(), 'restoreState', resource).then(function (updatedResource) {
          _this2.sendAction('restoredAction', updatedResource);

          _this2.get('resources').removeObject(resource);
        });
      }
    }
  }).reopenClass({
    positionalParams: ['resources']
  });
});