define('postedin/components/project-select', ['exports', 'project-components/components/project-select'], function (exports, _projectSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _projectSelect.default;
    }
  });
});