define('postedin/components/status-badge', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['status-badge'],
    tagName: 'span'
  });
});