define('postedin/helpers/math', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.math = math;
  function math(params /* , hash*/) {

    var first = params[0];
    var last = params[2];
    var operator = params[1];

    if (params.length !== 3) {
      return 'Math helper requires exactly 3 parameters.';
    }

    switch (operator) {
      case '+':
        return first + last;

      case '-':
        return first - last;

      case '*':
        return first * last;

      case '/':
        if (last === 0) {
          return 'Can\'t divide by zero';
        }
        return first / last;

      case '^':
        return Math.pow(first, last);

      case '%':
        if (last === 0) {
          return first;
        }

        return first / last * 100;

      default:
        return '';
    }
  }

  exports.default = Ember.Helper.helper(math);
});