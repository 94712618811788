define('postedin/controllers/admin/beta-users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'search', 'locality', 'suspended', 'deleted', { excludeActive: 'exclude-active' }, { bustParam: '_' }],
    search: '',
    locality: null,
    excludeActive: null,
    suspended: null,
    deleted: null,
    bustParam: null,
    page: null,

    usersWithStatus: Ember.computed('users.length', function () {
      return this.get('users').map(function (user) {
        if (user.deletedAt) {
          user.set('status', 'deleted');
        } else if (user.suspendedAt) {
          user.set('status', 'suspended');
        } else {
          user.set('status', 'active');
        }

        return user;
      });
    }),
    showUserStatus: Ember.computed('suspended', 'deleted', function () {
      return this.get('suspended') || this.get('deleted');
    })
  });
});