define('postedin/models/project-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Object.extend({
    i18n: Ember.inject.service(),
    projectImages: Ember.inject.service(),
    parsedLicenses: Ember.computed('licenses', function () {
      var _this = this;

      var licenses = Object.keys(this.get('licenses')).filter(function (license) {
        return license !== 'stockPhotos';
      }).map(function (license) {
        return _this.get('i18n').t('license.' + license.decamelize());
      });

      if (this.get('licenses.stockPhotos')) {
        var stockPhotoSiteNames = {
          'fotolia': 'Fotolia',
          'iStock': 'iStock',
          'shutterStock': 'ShutterStock',
          'gettyImages': 'Getty Images'
        };

        var stockPhotos = Object.keys(this.get('stockPhotoSites')).map(function (site) {
          return stockPhotoSiteNames[site];
        });

        licenses = licenses.concat(stockPhotos);
      }

      return licenses;
    }),
    hasLicenses: Ember.computed('licenses', function () {
      var licenses = this.get('licenses');
      for (var license in licenses) {
        if (licenses.hasOwnProperty(license)) {
          if (licenses[license]) {
            return true;
          }
        }
      }

      return false;
    }),
    dimensions: Ember.computed('width', 'height', {
      get: function get() {
        var dimensions = '';
        var width = parseInt(this.get('width'));
        var height = parseInt(this.get('height'));

        if (width || height) {
          if (width) {
            dimensions += width;
          } else {
            dimensions = '0';
          }

          dimensions += 'x';

          if (height) {
            dimensions += height;
          } else {
            dimensions += '0';
          }
        }

        return dimensions;
      },
      set: function set(param, value) {
        var _value$split = value.split('x'),
            _value$split2 = _slicedToArray(_value$split, 2),
            width = _value$split2[0],
            height = _value$split2[1];

        this.set('width', width);
        this.set('height', height);

        return value;
      }
    }),
    dimensionsold: Ember.computed('width', 'height', function () {
      var dimensions = '';
      var width = parseInt(this.get('width'));
      var height = parseInt(this.get('height'));

      if (width || height) {
        if (width) {
          dimensions += width;
        } else {
          dimensions = '0';
        }

        dimensions += 'x';

        if (height) {
          dimensions += height;
        } else {
          dimensions += '0';
        }
      }

      return dimensions;
    }),
    update: function update(name, licenses, stockPhotoSites, dimensions, additionalInformation, assignmentTypeIds) {
      return this.get('projectImages').update(this, name, licenses, stockPhotoSites, dimensions, additionalInformation, assignmentTypeIds);
    },
    remove: function remove() {
      return this.get('projectImages').remove(this);
    }
  });
});