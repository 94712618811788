define('postedin/components/content-request-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span'
  }).reopenClass({
    positionalParams: ['contentRequest']
  });
});