define('postedin/services/files', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    create: function create(data) {
      var hash = {
        contentType: false,
        data: data,
        cache: false,
        processData: false,
        dataType: 'json'
      };
      return this.post('/api/files', data, hash).then(function (record) {
        return record;
      });
    }
  });
});