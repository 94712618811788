define('postedin/components/image-upload', ['exports', 'postedin/mixins/validator'], function (exports, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_validator.default, {
    features: Ember.inject.service(),
    errors: {},
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    licenseTypes: Ember.computed('features', function () {
      return this.get('features.licenseTypes');
    }),
    stockPhotoSites: Ember.computed('features', function () {
      return this.get('features.stockPhotoSites');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('image.license')) {
        this.set('image.license', {});
      }

      this.setupErrorClearingObservers('errors', ['type', 'source']);
    },

    showErrorsDidChange: Ember.observer('showErrors', function () {
      if (this.get('showErrors')) {
        var image = this.get('image');

        this.validatorCheck(image, {
          license: {
            type: ['required', { in: this.get('licenseTypes').join(',') }],
            source: [{ required_unless: ['license.type', 'original'] }, 'url']
          }
        });
      }
    }),
    actions: {
      sendRemove: function sendRemove(image) {
        this.sendAction('onRemove', image);
      },
      setLicenseType: function setLicenseType(type) {
        var license = { type: type };
        var image = this.get('image');

        if (type == 'original') {
          Ember.set(license, 'source', '');
        }

        Ember.set(image, 'license', license);
      }
    }
  }).reopenClass({
    positionalParams: ['image']
  });
});