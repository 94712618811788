define('postedin/models/trashed-organization', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    organizations: Ember.inject.service(),
    canRestore: Ember.computed.bool('deletedAt'),
    restore: function restore() {
      return this.get('organizations').restore(this);
    }
  });
});