define('postedin/helpers/snake-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.snakeCase = snakeCase;
  function snakeCase(strings) {
    return strings.join('').underscore();
  }

  exports.default = Ember.Helper.helper(snakeCase);
});