define('postedin/models/release-note', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    releaseNotes: Ember.inject.service(),
    update: function update(title, description) {
      return this.get('releaseNotes').update(this, title, description);
    },
    remove: function remove() {
      return this.get('releaseNotes').remove(this);
    }
  });
});