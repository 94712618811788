define('postedin/routes/editors/faq/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    faq: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        model: this.get('faq').findById(params.id),
        categories: this.get('faq').findCategories()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash);

      var updates = hash.model.updatesHash();
      updates.category = hash.model.category;

      controller.set('model', hash.model);
      controller.set('updates', updates);
      controller.set('categories', hash.categories);

      controller.set('type', hash.model.category.type);
    }
  });
});