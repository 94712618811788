define('postedin/helpers/seconds-to-human-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          seconds = _ref2[0];

      var units = {
        week: 7 * 24 * 3600,
        day: 24 * 3600,
        hour: 3600,
        minute: 60,
        second: 1
      };

      if (!seconds || !Math.round(seconds)) {
        return '0 ' + this.get('i18n').t('seconds');
      }

      var formatted = [];
      for (var name in units) {
        if (!units.hasOwnProperty(name)) {
          continue;
        }

        var divisor = units[name];
        var divided = Math.floor(seconds / divisor);
        if (divided) {
          if (Math.abs(divided) > 1) {
            name += 's';
          }

          name = this.get('i18n').t(name);
          formatted.push(divided + ' ' + name);

          seconds -= divided * divisor;
        }
      }

      return formatted.join(', ');
    }
  });
});