define('postedin/controllers/account/password', ['exports', 'postedin/mixins/validation-rules'], function (exports, _validationRules) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationRules.default, {
    users: Ember.inject.service(),
    session: Ember.inject.service(),
    app: Ember.inject.service(),
    emailErrors: {},
    errors: {},
    observePassword: function () {
      this.set('errors.passwordRequired', false);
      this.set('errors.incorrectPassword', false);
    }.observes('password'),
    observeNewPassword: function () {
      this.set('errors.passwordTooShort', false);
      this.set('errors.newPasswordRequired', false);
      this.set('errors.newPasswordsDontMatch', false);
    }.observes('newPassword'),
    observeNewPasswordRepeated: function () {
      this.set('errors.newPasswordsDontMatch', false);
    }.observes('newPasswordRepeated'),
    actions: {
      savePassword: function savePassword() {
        var _this = this;

        var password = this.get('password');
        var newPassword = this.get('newPassword');
        var newPasswordRepeated = this.get('newPasswordRepeated');
        var valid = true;

        if (!password) {
          this.set('errors.passwordRequired', true);
          valid = false;
        }

        if (!newPassword) {
          this.set('errors.newPasswordRequired', true);
          valid = false;
        } else if (newPassword.length < 6) {
          this.set('errors.passwordTooShort', true);
          valid = false;
        }

        if (valid && newPassword !== newPasswordRepeated) {
          this.set('errors.newPasswordsDontMatch', true);
          valid = false;
        }

        if (!valid) {
          return;
        }

        this.set('error', false);
        this.set('saving', true);
        this.get('users').updatePassword(this.get('session.user'), { password: password, newPassword: newPassword }).then(function () {
          _this.set('saving', false);
          _this.set('saved', true);
          Ember.run.later(function () {
            return _this.set('saved', false);
          }, 1000);
          _this.set('password', '');
          _this.set('newPassword', '');
          _this.set('newPasswordRepeated', '');
        }, function (respond) {
          _this.set('saving', false);
          if (respond.payload.status === 422) {
            if (respond.payload.data.error && respond.payload.data.error.code === 'IncorrectPassword') {
              _this.set('errors.incorrectPassword', true);
            }
          } else {
            _this.set('error', true);
          }
        });
      }
    }
  });
});