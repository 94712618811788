define('postedin/components/client-transactions-linked', ['exports', 'master-accounts/components/client-transactions-linked'], function (exports, _clientTransactionsLinked) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clientTransactionsLinked.default;
    }
  });
});