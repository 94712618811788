define('postedin/components/pikaday-duedate', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    days: Ember.inject.service(),
    tagName: 'div',
    firstRender: true,
    disabledDates: [],
    fullDates: [],

    // We just load the current month + 2 for holidays and capacity due to these hacks
    setupPikaday: Ember.on('didRender', function () {
      var _this = this;

      if (this.get('firstRender')) {
        this.set('loading', true);

        Ember.RSVP.all([this.get('days').find(), this.get('days').find((0, _moment.default)().add(1, 'month').format('YYYY-MM')), this.get('days').find((0, _moment.default)().add(2, 'months').format('YYYY-MM'))]).then(function (months) {
          months.forEach(function (month) {
            month.forEach(function (day) {
              var date = (0, _moment.default)(day.id, 'YYYY-MM-DD');

              if (day.get('holiday')) {
                _this.get('disabledDates').pushObject(day.id);
              } else if (_this.get('contentRequest') && !([0, 6].includes(date.day()) || (0, _moment.default)().isAfter(date))) {
                var type = _this.get('contentRequest.type.name');
                if (type === 'legacy_article') {
                  type = 'article';
                }

                var taken = day.get('contentRequestCapacityTaken.' + type);
                var capacity = day.get('contentRequestCapacity.' + type);

                if (taken >= capacity) {
                  _this.get('fullDates').pushObject(day.id);
                }
              }
            });
          });

          _this.set('loading', false);
        });

        this.set('firstRender', false);
      }
    })
  });
});