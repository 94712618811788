define('postedin/controllers/editors/faq-categories/edit', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    faq: Ember.inject.service(),
    categoryTypes: ['private', 'public', 'content'],
    validate: function validate(data) {
      var rules = {
        name: 'required',
        active: 'required',
        type: 'required|in:private,public,content'
      };

      return this.validatorCheck(data, rules);
    },

    actions: {
      saveFaqCategory: function saveFaqCategory(category, updates) {
        var _this = this;

        if (this.validate(category)) {
          this.uiPromise(category.update(updates.name, updates.active, updates.type), 'saveFaqCategory').then(function () {
            _this.transitionToRoute('editors.faq-categories.show', category.id);
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});