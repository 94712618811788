define('postedin/initializers/session', ['exports', 'ember-simple-auth/services/session', 'postedin/config/environment', 'moment'], function (exports, _session, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var gapiLoaded = false;

  exports.default = {
    name: 'session',
    before: 'ember-simple-auth',
    initialize: function initialize(application) {
      _session.default.reopen({
        localState: Ember.inject.service(),
        user: false,
        isAuthenticatedDidChange: Ember.observer('isAuthenticated', function () {
          this.loadSessionData();
        }),
        reload: function reload() {
          this.loadSessionData();

          return this.get('loadingSessionPromise');
        },
        loadSessionData: function loadSessionData() {
          var _this = this,
              _arguments = arguments;

          if (this.get('isAuthenticated')) {
            this.set('loadingSessionPromise', new Ember.RSVP.Promise(function (resolve, reject) {
              Ember.$.ajax({
                url: '/api/session',
                method: 'get',
                beforeSend: function beforeSend(xhr) {
                  _this.authorize('authorizer:oauth2', function (headerName, headerValue) {
                    xhr.setRequestHeader(headerName, headerValue);
                  });

                  if (_this.get('localState.impersonating')) {
                    xhr.setRequestHeader('impersonating', _this.get('localState.impersonating'));
                  }

                  xhr.setRequestHeader('Time-Zone', _moment.default.tz.guess());
                },
                success: function success(session) {
                  if (_environment.default.build === 'admin' && !session.user.isAdmin) {
                    _this.invalidate();
                    reject();

                    return;
                  }

                  _this.set('user', application.__container__.factoryFor('model:user').create(session.user));

                  if (session.user.isAdmin && !gapiLoaded) {
                    _this.setupGoogleApi();
                  }

                  Object.keys(session).forEach(function (key) {
                    if (key !== 'user') {
                      _this.set(key, session[key]);
                    }
                  });

                  resolve(_this);
                },
                error: function error(response) {
                  if (response.status === 401) {
                    _this.invalidate();
                  }

                  reject.apply(undefined, _arguments);
                }
              });
            }));
          }
        },
        setupGoogleApi: function setupGoogleApi() {
          Ember.run.scheduleOnce('afterRender', function () {
            window.gapi = window.gapi || {};
            window.gapi.analytics = {
              q: [],
              ready: function ready(cb) {
                this.q.push(cb);
              }
            };
            Ember.$.getScript('https://apis.google.com/js/platform.js');
          });
        }
      });
    }
  };
});