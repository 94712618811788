define('postedin/models/content-article-revision', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    router: Ember.inject.service('-routing'),
    contentArticleRevisions: Ember.inject.service(),
    i18n: Ember.inject.service(),
    resolvedAnnotations: Ember.computed('annotations.@each.resolvedAt', function () {
      if (!this.get('annotations')) {
        return 0;
      }

      return this.get('annotations').filter(function (a) {
        return a.get('resolvedAt');
      }).length;
    }),
    approvedComment: Ember.computed('comments.length', function () {
      if (!this.get('comments')) {
        return false;
      }

      var comments = this.get('comments').filter(function (comment) {
        return comment.extra && comment.extra.approved;
      });

      return comments.length ? comments[0] : false;
    }),
    rejectedComment: Ember.computed('comments.length', function () {
      if (!this.get('comments')) {
        return false;
      }

      var comments = this.get('comments').filter(function (comment) {
        return comment.extra && comment.extra.rejected;
      });

      return comments.length ? comments[0] : false;
    }),
    annotatableHtml: Ember.computed('metaDescription', 'title', 'body', function () {
      var html = '';

      if (this.get('metaDescription')) {
        html += '<h5>' + this.get('i18n').t('meta_description') + '</h5><blockquote>' + this.get('metaDescription') + '</blockquote>';
      }

      html += '<h1>' + this.get('title') + '</h1>' + this.get('body');

      return html;
    }),
    words: Ember.computed('body', function () {
      return this.get('body').replace(/[–,;.:!¡¿\-?()]/g, '') // remove punctuation
      .replace(/<[^>]+>/g, ' ') // convert tags into whitespaces
      .split(/\s/) // split across whitespaces
      .filter(function (word) {
        return word.length;
      }) // remove empty keys
      .length;
    }),
    transitionToRoute: function transitionToRoute() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.get('router').transitionTo('content-article-revision-permalink', [this.id], queryParams);
    },
    permalinkUrl: function permalinkUrl() {
      return this.get('router').generateURL('content-article-revision-permalink', [this.id]);
    },
    reload: function reload() {
      return this.get('contentArticleRevisions').find(this.id);
    },
    resetAutoConclude: function resetAutoConclude() {
      return this.get('contentArticleRevisions').resetAutoConclude(this);
    },
    disableAutoConclude: function disableAutoConclude() {
      return this.get('contentArticleRevisions').disableAutoConclude(this);
    },
    publications: function publications() {
      return this.get('contentArticleRevisions').publications(this);
    },
    update: function update(title, body, metaDescription, uploads, attachments) {
      return this.get('contentArticleRevisions').update(this, title, body, metaDescription, uploads, attachments);
    },
    adminUpdate: function adminUpdate(updates) {
      return this.get('contentArticleRevisions').adminUpdate(this, updates);
    },
    approve: function approve(comment, rating, attachments) {
      return this.get('contentArticleRevisions').approve(this, comment, rating, attachments);
    },
    reject: function reject(comment, attachments, revisionRequested) {
      return this.get('contentArticleRevisions').reject(this, comment, attachments, revisionRequested);
    },
    requestReview: function requestReview(reviewers) {
      return this.get('contentArticleRevisions').requestReview(this, reviewers);
    },
    comment: function comment(_comment, attachments) {
      return this.get('contentArticleRevisions').comment(this, _comment, attachments);
    },
    annotate: function annotate(comment, text, location) {
      return this.get('contentArticleRevisions').annotate(this, comment, text, location);
    },
    flagAsPublished: function flagAsPublished(comment) {
      return this.get('contentArticleRevisions').flagAsPublished(this, comment);
    },
    addUpload: function addUpload(upload) {
      return this.get('contentArticleRevisions').addUpload(this, upload);
    }
  });
});