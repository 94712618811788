define('postedin/models/invoice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    invoices: Ember.inject.service(),
    updateInvoiceNumber: function updateInvoiceNumber(number) {
      return this.get('invoices').updateInvoiceNumber(this, number);
    },
    reject: function reject(reason) {
      return this.get('invoices').reject(this, reason);
    },
    resendEmail: function resendEmail() {
      return this.get('invoices').resendEmail(this);
    },
    setAsPaid: function setAsPaid(paypalTransactionId) {
      return this.get('invoices').setAsPaid(this, paypalTransactionId);
    },
    downloadPdf: function downloadPdf() {
      return this.get('invoices').downloadPdf(this);
    }
  });
});