define('postedin/controllers/admin-release-notes/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    releaseNotes: Ember.inject.service(),
    actions: {
      closeConfirmationModal: function closeConfirmationModal() {
        this.set('confirmationModal', false);
      },
      confirmReleaseNote: function confirmReleaseNote() {
        this.set('confirmationModal', true);
      },
      createReleaseNote: function createReleaseNote() {
        var _this = this;

        var releaseNote = this.get('releaseNote');

        this.set('creatingReleaseNote', true);
        this.get('releaseNotes').create(releaseNote).then(function () {
          _this.set('creatingReleaseNote', false);
          _this.transitionToRoute('admin-release-notes');
        }, function () {
          _this.set('creatingReleaseNote', false);
          _this.set('creatingError', true);
          _this.set('confirmationModal', false);
        });
      }
    }
  });
});