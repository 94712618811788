define('postedin/components/user-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserNameComponent = Ember.Component.extend({
    classNames: ['user-name'],
    session: Ember.inject.service(),
    showMenu: Ember.computed('session.user.isAdmin', 'user.id', function () {
      if (!this.get('session.user.isAdmin')) {
        return false;
      }

      if (this.get('user.id') === this.get('session.user.id')) {
        return false;
      }

      return true;
    }),
    actions: {
      impersonate: function impersonate(user) {
        user.impersonate().then(function () {
          window.location.reload();
        });
      }
    }
  });

  UserNameComponent.reopenClass({
    positionalParams: ['user']
  });

  exports.default = UserNameComponent;
});