define('postedin/components/admin-sortable-th', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    sortedClass: function () {
      return this.get('meta.sort.' + this.get('sort'));
    }.property('meta.sort', 'sort'),
    sortDescending: function () {
      return this.get('meta.sort.' + this.get('sort')) === 'ascending' ? 1 : 0;
    }.property('meta.sort', 'sort')
  });
});