define('postedin/controllers/admin/release-notes/create', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    releaseNotes: Ember.inject.service(),
    updates: Ember.computed.alias('model'),
    validate: function validate(data) {
      var rules = {
        title: 'required',
        description: 'required'
      };

      return this.validatorCheck(data, rules);
    },

    actions: {
      saveReleaseNote: function saveReleaseNote(note) {
        var _this = this;

        if (this.validate(note)) {
          this.uiPromise(this.get('releaseNotes').create(note.get('title'), note.get('description')), 'saveReleaseNote').then(function (note) {
            _this.transitionToRoute('admin.release-notes.show', note.id);
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});