define('postedin/helpers/stock-photo-site', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stockPhotoSite = stockPhotoSite;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function stockPhotoSite(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        site = _ref2[0];

    switch (site) {
      case 'fotolia':
        return 'Fotolia <span class="muted">&mdash;</span> <a href="http://www.fotolia.com" class="muted" target="_blank">fotolia.com <i class="icon-new-tab2"></i></a>';
      case 'iStock':
        return 'iStock <span class="muted">&mdash;</span> <a href="http://www.istockphoto.com" class="muted" target="_blank">istockphoto.com <i class="icon-new-tab2"></i></a>';
      case 'shutterStock':
        return 'ShutterStock <span class="muted">&mdash;</span> <a href="http://www.shutterstock.com" class="muted" target="_blank">shutterstock.com <i class="icon-new-tab2"></i></a>';
      case 'gettyImages':
        return 'Getty Images <span class="muted">&mdash;</span> <a href="http://www.gettyimages.com/" class="muted" target="_blank">gettyimages.com <i class="icon-new-tab2"></i></a>';
    }

    return '';
  }

  exports.default = Ember.Helper.helper(stockPhotoSite);
});