define('postedin/components/wizard-bar-step', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var WizardBarStepComponent = Ember.Component.extend({
    tagName: 'li',
    classNames: 'wizard-bar__step',
    willRender: function willRender() {
      if (!this[Ember.Component.HAS_BLOCK] && this.get('params') && this.get('params').length) {
        this.set('label', this.get('params')[0]);
      }
    }
  });

  WizardBarStepComponent.reopenClass({
    positionalParams: 'params'
  });

  exports.default = WizardBarStepComponent;
});