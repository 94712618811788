define('postedin/controllers/suggestions/show/edit', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    i18n: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('errors', Ember.Object.create({}));
      this.setupErrorClearingObservers('updates', [
      // 'summary', 'goal', 'focusPoints',
      'summary', 'focusPoints', 'sources']);
    },

    froalaOptions: Ember.computed('i18n', function () {
      return {
        editorClass: '',
        heightMin: 70,
        toolbarSticky: false,
        toolbarButtons: ['bold', 'italic', '|', 'formatUL', 'formatOL', '|', 'insertLink'],
        charCounterCount: true,
        pluginsEnabled: ['entities', 'lineBreaker', 'link', 'lists', 'url', 'charCounter'],
        pasteAllowedStyleProps: [],
        placeholderText: this.get('i18n').t('request-content.focus-points.placeholder')
      };
    }),
    validate: function validate(data) {
      data.summaryLength = this.get('summaryLength');

      return this.validatorCheck(data, {
        summary: 'required',
        // goal: 'required',
        focusPoints: 'required',
        sources: 'array',
        summaryLength: 'required|min:100'
      });
    },

    actions: {
      updateSuggestion: function updateSuggestion(suggestion, updates) {
        var _this = this;

        if (this.validate(updates)) {
          var data = Ember.getProperties(updates, ['summary', 'contentTypeGroup', 'keywords', 'focusPoints', 'sources']);

          Ember.set(data, 'keywords', data.keywords.map(function (k) {
            return k.name;
          }));

          var edit = suggestion.update(data);

          this.uiPromise(edit, 'updateSuggestion').then(function (suggestion) {
            _this.transitionToRoute('suggestions.show', suggestion.id);
          });
        }
      },
      updateCount: function updateCount(component) {
        this.set('summaryLength', component.get('editor.charCounter').count());
      }
    }
  });
});