define('postedin/controllers/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    users: Ember.inject.service(),
    editing: false,
    saving: false,
    errorSaving: false,
    resetPass: false,
    passMismatch: Ember.computed('model.password', 'model.passwordCheck', function () {
      var passw = this.get('model.password');
      var check = this.get('model.passwordCheck');

      if (passw === check) {
        return false;
      }

      return true;
    }),
    actions: {
      toggleEdit: function toggleEdit() {
        this.set('editing', !this.get('editing'));
      },
      saveUser: function saveUser() {
        var _this = this;

        this.set('saving', true);
        this.set('model.email', this.get('model.primaryEmail.email'));
        this.set('model.primaryEmail');
        this.get('users').update(this.get('model')).then(function () {
          _this.set('saving', false);
          _this.set('editing', false);
          _this.set('successSaving', true);
          _this.set('errorSaving', false);
        }, function () {
          _this.set('saving', false);
          _this.set('errorSaving', true);
        });
      }
    }
  });
});