define('postedin/components/content-request-article-extras-form', ['exports', 'postedin/mixins/ui-slider-hacks'], function (exports, _uiSliderHacks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    includeExtraPicturesWatcher: Ember.on('init', Ember.observer('includeExtraPictures', function () {
      if (this.get('includeExtraPictures')) {
        Ember.run.next(this, function () {
          _uiSliderHacks.default.wrapLabels();
        });
      } else {
        this.set('contentRequest.type.extraPictures', 0);
      }
    }))

  });
});