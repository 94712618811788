define('postedin/controllers/editors/faq/edit', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uiPromise.default, _validator.default, {
    faq: Ember.inject.service(),
    validate: function validate(data) {
      var rules = {
        question: 'required',
        answer: 'required',
        shortAnswer: 'required',
        active: 'required',
        category: 'required'
      };

      return this.validatorCheck(data, rules);
    },

    filteredCategories: Ember.computed('type', function () {
      var _this = this;

      if (this.get('type')) {
        if (this.get('updates.category.type') !== this.get('type')) {
          this.set('updates.category');
        }
      }

      return this.get('categories').filter(function (c) {
        return c.get('type') === _this.get('type');
      });
    }),
    actions: {
      saveQuestion: function saveQuestion(updates) {
        var _this2 = this;

        if (this.validate(updates)) {
          this.uiPromise(this.get('model').update(updates.question, updates.shortAnswer, updates.active, updates.category, updates.answer), 'saveQuestion').then(function (question) {
            _this2.transitionToRoute('editors.faq.show', question.id);
          });
        } else {
          this.scrollToFirstError();
        }
      }
    }
  });
});