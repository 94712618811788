define('postedin/components/client-transactions', ['exports', 'account-components/components/client-transactions'], function (exports, _clientTransactions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clientTransactions.default;
    }
  });
});