define('postedin/components/resource-table', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validation-rules'], function (exports, _uiPromise, _validationRules) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var ResourceTableComponent = Ember.Component.extend(_uiPromise.default, _validationRules.default, {
    editing: false,
    rules: [],
    tableHeadings: Ember.computed('headings.length', 'widths.length', function () {
      var _this = this;

      return this.getWithDefault('headings', []).map(function (heading, index) {
        return {
          name: heading,
          width: _this.getWithDefault('widths', [])[index]
        };
      });
    }),
    prepareResourceFields: Ember.on('init', Ember.observer('resources.@each.updatedAt', 'fields.length', 'rules.length', function () {
      var _this2 = this;

      this.get('resources').forEach(function (resource) {
        var debugContainerKey = Object.getPrototypeOf(resource)._debugContainerKey || resource._debugContainerKey;
        if (debugContainerKey) {
          resource.set('_name', debugContainerKey.split(':')[1]);
        }

        var fieldRules = {};
        _this2.get('rules').forEach(function (rule) {
          var _rule$split = rule.split(':'),
              _rule$split2 = _slicedToArray(_rule$split, 2),
              field = _rule$split2[0],
              rules = _rule$split2[1];

          fieldRules[field] = rules.split('|');
        });
        resource.set('_fields', _this2.get('fields').map(function (field) {
          var type = void 0;

          var _field$split = field.split(':');

          var _field$split2 = _slicedToArray(_field$split, 2);

          field = _field$split2[0];
          type = _field$split2[1];

          if (!type) {
            type = 'text';
          }

          var value = resource.get(field);

          return {
            field: field,
            type: type,
            value: value,
            rules: fieldRules[field] || []
          };
        }));
      });
    })),
    toggleEdit: function toggleEdit(resource) {
      if (resource.get('_editing')) {
        resource.set('_editing', false);
        this.set('editing', false);
        return;
      }

      if (this.get('editing')) {
        return;
      }

      this.set('editing', resource);
      resource.set('_editing', true);
      resource.get('_fields').forEach(function (data) {
        data.update = data.value;
        Ember.set(data, 'error', false);
      });
    },
    toggleDelete: function toggleDelete(resource) {
      if (resource.get('_deleting')) {
        resource.set('_deleting', false);
        this.set('editing', false);
        return;
      }

      if (this.get('editing')) {
        return;
      }

      this.set('editing', resource);
      resource.set('_deleting', true);
    },
    validateRequired: function validateRequired(value) {
      return !!value.trim();
    },
    validateUrl: function validateUrl(value) {
      return this.urlValidation(value);
    },
    validateRule: function validateRule(rule, value) {
      var method = 'validate' + rule.capitalize();
      if (typeof this[method] === 'function') {
        return this[method](value);
      }

      return true;
    },
    validate: function validate(resource) {
      var _this3 = this;

      var passed = true;
      resource.get('_fields').forEach(function (data) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = data.rules[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var rule = _step.value;

            if (!_this3.validateRule(rule, data.update)) {
              Ember.set(data, 'error', rule);
              passed = false;
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      });

      return passed;
    },

    actions: {
      toggleEdit: function toggleEdit(resource) {
        this.toggleEdit(resource);
      },
      toggleDelete: function toggleDelete(resource) {
        this.toggleDelete(resource);
      },
      saveEdit: function saveEdit(resource) {
        var _this4 = this;

        var dirty = resource.get('_fields').filter(function (data) {
          return data.value.trim() !== data.update.trim();
        });

        if (!dirty.length) {
          // let's just close it if nothing changed
          this.toggleEdit(resource);
          return;
        }

        if (!this.validate(resource)) {
          return;
        }

        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          var params = resource.get('_fields').map(function (data) {
            return data.update;
          });
          params.unshift(resource);
          params.unshift('editAction');
          params.push({
            resolve: resolve,
            reject: reject
          });
          _this4.sendAction.apply(_this4, _toConsumableArray(params));
        });

        this.uiPromise(promise, 'state', resource).then(function () {
          Ember.run.later(function () {
            _this4.toggleEdit(resource);
            _this4.resetUiPromise('state', resource);
          }, 500);
        });
      },
      remove: function remove(resource) {
        var _this5 = this;

        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this5.sendAction('deleteAction', resource, {
            resolve: resolve,
            reject: reject
          });
        });

        this.uiPromise(promise, 'deleteState', resource);
        promise.then(function () {
          _this5.set('editing', false);
          resource.set('_deleting', false);
        });
      }
    }
  });

  ResourceTableComponent.reopenClass({
    positionalParams: ['resources']
  });

  exports.default = ResourceTableComponent;
});