define('postedin/components/content-request-legacy-article/details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hasExtras: Ember.computed.or('contentRequest.type.{extraPictures,metaDescription}'),
    requiresMetaDescription: Ember.computed.or('contentRequest.type.{metaDescription,legacyContentType.includesMetaDescription}')
  }).reopenClass({
    positionalParams: ['contentRequest']
  });
});