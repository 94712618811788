define('postedin/mixins/selected-project', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    selectedProject: function selectedProject() /* model*/{
      return this.modelFor('project');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('project', this.selectedProject(model));
    }
  });
});