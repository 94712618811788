define('postedin/components/project-user-roles/custom', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      setAllRoles: function setAllRoles() {
        var checked = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        var only = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        this.sendAction('setAllRoles', checked, only);
      },
      setRole: function setRole(role, event) {
        this.sendAction('setRole', role, event);
      }
    }
  });
});