define('postedin/components/content-request-article/revision-header', ['exports', 'postedin/mixins/attachments', 'postedin/mixins/ui-promise', 'postedin/mixins/has-uploads', 'postedin/mixins/component-window-details'], function (exports, _attachments, _uiPromise, _hasUploads, _componentWindowDetails) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_attachments.default, _uiPromise.default, _componentWindowDetails.default, {
    tagName: 'div',
    classNames: ['toolbar'],
    session: Ember.inject.service(),
    approveRating: null,
    approveComment: '',
    rejectComment: '',
    rejectRevisionRequested: false,
    reviewers: [],
    isAdmin: Ember.computed('session', function () {
      return this.get('session.user.isAdmin');
    }),
    canCreateFinalRevision: Ember.computed.alias('contentRequest.type.canCreateFinalRevision'),
    hasFinalRevision: Ember.computed('revisions.@each.status', function () {
      return this.get('revisions').filter(function (r) {
        return r.status === 'final';
      }).length;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var Uploader = Ember.Object.extend(_hasUploads.default);
      this.set('approveUploader', Uploader.create({}));
      this.set('rejectUploader', Uploader.create({}));

      if (this.get('activeRevision.canViewReviews')) {
        this.set('reviewers', this.get('contentRequest.project').reviewers());
      }
    },


    lowApproveRating: Ember.computed('approveRating', function () {
      var rating = this.get('approveRating');
      return rating && rating < 3;
    }),
    isApproved: Ember.computed('activeRevision.status', function () {
      return this.get('activeRevision.status') === 'approved';
    }),
    isRejected: Ember.computed('activeRevision.status', function () {
      return this.get('activeRevision.status') === 'rejected';
    }),
    isPublished: Ember.computed('activeRevision.status', function () {
      return this.get('activeRevision.publishedAt');
    }),

    fixedActions: Ember.computed('windowDetails.updated', function () {
      var breakPoint = this.$().offset().top + this.$().outerHeight();

      return this.get('windowDetails.scrollPosition.top') >= breakPoint;
    }),

    showRevisionsDropdown: Ember.computed.gt('revisions.length', 1),

    showReviews: Ember.computed.or('activeRevision.canRequestReview', 'activeRevision.reviews.length'),
    pendingReviews: Ember.computed('activeRevision.reviews.@each.status', function () {
      return this.get('activeRevision.reviews').filter(function (r) {
        return r.status === 'pending';
      });
    }),

    userReview: Ember.computed('activeRevision.reviews.@each.user', function () {
      var _this = this;

      if (!this.get('activeRevision.reviews')) {
        return false;
      }

      var reviews = this.get('activeRevision.reviews').filter(function (review) {
        return review.get('user.id') === _this.get('session.user.id');
      });

      return reviews.length ? reviews[0] : false;
    }),
    userReviewCompleted: Ember.computed('userReview', function () {
      var userReview = this.get('userReview');
      if (userReview && userReview.get('status') !== 'pending') {
        return userReview;
      }

      return false;
    }),
    requestedReview: Ember.computed('userReview', function () {
      var userReview = this.get('userReview');
      if (userReview && userReview.get('status') === 'pending') {
        return userReview;
      }

      return false;
    }),
    disableRequestReviewForm: Ember.computed('toggleRequestReviewModal.waiting', 'requestReview.waiting', 'reviewers.length', function () {
      if (this.get('toggleRequestReviewModal.waiting') || this.get('requestReview.waiting')) {
        return true;
      }

      return !this.get('reviewers.length');
    }),
    disableRequestReviewCancel: Ember.computed.or('requestReview.waiting'),

    disabledApproveSubmit: Ember.computed('approve.waiting', 'approveRating', 'lowApproveRating', 'approveComment', 'approveUploader.isUploading', function () {
      if (this.get('approve.waiting')) {
        return true;
      }

      if (!this.get('approveRating')) {
        return true;
      }

      if (this.get('lowApproveRating') && !this.get('approveComment').trim()) {
        return true;
      }

      if (this.get('approveUploader.isUploading')) {
        return true;
      }

      return false;
    }),

    actions: {
      toggleApproveModal: function toggleApproveModal() {
        this.toggleProperty('showApproveModal');
      },
      toggleRejectModal: function toggleRejectModal() {
        var _this2 = this;

        this.toggleProperty('showRejectModal');

        if (this.get('showRejectModal')) {
          this.set('nextAvailableDay', false);
          this.uiPromise(this.get('contentRequest').loadNextDeadline(), 'availableDay').then(function (day) {
            _this2.set('nextAvailableDay', day.id);
          });
        }
      },
      toggleRejectRevisionRequested: function toggleRejectRevisionRequested() {
        this.toggleProperty('rejectRevisionRequested');
      },
      approve: function approve(comment, rating, attachments) {
        var _this3 = this;

        if (this.get('disabledApproveSubmit')) {
          return;
        }

        this.uiPromise(this.get('activeRevision').approve(comment, rating, attachments), 'approve').then(function () {
          _this3.set('showApproveModal', false);
          _this3.get('contentRequest').reload();
          _this3.get('contentRequest').get('type').loadRevisions();
        });
      },
      reject: function reject(comment, attachments, revisionRequested) {
        var _this4 = this;

        if (this.get('rejectUploader.isUploading')) {
          return;
        }

        this.uiPromise(this.get('activeRevision').reject(comment, attachments, revisionRequested), 'reject').then(function () {
          _this4.set('showRejectModal', false);
          _this4.get('contentRequest').reload();
          _this4.get('contentRequest').get('type').loadRevisions();
        });
      },
      requestReview: function requestReview(reviewer, statusName) {
        var _this5 = this;

        if (!reviewer || this.get('disableRequestReviewForm')) {
          return;
        }

        if (!statusName) {
          statusName = 'requestReview';
        }

        this.uiPromise(this.get('activeRevision').requestReview([reviewer]), reviewer.get('reviewRequestStatus')).then(function () {
          _this5.set('showRequestReviewModal', false);
        });
      },
      reviewJudged: function reviewJudged() {
        this.get('contentRequest').get('type').loadRevisions();
      }
    }
  });
});