define('postedin/components/project-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProjectNameComponent = Ember.Component.extend({
    tagName: 'span',
    classNames: ['project-name'],
    logoSize: 15,
    showOrganization: Ember.computed.and('organization', 'project.organization'),
    showLogo: Ember.computed('logo', function () {
      var logo = this.get('logo');
      if (typeof logo !== 'undefined') {
        return logo;
      }

      return true;
    }),
    showBalance: Ember.computed.and('project.account.id', 'balance')
  });

  ProjectNameComponent.reopenClass({
    positionalParams: ['project']
  });

  exports.default = ProjectNameComponent;
});