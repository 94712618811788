define('postedin/controllers/admin-faq/new-category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    faq: Ember.inject.service(),
    categoryTypes: ['private', 'public'],
    actions: {
      createCategory: function createCategory() {
        var _this = this;

        var category = this.get('category');

        this.set('creatingCategory', true);
        this.get('faq').createCategory(category).then(function () {
          _this.transitionToRoute('admin-faq.categories');
        }, function () {
          _this.set('creatingCategory', false);
          _this.set('creatingError', true);
        });
      }
    }
  });
});