define('postedin/components/admin-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['admin-bar'],
    users: Ember.inject.service(),
    actions: {
      impersonate: function impersonate(email) {
        var _this = this;

        this.set('impersonating', true);
        var reject = function reject() {
          _this.set('impersonating', false);
        };
        this.get('users').find().then(function (users) {
          users.forEach(function (user) {
            if (user.email === email) {
              user.impersonate().then(function () {
                window.location.reload();
              }, reject);
            }
          });
        }, reject);
      }
    }
  });
});