define('postedin/components/ui-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var SHOW_DELAY = 500;
  var HIDE_DELAY = 200;

  exports.default = Ember.Component.extend({
    uiPopup: Ember.inject.service(),
    classNames: [],
    componentName: null,
    $target: null,
    options: {},

    containerStyle: Ember.computed('$target', function () {
      var $target = this.get('$target');

      if (!$target) {
        return Ember.String.htmlSafe('');
      }

      var styles = new Map();

      styles.set('bottom', Ember.$(window).height() - $target.offset().top + 'px');
      styles.set('left', $target.offset().left + 'px');

      return Ember.String.htmlSafe([].concat(_toConsumableArray(styles)).map(function (style) {
        return style.join(':');
      }).join(';'));
    }),

    didInsertElement: function didInsertElement() {
      var uiPopup = this.get('uiPopup');

      uiPopup.on('show', this, 'showPopup');
      uiPopup.on('cancelShow', this, 'cancelShowPopup');
      uiPopup.on('removeOrphanedPopup', this, 'removeOrphanedPopup');
    },
    willDestroyElement: function willDestroyElement() {
      var uiPopup = this.get('uiPopup');

      uiPopup.off('show', this, 'showPopup');
      uiPopup.off('cancelShow', this, 'cancelShowPopup');
      uiPopup.off('removeOrphanedPopup', this, 'removeOrphanedPopup');
    },
    showPopup: function showPopup(target, componentName, options) {
      var _this = this;

      this.cancelShowPopup();

      this._showPopupDelay = Ember.run.later(function () {
        // we do another "next" here so we don't run into race conditions,
        // we need the original component to be completely removed correctly
        // so it runs events to clean things up
        _this.actuallyHidePopup();
        Ember.run.next(function () {
          return _this.actuallyShowPopup(target, componentName, options);
        });

        _this._showPopupDelay = null;
      }, SHOW_DELAY);
    },
    cancelShowPopup: function cancelShowPopup() {
      Ember.run.cancel(this._showPopupDelay);
    },
    actuallyShowPopup: function actuallyShowPopup(target, componentName, options) {
      var _this2 = this;

      var $target = Ember.$(target);

      this.setProperties({
        $target: $target,
        componentName: componentName,
        options: options
      });

      this._targetMouseEnter = function (e) {
        return _this2.cancelHidePopup(e);
      };
      this._targetMouseLeave = function (e) {
        return _this2.hidePopup(e);
      };

      $target.on('mouseenter', this._targetMouseEnter);
      $target.on('mouseleave', this._targetMouseLeave);

      Ember.run.next(function () {
        var $uiPopup = _this2.$('.ui-popup');

        if ($uiPopup.length) {
          $uiPopup.on('mouseenter', function (e) {
            return _this2.cancelHidePopup(e);
          });
          $uiPopup.on('mouseleave', function (e) {
            return _this2.hidePopup(e);
          });
        } else {
          _this2.hidePopup();
        }
      });
    },
    hidePopup: function hidePopup() {
      var _this3 = this;

      this.cancelHidePopup();

      this._hidePopupDelay = Ember.run.later(function () {
        _this3.actuallyHidePopup();

        _this3._hidePopupDelay = null;
      }, HIDE_DELAY);
    },
    cancelHidePopup: function cancelHidePopup() {
      Ember.run.cancel(this._hidePopupDelay);
    },
    actuallyHidePopup: function actuallyHidePopup() {
      var $target = this.get('$target');

      if ($target) {
        $target.off('mouseenter', this._targetMouseEnter);
        $target.off('mouseleave', this._targetMouseLeave);
      }

      this.setProperties({
        $target: null,
        componentName: null,
        options: {}
      });
    },
    removeOrphanedPopup: function removeOrphanedPopup() {
      var _this4 = this;

      Ember.run.next(function () {
        var $target = _this4.get('$target');

        if ($target && !$target.is(':visible')) {
          _this4.actuallyHidePopup();
        }
      });
    }
  });
});