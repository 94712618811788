define('postedin/models/faq-category', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    faq: Ember.inject.service(),
    update: function update(name, active, type) {
      return this.get('faq').updateCategory(this, name, active, type);
    },
    swapOrder: function swapOrder(_ref) {
      var displayOrder = _ref.displayOrder;

      return this.get('faq').swapCategoryOrder(this, displayOrder);
    }
  });
});