define('postedin/services/legacy-content-type-groups', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'legacy-content-type-group',
    all: function all() {
      var _this = this;

      return this.request('legacy-content-type-groups').then(function (response) {
        return _this.pushResponse(response);
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('legacy-content-type-groups/' + id).then(function (record) {
        return _this2.push(record);
      });
    },
    create: function create(name) {
      var _this3 = this;

      return this.post('legacy-content-type-groups', { name: name }).then(function (record) {
        return _this3.push(record);
      });
    }
  });
});