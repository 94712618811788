define('postedin/components/publisher-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PublisherIconComponent = Ember.Component.extend({
    tagName: 'i',
    classNames: 'publisher-icon',
    classNameBindings: ['iconClass'],
    iconClass: Ember.computed('publisher.type', function () {
      switch (this.get('publisher.type')) {
        case 'wordpress_xmlrpc':
          return 'icon-wordpress';
        case 'hubspot':
          return 'icon-hubspot';
      }
    })
  });

  PublisherIconComponent.reopenClass({
    positionalParams: ['publisher']
  });

  exports.default = PublisherIconComponent;
});