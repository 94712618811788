define('postedin/routes/beta-users/all', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    betaUsers: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      descending: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var page = Math.max(1, parseInt(params.page));
      params.all = 1;

      return this.get('betaUsers').find(params, page);
    },
    setupController: function setupController(controller, hash) {
      controller.set('model', hash.users);
      controller.set('meta', hash.meta);
      controller.set('newSearch', hash.meta.search || '');
    }
  });
});