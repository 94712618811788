define('postedin/routes/suggestions/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // model(params) {
    //   // if (params.duplicateId) {
    //   //   hash.duplicateId = params.duplicateId;
    //   //   hash.duplicate = this.get('contentSuggestions').findById(params.duplicateId);
    //   // }

    //   // return Ember.RSVP.hash(hash);
    // },
  });
});