define('postedin/helpers/t-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tStatus = tStatus;
  function tStatus(status) {
    if (status instanceof Array) {
      status = status[0];
    }

    if (!status) {
      return '';
    }

    return 'content.statuses.' + status;
  }

  exports.default = Ember.Helper.helper(tStatus);
});