define('postedin/routes/calendar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    contentRequests: Ember.inject.service(),
    days: Ember.inject.service(),
    queryParams: {
      projects: { refreshModel: true },
      labels: { refreshModel: true },
      statuses: { refreshModel: true },
      month: { refreshModel: true },
      week: { refreshModel: true },
      // because things get buggy with arrays we workaround by changing a timestamp which will be sent to
      // this as and cause things to refresh reliably
      bustParam: { refreshModel: true }
    },
    scrollToOnActivate: true,
    model: function model(params) {
      if (!params.week && !params.month) {
        params.week = (0, _moment.default)().format('YYYY-WW');
      }

      if (params.month && !(0, _moment.default)(params.month, 'YYYY-MM').isValid()) {
        params.month = (0, _moment.default)().format('YYYY-MM');
      }

      if (params.week && !(0, _moment.default)(params.week, 'YYYY-WW').isValid()) {
        params.week = (0, _moment.default)().format('YYYY-WW');
      }

      var monthDays = params.month ? params.month : (0, _moment.default)(params.week, 'YYYY-WW').format('YYYY-MM');

      return Ember.RSVP.hash({
        params: params,
        currentMonth: params.month,
        currentWeek: params.week,
        model: this.get('contentRequests').find({
          calendar: true,
          projects: params.projects,
          labels: params.labels,
          legacyContentTypeGroups: params.legacyContentTypeGroups,
          statuses: params.statuses,
          month: params.month,
          week: params.week
        }),
        monthDays: this.get('days').find(monthDays)
      });
    },
    setupController: function setupController(controller, hash) {
      var events = {};
      hash.model.contentRequests.forEach(function (contentRequest) {
        var dueDate = contentRequest.get('dueDate');
        var projectId = contentRequest.get('project.id');

        if (!events[dueDate]) {
          events[dueDate] = {
            start: dueDate,
            projects: {}
          };
        }

        if (!events[dueDate].projects[projectId]) {
          events[dueDate].projects[projectId] = {
            project: contentRequest.get('project'),
            statuses: {}
          };
        }

        if (!events[dueDate].projects[projectId].statuses[contentRequest.get('status')]) {
          events[dueDate].projects[projectId].statuses[contentRequest.get('status')] = [];
        }

        events[dueDate].projects[projectId].statuses[contentRequest.get('status')].push(contentRequest);
      });

      var holidays = [];
      var model = Object.keys(events).map(function (date) {
        events[date].projects = Object.keys(events[date].projects).map(function (p) {
          return events[date].projects[p];
        });

        return events[date];
      });

      hash.monthDays.forEach(function (day) {
        if (day.holiday) {
          holidays.push(day.id);
          model.push({
            id: 1000000 + day.id,
            title: 'Holiday',
            holiday: true,
            start: day.id
          });
        }
      });

      controller.setProperties(hash);
      this._super(controller, model);

      if (hash.params.month) {
        controller.set('view', 'month');
      }
      controller.set('events', events);
      controller.set('loading', false);
      controller.set('holidays', holidays);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('projects', []);
        controller.set('labels', []);
        controller.set('legacyContentTypeGroups', []);
        controller.set('statuses', []);
        controller.set('bustParam', null);
        controller.set('page', null);
        controller.set('month', null);
        controller.set('week', null);
        controller.set('loading', true);
      }
    },

    actions: {
      loading: function loading() {
        if (this.controller) {
          this.set('controller.loading', true);
        }
      }
    }
  });
});