define('postedin/helpers/explicit-query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.explicitQueryParams = explicitQueryParams;
  function explicitQueryParams(params, hash) {
    var values = Ember.assign({}, hash);
    values._explicitQueryParams = true;

    return Ember.Object.create({
      isQueryParams: true,
      values: values
    });
  }

  exports.default = Ember.Helper.helper(explicitQueryParams);
});