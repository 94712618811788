define('postedin/components/text-truncate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lines: 10,
    innerHeight: 0,
    showMore: true,
    height: Ember.computed('lineHeight', 'lines', function () {
      return parseInt(this.get('lineHeight')) * parseInt(this.get('lines'));
    }),

    didTruncate: Ember.computed('height', 'innerHeight', function () {
      return this.get('height') < this.get('innerHeight');
    }),

    didInsertElement: function didInsertElement() {
      this.set('lineHeight', this.$().height());
      this.set('ready', true);
    },
    didRender: function didRender() {
      this.calculateInnerHeight();
    },
    calculateInnerHeight: function calculateInnerHeight() {
      var children = this.$('.truncate').children();
      var innerHeight = 0;

      if (children.length > 1) {
        children.each(function (index, el) {
          innerHeight += Ember.$(el).height();
        });
      } else {
        innerHeight = Ember.$(children[0]).height();
      }

      this.set('innerHeight', innerHeight);
      this.set('shouldTruncate', innerHeight > this.get('height'));
    },


    truncateStyle: Ember.computed('shouldTruncate', function () {
      if (!this.get('shouldTruncate')) {
        return Ember.String.htmlSafe('');
      }

      var styles = ['height: ' + (this.get('height') - 1) + 'px'];

      return Ember.String.htmlSafe(styles.join('; '));
    }),

    actions: {
      showMore: function showMore() {
        this.setProperties({
          'height': this.get('innerHeight'),
          'didTruncate': false
        });
      }
    }
  });
});