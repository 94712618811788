define('postedin/routes/chile-payments/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    payments: Ember.inject.service(),
    model: function model() {
      return this.get('payments').find();
    }
  });
});