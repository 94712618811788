define('postedin/routes/admin/categories/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    categories: Ember.inject.service(),
    betaCategories: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        categories: this.get('categories').find(),
        betaCategories: this.get('betaCategories').all()
      });
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.categories);
      controller.set('newCategory', Ember.Object.create());
      controller.set('betaCategories', hash.betaCategories);
      controller.setupErrorClearingObservers('newCategory', ['name', 'betaCategoryId']);
    },

    actions: {
      refresh: function refresh() {
        return this.refresh();
      }
    }
  });
});