define('postedin/helpers/legacy-content-types-grouped', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.legacyContentTypesGrouped = legacyContentTypesGrouped;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function legacyContentTypesGrouped(types) {
    var flatten = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    var groups = {};

    types.forEach(function (type) {
      var key = type.group.toLowerCase();

      if (!groups[key]) {
        groups[key] = {
          groupName: type.group,
          types: []
        };
      }

      groups[key].types.push(type);
    });

    if (!flatten) {
      return Object.keys(groups).sort().map(function (group) {
        return groups[group];
      });
    }

    var first = true;
    var flat = [];
    Object.keys(groups).sort().forEach(function (group) {
      flat.push({
        isGroup: true,
        groupName: groups[group].groupName,
        isFirst: first,
        allHidden: !groups[group].types.filter(function (t) {
          return !t.hide;
        }).length
      });

      groups[group].types.forEach(function (type) {
        return flat.push(type);
      });

      first = false;
    });

    return flat;
  }

  exports.default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        types = _ref3[0];

    var flatten = _ref2.flatten;
    return legacyContentTypesGrouped(types, flatten);
  });
});