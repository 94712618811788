define('postedin/routes/admin/legacy-content-types/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    legacyContentTypes: Ember.inject.service(),
    model: function model() {
      return this.get('legacyContentTypes').all();
    }
  });
});