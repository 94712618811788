define('postedin/models/project-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    projectUsers: Ember.inject.service(),
    update: function update(roles) {
      return this.get('projectUsers').update(this, roles);
    },
    remove: function remove() {
      return this.get('projectUsers').remove(this);
    }
  });
});