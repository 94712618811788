define('postedin/components/content-request/goal-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    selectedGoal: Ember.computed.alias('value.firstObject'),
    actions: {
      setGoal: function setGoal(goal) {
        if (this.get('value.firstObject') !== goal) {
          this.set('value', [goal]);
        } else {
          this.set('value', []);
        }

        if (this.get('helpGoal')) {
          this.set('helpGoal', goal);
        }
      },
      setHelpGoal: function setHelpGoal(goal) {
        this.set('helpGoal', goal);
      }
    }
  });
});