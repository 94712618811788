define('postedin/mixins/hacky-move-inline-with-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    moveFormInlineWith: function moveFormInlineWith(selector, scrollTo) {
      Ember.run.later(function () {
        // FIXME: this controller should be a component for this kind of thing?
        var $form = Ember.$('#PeopleForm');
        if ($form.is(':hidden')) {
          $form.css('opacity', 0);
          return;
        }

        var $line = Ember.$('#PeopleFormLine');
        var $parent = $form.closest('.grid-row');
        var $selected = $parent.find(selector);

        if (!$selected.length) {
          return;
        }

        $parent.css({
          position: 'relative',
          marginBottom: 330
        });
        $form.css({
          position: 'absolute',
          top: $selected.offset().top - $parent.offset().top,
          minWidth: 350
        });
        $line.css({
          position: 'absolute',
          left: $selected.offset().left - $parent.offset().left + $selected.outerWidth(),
          top: $selected.offset().top - $parent.offset().top + 12,
          backgroundColor: '#309074',
          width: 60,
          height: 2
        });
        $form.css('opacity', 1);

        if (scrollTo) {
          Ember.run.later(function () {
            Ember.$('html, body').animate({
              scrollTop: $selected.offset().top - 10
            }, 100);
          });
        }
      });
    }
  });
});