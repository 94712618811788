define('postedin/templates/objects', ['exports', 'dev/templates/objects'], function (exports, _objects) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _objects.default;
    }
  });
});