define('postedin/services/publishers/wordpress-xmlrpc', ['exports', 'postedin/mixins/validator'], function (exports, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_validator.default, {
    validateForSendRules: function validateForSendRules() {
      return {
        postTitle: 'required',
        postAuthor: 'required'
      };
    },
    validateForSend: function validateForSend(data) {
      return this.validator(data, this.validateForSendRules(data)).passes();
    }
  });
});