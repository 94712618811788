define('postedin/helpers/currency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.currency = currency;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // http://stackoverflow.com/a/2901298/1262923
  function separateNumbers(number, separator) {
    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return parts.join('.');
  }

  function currency(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        amount = _ref2[0],
        currency = _ref2[1],
        onlyNumbers = _ref2[2];

    Ember.assert('Currency must be specified', currency);
    currency = currency.toUpperCase();
    amount = amount ? parseInt(amount) : 0;

    if (currency === 'CLP') {
      if (onlyNumbers) {
        return separateNumbers(amount, '.');
      } else {
        return 'CLP$ ' + separateNumbers(amount, '.');
      }
    }

    if (currency === 'USD') {
      // cents to dollars
      amount = (amount / 100).toFixed(2);
      if (onlyNumbers) {
        return separateNumbers(amount, ',');
      } else {
        return '$' + separateNumbers(amount, ',') + ' USD';
      }
    }

    return amount;
  }

  exports.default = Ember.Helper.helper(currency);
});