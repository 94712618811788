define('postedin/components/ui-promise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UiPromiseComponent = Ember.Component.extend({
    tagName: 'span',
    classNames: ['ui-promise'],
    i18n: Ember.inject.service(),
    showSuccess: Ember.computed('success', 'state.success', function () {
      return this.get('success') && this.get('state.success');
    }),
    showSpinner: Ember.computed('state.waiting', 'state.waitingAndSaved', 'spinnerOnly', function () {
      if (this.get('spinnerOnly')) {
        return this.get('state.waitingAndSaved');
      }

      return this.get('state.waiting');
    }),
    showError: Ember.computed('state.error', 'spinnerOnly', function () {
      if (this.get('spinnerOnly')) {
        return false;
      }

      return this.get('state.error');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('successText')) {
        this.set('successText', this.get('i18n').t('success'));
      }

      if (!this.get('savedText')) {
        this.set('savedText', this.get('i18n').t('saved'));
      }

      if (!this.get('errorText')) {
        this.set('errorText', this.get('i18n').t('error'));
      }
    }
  });

  UiPromiseComponent.reopenClass({
    positionalParams: ['state']
  });

  exports.default = UiPromiseComponent;
});