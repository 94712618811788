define('postedin/mixins/scrolling', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // No shame here: https://coderwall.com/p/tes2cw/binding-to-scroll-events-with-ember-js
    bindScrolling: function bindScrolling() {
      var onScroll = void 0;
      var _this = this;

      onScroll = function onScroll() {
        return _this.onScroll();
      };

      this.$(document).bind('touchmove', onScroll);
      this.$(window).bind('scroll', onScroll);
    },
    unbindScrolling: function unbindScrolling() {
      this.$(window).unbind('scroll');
      this.$(document).unbind('touchmove');
    }
  });
});