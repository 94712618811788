define('postedin/services/notes', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'note',
    find: function find(id) {
      var _this = this;

      return this.request('notes/' + id).then(function (record) {
        return _this.push(record);
      });
    }
  });
});