define('postedin/controllers/account/notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    users: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      updateNotificationSettings: function updateNotificationSettings() {
        var _this = this;

        this.set('error', false);
        this.set('saving', true);

        var data = this.getProperties('emailNotifications');

        this.get('users').updatePreferences(this.get('session.user'), data).then(function () {
          _this.get('session').loadSessionData();
          _this.set('saved', true);
          Ember.run.later(function () {
            return _this.set('saved', false);
          }, 2000);
        }, function () {
          _this.set('error', true);
        }).finally(function () {
          Ember.run.later(function () {
            return _this.set('saving', false);
          }, 2000);
        });
      }
    }
  });
});