define('postedin/components/modal-dialog-overlay', ['exports', 'ember-modal-dialog/components/modal-dialog-overlay'], function (exports, _modalDialogOverlay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalDialogOverlay.default.extend({
    overlayCloseOnclick: true,
    click: function click(event) {
      if (!this.get('overlayCloseOnclick')) {
        return;
      }

      this._super(event);
    }
  });
});