define('postedin/components/project-image-requirement', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProjectImageRequirementComponent = Ember.Component.extend({
    i18n: Ember.inject.service(),
    isContentRequestImageRequirement: Ember.computed.and('image.type', 'image.projectImage.id'),
    licenses: Ember.computed('image.licenses', function () {
      var _this = this;

      var licenses = Object.keys(this.get('image.licenses')).filter(function (license) {
        return license !== 'stockPhotos';
      }).map(function (license) {
        return _this.get('i18n').t('license.' + license.decamelize());
      });

      if (this.get('image.licenses.stockPhotos')) {
        var stockPhotoSiteNames = {
          'fotolia': 'Fotolia',
          'iStock': 'iStock',
          'shutterStock': 'ShutterStock',
          'gettyImages': 'Getty Images'
        };

        var stockPhotos = Object.keys(this.get('image.stockPhotoSites')).map(function (site) {
          return stockPhotoSiteNames[site];
        });

        licenses = licenses.concat(stockPhotos);
      }

      return licenses;
    }),
    hasLicenses: Ember.computed('image.licenses', function () {
      var licenses = this.get('image.licenses');
      for (var license in licenses) {
        if (licenses.hasOwnProperty(license)) {
          if (licenses[license]) {
            return true;
          }
        }
      }

      return false;
    })
  });

  ProjectImageRequirementComponent.reopenClass({
    positionalParams: ['image']
  });

  exports.default = ProjectImageRequirementComponent;
});