define('postedin/controllers/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    app: Ember.inject.service(),
    session: Ember.inject.service()
  });
});