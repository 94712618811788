define('postedin/routes/note-permalink', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    notes: Ember.inject.service(),
    model: function model(param) {
      return this.get('notes').find(param.id);
    },
    afterModel: function afterModel(model) {
      if (model.get('notableType') === 'App\\Publication') {
        this.transitionTo('content.show.revisions.show', model.get('notable.topic.id'), model.get('notable.revision'), {
          queryParams: {
            note: model.get('id')
          }
        });

        return;
      }

      this.transitionTo('not-found');
    }
  });
});