define('postedin/components/page-views', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pageViews: Ember.inject.service(),
    isOpen: false,
    opening: false,
    views: [],
    preparedPath: Ember.computed('path', function () {
      var path = this.get('path');
      if (path) {
        return Ember.isArray(path) ? path.join(', ') : path;
      }
    }),
    open: function open() {
      var _this = this;

      this.set('opening', true);
      this.get('pageViews').findByPath(this.get('path')).then(function (views) {
        _this.set('views', views.filter(function (view) {
          return view.get('user.tempType') !== 'admin';
        }));
        _this.set('isOpen', true);
      }).finally(function () {
        return _this.set('opening');
      });
    },
    close: function close() {
      this.set('isOpen', false);
    },

    watchPath: Ember.observer('path', function () {
      this.set('isOpen');
      this.set('opening');
    }),
    actions: {
      toggle: function toggle() {
        if (this.get('opening')) {
          return;
        }

        if (this.get('isOpen')) {
          this.close();
        } else {
          this.open();
        }
      }
    }
  }).reopenClass({
    positionalParams: ['path']
  });
});