define('postedin/components/content-request-guidelines-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    tagName: '',
    povOptions: [1, 2, 3],
    froalaOptions: Ember.computed('i18n', function () {
      return {
        editorClass: '',
        heightMin: 70,
        toolbarSticky: false,
        toolbarButtons: ['bold', 'italic', '|', 'formatUL', 'formatOL', '|', 'insertLink'],
        charCounterCount: true,
        pluginsEnabled: ['entities', 'lineBreaker', 'link', 'lists', 'url', 'charCounter'],
        pasteAllowedStyleProps: [],
        placeholderText: this.get('i18n').t('request-content.focus-points.placeholder')
      };
    }),
    languageOptions: ['spanish', 'english', 'portuguese'],
    dialectOptions: ['local', 'neutral'],
    legacy: Ember.computed.equal('updates.type.name', 'legacy_article'),
    showAdvanced: Ember.computed('legacy', 'advanced', function () {
      if (this.get('legacy')) {
        return false;
      }

      return this.get('advanced');
    }),
    isDefaultTone: Ember.computed('contentRequest.{tone,project.profile.tone}', function () {
      return this.get('contentRequest.tone') == this.get('contentRequest.project.profile.tone');
    }),
    isDefaultTargetAudience: Ember.computed('contentRequest.{targetAudience,project.profile.targetAudience}', function () {
      return this.get('contentRequest.targetAudience') == this.get('contentRequest.project.profile.targetAudience');
    }),
    isDefaultAvoid: Ember.computed('contentRequest.{avoid,project.profile.thingsToAvoid}', function () {
      return this.get('contentRequest.avoid') == this.get('contentRequest.project.profile.thingsToAvoid');
    }),
    isDefaultPOV: Ember.computed('contentRequest.{pointOfView,project.profile.pointOfView}', function () {
      return parseInt(this.get('contentRequest.pointOfView')) == parseInt(this.get('contentRequest.project.profile.pointOfView'));
    }),
    isDefaultSpanishType: Ember.computed('contentRequest.{language,project.profile.language}', function () {
      return this.get('contentRequest.language') == this.get('contentRequest.project.profile.language');
    }),
    isDefaultDialect: Ember.computed('contentRequest.{dialect,project.profile.dialect}', function () {
      return this.get('contentRequest.dialect') == this.get('contentRequest.project.profile.dialect');
    }),
    hasDefaultGuidelines: Ember.computed.and('isDefaultTone', 'isDefaultTargetAudience', 'isDefaultAvoid', 'isDefaultPOV', 'isDefaultSpanishType', 'isDefaultDialect'),
    hasModifiedGuidelines: Ember.computed.not('hasDefaultGuidelines'),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('contentRequest.projectLabel.id')) {
        this.set('selectedLabel', this.get('contentRequest.projectLabel'));
      }
    },

    actions: {
      labelSelected: function labelSelected(label) {
        this.set('selectedLabel', label);
        this.set('updates.projectLabelId', label.id);
      },
      updateCount: function updateCount(component) {
        this.set('focusPointsCharCount', component.get('editor.charCounter').count());
      }
    }
  });
});