define('postedin/services/project-images', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var imageLicenses = ['creativeCommons', 'creativeCommonsZero', 'original', 'stockPhotos'];

  var imageStockPhotoSites = ['fotolia', 'iStock', 'shutterStock', 'gettyImages'];

  exports.default = _repository.default.extend({
    model: 'project-image',
    licenses: imageLicenses,
    stockPhotoSites: imageStockPhotoSites,
    update: function update(projectImage, name, licenses, stockPhotoSites, dimensions, additionalInformation, assignmentTypeIds) {
      var _this = this;

      var preparedLicenses = {};
      Object.keys(licenses).forEach(function (license) {
        if (licenses[license] && imageLicenses.includes(license)) {
          preparedLicenses[license] = true;
        }
      });

      var preparedStockSites = {};
      Object.keys(stockPhotoSites).forEach(function (site) {
        if (stockPhotoSites[site] && imageStockPhotoSites.includes(site)) {
          preparedStockSites[site] = true;
        }
      });

      var data = {
        name: name,
        licenses: preparedLicenses,
        stockPhotoSites: preparedStockSites,
        dimensions: dimensions,
        additionalInformation: additionalInformation,
        assignmentTypeIds: assignmentTypeIds
      };

      return this.put('project-images/' + projectImage.id, data).then(function (record) {
        return _this.push(record);
      });
    },
    remove: function remove(projectImage) {
      var _this2 = this;

      return this.delete('project-images/' + projectImage.id).then(function () {
        _this2.removeObject(projectImage.id);
      });
    }
  });
});