define('postedin/components/publishers/prepare-wordpress-xmlrpc', ['exports', 'postedin/mixins/ui-promise', 'postedin/mixins/validator'], function (exports, _uiPromise, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PrepareWordpressXmlrpcComponent = Ember.Component.extend(_uiPromise.default, _validator.default, {
    loading: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.prepare();

      this.setupErrorClearingObservers('prepared', ['postTitle', 'postAuthor']);

      this.set('loaders', [Ember.Object.create({
        name: 'categories',
        icon: 'icon-folder-open',
        load: function load() {
          return _this.loadCategories();
        }
      }), Ember.Object.create({
        name: 'authors',
        icon: 'icon-users',
        load: function load() {
          return _this.loadAuthors();
        }
      })]);
    },

    htmlMetaDescription: Ember.computed('revision.metaDescription', function () {
      return '<meta name="description" content="' + this.get('revision.metaDescription') + '">';
    }),
    prepare: function prepare() {
      this.set('prepared', Ember.Object.create({
        postTitle: this.getWithDefault('publication.preparedData.postTitle', this.get('revision.title')),
        tags: this.getWithDefault('publication.preparedData.tags', [])
      }));

      var isUuId = this.get('publication.preparedData.featuredImageId.length') === 36;
      if (isUuId) {
        this.selectFeaturedImageByUuId(this.get('publication.preparedData.featuredImageId'));
      } else {
        this.selectFeaturedImageById(this.get('publication.preparedData.featuredImageId'));
      }

      if (!this.get('loading')) {
        this.selectCategoryById(this.get('publication.preparedData.categoryTermId'));
        this.selectAuthorById(this.get('publication.preparedData.postAuthor'));
      }
    },
    loadCategories: function loadCategories() {
      var _this2 = this;

      var promise = this.get('publisher').load('categories');
      promise.then(function (categories) {
        _this2.set('categories', categories);
        _this2.selectCategoryById(_this2.get('publication.preparedData.categoryTermId'));
      });

      return promise;
    },
    loadAuthors: function loadAuthors() {
      var _this3 = this;

      var promise = this.get('publisher').load('authors');
      promise.then(function (authors) {
        _this3.set('authors', authors);
        _this3.selectAuthorById(_this3.get('publication.preparedData.postAuthor'));
      });

      return promise;
    },
    selectCategoryById: function selectCategoryById(id) {
      var category = this.get('categories').findBy('term_id', id);
      if (category) {
        this.selectCategory(category);
      }
    },
    selectCategory: function selectCategory(term) {
      this.set('selectedCategory', term);
      this.set('prepared.categoryTermId', term ? term.term_id : null);
    },
    selectAuthor: function selectAuthor(author) {
      this.set('selectedAuthor', author);
      this.set('prepared.postAuthor', author.user_id);
    },
    selectAuthorById: function selectAuthorById(id) {
      var author = this.get('authors').findBy('user_id', id);
      if (author) {
        this.selectAuthor(author);
      }
    },
    selectFeaturedImageById: function selectFeaturedImageById(id) {
      var image = this.get('revision.attachments').findBy('id', id);
      if (image) {
        this.selectFeaturedImage(image);
      }
    },
    selectFeaturedImageByUuId: function selectFeaturedImageByUuId(uuid) {
      var images = this.get('revision.attachments').concat(this.get('revision.uploads'));
      var image = images.findBy('uuid', uuid);

      if (image) {
        this.selectFeaturedImage(image);
      }
    },
    selectFeaturedImage: function selectFeaturedImage(image) {
      this.set('selectedFeaturedImage', image);

      if (image._model === 'file') {
        this.set('prepared.featuredImageId', image.id);
      } else {
        this.set('prepared.featuredImageId', image.uuid);
      }
    },
    validateForSend: function validateForSend(publication, data) {
      return this.validatorCheck(data, publication.validateForSendRules(data));
    },
    sendNextAction: function sendNextAction() {
      if (this.get('next')) {
        this.sendAction('next');
      }
    },

    actions: {
      textMetaCopiedSuccess: function textMetaCopiedSuccess() {
        var _this4 = this;

        this.set('textMetaCopied', true);
        Ember.run.later(function () {
          return _this4.set('textMetaCopied');
        }, 1500);
      },
      htmlMetaCopiedSuccess: function htmlMetaCopiedSuccess() {
        var _this5 = this;

        this.set('htmlMetaCopied', true);
        Ember.run.later(function () {
          return _this5.set('htmlMetaCopied');
        }, 1500);
      },
      selectCategory: function selectCategory(term) {
        this.selectCategory(term);
      },
      selectAuthor: function selectAuthor(author) {
        this.selectAuthor(author);
      },
      toggleSelectFeaturedImage: function toggleSelectFeaturedImage() {
        if (this.get('save.waiting')) {
          return;
        }

        this.toggleProperty('showSelectFeaturedImage');
      },
      selectFeaturedImage: function selectFeaturedImage(image) {
        this.selectFeaturedImage(image);
        this.set('showSelectFeaturedImage', false);
      },
      save: function save(publication, prepared) {
        var _this6 = this;

        var nextAfterSave = this.get('nextAfterSave');

        var data = prepared.getProperties(['postTitle', 'postAuthor', 'categoryTermId', 'tags', 'featuredImageId']);

        this.uiPromise(publication.prepare(data), 'save').then(function (publication) {
          _this6.prepare();

          if (nextAfterSave && _this6.validateForSend(publication, data)) {
            _this6.sendNextAction();
          }
        });
      },
      nextAfterSave: function nextAfterSave(send) {
        this.set('nextAfterSave', send);
      }
    }
  });

  PrepareWordpressXmlrpcComponent.reopenClass({
    positionalParams: ['publisher', 'revision', 'publication']
  });

  exports.default = PrepareWordpressXmlrpcComponent;
});