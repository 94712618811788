define('postedin/mixins/validation-rules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    requiredValidation: function requiredValidation(str) {
      return !!str;
    },
    urlValidation: function urlValidation(str) {
      // below allowed things like http://localhost which we don't want, so require a "." manually because screw editing complex regex
      if (!str.includes('.')) {
        return false;
      }

      var re = new RegExp('^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,\'/\\+&amp;%$#=~])*$');
      return re.test(str);
    },
    emailValidation: function emailValidation(str) {
      var re = new RegExp('^.+@[^.].*.[a-z]{2,}$');
      return re.test(str);
    },
    fileValidation: function fileValidation() /* file, formats*/{
      return true;

      // if (! (file && formats)) {
      //   return true;
      // }
      //
      // if (! Array.isArray(formats)) {
      //   return true;
      // }
      //
      // if (! file.type.split('/')[1]) {
      //   return true;
      // } else {
      //   if (formats.includes(file.type.split('/')[1]) || formats.includes(file.name.split('.').pop())) {
      //     return true;
      //   }
      // }
    },
    imageValidation: function imageValidation(file) {
      var formats = ['png', 'jpg', 'jpeg', 'gif'];
      return this.fileValidation(file, formats);
    }
  });
});