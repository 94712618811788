define('postedin/components/content-request-popup', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['content-request-popup'],

    optionsDidChange: Ember.on('init', Ember.observer('options', 'options.contentRequest', function () {
      var _this = this;

      Object.keys(this.get('options')).forEach(function (option) {
        _this.set(option, _this.get('options.' + option));
      });
    })),

    dueDate: Ember.computed('contentRequest.dueDate', function () {
      var dueDate = (0, _moment.default)(this.get('contentRequest.dueDate'), 'YYYY-MM-DD');

      return dueDate.format('dddd, Do MMMM');
    })
  });
});