define('postedin/mixins/route-query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array') {
        return value;
      }
      return '' + value;
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array') {
        if (!value) {
          return null;
        }

        var arr = [];
        for (var i = 0; i < value.length; i++) {
          arr.push(value[i]);
        }

        return arr;
      }

      if (defaultValueType === 'boolean') {
        return value === 'true' ? true : false;
      }

      if (defaultValueType === 'number') {
        return Number(value).valueOf();
      }

      return value;
    }
  });
});