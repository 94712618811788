define('postedin/controllers/admin/legacy-content-types/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['filter'],
    filteredModel: Ember.computed('model.length', 'filter', function () {
      var _this = this;

      return this.get('model').filter(function (type) {
        switch (_this.get('filter')) {
          case 'hidden':
            return type.get('hide');
          case 'visible':
            return !type.get('hide');
          case 'inactive':
            return !type.get('active');
          case 'active':
          default:
            return type.get('active');
        }
      }).sortBy('name');
    }),
    actions: {
      filter: function filter(_filter) {
        this.set('filter', _filter);
      }
    }
  });
});