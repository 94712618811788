define('postedin/mixins/attachments', ['exports', 'postedin/mixins/has-uploads'], function (exports, _hasUploads) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_hasUploads.default, {
    extractAttachments: function extractAttachments() {
      return this.get('uploads').filter(function (upload) {
        // if new include it unless set to remove so we never attach in the first place
        if (upload.new) {
          return !upload.remove;
        }

        // otherwise include those set to remove
        return upload.remove;
      }).map(function (upload) {
        return upload.getProperties('uuid', 'remove');
      });
    },
    setupAttachments: function setupAttachments(attachments) {
      if (attachments) {
        this.set('uploads', attachments.map(function (upload) {
          // This is due to restrictions from our current relationship loading
          // https://github.com/postedin/marketplace/issues/256
          if (!upload.setProperties) {
            upload = Ember.Object.create(upload);
          }

          upload.setProperties({
            new: false,
            uploading: false,
            remove: false,
            cancel: false,
            failed: false,
            speed: false,
            eta: false,
            progress: false
          });

          return upload;
        }));
      }
    }
  });
});