define('postedin/controllers/admin-projects/show/suspend', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    projects: Ember.inject.service(),
    warnedObserve: function () {
      if (!this.get('form.suspended')) {
        this.set('form.suspending', '');
      }
    }.observes('form.suspended'),
    actions: {
      saveWarn: function saveWarn() {
        var _this = this;

        this.set('saving', true);
        this.set('form.users', this.get('users'));
        this.set('form.suspended', this.get('form.suspended') === 'yes' ? true : false);
        this.get('projects').suspend(this.get('model.id'), this.get('form')).then(function () {
          _this.transitionToRoute('admin-projects.show', _this.get('model'));
        }, function () {
          _this.set('saving', false);
        });
      }
    }
  });
});