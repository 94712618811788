define('postedin/app', ['exports', 'postedin/resolver', 'ember-load-initializers', 'postedin/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    sourcemaps: { enabled: true },
    Resolver: _resolver.default,
    engines: {
      clients: {
        dependencies: {
          services: ['router', 'session', 'api', 'i18n', 'headData'],
          externalRoutes: {
            login: 'login',
            organization: 'organization',
            'organization.account': 'organization.accounts.show',
            'organization.edit': 'admin.organizations.edit'
          }
        }
      },
      masterAccounts: {
        dependencies: {
          services: ['router', 'session', 'api', 'i18n', 'headData'],
          externalRoutes: {
            login: 'login',
            organization: 'organization',
            'organization.account': 'organization.accounts.show'
          }
        }
      },
      dev: {
        dependencies: {
          services: ['router', 'session', 'i18n', 'api']
        }
      }
    }
  }).reopenClass({
    notFound: function notFound() {
      return Ember.RSVP.reject({ status: 404 });
    },
    forbidden: function forbidden() {
      return Ember.RSVP.reject({ status: 403 });
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});