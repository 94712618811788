define('postedin/controllers/calendar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    uiPopup: Ember.inject.service(),
    queryParams: ['projects', 'labels', 'statuses', 'month', 'week', { legacyContentTypeGroups: 'groups' }, { bustParam: '_' }],
    bustParam: null,
    projects: [],
    labels: [],
    loading: true,
    month: null,
    week: null,
    calendarDate: (0, _moment.default)(),
    legacyContentTypeGroups: [],
    statuses: [],
    view: 'week',

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('month')) {
        this.set('view', 'month');
        this.set('week', null);
      }
    },

    viewDidChange: function () {
      switch (this.get('view')) {
        case 'week':
          this.setProperties({
            currentMonth: null,
            month: null,
            week: (0, _moment.default)(this.get('currentMonth'), 'YYYY-MM').format('YYYY-WW'),
            currentWeek: this.get('week')
          });
          break;
        case 'month':
          this.setProperties({
            currentMonth: this.get('month'),
            month: (0, _moment.default)(this.get('currentWeek'), 'YYYY-WW').format('YYYY-MM'),
            week: null,
            currentWeek: null
          });
          break;
      }
    }.observes('view')
  });
});