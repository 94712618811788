define('postedin/services/content-reviews', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'content-review',
    findById: function findById(id) {
      var _this = this;

      return this.request('content-reviews/' + id).then(function (record) {
        record.reviewable._emberModel = record.reviewable._model;

        return _this.push(record, 'content-review', true);
      });
    },
    approve: function approve(contentReview, comment, attachments) {
      var _this2 = this;

      var data = {
        comment: comment,
        attachments: attachments
      };

      return this.post('content-reviews/' + contentReview.id + '/approve', data).then(function (record) {
        return _this2.push(record);
      });
    },
    reject: function reject(contentReview, comment, attachments) {
      var _this3 = this;

      var data = {
        comment: comment,
        attachments: attachments
      };

      return this.post('content-reviews/' + contentReview.id + '/reject', data).then(function (record) {
        return _this3.push(record);
      });
    },
    comment: function comment(contentReview, _comment, attachments) {
      var _this4 = this;

      var data = {
        comment: _comment,
        attachments: attachments
      };

      return this.post('content-reviews/' + contentReview.id + '/comments', data).then(function (record) {
        return _this4.push(record, 'comment');
      });
    },
    annotate: function annotate(review, comment, text, location) {
      var _this5 = this;

      var data = {
        comment: comment,
        text: text,
        location: location
      };

      return this.post('content-reviews/' + review.id + '/annotations', data).then(function (record) {
        return _this5.push(record, 'annotation');
      });
    },
    notify: function notify(review) {
      var _this6 = this;

      return this.post('content-reviews/' + review.id + '/notification').then(function (record) {
        return _this6.push(record);
      });
    }
  });
});