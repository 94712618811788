define('postedin/helpers/verb-scan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.verbScan = verbScan;


  function contains(string, substring) {
    return string.indexOf(substring) > -1;
  }

  function verbScan(params /* , hash*/) {
    var action = params[0];

    if (contains(action, 'Add')) {
      return 'add';
    } else if (contains(action, 'Edit')) {
      return 'edit';
    } else if (contains(action, 'Update')) {
      return 'edit';
    } else if (contains(action, 'Delete')) {
      return 'delete';
    } else if (contains(action, 'SetProfile')) {
      return 'set_profile';
    } else {
      return 'did_something';
    }
  }

  exports.default = Ember.Helper.helper(verbScan);
});