define('postedin/services/echo', ['exports', 'postedin/libs/echo', 'npm:pusher-js', 'postedin/config/environment'], function (exports, _echo, _npmPusherJs, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  Ember.runInDebug(function () {
    _npmPusherJs.default.logToConsole = true;
  });

  var enabled = _environment.default.environment !== 'test';

  var ChannelStub = function () {
    function ChannelStub() {
      _classCallCheck(this, ChannelStub);
    }

    _createClass(ChannelStub, [{
      key: 'listen',
      value: function listen() {}
    }, {
      key: 'notification',
      value: function notification() {}
    }]);

    return ChannelStub;
  }();

  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (enabled) {
        try {
          this.set('echo', new _echo.default({
            broadcaster: 'pusher',
            key: _environment.default.pusherKey,
            cluster: _environment.default.pusherCluster,
            namespace: 'App.Events.Broadcasters'
          }));
        } catch (exception) {
          enabled = false;

          if (exception === 'You must pass your app key when you instantiate Pusher.') {
            return;
          }

          throw exception;
        }
      }
    },
    channel: function channel(_channel) {
      if (!enabled) {
        return new ChannelStub();
      }

      return this.get('echo').channel(_channel);
    },
    leave: function leave(channel) {
      if (!enabled) {
        return;
      }

      return this.get('echo').leave(channel);
    }
  });
});