define('postedin/services/publishers/publisher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    validateForSendRules: function validateForSendRules() /* data*/{
      throw new TypeError('Abstract method "Publisher.validateForSendRules" must overridden');
    },
    validateForSend: function validateForSend() /* data*/{
      throw new TypeError('Abstract method "Publisher.validateForSend" must overridden');
    }
  });
});