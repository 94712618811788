define('postedin/models/comment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    router: Ember.inject.service('-routing'),
    commentsRepository: Ember.inject.service('comments'),
    // This is to make it more obvious to a user that their comment was inserted.
    flashHighlight: function flashHighlight() {
      var _this = this;

      this.set('highlight', true);
      Ember.run.later(function () {
        _this.set('highlight', false);
      }, 500);
    },
    transitionToRoute: function transitionToRoute() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.get('router').transitionTo('comment-permalink', [this.id], queryParams);
    },
    comment: function comment(_comment, attachments) {
      return this.get('commentsRepository').comment(this, _comment, attachments);
    }
  });
});