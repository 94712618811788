define('postedin/services/features', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    languages: ['english', 'spanish', 'portuguese'],
    languageOptions: Ember.computed('languages', function () {
      var _this = this;

      return this.get('languages').map(function (language) {
        return {
          value: language,
          text: _this.get('i18n').t('languages.' + language)
        };
      });
    }),
    licenseTypes: ['original', 'cc', 'cc0', 'stock'],
    stockPhotoSites: ['fotolia', 'iStock', 'shutterStock', 'gettyImages'],
    focusPointsFroalaOptions: Ember.computed(function () {
      return {
        editorClass: '',
        heightMin: 100,
        toolbarSticky: false,
        toolbarButtons: ['bold', 'italic', '|', 'formatUL', 'formatOL', '|', 'insertLink'],
        charCounterCount: true,
        pluginsEnabled: ['entities', 'lineBreaker', 'link', 'lists', 'url', 'charCounter'],
        pasteAllowedStyleProps: [],
        htmlAllowedStyleProps: [],
        htmlAllowedAttrs: ['title', 'href', 'alt', 'src'],
        htmlAllowedTags: ['p', 'strong', 'em', 'ul', 'ol', 'li', 'a'],
        placeholderText: false
      };
    })
  });
});