define('postedin/components/project-profile-form', ['exports', 'postedin/mixins/validation-rules', 'postedin/mixins/attachments'], function (exports, _validationRules, _attachments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_validationRules.default, _attachments.default, {
    projects: Ember.inject.service(),
    images: Ember.inject.service(),
    queryCurrentTab: function () {
      return this.set('currentTab', this.get('tab') || 'project');
    }.observes('tab'),
    currentTab: Ember.computed(function () {
      return this.get('tab') || 'project';
    }),
    prepareData: function prepareData(project, profile) {
      // Setting up profile data
      profile.set('name', project.get('name'));
      this.set('profile', profile);

      // Set image dimensions

      var _profile$get$split = profile.get('imageDimensions').split('x'),
          _profile$get$split2 = _slicedToArray(_profile$get$split, 2),
          width = _profile$get$split2[0],
          height = _profile$get$split2[1];

      profile.set('dimensionW', width);
      profile.set('dimensionH', height);
    },

    setupComponent: function () {
      this.set('initialized', false);

      var project = this.get('project');

      this.setupAttachments(project.get('profile.attachments'));

      this.prepareData(project, this.get('profile'));
      this.set('initialized', true);
    }.on('init'),
    validate: function validate() {
      this.set('error', false);
      this.set('errors', {});

      var isValid = true;

      if (document.getElementById('inputLogo').length) {
        var file = document.getElementById('inputLogo').files[0];
        if (!this.imageValidation(file)) {
          this.set('errors.logoImageFormat', true);
          isValid = false;
        }
      }

      return isValid;
    },
    updateProfile: function updateProfile(imageId) {
      var _this = this;

      if (imageId) {
        this.set('profile.logoImageId', imageId);
      }

      var attachments = this.extractAttachments();
      this.set('profile.attachments', attachments);

      this.get('projects').updateProfile(this.get('project.id'), this.get('profile')).then(function () {
        _this.get('project').reload().then(function (project) {
          _this.setupAttachments(project.get('profile.attachments'));

          _this.set('saving', false);
          _this.set('saved', true);

          Ember.run.later(function () {
            return _this.set('saved', false);
          }, 2000);
        });
      }, function (reason) {
        if (reason.status === 422) {
          // TODO: add other possible errors
          var errorFlag = false;
          if (reason.responseJSON.errors.website) {
            _this.set('errors.websiteFormatUrl', true);
            errorFlag = true;
          }
          if (reason.responseJSON.errors.banner) {
            _this.set('errors.bannerFormatUrl', true);
            errorFlag = true;
          }
          if (reason.responseJSON.errors.facebook) {
            _this.set('errors.facebookFormatUrl', true);
            errorFlag = true;
          }
          if (reason.responseJSON.errors.twitter) {
            _this.set('errors.twitterFormatUrl', true);
            errorFlag = true;
          }

          if (errorFlag) {
            _this.set('errorFields', true);
            Ember.run.later(function () {
              return _this.set('errorFields', false);
            }, 2000);
          }
        } else {
          _this.set('error', true);
        }
      }).finally(function () {
        Ember.run.later(function () {
          return _this.set('saving', false);
        }, 2000);
      });
    },


    actions: {
      setTab: function setTab(tab) {
        this.set('currentTab', tab);
      },
      save: function save() {
        var _this2 = this;

        if (this.get('isUploading')) {
          return;
        }

        if (!this.validate()) {
          this.set('errorFields', true);
          Ember.run.later(function () {
            return _this2.set('errorFields', false);
          }, 2000);
          return;
        }

        this.set('profile.imageDimensions', (this.get('profile.dimensionW') || '') + 'x' + (this.get('profile.dimensionH') || ''));
        if (this.get('profile.imageDimensions') === 'x') {
          this.set('profile.imageDimensions', '');
        }

        var formData = new FormData(document.getElementById('projectProfileForm'));
        this.set('saving', true);

        if (!this.get('profile.logoFile')) {
          this.updateProfile();
        } else {
          this.get('images').create(formData).then(function (image) {
            _this2.updateProfile(image.id);
          }, function (reason) {
            if (reason.status === 422) {
              if (reason.responseJSON.errors.image) {
                _this2.set('errors.logoImageFormat', true);
                _this2.set('errorFields', true);
                Ember.run.later(function () {
                  return _this2.set('errorFields', false);
                }, 2000);
              }
            }
          }).finally(function () {
            _this2.set('project.logoFile', null);
            Ember.run.later(function () {
              return _this2.set('saving', false);
            }, 2000);
          });
        }
      }
    }
  });
});