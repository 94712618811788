define('postedin/models/project', ['exports', 'moment', 'postedin/models/model'], function (exports, _moment, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _model.default.extend({
    apiUrl: 'projects',
    router: Ember.inject.service(),
    projects: Ember.inject.service(),
    organizations: Ember.inject.service(),
    createSuggestionUrl: Ember.computed('id', function () {
      return this.get('router').urlFor('suggestions.create', { queryParams: { project: this.get('id') } });
    }),
    suggestionDescriptionUpdatedColor: Ember.computed('suggestionsDescriptionUpdatedAt', function () {
      var updated = this.get('suggestionsDescriptionUpdatedAt');
      if (!updated) {
        return '';
      }

      updated = (0, _moment.default)(updated);
      var now = (0, _moment.default)();

      if (updated > now.subtract(2, 'days')) {
        return 'u-c-red5';
      }

      if (updated > now.subtract(7, 'days')) {
        return 'u-c-orange5';
      }

      return 'u-c-gray7';
    }),
    loadProfile: function loadProfile() {
      return this.get('projects').profile(this.get('id'));
    },
    loadOrganization: function loadOrganization() {
      return this.get('organizations').findById(this.get('organization.id'));
    },
    loadProjectLegacyContentTypes: function loadProjectLegacyContentTypes() {
      return this.get('projects').projectLegacyContentTypes(this.get('id'));
    },
    loadLegacyContentTypes: function loadLegacyContentTypes() {
      return this.get('projects').legacyContentTypes(this.get('id'));
    },
    loadLabels: function loadLabels() {
      return this.get('projects').labels(this);
    },
    loadReferenceSites: function loadReferenceSites() {
      return this.get('projects').referenceSites(this);
    },
    loadForbiddenSources: function loadForbiddenSources() {
      return this.get('projects').forbiddenSources(this);
    },
    loadKeywords: function loadKeywords() {
      return this.get('projects').keywords(this);
    },
    loadImageRequirements: function loadImageRequirements() {
      return this.get('projects').imageRequirements(this);
    },
    loadNotes: function loadNotes() {
      return this.get('projects').notes(this.get('id'));
    },
    loadPublishers: function loadPublishers() {
      return this.get('projects').publishers(this.get('id'));
    },
    loadUsers: function loadUsers() {
      return this.get('projects').users(this);
    },
    loadManagers: function loadManagers() {
      return this.get('projects').managers(this);
    },
    loadAdmins: function loadAdmins() {
      return this.get('projects').admins(this);
    },
    loadEditors: function loadEditors() {
      return this.get('projects').editors(this);
    },
    loadAuditLog: function loadAuditLog(params) {
      return this.get('projects').loadAuditLog(this, params);
    },
    createContentRequest: function createContentRequest(contentType, legacyContentType, contentCategory) {
      return this.get('projects').createContentRequest(this, contentType, legacyContentType, contentCategory);
    },
    createContentSuggestion: function createContentSuggestion(group, summary, goal, focusPoints, targetAudience, duplicateId, keywords, sources) {
      return this.get('projects').createContentSuggestion(this, group, summary, goal, focusPoints, targetAudience, duplicateId, keywords, sources);
    },
    selectAccount: function selectAccount(account) {
      return this.get('projects').selectAccount(this, account);
    },
    removeAccount: function removeAccount() {
      return this.get('projects').removeAccount(this);
    },
    loadTransactions: function loadTransactions(params) {
      return this.get('projects').transactions(this, params);
    },
    createNote: function createNote(data) {
      return this.get('projects').createNote(this, data);
    },
    addUser: function addUser(user, roles) {
      return this.get('projects').addUser(this, user, roles);
    },
    invites: function invites() {
      return this.get('projects').invites(this);
    },
    inviteUser: function inviteUser(email, roles) {
      return this.get('projects').inviteUser(this, email, roles);
    },
    removeUser: function removeUser(user) {
      return this.get('projects').removeUser(this, user);
    },
    reload: function reload() {
      return this.get('projects').findById(this.get('id'));
    },
    reviewers: function reviewers() {
      return this.get('projects').reviewers(this);
    },
    addReviewer: function addReviewer(user) {
      return this.get('projects').addReviewer(this, user);
    },
    removeReviewer: function removeReviewer(user) {
      return this.get('projects').removeReviewer(user);
    },
    updateSetting: function updateSetting(param, value) {
      return this.updateSettings(_defineProperty({}, param, value));
    },
    updateSettings: function updateSettings(settings) {
      return this.get('projects').updateSettings(this.get('id'), settings);
    },
    updateProfile: function updateProfile(updates) {
      return this.get('projects').updateProfile(this.get('id'), updates);
    },
    updates: function updates() {
      return this.get('projects').updates(this);
    },
    createKeyword: function createKeyword(name) {
      return this.get('projects').createKeyword(this, name);
    },
    createKeywords: function createKeywords(keywords) {
      return this.get('projects').createKeywords(this, keywords);
    },
    createReferenceSite: function createReferenceSite(name, url, description) {
      return this.get('projects').createReferenceSite(this, name, url, description);
    },
    createForbiddenSource: function createForbiddenSource(name, url, description) {
      return this.get('projects').createForbiddenSource(this, name, url, description);
    },
    createLabel: function createLabel(name, color) {
      return this.get('projects').createLabel(this, name, color);
    },
    createImageRequirement: function createImageRequirement(name, licenses, stockPhotoSites, dimensions, additionalInformation, assignmentTypeIds) {
      return this.get('projects').createImageRequirement(this, name, licenses, stockPhotoSites, dimensions, additionalInformation, assignmentTypeIds);
    },
    delete: function _delete() {
      return this.get('projects').remove(this);
    }
  });
});