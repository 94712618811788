define('postedin/services/project-users', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'project-user',
    update: function update(projectUser, roles) {
      var _this = this;

      var params = {
        roles: roles
      };

      return this.put('project-users/' + projectUser.get('id'), params).then(function (record) {
        return _this.push(record);
      });
    },
    remove: function remove(projectUser) {
      var _this2 = this;

      return this.delete('project-users/' + projectUser.get('id')).then(function () {
        _this2.removeObject(projectUser.get('id'));
      });
    }
  });
});