define('postedin/models/user', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apiUrl: 'users',
    session: Ember.inject.service(),
    users: Ember.inject.service(),
    globalRoles: Ember.computed('isAdmin', 'isGlobalEditor', 'isGlobalManager', 'isGlobalOrganizationManager', function () {
      var _this = this;

      var roles = [];

      ['isAdmin', 'isGlobalEditor', 'isGlobalManager', 'isGlobalOrganizationManager'].forEach(function (role) {
        if (_this.get(role)) {
          roles.push(role.substring(2).camelize());
        }
      });

      return roles;
    }),
    reload: function reload() {
      return this.get('session').reload();
    },

    canViewRejectedSuggestions: Ember.computed('tempType', function () {
      return this.get('tempType') !== 'partner';
    }),
    update: function update(name, email, password, tempType, globalRoles) {
      return this.get('users').update(this, name, email, password, tempType, globalRoles);
    },
    impersonate: function impersonate() {
      return this.get('users').impersonate(this);
    },
    loadReviews: function loadReviews(params) {
      return this.get('users').loadReviews(this, params);
    },
    loadAllPendingReviews: function loadAllPendingReviews() {
      return this.get('users').loadAllPendingReviews(this);
    }
  });
});