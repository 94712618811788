define('postedin/services/annotations', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'annotation',
    resolve: function resolve(annotation) {
      var _this = this;

      return this.post('annotations/' + annotation.id + '/resolve').then(function (record) {
        return _this.push(record);
      });
    }
  });
});