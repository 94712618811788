define('postedin/components/beta-user-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    className: 'beta-user-buttons',
    betaUsers: Ember.inject.service(),
    confirmAction: null,
    actions: {
      confirm: function confirm(action) {
        this.set('confirmAction', action);
      },
      cancel: function cancel() {
        this.set('confirmAction', null);
      },
      validate: function validate() {
        var _this = this;

        var user = this.get('user');

        this.get('betaUsers').validate(user.get('id')).then(function () {
          _this.set('confirmAction', null);
        });
      },
      activate: function activate() {
        var _this2 = this;

        var user = this.get('user');

        this.get('betaUsers').activate(user.get('id')).then(function () {
          _this2.set('confirmAction', null);
        });
      },
      suspend: function suspend() {
        var _this3 = this;

        var user = this.get('user');

        this.get('betaUsers').suspend(user.get('id')).then(function () {
          _this3.set('confirmAction', null);
        });
      },
      delete: function _delete() {
        var _this4 = this;

        var user = this.get('user');

        this.get('betaUsers').remove(user.get('id')).then(function () {
          _this4.set('confirmAction', null);
          _this4.get('onDelete')();
        });
      }
    }
  });
});