define('postedin/services/issues', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'issue',
    findById: function findById(id) {
      var _this = this;

      return this.request('issues/' + id).then(function (record) {
        record.issuable._emberModel = record.issuable._model;

        if (record.issuable.type && record.issuable.type._model) {
          record.issuable.type._emberModel = record.issuable.type._model;
        }

        return _this.push(record);
      });
    },
    comment: function comment(issue, body, attachments) {
      var _this2 = this;

      var data = {
        body: body,
        attachments: attachments
      };
      return this.post('issues/' + issue.id + '/comments', data).then(function (record) {
        _this2.pushCommentable(record);

        return _this2.push(record, 'comment');
      });
    },
    open: function open(issue, comment, attachments) {
      var _this3 = this;

      var data = {
        comment: comment,
        attachments: attachments
      };
      return this.post('issues/' + issue.id + '/open', data).then(function (record) {
        _this3.pushCommentable(record);

        return _this3.push(record, 'comment');
      });
    },
    close: function close(issue, comment, attachments) {
      var _this4 = this;

      var data = {
        comment: comment,
        attachments: attachments
      };
      return this.post('issues/' + issue.id + '/close', data).then(function (record) {
        _this4.pushCommentable(record);

        return _this4.push(record, 'comment');
      });
    },
    pushCommentable: function pushCommentable(comment) {
      if (comment.commentable) {
        var model = this.modelExists(comment.commentableType.replace('App\\', ''));
        if (model) {
          this.push(comment.commentable, model);
        }
      }
    }
  });
});