define('postedin/components/loading-queue', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LoadingQueueComponent = Ember.Component.extend(_uiPromise.default, {
    tagName: 'ul',
    classNames: 'loading-queue',
    loading: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('loaders').forEach(function (loader) {
        loader.set('phrase', 'loaders.loading_' + loader.get('name').underscore());
        _this.load(loader);
      });
    },
    load: function load(loader) {
      var _this2 = this;

      this.uiPromise(loader.load(), 'state', loader).then(function () {
        return _this2.checkLoaded();
      });
    },
    checkLoaded: function checkLoaded() {
      var _this3 = this;

      var loading = this.get('loaders').filter(function (loader) {
        return !loader.get('state.success');
      }).length;

      if (!loading) {
        Ember.run.later(function () {
          return _this3.set('loading', false);
        }, 500);
      }
    },

    actions: {
      retry: function retry(loader) {
        this.load(loader);
      }
    }
  });

  LoadingQueueComponent.reopenClass({
    positionalParams: ['loaders']
  });

  exports.default = LoadingQueueComponent;
});