define('postedin/templates/show/users', ['exports', 'clients/templates/show/users'], function (exports, _users) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _users.default;
    }
  });
});