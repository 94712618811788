define('postedin/models/account', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apiUrl: 'accounts',
    accounts: Ember.inject.service(),
    reload: function reload() {
      return this.get('accounts').findById(this.id);
    },
    update: function update(updates) {
      return this.get('accounts').update(this, updates);
    },
    close: function close() {
      return this.get('accounts').close(this);
    },
    delete: function _delete() {
      return this.get('accounts').remove(this);
    },
    analyze: function analyze() {
      return this.get('accounts').analyze(this);
    },
    addCredits: function addCredits(credits, note) {
      return this.get('accounts').addCredits(this, credits, note);
    },
    transferCredits: function transferCredits(account, credits, note) {
      return this.get('accounts').transferCredits(this, account, credits, note);
    },
    loadTransactions: function loadTransactions(page) {
      var perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      return this.get('accounts').loadTransactions(this, page, perPage);
    }
  });
});