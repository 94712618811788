define('postedin/routes/editors/capacity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    days: Ember.inject.service(),
    queryParams: {
      month: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        currentMonth: params.month,
        days: this.get('days').find(params.month)
      });
    },
    setupController: function setupController(controller, hash) {
      var holidays = hash.days.filter(function (day) {
        return day.holiday;
      }).map(function (day) {
        return day.id;
      });

      controller.set('holidays', holidays);
      controller.setProperties(hash);
    }
  });
});