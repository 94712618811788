define('postedin/components/admin-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var shownPages = 16;

  exports.default = Ember.Component.extend({
    classNames: 'backgrid-paginator',
    pages: function () {
      var pages = [];
      var currentPage = parseInt(this.get('currentPage'));
      for (var page = 1; page <= this.get('lastPage'); page++) {
        pages.push({
          number: page,
          active: page === currentPage
        });
      }

      var start = currentPage - Math.ceil(shownPages / 2);
      var end = Math.min(currentPage + Math.ceil(shownPages / 2) - 1, parseInt(this.get('lastPage')));
      if (start <= 0) {
        end -= start;
        start = 0;
      }

      return pages.slice(start, end);
    }.property('currentPage', 'lastPage'),
    previousPage: function () {
      return this.get('currentPage') - 1;
    }.property('currentPage'),
    nextPage: function () {
      if (this.get('currentPage') >= this.get('lastPage')) {
        return false;
      }

      return this.get('currentPage') + 1;
    }.property('currentPage', 'lastPage'),
    actions: {
      scroll: function scroll() {
        if (this.get('scrollTo')) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$(this.get('scrollTo')).offset().top
          }, 80);
        }
      }
    }
  });
});