define('postedin/helpers/t-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tFeed = tFeed;
  function tFeed(params /* , hash*/) {
    return 'feed.' + params[0].action;
  }

  exports.default = Ember.Helper.helper(tFeed);
});