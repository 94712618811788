define('postedin/controllers/login/registration', ['exports', 'postedin/mixins/validation-rules'], function (exports, _validationRules) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationRules.default, {
    users: Ember.inject.service(),
    validate: function validate() {
      this.set('errors', {});

      var isValid = true;

      if (this.get('model.name') === null || this.get('model.name') === '') {
        this.set('errors.nameRequired', true);
        isValid = false;
      }

      if (this.get('model.email') === null || this.get('model.email') === '') {
        this.set('errors.emailRequired', true);
        isValid = false;
      } else {
        if (!this.emailValidation(this.get('model.email'))) {
          this.set('errors.emailInvalid', true);
          isValid = false;
        }
      }

      if (this.get('model.password') === null || this.get('model.password') === '') {
        this.set('errors.passwordRequired', true);
        isValid = false;
      } else {
        if (this.get('model.password.length') < 6) {
          this.set('errors.passwordTooShort', true);
          isValid = false;
        }
      }

      if (this.get('model.password') !== this.get('model.password_confirmation')) {
        this.set('errors.confirmationPasswordDontMatch', true);
        isValid = false;
      }

      return isValid;
    },

    actions: {
      register: function register() {
        var _this = this;

        if (!this.validate()) {
          return false;
        }

        this.set('saving', true);
        return this.get('users').createPartner(this.get('model')).then(function () {
          _this.transitionToRoute('login');
        }, function () {
          return _this.set('saving', false);
        });
      }
    }
  });
});