define('postedin/helpers/bs-read-path', ['exports', 'ember-bootstrap/helpers/bs-read-path'], function (exports, _bsReadPath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bsReadPath.default;
    }
  });
  Object.defineProperty(exports, 'readPath', {
    enumerable: true,
    get: function () {
      return _bsReadPath.readPath;
    }
  });
});