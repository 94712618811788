define('postedin/helpers/publication-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.publicationStatus = publicationStatus;
  function publicationStatus(status) {
    if (status instanceof Array) {
      status = status[0];
    }

    var statuses = {
      pending: 'submitted',
      revision_requested: 'pending',
      approved: 'completed'
    };

    return statuses[status];
  }

  exports.default = Ember.Helper.helper(publicationStatus);
});