define('postedin/services/unread-release-notes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    echo: Ember.inject.service(),
    releaseNotes: Ember.inject.service(),
    count: 0,
    init: function init() {
      var _this = this;

      this.fetchCount();

      this.get('echo').channel('general').listen('ReleaseNoteCreated', function () {
        _this.fetchCount();
      });
    },
    fetchCount: function fetchCount() {
      var _this2 = this;

      this.get('releaseNotes').unreadCount().then(function (count) {
        _this2.set('count', count);
      });
    }
  });
});