define('postedin/components/navigation-bar', ['exports', 'postedin/mixins/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_utils.default, {
    router: Ember.inject.service('-routing'),
    classNames: ['nav-bar'],
    session: Ember.inject.service(),
    singleProject: Ember.computed('organizations.length', 'organizations.@each.projects', function () {
      if (!this.get('organizations.length')) {
        return false;
      }

      var projects = 0;
      var project = void 0;
      this.get('organizations').forEach(function (organization) {
        if (!organization.get('projects')) {
          // must still be loading the projects
          return;
        }

        projects += organization.get('projects.length');
        project = organization.get('projects.firstObject');
      });

      return projects === 1 ? project : false;
    }),
    projectOptions: Ember.computed('organizations.length', 'organizations.@each.projects', function () {
      var options = [];

      this.get('organizations').forEach(function (organization) {
        var organizationOption = {
          name: organization.name,
          isOrganization: true,
          organization: organization
        };
        options.push(organizationOption);

        var projectOptions = [];
        organization.get('projects').forEach(function (project) {
          var option = {
            name: project.name,
            isProject: true,
            organization: organizationOption,
            project: project
          };
          projectOptions.push(option);
          options.push(option);
        });

        organizationOption.projects = projectOptions;
      });

      return options;
    }),
    canRequestContent: Ember.computed('organizations.length', 'organizations.@each.projects', function () {
      if (!this.get('organizations.length')) {
        return false;
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('organizations').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var organization = _step.value;

          if (!organization.get('projects')) {
            continue;
          }

          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = organization.get('projects').toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var project = _step2.value;

              if (project.get('canRequestContent')) {
                return true;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    }),
    canSuggestContent: Ember.computed('organizations.length', 'organizations.@each.projects', function () {
      if (!this.get('organizations.length')) {
        return false;
      }

      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.get('organizations').toArray()[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var organization = _step3.value;

          if (!organization.get('projects')) {
            continue;
          }

          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = organization.get('projects').toArray()[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var project = _step4.value;

              if (project.get('canSuggestContent')) {
                return true;
              }
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      return false;
    }),
    canViewSuggestions: Ember.computed('organizations.length', 'organizations.@each.projects', function () {
      if (!this.get('organizations.length')) {
        return false;
      }

      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = this.get('organizations').toArray()[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var organization = _step5.value;

          if (!organization.get('projects')) {
            continue;
          }

          var _iteratorNormalCompletion6 = true;
          var _didIteratorError6 = false;
          var _iteratorError6 = undefined;

          try {
            for (var _iterator6 = organization.get('projects').toArray()[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
              var project = _step6.value;

              if (project.get('canViewSuggestions')) {
                return true;
              }
            }
          } catch (err) {
            _didIteratorError6 = true;
            _iteratorError6 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion6 && _iterator6.return) {
                _iterator6.return();
              }
            } finally {
              if (_didIteratorError6) {
                throw _iteratorError6;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      return false;
    }),
    openSuggestionsCount: Ember.computed('organizations.length', 'organizations.@each.projects', function () {
      if (!this.get('organizations.length')) {
        return 0;
      }

      var count = 0;

      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = this.get('organizations').toArray()[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var organization = _step7.value;

          if (!organization.get('projects')) {
            continue;
          }

          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            for (var _iterator8 = organization.get('projects').toArray()[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              var project = _step8.value;

              if (project.get('openSuggestionsCount')) {
                count += project.get('openSuggestionsCount');
              }
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      return count;
    })
  });
});