define('postedin/controllers/admin-release-notes/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    releaseNotes: Ember.inject.service(),
    actions: {
      closeConfirmationModal: function closeConfirmationModal() {
        this.set('confirmationModal', false);
        this.set('deletingError', false);
      },
      confirmDelete: function confirmDelete() {
        this.set('confirmationModal', true);
      },
      deleteReleaseNote: function deleteReleaseNote() {
        var _this = this;

        var releaseNote = this.get('model');

        this.set('deletingReleaseNote', true);
        this.get('releaseNotes').remove(releaseNote).then(function () {
          _this.set('deletingReleaseNote', false);
          _this.transitionToRoute('admin-release-notes');
        }, function () {
          _this.set('deletingReleaseNote', false);
          _this.set('deletingError', true);
        });
      }
    }
  });
});