define('postedin/routes/international-payments/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    payments: Ember.inject.service(),
    invoices: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        payment: this.get('payments').findById(params.id)
      });
    },
    setupController: function setupController(controller, model) {
      model.payment.invoices.forEach(function (invoice) {
        invoice.set('newInvoiceNumber', invoice.get('invoiceNumber'));
        invoice.set('rejectComment', '');
        invoice.set('paidDialog', false);
        invoice.set('rejectDialog', false);
        invoice.set('rejectionDetailsDialog', false);
        invoice.set('paypalDetailsDialog', false);

        invoice.set('rejectReasons', [{ checked: false, column: 1, phrase: 'Email de Paypal incorrecto o inválido.' }, { checked: false, column: 1, phrase: 'No has proporcionado tu nombre completo.' }, { checked: false, column: 1, phrase: 'No has ingresado correctamente tu dirección.' }, { checked: false, column: 1, phrase: 'Ha ocurrido un error al enviar tu pago.' }, { checked: false, column: 1, phrase: 'Otros.' }]);
      });

      this._super(controller, model);

      controller.set('finishDialog', false);
      controller.set('finishError', false);
      controller.set('newSearch', controller.get('search'));
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});