define('postedin/services/status-groups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var statusObject = function statusObject(id) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    data.id = id;

    return Ember.Object.create(data);
  };

  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    stages: Ember.computed('session.user.isAdmin', 'session.user.tempType', function () {
      if (this.get('session.user.isAdmin') || this.get('session.user.tempType') === 'editor') {
        return this.get('_stages');
      }

      return this.get('_stages').copy().map(function (stage) {
        stage.statuses = stage.statuses.filter(function (status) {
          return !status.get('adminOnly');
        });

        return stage;
      });
    }),
    _stages: [{
      id: 'planning',
      phrase: 'content.stages.planning',
      statuses: [statusObject('requested')]
    }, {
      id: 'content',
      phrase: 'content.stages.content',
      statuses: [statusObject('searching'), statusObject('assigned'), statusObject('quality_assurance'), statusObject('submitted'), statusObject('writing'), statusObject('reviewing')]
    }, {
      id: 'done',
      phrase: 'content.stages.done',
      statuses: [statusObject('written'), statusObject('published')]
    }, {
      id: 'concluded',
      phrase: 'content.stages.concluded',
      archive: true,
      calendar: false,
      statuses: [statusObject('canceled', { archive: true, calendar: false }), statusObject('closed', { archive: true, calendar: false })]
    }],
    statuses: function statuses() {
      var _ref;

      return (_ref = []).concat.apply(_ref, _toConsumableArray(this.get('stages').map(function (stage) {
        return stage.statuses;
      })));
    }
  });
});