define('postedin/routes/editors/analyze-accounts', ['exports', 'moment', 'npm:csv-stringify'], function (exports, _moment, _npmCsvStringify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    accounts: Ember.inject.service(),
    model: function model() {
      return this.get('accounts').analyze();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var csv = [];

      model.forEach(function (organizationAccounts) {
        organizationAccounts.accounts.forEach(function (accountStats) {
          accountStats.stats.months.forEach(function (stats) {
            var row = {
              organization: organizationAccounts.organization.name.trim(),
              account: accountStats.account.name.trim(),
              month: (0, _moment.default)(stats.date).format('MMMM, YYYY')
            };

            Object.keys(stats).forEach(function (stat) {
              if (stat !== 'date') {
                row[stat] = stats[stat];
              }
            });

            csv.push(row);
          });
        });
      });

      controller.set('csv');
      (0, _npmCsvStringify.default)(csv, function (err, csv) {
        controller.set('csv', escape(csv));
        controller.set('csvFilename', 'postedin-analyze-accounts-' + (0, _moment.default)().format('DD-MM-YYYY') + '.csv');
      });
    }
  });
});