define('postedin/routes/editors/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    api: Ember.inject.service(),
    model: function model() {
      return this.get('api').all('projects?inactive=1');
    },
    setupController: function setupController(controller, _ref) {
      var data = _ref.data;

      var model = new Map();

      data.forEach(function (project) {
        var organization = project.get('organization');

        if (!model.has(organization.id)) {
          model.set(organization.id, {
            organization: organization,
            projects: []
          });
        }

        model.get(organization.id).projects.push(project);
      });

      controller.set('model', model);
    }
  });
});