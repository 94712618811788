define('postedin/components/project-user-roles/picker', ['exports', 'postedin/mixins/scroll-to'], function (exports, _scrollTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_scrollTo.default, {
    customOnly: false,
    isCustomPreset: Ember.computed.equal('activePreset.id', 'custom'),
    didInsertElement: function didInsertElement() {
      this.restorePreset(this.get('projectUser.roles'));
    },
    restorePreset: function restorePreset(userRoles) {
      var presets = this.get('presets');
      var roles = Object.keys(userRoles);
      var preset = void 0;

      if (!roles.length) {
        preset = presets.find(function (preset) {
          return preset.id === 'client';
        });
      } else {
        preset = presets.find(function (preset) {
          if (preset.roles.length !== roles.length) {
            return false;
          }

          roles.forEach(function (role) {
            if (!preset.roles.contains(role)) {
              return false;
            }
          });

          return true;
        });
      }

      if (!preset) {
        preset = presets.find(function (preset) {
          return preset.id === 'custom';
        });
      }

      this.selectPreset(preset);
    },

    presets: Ember.computed(function () {
      return [{
        id: 'client',
        icon: 'icon-eye',
        roles: []
      }, {
        id: 'content-suggestor',
        icon: 'icon-lamp5',
        roles: ['suggestContent']
      }, {
        id: 'content-planner',
        icon: 'icon-calendar2',
        roles: ['viewContentRequests', 'requestContent', 'judgeSuggestedContent', 'viewContentComments', 'viewCreditsUsageAndBalance']
      }, {
        id: 'content-editor',
        icon: 'icon-file-check',
        roles: ['viewContentRequests', 'viewContentComments', 'viewLatestContentRevision', 'viewAllContentRevisions', 'createContentRevisionNotes', 'viewContentRevisionComments', 'judgeContentRevisions']
      }, {
        id: 'publisher',
        icon: 'icon-file-upload',
        roles: ['viewContentRequests', 'viewLatestContentRevision', 'publishContent']
      }, {
        id: 'content-manager',
        icon: 'icon-folder',
        roles: ['viewContentRequests', 'suggestContent', 'requestContent', 'judgeSuggestedContent', 'viewLatestContentRevision', 'viewAllContentRevisions', 'viewContentComments', 'judgeContentRevisions', 'createContentRevisionNotes', 'viewContentRevisionComments', 'publishContent', 'viewCreditsUsageAndBalance']
      }, {
        id: 'project-manager',
        icon: 'icon-shield',
        roles: ['updateProfile', 'viewContentRequests', 'suggestContent', 'requestContent', 'judgeSuggestedContent', 'viewLatestContentRevision', 'viewAllContentRevisions', 'viewContentComments', 'judgeContentRevisions', 'createContentRevisionNotes', 'viewContentRevisionComments', 'publishContent', 'viewCreditsUsageAndBalance']
      }, {
        id: 'custom',
        icon: 'icon-cog',
        roles: []
      }];
    }),
    projectUserRolesDidChange: Ember.observer('lastChanged', function () {
      var _this = this;

      var roles = Object.keys(this.get('projectUser.roles')).filter(function (role) {
        return _this.get('projectUser.roles.' + role);
      });

      Ember.set(this.customPreset(), 'roles', roles);
    }),
    customOnlyDidChange: Ember.on('init', Ember.observer('customOnly', function () {
      if (this.get('customOnly')) {
        this.selectPreset(this.customPreset());
      }
    })),
    setPresetRoles: function setPresetRoles(roles) {
      this.send('setAllRoles', this.get('projectUser'), false);
      this.send('setAllRoles', this.get('projectUser'), true, roles);
    },
    selectPreset: function selectPreset(preset) {
      var _this2 = this;

      var roles = preset.roles.map(function (role) {
        return { name: role };
      });

      this.set('activePreset', preset);

      if (preset.id === 'custom') {
        Ember.run.next(function () {
          return _this2.scrollTo(_this2.$().find('#CustomPresetButton'), true);
        });
      } else {
        this.setPresetRoles(roles);
      }
    },
    customPreset: function customPreset() {
      return this.get('presets').filter(function (p) {
        return p.id === 'custom';
      })[0];
    },

    actions: {
      setAllRoles: function setAllRoles(user) {
        var checked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var only = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        var onlyRoles = false;
        if (only) {
          onlyRoles = only.map(function (o) {
            return o.name;
          });
        }

        Object.keys(user.get('roles')).forEach(function (role) {
          if (!onlyRoles || onlyRoles.includes(role)) {
            user.set('roles.' + role, checked);
          }
        });
        this.set('lastChanged', new Date().getTime());
      },
      setRole: function setRole(user, role, event) {
        user.set('roles.' + role, event.target.checked);
        this.set('lastChanged', new Date().getTime());
      },
      selectPreset: function selectPreset(preset) {
        this.selectPreset(preset);
      },
      selectCustomPreset: function selectCustomPreset() {
        var _this3 = this;

        // next because the button doing this is inside the button to select a different role
        // and that code runs overriding this
        Ember.run.next(function () {
          return _this3.selectPreset(_this3.customPreset());
        });
      }
    }
  });
});