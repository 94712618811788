define('postedin/components/updated-users-widget', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    betaUsers: Ember.inject.service(),
    actions: {
      confirmUser: function confirmUser(user) {
        user.set('saving', true);
        this.get('betaUsers').confirm(user).then(function () {
          user.set('saving', false);
          user.set('saved', true);
        });
      }
    }
  });
});