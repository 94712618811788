define('postedin/components/content-request-article/revision', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    imagesCount: Ember.computed('revision.attachments.length', 'revision.uploads.length', function () {
      return this.getWithDefault('revision.attachments.length', 0) + this.getWithDefault('revision.uploads.length', 0);
    }),
    evenImages: Ember.computed('revision.attachments.length', function () {
      return this.get('revision.attachments').filter(function (_, i) {
        return i % 2 === 0;
      });
    }),
    oddImages: Ember.computed('revision.attachments.length', function () {
      return this.get('revision.attachments').filter(function (_, i) {
        return i % 2;
      });
    }),
    annotationFormMessage: Ember.computed('revision.status', function () {
      if (this.get('revision.status') === 'pending') {
        return this.get('i18n').t('remember_that_you_need_to_reject_this_revision_in_order_for_any_changes_to_be_requested');
      }

      return false;
    }),
    // HACK: this is to force the dynamic compile to rerender
    hackyHideExpiredWarning: false,
    showExpiredWarning: Ember.computed('revision.expiringWarningTemplate', 'hackyHideExpiredWarning', function () {
      if (this.get('hackyHideExpiredWarning')) {
        return false;
      }

      return this.get('revision.expiringWarningTemplate');
    }),
    expiringWarningTemplateDidChange: Ember.observer('revision.expiringWarningTemplate', function () {
      var _this = this;

      this.set('hackyHideExpiredWarning', true);
      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.set('hackyHideExpiredWarning', false);
      });
    }),
    model: Ember.computed.or('review', 'revision'),
    annotations: Ember.computed.union('review.annotations', 'revision.annotations'),
    comments: Ember.computed.or('review.comments', 'revision.comments'),
    showSidebar: Ember.computed.or('revision.canViewComments', 'review'),
    canCreateComment: Ember.computed.or('revision.canCreateComment', 'review.canComment'),
    canCreateAnnotation: Ember.computed.or('revision.canCreateAnnotation', 'review.canCreateAnnotation'),
    cantAnnotateMessage: Ember.computed('revision.canCreateAnnotation', 'contentRequest.project.canCreateContentRevisionNotes', function () {
      if (!this.get('revision.canCreateAnnotation') && this.get('contentRequest.project.canCreateContentRevisionNotes')) {
        return this.get('i18n').t('can_only_annotate_on_the_latest_pending_revision');
      }

      return false;
    }),
    saveAnnotation: function saveAnnotation(comment, selectedText, location, promise) {
      var _this2 = this;

      var annotate = this.get('model').annotate(comment, selectedText, location);

      annotate.then(function (annotation) {
        _this2.get('annotations').pushObject(annotation);
        _this2.get('comments').pushObject(annotation.get('comment'));
        promise.resolve();
      }, promise.reject);
    },

    actions: {
      reloadComments: function reloadComments() {
        this.get('model').reload();
      },
      showDiffDialog: function showDiffDialog() {
        this.set('showDiff', true);
      },
      saveComment: function saveComment(comment, attachments, promise) {
        var _this3 = this;

        this.get('model').comment(comment, attachments).then(function (comment) {
          comment.flashHighlight();
          _this3.get('model').get('comments').pushObject(comment);
          promise.resolve();
        }, promise.reject);
      },
      saveAnnotation: function saveAnnotation(comment, selectedText, startNode, endNode, startOffset, endOffset, promise) {
        var guest = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;

        var location = {
          selectedText: selectedText, startNode: startNode, endNode: endNode, startOffset: startOffset, endOffset: endOffset
        };

        this.saveAnnotation(comment, selectedText, location, promise, guest);
      },
      saveImageAnnotation: function saveImageAnnotation(comment, imageHtml, imageIndex, promise) {
        var location = {
          imageIndex: imageIndex
        };

        this.saveAnnotation(comment, imageHtml, location, promise);
      },
      deselectAnnotation: function deselectAnnotation() {
        this.set('selectedAnnotation');
      }
    }
  }).reopenClass({
    positionalParams: ['revision']
  });
});