define('postedin/components/audit-log-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AuditLogListComponent = Ember.Component.extend({});

  AuditLogListComponent.reopenClass({
    positionalParams: ['logs']
  });

  exports.default = AuditLogListComponent;
});