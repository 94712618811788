define('postedin/models/client-transaction', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apiUrl: 'client-transactions',
    in: Ember.computed.equal('type', 'transfer.in'),
    out: Ember.computed.equal('type', 'transfer.out')
  });
});