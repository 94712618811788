define('postedin/components/blaze-field', ['exports', 'blaze-components/components/blaze-field'], function (exports, _blazeField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _blazeField.default;
    }
  });
});