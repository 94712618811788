define('postedin/components/content-request-card', ['exports', 'postedin/helpers/content-request-stage'], function (exports, _contentRequestStage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ContentRequestCard = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    contentTypeName: Ember.computed('contentRequest.type.name', function () {
      var type = this.get('contentRequest.type.name').dasherize();
      if (type === 'legacy-article') {
        return 'article';
      }

      return type;
    }),
    contentTypePhrase: Ember.computed('contentRequest.type.name', function () {
      var type = this.get('contentRequest.type.name').dasherize();
      if (type === 'legacy-article') {
        return this.get('contentRequest.type.legacyContentType.name');
      }

      return this.get('i18n').t('content-request-type.' + this.get('contentTypeName'));
    }),
    showDueDate: Ember.computed('contentRequest.dueDate', 'contentRequest.status', function () {
      var contentRequest = this.get('contentRequest');
      var stage = (0, _contentRequestStage.contentRequestStage)(contentRequest.status);
      if (stage === 'planning' || stage === 'content') {
        return !!contentRequest.get('dueDate');
      }

      return false;
    }),
    showReplyToContinue: Ember.computed('session.user.tempType', 'contentRequest.status', function () {
      return this.get('contentRequest.status') === 'checking_sources' && this.get('session.user.tempType') === 'partner';
    }),
    showLastRevisionLink: Ember.computed('contentRequest.type.revisionCount', 'contentRequest.project.isEditor', function () {
      return this.get('contentRequest.type.revisionCount') && this.get('contentRequest.project.isEditor');
    }),
    isDone: Ember.computed.match('contentRequest.status', /written|published/),
    showRating: Ember.computed.and('contentRequest.rating', 'isDone'),
    // Rob: I have no idea why this is checking status
    showPublishButton: Ember.computed.and('contentRequest.canPublish', 'contentRequest.status'),
    explanationText: Ember.computed('contentRequest.status', function () {
      switch (this.get('contentRequest.status')) {
        case 'requested':
          return this.get('i18n').t('waiting_creation') + '...';
        case 'checking_sources':
          return this.get('i18n').t('checking_sources') + '...';
        case 'searching':
          return this.get('i18n').t('searching_writer') + '...';
        case 'assigned':
          return this.get('i18n').t('waiting_revision') + '...';
        case 'quality_assurance':
          return this.get('i18n').t('waiting_qa_check') + '...';
        case 'writing':
          return this.get('i18n').t('waiting_correction') + '...';
        case 'submitted':
        case 'reviewing':
          return this.get('i18n').t('waiting_review') + '...';
        case 'published':
          return this.get('i18n').t('published') + ' :)';
      }

      return '';
    })
  });

  ContentRequestCard.reopenClass({
    positionalParams: ['contentRequest']
  });

  exports.default = ContentRequestCard;
});