define('postedin/models/content-article', ['exports', 'postedin/models/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    contentArticles: Ember.inject.service(),
    actionRequired: Ember.computed('status', 'current', function () {
      return this.get('status') === 'pending' && this.get('current');
    }),
    loadRevisions: function loadRevisions() {
      return this.get('contentArticles').revisions(this, this.get('legacy'));
    },
    createNewRevision: function createNewRevision(title, body, metaDescription, uploads, attachments) {
      return this.get('contentArticles').createNewRevision(this, title, body, metaDescription, uploads, attachments, this.get('legacy'));
    }
  });
});