define('postedin/helpers/accounting-negative', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.accountingNegative = accountingNegative;
  function accountingNegative(params /* , hash*/) {
    var number = parseInt(params[0]);

    if (number < 0) {
      return '(' + -number + ')';
    }

    return number;
  }

  exports.default = Ember.Helper.helper(accountingNegative);
});