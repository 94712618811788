define('postedin/routes/chile-payments/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    betaUsers: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('betaUsers').getPayableFreelancers(params.user ? 1 : null, !!params.user).then(function (model) {
          if (params.user) {
            model.users = model.users.filter(function (user) {
              return user.id === params.user;
            });

            if (model.users.length) {
              model.editableName = true;
              model.name = model.name + ': #' + model.users[0].id + ' ' + model.users[0].name;
              model.singleUser = true;

              resolve(model);
            } else {
              reject({ status: 404 });
            }

            return;
          }

          resolve(model);
        });
      });
    },
    setupController: function setupController(controller, hash) {
      var model = {
        users: hash.users.map(function (user) {
          user = Ember.copy(user);
          user.averageRating = user.averageRating.toFixed(2);
          return user;
        }),
        missionCount: hash.users.reduce(function (previous, current) {
          return previous + current.missionCount;
        }, 0),
        earnings: hash.users.reduce(function (previous, current) {
          return previous + current.earnings;
        }, 0)
      };
      model.averageEarnings = Math.round(model.earnings / model.missionCount);
      var ratings = hash.users.reduce(function (previous, current) {
        return previous + current.averageRating;
      }, 0);
      model.averageRating = (ratings / model.users.length).toFixed(2);

      this._super(controller, model);

      controller.set('name', hash.name);
      controller.set('editableName', hash.editableName);
      controller.set('billableUntil', hash.billableUntil);

      controller.set('confirmationModal', false);
      controller.set('sendingEmails', false);
      controller.set('emailsSent', false);
    }
  });
});