define('postedin/routes/admin-faq/new-category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('category', { type: 'private' });
      controller.set('creatingQuestion', false);
      controller.set('creatingError', false);
      controller.set('confirmationModal', false);
    }
  });
});