define('postedin/controllers/admin-content-types/show/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    contentTypes: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        this.set('saving', true);
        return this.get('contentTypes').update(this.get('model')).then(function (respond) {
          _this.transitionToRoute('admin-content-types.show', respond);
        }, function () {
          return _this.set('saving', false);
        });
      },
      confirmationModal: function confirmationModal() {
        this.set('confirmationModal', !this.get('confirmationModal'));
      },
      delete: function _delete() {
        var _this2 = this;

        this.set('saving', true);
        return this.get('contentTypes').remove(this.get('model')).then(function () {
          _this2.transitionToRoute('admin-content-types');
        }, function () {
          return _this2.set('saving', false);
        });
      }
    }
  });
});