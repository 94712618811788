define('postedin/components/time-input', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var padZero = function padZero(number) {
    return ('0' + number).slice(-2);
  };

  var hours = [].concat(_toConsumableArray(Array(24).keys())).map(function (h) {
    return padZero(h);
  });
  var minutes = [].concat(_toConsumableArray(Array(60).keys())).map(function (m) {
    return m < 10 ? '0' + m : m;
  });

  exports.default = Ember.Component.extend({
    hours: hours,
    minutes: minutes,
    timeDidChange: Ember.observer('hour', 'minute', function () {
      var _this = this;

      Ember.run.next(function () {
        var value = '';
        if (_this.get('hour')) {
          value = _this.get('hour') + ':' + _this.get('minute');
        }

        _this.set('value', value);
      });
    }),
    valueDidChange: Ember.on('init', Ember.observer('value', function () {
      var _this2 = this;

      Ember.run.next(function () {
        var value = (0, _moment.default)(_this2.get('value'), 'HH:mm');
        if (!value.isValid()) {
          _this2.set('hour', '');
          _this2.set('minute', '00');

          return;
        }

        var hour = padZero(value.hour());
        if (hour !== _this2.get('hour')) {
          _this2.set('hour', hour);
        }

        var minute = padZero(value.minute());
        if (minute !== _this2.get('minute')) {
          _this2.set('minute', minute);
        }
      });
    }))
  });
});