define('postedin/controllers/admin-faq/questions/show/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    faq: Ember.inject.service(),
    model: function model() {
      return this.modelFor('admin-faq.questions.show');
    },

    actions: {
      updateQuestion: function updateQuestion() {
        var _this = this;

        var question = this.get('model');
        this.set('updatingQuestion', true);
        this.get('faq').update(question).then(function () {
          _this.set('updatingQuestion', false);
          _this.transitionToRoute('admin-faq.questions.show', question);
        }, function () {
          _this.set('updatingQuestion', false);
          _this.set('updatingError', true);
        });
      }
    }
  });
});