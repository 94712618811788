define('postedin/components/content-filters', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_uiPromise.default, {
    session: Ember.inject.service(),
    organizationRepository: Ember.inject.service('organizations'),
    legacyContentTypeGroupRepository: Ember.inject.service('legacyContentTypeGroups'),
    store: Ember.inject.service('simple-store'),
    statusGroups: Ember.inject.service(),
    organizations: [],
    projectsFilter: [],
    projectLabelsFilter: [],
    legacyContentTypeGroupsFilter: [],
    search: '',
    statuses: [],
    revisions: '',
    hasRejectedReviews: 0,
    issue: 0,
    dueSoon: 0,
    approveRequiresAttention: 0,
    rejectRequiresAttention: 0,
    ratings: [],
    includeArchived: false,
    advanced: false,
    showAdvanced: Ember.computed.or('advanced', 'issue', 'dueSoon', 'approveRequiresAttention', 'rejectRequiresAttention', 'ratings.length'),
    showArchived: Ember.computed.oneWay('includeArchived'),
    hideArchived: Ember.computed('showArchived', 'calendar', function () {
      if (this.get('calendar')) {
        return false;
      }

      return !this.get('showArchived');
    }),
    showEditorFilters: Ember.computed('session.user.tempType', function () {
      return this.get('session.user.tempType') !== 'partner';
    }),
    showReviewFilters: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.uiPromise(this.loadLegacyContentTypeGroups(), 'legacyContentTypeGroupsLoading');

      if (!this.get('singleOrganization') && !this.get('singleProject')) {
        // if we already have projects no need to block the UI with loading,
        // it will just update it if it finds anything different
        var organizations = this.get('store').find('organization').sortBy('name');
        if (Ember.get(organizations, 'length')) {
          // all organizations have projects key
          if (!organizations.filter(function (o) {
            return !Ember.get(o, 'projects');
          }).length) {
            this.set('organizations', organizations);
          }
        }

        if (this.get('organizations.length')) {
          this.loadProjects();
        } else {
          this.uiPromise(this.loadProjects(), 'projectsLoading');
        }
      }
    },

    statusesDidChange: Ember.on('init', Ember.observer('statuses.@each', function () {
      var statusFilter = this.get('statuses');
      this.get('statusGroups').statuses().forEach(function (status) {
        Ember.set(status, 'checked', statusFilter.includes(Ember.get(status, 'id')));
      });
    })),
    searchChanged: Ember.on('init', Ember.observer('search', function () {
      var search = this.get('search');
      if (search !== this.get('newSearch')) {
        this.set('newSearch', search);
      }
    })),
    projectLabelsFilterDidChange: Ember.on('init', Ember.observer('projectLabelsFilter.length', function () {
      if (this.get('singleProject')) {
        var project = this.get('singleProject');
        var labelIds = this.get('projectLabelsFilter').map(function (l) {
          return parseInt(l);
        });

        Ember.set(project, 'selectedLabels', Ember.get(project, 'labels').filter(function (label) {
          return labelIds.includes(Ember.get(label, 'id'));
        }));
      }
    })),
    selectedProjects: Ember.computed('organizations.length', 'projectsFilter.length', 'projectLabelsFilter.length', function () {
      var projectIds = this.get('projectsFilterIds');
      var labelIds = this.get('projectLabelsFilter').map(function (l) {
        return parseInt(l);
      });

      var selected = [];
      this.get('organizations').forEach(function (organization) {
        var projects = Ember.get(organization, 'projects').filter(function (project) {
          return projectIds.includes(Ember.get(project, 'id'));
        }).map(function (project) {
          Ember.set(project, 'selectedLabels', Ember.get(project, 'labels').filter(function (label) {
            return labelIds.includes(Ember.get(label, 'id'));
          }));

          return project;
        });

        if (projects.length) {
          selected.push({
            organization: organization,
            projects: projects
          });
        }
      });

      return selected;
    }),
    selectedLegacyContentTypeGroups: Ember.computed('legacyContentTypeGroupsFilter.length', 'legacyContentTypeGroupOptions.length', function () {
      var selectedGroupIds = this.get('legacyContentTypeGroupsFilter');
      var groups = this.get('legacyContentTypeGroupOptions');

      if (!groups) {
        return [];
      }

      return selectedGroupIds.map(function (groupId) {
        return groups.find(function (group) {
          return group.id === parseInt(groupId);
        });
      }).filter(function (g) {
        return g;
      });
    }),
    filterProjectLabelOptions: Ember.computed('organizations.length', 'projectLabelsFilter.length', function () {
      var labelIds = this.get('projectLabelsFilter').map(function (l) {
        return parseInt(l);
      });
      var options = [];
      this.get('organizations').forEach(function (organization) {
        Ember.get(organization, 'projects').forEach(function (project) {
          options.push({
            groupName: Ember.get(organization, 'name') + ' / ' + Ember.get(project, 'name'),
            options: Ember.get(project, 'labels').filter(function (label) {
              return !labelIds.includes(Ember.get(label, 'id'));
            })
          });
        });
      });

      return options.filter(function (group) {
        return group.options.length;
      });
    }),
    // this is used because we need the project ids from the input and also all the projects from the filtered labels
    projectsFilterIds: Ember.computed('organizations.length', 'projectsFilter.length', 'projectLabelsFilter.length', function () {
      var projectIds = this.get('projectsFilter').map(function (p) {
        return parseInt(p);
      });
      var labelIds = this.get('projectLabelsFilter').map(function (l) {
        return parseInt(l);
      });

      this.get('organizations').forEach(function (organization) {
        Ember.get(organization, 'projects').forEach(function (project) {
          Ember.get(project, 'labels').forEach(function (label) {
            if (labelIds.includes(Ember.get(label, 'id'))) {
              projectIds.push(Ember.get(project, 'id'));
            }
          });
        });
      });

      return projectIds.uniq();
    }),
    ratingInputs: Ember.computed('ratings.length', function () {
      var ratings = this.get('ratings').map(function (n) {
        return parseInt(n);
      });
      return [].concat(_toConsumableArray(Array(5).keys())).map(function (index) {
        var rating = index + 1;
        return {
          checked: ratings.includes(rating),
          number: rating
        };
      });
    }),
    loadProjects: function loadProjects() {
      var _this = this;

      return this.get('organizationRepository').allWithProjects().then(function (organizations) {
        _this.set('organizations', organizations);
      });
    },
    loadLegacyContentTypeGroups: function loadLegacyContentTypeGroups() {
      var _this2 = this;

      return this.get('legacyContentTypeGroupRepository').all().then(function (groups) {
        _this2.set('legacyContentTypeGroupOptions', groups);
      });
    },
    searchProjectLabels: function searchProjectLabels(label, term) {
      term = term.toUpperCase();
      var findLabelName = Ember.get(label, 'name').searchable().indexOf(term);

      if (findLabelName === -1) {
        var findProjectName = Ember.get(label, 'project.name').searchable().indexOf(term);
        if (findProjectName === -1) {
          return Ember.get(label, 'project.organization.name').searchable().indexOf(term);
        }

        return findProjectName;
      }

      return findLabelName;
    },
    refreshContent: function refreshContent() {
      // because things get buggy with arrays we workaround by changing a timestamp which will be sent to
      // this as `bust` and cause things to refresh
      this.set('bust', new Date().getTime());
      this.set('page', null);

      this.$().find('input, select').blur();
    },
    filterProject: function filterProject(project) {
      if (!project) {
        return;
      }

      var projects = this.get('projectsFilter');
      projects.push(Ember.get(project, 'id'));
      this.set('projectsFilter', projects.uniq());

      this.set('selectedProject');
    },
    removeProjectLabelFilter: function removeProjectLabelFilter(label) {
      var labels = this.get('projectLabelsFilter');
      this.set('projectLabelsFilter', labels.without(Ember.get(label, 'id')).without(Ember.get(label, 'id') + '').uniq());
    },
    removeLegacyContentTypeGroupFilter: function removeLegacyContentTypeGroupFilter(group) {
      var groups = this.get('legacyContentTypeGroupsFilter');
      this.set('legacyContentTypeGroupsFilter', groups.without(Ember.get(group, 'id')).without(Ember.get(group, 'id') + '').uniq());
    },
    filterStatuses: function filterStatuses() {
      var statuses = this.get('statusGroups').statuses().filter(function (status) {
        return Ember.get(status, 'checked');
      });

      this.set('statuses', statuses.map(function (s) {
        return Ember.get(s, 'id');
      }));

      this.refreshContent();
    },

    actions: {
      search: function search(newSearch) {
        this.set('search', newSearch);

        this.refreshContent();
      },
      clearSearch: function clearSearch() {
        this.set('search', '');

        this.refreshContent();
      },
      filterProject: function filterProject(project) {
        this.filterProject(project);

        this.refreshContent();
      },
      removeProjectFilter: function removeProjectFilter(project) {
        var _this3 = this;

        var projects = this.get('projectsFilter');
        this.set('projectsFilter', projects.without(Ember.get(project, 'id')).without(Ember.get(project, 'id') + '').uniq());

        Ember.get(project, 'selectedLabels').forEach(function (label) {
          _this3.removeProjectLabelFilter(label);
        });

        this.refreshContent();
      },
      filterProjectLabel: function filterProjectLabel(label) {
        if (!label) {
          return;
        }

        var labels = this.get('projectLabelsFilter');
        labels.push(Ember.get(label, 'id'));
        this.set('projectLabelsFilter', labels.uniq());

        this.refreshContent();
      },
      removeProjectLabelFilter: function removeProjectLabelFilter(label) {
        this.removeProjectLabelFilter(label);

        // this works weird to the user if the project doesn't remain
        this.filterProject(Ember.get(label, 'project'));

        this.refreshContent();
      },
      filterLegacyContentTypeGroup: function filterLegacyContentTypeGroup(group) {
        if (!group) {
          return;
        }

        var groups = this.get('legacyContentTypeGroupsFilter');
        groups.push(Ember.get(group, 'id'));
        this.set('legacyContentTypeGroupsFilter', groups.uniq());

        this.refreshContent();
      },
      removeLegacyContentTypeGroupFilter: function removeLegacyContentTypeGroupFilter(group) {
        this.removeLegacyContentTypeGroupFilter(group);

        this.refreshContent();
      },
      toggleStatusGroup: function toggleStatusGroup(statusGroup) {
        var unchecked = statusGroup.statuses.filter(function (s) {
          return !Ember.get(s, 'checked');
        }).length;

        statusGroup.statuses.forEach(function (status) {
          Ember.set(status, 'checked', !!unchecked);
        });

        this.filterStatuses();
      },
      filterStatus: function filterStatus() {
        var _this4 = this;

        Ember.run.later(function () {
          return _this4.filterStatuses();
        });
      },
      filterRevisions: function filterRevisions(revisions) {
        var validRevisions = ['0', '1', '2plus'];

        if (validRevisions.includes(revisions)) {
          this.set('revisions', revisions);
        } else {
          this.set('revisions', '');
        }

        this.refreshContent();
      },
      filterHasRejectedReviews: function filterHasRejectedReviews(checked) {
        this.set('hasRejectedReviews', checked ? 1 : 0);
        this.refreshContent();
      },
      filterIssue: function filterIssue(checked) {
        this.set('issue', checked ? 1 : 0);
        this.refreshContent();
      },
      filterDueSoon: function filterDueSoon(checked) {
        this.set('dueSoon', checked ? 1 : 0);
        this.refreshContent();
      },
      filterApproveRequiresAttention: function filterApproveRequiresAttention(checked) {
        this.set('approveRequiresAttention', checked ? 1 : 0);
        this.refreshContent();
      },
      filterRejectRequiresAttention: function filterRejectRequiresAttention(checked) {
        this.set('rejectRequiresAttention', checked ? 1 : 0);
        this.refreshContent();
      },
      filterRating: function filterRating(rating, event) {
        var ratings = [].concat(this.get('ratings')).map(function (n) {
          return parseInt(n);
        });

        if (event.target.checked) {
          ratings.push(rating);
        } else {
          ratings = ratings.without(rating);
        }

        this.set('ratings', ratings.uniq());
        this.refreshContent();
      },
      includeArchived: function includeArchived() {
        var _this5 = this;

        Ember.run.later(function () {
          _this5.set('includeArchived', _this5.get('showArchived') ? 1 : 0);

          var statuses = _this5.get('statusGroups').statuses();

          if (_this5.get('includeArchived')) {
            // if at least 1 status has been filtered then lets filter the archived ones too
            var isFiltered = statuses.filter(function (status) {
              return Ember.get(status, 'checked');
            }).length;

            if (isFiltered) {
              statuses.forEach(function (status) {
                if (Ember.get(status, 'archive')) {
                  Ember.set(status, 'checked', true);
                }
              });

              _this5.filterStatuses();
            } else {
              _this5.refreshContent();
            }

            return;
          }

          statuses.forEach(function (status) {
            if (Ember.get(status, 'archive')) {
              Ember.set(status, 'checked', false);
            }
          });

          _this5.refreshContent();
        });
      },
      toggleAdvanced: function toggleAdvanced() {
        this.toggleProperty('advanced');
      }
    }
  });
});