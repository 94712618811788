define('postedin/components/content-type-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'i',
    classNameBindings: ['icon'],
    icon: Ember.computed('type', function () {
      var icons = {
        article: 'icon-blog',
        listicle: 'icon-list'
      };

      return icons[this.get('type')] || 'icon-file-text2';
    })
  });
});