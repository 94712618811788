define('postedin/models/setting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    settings: Ember.inject.service(),
    updatedValue: Ember.computed.oneWay('value'),
    dirty: Ember.computed('value', 'updatedValue', function () {
      var _getProperties = this.getProperties('value', 'updatedValue'),
          value = _getProperties.value,
          updatedValue = _getProperties.updatedValue;

      if (Number.isInteger(value)) {
        updatedValue = parseInt(updatedValue);
      }

      return value !== updatedValue;
    }),
    update: function update(value) {
      return this.get('settings').update(this, value);
    }
  });
});