define('postedin/mixins/validator', ['exports', 'npm:validatorjs'], function (exports, _npmValidatorjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _npmValidatorjs.default.prototype._addFailure = function (rule) {
    this.errors.add(rule.attribute, rule.name);
    this.errorCount++;
  };

  exports.default = Ember.Mixin.create({
    validator: function validator(data, rules, customMessages) {
      return new _npmValidatorjs.default(data, rules, customMessages);
    },
    validatorCheck: function validatorCheck(data, rules, customMessages) {
      var errorProperty = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'errors';

      var validator = this.validator(data, rules, customMessages);

      this.set(errorProperty, {});

      if (validator.fails()) {
        this.withErrors(validator, errorProperty);

        return false;
      }

      return true;
    },
    withErrors: function withErrors(errors) {
      var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'errors';

      if (errors instanceof _npmValidatorjs.default) {
        this.set(property + 'Count', errors.errorCount);
        errors = errors.errors;
      }

      this.set(property, this.computableErrors(errors));
    },
    computableErrors: function computableErrors(errors) {
      var errorsHash = Ember.Object.create({});
      Object.keys(errors.all()).forEach(function (field) {
        var crawl = [];
        field.split('.').forEach(function (part) {
          crawl.push(part);
          var property = crawl.join('.');
          if (!errorsHash.get(property)) {
            errorsHash.set(property, {});
          }
        });

        errors.get(field).forEach(function (error) {
          errorsHash.set(field + '.' + error, true);
        });
      });

      return errorsHash;
    },
    serverFailureToValidatorErrors: function serverFailureToValidatorErrors(fail) {
      var _this = this;

      var errorsProperty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'errors';

      if (fail.payload.status !== 422 || !fail.payload.errors) {
        return;
      }

      Object.keys(fail.payload.errors).forEach(function (key) {
        var type = fail.payload.errors[key][0].split(';')[0];

        if (type.startsWith('validation.')) {
          if (!_this.get(errorsProperty + '.' + key)) {
            _this.set(errorsProperty + '.' + key, {});
          }

          type = type.replace('validation.', '');
          _this.set(errorsProperty + '.' + key + '.' + type, true);
        }
      });
    },
    setupErrorClearingObservers: function setupErrorClearingObservers(dataProperty, fields) {
      var _this2 = this;

      var errorsProperty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'errors';

      fields.forEach(function (property) {
        var observe = property;
        if (dataProperty) {
          observe = dataProperty + '.' + observe;
        }

        _this2.addObserver(observe, function () {
          var errorProperty = errorsProperty + '.' + property;
          var errors = this.get(errorProperty);
          if (!errors) {
            return;
          }

          var hasErrors = Object.keys(errors).filter(function (e) {
            return errors[e];
          }).length;
          if (!hasErrors) {
            return;
          }

          var errorsCountProperty = errorsProperty + 'Count';
          var errorsCount = this.get(errorsCountProperty);
          if (errorsCount && Number.isInteger(errorsCount) && errorsCount > 0) {
            this.decrementProperty(errorsCountProperty);
          }

          this.set(errorProperty, false);
        });
      });
    },
    scrollToFirstError: function scrollToFirstError() {
      var _this3 = this;

      Ember.run.later(function () {
        var $el = Ember.$(_this3.element || 'body');

        var searches = ['.form__validation-error', '.input.form__input--error', '.form__input--error', '.form__label--error',
        // legacy
        '.resource-form__input--error', '.resource-form__error'];

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = searches[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var search = _step.value;

            var $search = $el.find(search);

            if ($search.length) {
              Ember.$('html, body').animate({
                scrollTop: $search.first().offset().top - 20
              }, 100);

              return;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      });
    }
  });
});