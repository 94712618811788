define('postedin/routes/admin-release-notes/show/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('admin-release-notes.show');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('updatingReleaseNote', false);
      controller.set('updatingError', false);
    }
  });
});