define('postedin/services/accounts', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'account',
    findById: function findById(id) {
      var _this = this;

      return this.request('accounts/' + id).then(function (record) {
        return _this.push(record);
      });
    },
    update: function update(account, updates) {
      var _this2 = this;

      return this.put('accounts/' + account.id, updates).then(function (record) {
        return _this2.push(record);
      });
    },
    close: function close(account) {
      var _this3 = this;

      return this.post('accounts/' + account.id + '/closed').then(function (record) {
        return _this3.push(record);
      });
    },
    remove: function remove(account) {
      var _this4 = this;

      return this.delete('accounts/' + account.id).then(function () {
        return _this4.removeObject(account.id);
      });
    },
    analyze: function analyze(account) {
      if (account && account.id) {
        return this.request('accounts/' + account.id + '/analyze');
      }

      return this.request('accounts/analyze');
    },
    addCredits: function addCredits(account, credits, note) {
      var _this5 = this;

      return this.post('accounts/' + account.id + '/credits', { credits: credits, note: note }).then(function (record) {
        return _this5.push(record);
      });
    },
    transferCredits: function transferCredits(account, toAccount, credits, note) {
      var _this6 = this;

      var accountId = toAccount.id;

      return this.post('accounts/' + account.id + '/transfer', { accountId: accountId, credits: credits, note: note }).then(function (record) {
        return _this6.push(record);
      });
    },
    loadTransactions: function loadTransactions(account, page) {
      var _this7 = this;

      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      page = parseInt(page) || 1;
      if (perPage) {
        page += '&perpage=' + perPage;
      }

      return this.request('accounts/' + account.id + '/transactions?page=' + page).then(function (response) {
        var transactions = _this7.pushResponse(response, 'account-transaction');
        delete response.data;

        return { transactions: transactions, meta: response };
      });
    }
  });
});