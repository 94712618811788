define('postedin/services/revisions', ['exports', 'postedin/services/repository', 'npm:query-string'], function (exports, _repository, _npmQueryString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    find: function find(_ref) {
      var _this = this;

      var page = _ref.page,
          project = _ref.project,
          status = _ref.status,
          current = _ref.current;

      var query = _npmQueryString.default.stringify({
        page: parseInt(page) > 0 ? page : 1,
        project: project ? project.id : undefined,
        status: status,
        current: current ? 1 : undefined
      }, {
        arrayFormat: 'bracket'
      });

      return this.request('revisions?' + query).then(function (response) {
        var revisions = _this.pushResponse(response, false, true);
        delete response.data;

        return { revisions: revisions, meta: response };
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('revisions/' + id).then(function (record) {
        return _this2.push(record, false, true);
      });
    }
  });
});