define('postedin/components/toolbar-reviews-dropdown', ['exports', 'postedin/mixins/ui-promise'], function (exports, _uiPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uiPromise.default, {
    session: Ember.inject.service(),

    reviews: Ember.computed('revision.reviews', function () {
      return this.get('revision.reviews');
    }),

    revisionDidChange: Ember.on('init', Ember.observer('revision.id', function () {
      var _this = this;

      this.set('reviewersLoaded', false);
      this.get('users').then(function (users) {
        _this.set('reviewersLoaded', true);
        _this.set('reviewers', _this.formatUsers(users));
      });
    })),

    formatUsers: function formatUsers(users) {
      var _this2 = this;

      users = users.map(function (user) {
        // using the user model here is bad because this data can go over
        // to other revisions but still have a review set so need to unset it here
        user.set('review');
        user.set('requestReview');

        user.set('canRequestReview', true);
        _this2.get('reviews').forEach(function (review) {
          if (review.get('user.id') === user.get('id')) {
            user.set('review', review);
            user.set('canRequestReview', false);
          }

          if (_this2.get('session.user.id') == user.get('id') || _this2.get('revision.canRequestReview') == false) {
            user.set('canRequestReview', false);
          }
        });

        return user;
      });

      if (!this.get('revision.canRequestReview')) {
        users = users.filter(function (user) {
          return user.review;
        });
      }

      return users;
    },


    actions: {
      requestReview: function requestReview(reviewer) {
        this.uiPromise(this.get('revision').requestReview([reviewer]), 'requestReview', reviewer);
      }
    }
  });
});