define('postedin/routes/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    legacyTopics: Ember.inject.service(),
    model: function model(_ref) {
      var path = _ref.path;

      if (path.startsWith('p/')) {
        return this.redirectProject(path);
      }

      if (path.startsWith('o/')) {
        return this.redirectOrganization(path);
      }

      if (path.startsWith('c/')) {
        return this.redirectTopicsToContentRequests(path);
      }

      if (path.startsWith('content/') || path === 'content') {
        return this.transitionTo(path === 'content/calendar' ? 'calendar' : 'content-requests');
      }
    },
    hasRoute: function hasRoute(name) {
      return this.get('router.router.recognizer').hasRoute(name);
    },
    redirectProject: function redirectProject(path) {
      var parts = path.split('/');
      var id = parts[1].split('_');
      id = id.length > 1 ? id[1] : id[0];

      var routeName = 'project';
      if (parts.length > 2) {
        for (var i = 2; i < parts.length; i++) {
          routeName += '.' + parts[i];
        }
      }

      if (this.hasRoute(routeName)) {
        return this.transitionTo(routeName, id);
      }

      return this.transitionTo('project', id);
    },
    redirectOrganization: function redirectOrganization(path) {
      var parts = path.split('/');
      var id = parts[1].split('_');
      id = id.length > 1 ? id[1] : id[0];

      var routeName = 'organization';
      if (parts.length > 2) {
        for (var i = 2; i < parts.length; i++) {
          routeName += '.' + parts[i];
        }
      }

      if (this.hasRoute(routeName)) {
        return this.transitionTo(routeName, id);
      }

      return this.transitionTo('organization', id);
    },
    redirectTopicsToContentRequests: function redirectTopicsToContentRequests(path) {
      var _this = this;

      var parts = path.split('/');
      var id = parts[1].split('_');
      id = id.length > 1 ? id[1] : id[0];

      var promise = this.get('legacyTopics').findById(id);

      promise.then(function (topic) {
        if (topic.contentRequestId) {
          var routeName = 'content-requests.show';
          if (parts.length > 2) {
            for (var i = 2; i < parts.length; i++) {
              routeName += '.' + parts[i];
            }
          }

          if (_this.hasRoute(routeName)) {
            return _this.transitionTo(routeName, topic.contentRequestId);
          }

          return _this.transitionTo('content-requests.show', topic.contentRequestId);
        }
      });

      return promise;
    }
  });
});