define('postedin/helpers/calendar-weeks', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calendarWeeks = calendarWeeks;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function calendarWeeks(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        currentDate = _ref2[0],
        view = _ref2[1],
        holidays = _ref2[2];

    currentDate = (0, _moment.default)(currentDate);
    var startDate = void 0,
        endDate = void 0;

    switch (view) {
      case 'month':
        startDate = currentDate.clone().startOf('month').startOf('isoWeek');
        endDate = currentDate.clone().endOf('month').endOf('isoWeek');
        break;
      case 'week':
        startDate = currentDate.clone().startOf('isoWeek');
        endDate = currentDate.clone().endOf('isoWeek');
        break;
    }

    var weeks = {};
    var date = (0, _moment.default)(startDate);
    while (date <= endDate) {
      var weekIndex = date.format('YYYY-WW');
      if (!weeks[weekIndex]) {
        weeks[weekIndex] = {
          date: (0, _moment.default)(date),
          days: []
        };
      }

      var day = {
        date: (0, _moment.default)(date)
      };

      day.today = (0, _moment.default)().isSame(day.date, 'day');
      day.differentMonth = day.date.month() !== currentDate.month();
      day.holiday = holidays.includes(day.date.format('YYYY-MM-DD'));

      weeks[weekIndex].days.push(day);

      date.add(1, 'day');
    }

    return Object.keys(weeks).sort().map(function (w) {
      return weeks[w];
    });
  }

  exports.default = Ember.Helper.helper(calendarWeeks);
});