define('postedin/mixins/has-uploads', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this.set('uploads', []);
      this._super.apply(this, arguments);
    },

    isUploading: Ember.computed('uploads.@each.uploading', function () {
      var uploads = this.get('uploads');
      return uploads.isAny('uploading');
    })
  });
});