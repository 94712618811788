define('postedin/services/document', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    documentTitleList: Ember.inject.service(),
    title: function () {
      return this.get('documentTitleList').toString();
    }.property('documentTitleList.length')
  });
});