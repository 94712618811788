define('postedin/services/payments', ['exports', 'postedin/services/repository'], function (exports, _repository) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _repository.default.extend({
    model: 'payment',
    find: function find(type) {
      var _this = this;

      return this.request('payments?' + (type ? type : '')).then(function (response) {
        return _this.pushResponse(response);
      });
    },
    findById: function findById(id) {
      var _this2 = this;

      return this.request('payments/' + id).then(function (record) {
        return _this2.push(record);
      });
    },
    findInvoices: function findInvoices(params) {
      return this.request('payments/' + params.id + '/invoices').then(function (r) {
        return r.data;
      });
    },
    finish: function finish(payment) {
      var _this3 = this;

      return this.put('payments/' + payment.id + '/finish').then(function (record) {
        return _this3.push(record);
      });
    }
  });
});