define('postedin/components/list-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    list: [],
    unique: true,
    initialized: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.later(function () {
        return _this.valueToList(true);
      });
    },

    listLastObjectDidChange: Ember.observer('list.lastObject.value', function () {
      if (this.get('list.lastObject.value').trim()) {
        this.get('list').pushObject(Ember.Object.create({ value: '' }));
      }
    }),
    valueDidChange: Ember.on('init', Ember.observer('value', function () {
      this.valueToList();
    })),
    listDidChange: Ember.observer('list.@each.value', function () {
      this.set('value', this.listToArray(this.get('list')));
    }),
    valueToList: function valueToList() {
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var list = this.get('value');
      if (!Ember.isArray(list)) {
        list = [];
      }

      list = list.map(function (v) {
        return v.trim();
      }).filter(function (v) {
        return v;
      });
      var currentList = this.listToArray(this.get('list'));
      if (!force && currentList.join(';;') === list.join(';;')) {
        return;
      }

      list.push('');
      this.set('list', list.map(function (v) {
        return Ember.Object.create({ value: v });
      }));
    },
    listToArray: function listToArray(list) {
      if (!Ember.isArray(list)) {
        list = [];
      }

      list = list.map(function (i) {
        return i.get('value').trim();
      }).filter(function (v) {
        return v;
      });

      if (this.get('unique')) {
        list = list.uniq();
      }

      return list;
    }
  });
});