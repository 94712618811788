define('postedin/controllers/admin/legacy-content-types/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      create: function create() {
        this.save(this.get('model'));
      }
    },
    save: function save(type) {
      var _this = this;

      var input = type.getProperties('active', 'name', 'cost', 'words', 'pictures', 'summary', 'details', 'minimumDays');

      if (this.validateInput(input)) {
        this.set('saving', true);

        var activeHide = type.get('active');
        type.set('active', activeHide === 'yes');

        var typeHide = type.get('hide');
        type.set('hide', typeHide === 'yes');

        var importsSources = type.get('importsBetaSources');
        type.set('importsBetaSources', importsSources === 'yes');

        var typeIncludesMetaDescription = type.get('includesMetaDescription');
        type.set('includesMetaDescription', typeIncludesMetaDescription === 'yes');

        var typeIncludesExtraPictures = type.get('includesExtraPictures');
        type.set('includesExtraPictures', typeIncludesExtraPictures === 'yes');

        var typeDisableMetaDescription = type.get('disableMetaDescription');
        type.set('disableMetaDescription', typeDisableMetaDescription === 'yes');

        var typeDisableExtraPictures = type.get('disableExtraPictures');
        type.set('disableExtraPictures', typeDisableExtraPictures === 'yes');

        var typeComplex = type.get('complex');
        type.set('complex', typeComplex === 'yes');

        if (type.legacyContentTypeGroup) {
          type.set('legacyContentTypeGroupId', type.legacyContentTypeGroup.id);
        }

        type.save().then(function () {
          type.set('active', type.get('active') ? 'yes' : 'no');
          type.set('hide', type.get('hide') ? 'yes' : 'no');
          type.set('importsBetaSources', type.get('importsBetaSources') ? 'yes' : 'no');
          type.set('includesMetaDescription', type.get('includesMetaDescription') ? 'yes' : 'no');
          type.set('includesExtraPictures', type.get('includesExtraPictures') ? 'yes' : 'no');
          type.set('disableMetaDescription', type.get('disableMetaDescription') ? 'yes' : 'no');
          type.set('disableExtraPictures', type.get('disableExtraPictures') ? 'yes' : 'no');
          type.set('complex', type.get('complex') ? 'yes' : 'no');

          _this.transitionToRoute('admin.legacy-content-types');
        }, function () {
          return _this.set('saving', false);
        });
        type.set('hide', typeHide);
      }
    },
    resetErrors: function resetErrors() {
      this.set('nameRequired', false);
      this.set('costRequired', false);
      this.set('summaryRequired', false);
      this.set('detailsRequired', false);
      this.set('minimumDaysRequired', false);
    },
    validateInput: function validateInput(input) {
      this.resetErrors();
      var hasErrors = false;

      if (!input.name) {
        this.set('nameRequired', true);
        hasErrors = true;
      }

      if (!input.summary) {
        this.set('summaryRequired', true);
        hasErrors = true;
      }

      if (!input.details) {
        this.set('detailsRequired', true);
        hasErrors = true;
      }

      if (!input.cost) {
        this.set('costRequired', true);
        hasErrors = true;
      }

      if (!input.minimumDays) {
        this.set('minimumDaysRequired', true);
        hasErrors = true;
      }

      if (typeof parseInt(input.pictures) !== 'number') {
        this.set('picturesRequired', true);
        hasErrors = true;
      }

      return !hasErrors;
    }
  });
});