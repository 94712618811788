define('postedin/components/order-arrows', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    waiting: false,
    actions: {
      moveUp: function moveUp(value) {
        this.sendAction('moveUp', value);
      },
      moveDown: function moveDown(value) {
        this.sendAction('moveDown', value);
      }
    }
  });
});